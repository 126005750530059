<template>
  <div>
    <Header></Header>
    <div v-if="$store.state.user" class="tile is-parent">
      <div class="tile is-vertical container">
        <router-view></router-view>
      </div>
    </div>
    <b-loading v-else :active="true" type="primary" />
  </div>
</template>

<script>
import Header from './../components/header.vue';

export default {
  name: 'logged',
  components: { Header }
};
</script>

<style>
.container {
  max-width: 1200px;
  margin: auto;
  background-color: white !important;
}

@media all and (min-width: 1201px) {
  .container {
    max-width: 1400px;
  }
}
</style>
