<template>
  <div>
    <div style="justify-content:space-between; display:flex; align-items: baseline;">
      <div style="display:flex; justify-content: flex-start; align-items: center;font-size: 28px; font-weight: bold; color: #161032">

        <b-icon
          pack="fas"
          icon="user-plus"
          size="is-small"
        >
        </b-icon>&nbsp;{{ $t('add') }}
      </div>

      <div style="display:flex; justify-content: flex-end; align-items: center;">
        <b-field>
          <div style="display: flex; align-items: center;">
            <b-taginput
              ref="tagInput"
              v-model="tags"
              :data="userTags"
              :has-counter="false"
              maxlength="20"
              maxtags="1"
              autocomplete
              :allow-new="true"
              :open-on-focus="true"
              icon="label"
              :placeholder="$t('tagName')"
              @focus="handleFocus"
              @input="handleInput"
            >
            </b-taginput>
            <div
              v-if="showValidateButton && tags.length === 0"
              style="color: white; background-color: #12c477; padding:2px; margin-left: 10px; margin-top:-5px; border-radius: 5px; height: 20px; width: 20px"
              @click="addTag"
            >
              <b-icon
                pack="fas"
                icon="check"
                size="is-small"
                style="color: white"
              > </b-icon>
            </div>

          </div>
        </b-field>
      </div>
    </div>
    <div
      class="tile is-ancestor is-vertical"
      :name="index"
      style=" margin-top: 20px !important; margin-bottom: 20px !important;"
      v-for="(line, index) in invitations"
      :key="index"
    >
      <div
        class="tile "
        style=""
      >
        <input
          class="inputC"
          v-model="line.surname"
          label="prenom"
          :placeholder="$t('fName')"
          type="text"
          value=""
          style="margin-right: 20px;font-size: 15px !important; width: 130px"
        />
        <input
          class="inputC"
          v-model="line.name"
          label="nom"
          :placeholder="$t('lName')"
          type="text"
          value=""
          style="margin-right: 20px;font-size: 15px !important; width: 130px"
        />
        <input
          class="inputC"
          v-model="line.email"
          label="email"
          :placeholder="$t('email')"
          type="text"
          value=""
          style="margin-right: 20px;font-size: 15px !important"
          :class="{
            'is-danger-custom': line.email && $store.getters.clients.some((client) => client.user.email === line.email)
          }"
        />
        <vue-tel-input
          v-model="line.phone"
          :enabledCountryCode="true"
          mode="international"
          :inputOptions="{
          placeholder: 'mobile',
          styleClasses: 'inputCTel',
        }"
          :validCharactersOnly="true"
          styleClasses="inputC"
          :preferredCountries="['FR']"
          wrapperClasses="inputC"
          :disabledFetchingCountry="true"
          defaultCountry="FR"
          v-on:enter="addLine()"
          style="margin-right: 20px; font-size: 15px !important"
          class="inputC"
        ></vue-tel-input>
        <!-- <input
          class="inputC"
          v-model="line.phone"
          label="phone"
          :placeholder="$t('phone')"
          type="phone"
          value=""
          style="margin-right: 20px"
          v-on:keyup.enter="addLine()"
        /> -->

        <b-button
          @click="removeLine(index)"
          type="is-small"
          icon-left="trash"
          style="
            margin-top: 10px;
            border: 0px !important;
            background-color: white !important;
          "
        >
        </b-button>
      </div>
      <h6
        class="is-danger"
        v-if="line.email && $store.getters.clients.some((client) => client.user.email === line.email)"
      >
        <span class="email_warning">⚠ {{
          $t('emailAlreadyUsed') }}</span>
      </h6>
      <div
        class="tile"
        style="display: flex; justify-content: center"
      >
        <b-button
          v-if="index + 1 === invitations.length && invitations.length < 50"
          @click="addLine"
          expanded
          icon-left="plus"
          style="
            margin-right: 10px;
            margin-top: 20px;
            font-size: 14px;
            background-color: white;
            color: #828282;
            border: 1px dashed #e1e1e1;
          "
        >&nbsp;&nbsp;{{ $t('addContact') }}</b-button>

        <b-button
          v-if="index + 1 === invitations.length && invitations.length < 50"
          @click="openFileExplorer"
          icon-left="upload"
          expanded
          style="
            margin-right: 10px;
            font-size: 14px;
            margin-top: 20px;
            background-color: white;
            color: #828282;
            border: 1px dashed #e1e1e1;
          "
        >&nbsp;&nbsp;{{ $t('importCSV') }}</b-button>
      </div>
      <input
        v-if="index + 1 === invitations.length"
        @change="importCsv"
        ref="csvFiles"
        type="file"
        accept="text/csv"
        style="display: none"
      />
    </div>

    <b-modal
      v-model="isCardModalActive"
      :width="840"
    >
      <div class="card">
        <div class="card-content">
          <h3 style="text-align: center !important">
            {{ $t('columnFit') }}
          </h3>
          <br />
          <div class="tile is-ancestor">
            <div class="tile is-parent">
              <b-tag
                :style="{ backgroundColor: colors.surname }"
                size="is-medium"
              >{{ $t('fName') }}</b-tag>

              <b-select
                :placeholder="$t('choose')"
                v-model="columnsByFields.surname"
                size="is-small"
              >
                <option
                  v-for="column in columns"
                  :value="column"
                  :key="column"
                >
                  {{ getColumnName(column) }}
                </option>
              </b-select>
            </div>
            <div class="tile is-parent">
              <b-tag
                :style="{ backgroundColor: colors.name }"
                size="is-medium"
              >{{ $t('lName') }}</b-tag>
              <b-select
                :placeholder="$t('choose')"
                v-model="columnsByFields.name"
                size="is-small"
              >
                <option
                  v-for="column in columns"
                  :value="column"
                  :key="column"
                >
                  {{ getColumnName(column) }}
                </option>
              </b-select>
            </div>
            <div class="tile is-parent">
              <b-tag
                :style="{ backgroundColor: colors.email }"
                size="is-medium"
              >{{ $t('email') }}</b-tag>
              <b-select
                :placeholder="$t('choose')"
                v-model="columnsByFields.email"
                size="is-small"
              >
                <option
                  v-for="column in columns"
                  :value="column"
                  :key="column"
                >
                  {{ getColumnName(column) }}
                </option>
              </b-select>
            </div>
            <div class="tile is-parent">
              <b-tag
                :style="{ backgroundColor: colors.phone }"
                size="is-medium"
              > {{ $t('phone') }} </b-tag>
              <b-select
                :placeholder="$t('choose')"
                v-model="columnsByFields.phone"
                size="is-small"
              >
                <option
                  v-for="column in columns"
                  :value="column"
                  :key="column"
                >
                  {{ getColumnName(column) }}
                </option>
              </b-select>
            </div>
          </div>
          <br />

          <br />
          <div style="overflow-x: auto">
            <csv-container
              :rows="rows"
              :columns="columns"
              :data="data"
              :zones="zones"
            ></csv-container>
          </div>
          <br />
          <div class="line">
            <b-checkbox v-model="ignoreFirstLine">{{ $t('ignoreFirstLine') }}</b-checkbox>
            <button
              class="button-3d"
              @click="saveCsvInvitations"
              :disabled="!columnSelectionIsComplete"
            >
              {{ $t('validate') }}
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <br />

    <div style="display: flex; justify-content: center;">

      <div> <button
          @click="$emit('save', tags)"
          class="button-3d-small"
        >{{ $t('save') }}</button></div>

    </div>
  </div>
</template>

<script>
import Papa from 'papaparse';
import csvContainer from './csv/csvContainer.vue';

/**
 * @name invitationlist
 * @desc Component for managing invitations
 */
export default {
  components: { csvContainer },
  name: 'invitationlist',
  props: {
    /**
     * List of invitations
     */
    invitations: [],
    /**
     * List of user tags
     */
    userTags: []
  },
  data() {
    return {
      /**
       * @type {boolean}
       * @desc Indicates if block removal is enabled
       */
      blockRemoval: true,
      /**
       * @type {boolean}
       * @desc Indicates if the card modal is active
       */
      isCardModalActive: false,
      /**
       * @type {number}
       * @desc Number of rows
       */
      rows: 5,
      /**
       * @type {number}
       * @desc Number of columns
       */
      columns: 10,
      /**
       * @type {array}
       * @desc Data array
       */
      data: [],
      /**
       * @type {array}
       * @desc Tags array
       */
      tags: [],
      /**
       * @type {array}
       * @desc User tags array
       */
      userTags: [],
      /**
       * @type {boolean}
       * @desc Indicates if the validate button is shown
       */
      showValidateButton: false,
      /**
       * @type {object}
       * @desc Columns by fields object
       */
      columnsByFields: {
        surname: null,
        name: null,
        email: null,
        phone: null
      },
      /**
       * @type {object}
       * @desc Colors object
       */
      colors: {
        surname: '#f3f3ff',
        name: '#c3c3f4',
        email: '#9688ff',
        phone: '#7c9cf7',
        disable: '#828282'
      },
      /**
       * @type {boolean}
       * @desc Indicates if the first line should be ignored
       */
      ignoreFirstLine: false
    };
  },
  watch: {
    invitations() {
      this.blockRemoval = this.invitations.length <= 1;
    },
    isCardModalActive(newValue) {
      if (!newValue) {
        this.data = [];
        this.$refs.csvFiles[0].value = '';
        this.columnsByFields = {
          surname: null,
          name: null,
          email: null,
          phone: null
        };
        this.ignoreFirstLine = false;
      }
    }
  },
  methods: {
    /**
     * @name addTag
     * @desc Add a tag
     */
    addTag() {
      this.$refs.tagInput.addTag();
      this.showValidateButton = false;
    },
    /**
     * @name handleFocus
     * @desc Handle focus event
     */
    handleFocus() {
      this.showValidateButton = true;
    },
    /**
     * @name handleInput
     * @desc Handle input event
     * @param {string} value - Input value
     */
    handleInput(value) {
      this.showValidateButton = value.length > 0;
    },
    /**
     * @name addLine
     * @desc Add a new line for invitation
     */
    addLine() {
      let checkEmptyinvitations = this.invitations.filter(
        (line) => line.surname === null
      );
      if (
        (checkEmptyinvitations.length >= 1 && this.invitations.length > 0) ||
        this.invitations.length >= 50
      ) {
        return;
      }
      this.invitations.push({
        surname: null,
        name: null,
        email: null,
        phone: null
      });
    },
    /**
     * @name removeLine
     * @desc Remove an invitation line
     * @param {number} lineId - Index of the line to remove
     */
    removeLine(lineId) {
      if (!this.blockRemoval) {
        this.invitations.splice(lineId, 1);
      }
    },
    /**
     * @name openFileExplorer
     * @desc Open file explorer to select a CSV file
     */
    openFileExplorer() {
      this.$refs.csvFiles[0].click();
    },
    /**
     * @name importCsv
     * @desc Import CSV file data
     * @param {Event} e - File input event
     */
    importCsv(e) {
      const files = [...e.target.files];
      if (files.length < 1) {
        return;
      }
      const file = files[0];
      Papa.parse(file, {
        skipEmptyLines: true,
        encoding: 'UTF-8',
        complete: function (result) {
          this.data = result.data;
          let maxLength = 0;
          for (const line of this.data) {
            if (line.length > maxLength) maxLength = line.length;
          }
          this.columns = maxLength;
          this.isCardModalActive = true;
        }.bind(this)
      });
    },
    /**
     * @name saveCsvInvitations
     * @desc Save CSV invitations data
     */
    saveCsvInvitations() {
      const nInvitations = this.data
        .filter((_, index) => index !== 0 || !this.ignoreFirstLine)
        .map((line) => {
          const { surname, name, email, phone } = this.columnsByFields;
          return {
            surname: line[surname - 1],
            name: line[name - 1],
            email: line[email - 1],
            phone: line[phone - 1]
          };
        });
      if (this.invitations.length === 1) {
        const { surname, name, email } = this.invitations[0];
        if ((!surname, !name, !email)) this.invitations.splice(0, 1);
      }
      for (const invitation of nInvitations) {
        this.invitations.push(invitation);
      }
      this.isCardModalActive = false;
    },
    /**
     * @name getColumnName
     * @desc Get the column name based on index
     * @param {number} index - Column index
     * @returns {string} - Column name
     */
    getColumnName(index) {
      return [...'abcdefghijklmnopqrstuvwxyz'.toUpperCase()][index - 1];
    }
  },
  computed: {
    /**
     * @name zones
     * @desc Compute zones for CSV data
     * @returns {array} - Array of zones
     */
    zones() {
      const nZones = Object.entries(this.columnsByFields)
        .filter(([_, value]) => value)
        .map(([key, value]) => ({
          name: key,
          color: this.colors[key],
          start: { row: this.ignoreFirstLine ? 2 : 1, column: value },
          end: { row: -1, column: value }
        }));
      if (this.ignoreFirstLine)
        nZones.push({
          name: 'disable',
          color: this.colors['disable'],
          start: { row: 1, column: 1 },
          end: { row: 1, column: -1 }
        });
      return nZones;
    },
    /**
     * @name columnSelectionIsComplete
     * @desc Check if column selection is complete
     * @returns {boolean} - True if column selection is complete
     */
    columnSelectionIsComplete() {
      const { surname, name, email } = this.columnsByFields;
      return Boolean(surname && name && email);
    }
  },
  mounted() {
    this.addLine();
  }
};
</script>

<style src="./index.css"></style>

<style>
.lines {
  margin: auto;
  width: 80%;
}

.line {
  display: flex;
  margin: 10px;
  justify-content: space-between;
  align-items: center;
}

.email_warning {
  color: red;
  position: absolute;
  right: 385px;
  margin-top: 5px;
  font-size: 12px;
}

@media all and (max-width: 600px) {
  .email_warning {
    right: 180px;
    top: 226px;
  }
}

@media all and (min-width: 601px) and (max-width: 768px) {
  .email_warning {
    right: 570px;
    top: 227px;
  }
}
</style>

<style scoped>
.card {
  position: relative;
}
</style>