<template>
  <div style="height: 650px;padding-left: 30px; padding-right: 30px; padding-top: 200px;">
    <div style="margin-top: -20px; display: flex;
    align-items: center;
    justify-content: space-between;">
      <div><b-dropdown
          v-model="selectedTemplate"
          placeholder="Modèle de mail"
          aria-role="list"
        >
          <template
            v-if="selectedTemplate"
            #trigger
          >
            <b-button
              :label="selectedTemplate.name"
              type="is-light is-primary"
              @click="toggleActive"
              :icon-right="active ? 'caret-up' : 'caret-down'"
            />
          </template>

          <template
            v-else
            #trigger
          >
            <b-button
              :label="$t('selectModel')"
              type="is-primary"
              :icon-right="active ? 'caret-up' : 'caret-down'"
            />
          </template>

          <b-dropdown-item
            v-for="template of mailTemplates"
            :key="template.id"
            :value="template"
            aria-role="listitem"
            style="height:50px; width: 400px; display: flex; align-items: center;"
          >
            <img
              style="height:40px; float: left; margin-right: 10px;"
              :src="template.previewUrl"
            />
            {{ template.name }}
          </b-dropdown-item>
        </b-dropdown></div>
      <div>
        <div v-if="type == 'remerciement'"></div>
        <div v-else>
          <b-button
            @click="copyInterviewUrl()"
            size="is-small"
            icon-left="link"
            style=" font-size: 12px; background-color:white; color: #828282; margin-right:10px"
          >
            {{ $t('copyLink') }}
          </b-button>
        </div>
      </div>

    </div>
    <!-- <b-tooltip
      position="is-right"
      type="is-primary is-light"
    > <template v-slot:content>
        {{ $t('tooltip_model') }}<br />
        {{ $t('tooltip_model2') }}
      </template>
      <img
        src="/img/sos.png"
        class="sizeSos"
      />
    </b-tooltip> -->

    <div
      v-if="messageData && messageData.subject && messageData.content"
      style="margin-top: 20px; height: 500px;"
    >
      <span class="box">
        <p><strong>Objet :</strong>&nbsp;{{ messageData.subject }}</p> <br />
        <p
          class="message-content"
          v-html="messageData.content"
        ></p>
      </span>

    </div>
  </div>
</template>

<script>
/**
 * @name SelectModel
 * @desc Component for selecting a mail template
 */
export default {
  props: {
    /**
     * Interview template object
     */
    interviewTemplate: {
      type: Object,
      required: true
    },
    /**
     * Type of the template
     */
    type: {
      type: String,
      required: true
    },
    /**
     * Object on behalf of which the template is selected
     */
    onBehalf: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      /**
       * @type {Object}
       * @desc Data for the message
       */
      messageData: null,
      /**
       * @type {Array}
       * @desc Available mail templates
       */
      mailTemplates: null,
      /**
       * @type {Object}
       * @desc Selected template
       */
      selectedTemplate: null,
      /**
       * @type {Object}
       * @desc Variables for the template
       */
      variables: null,
      /**
       * @type {Boolean}
       * @desc Flag for active state
       */
      active: false
    };
  },
  methods: {
    /**
     * @name toggleActive
     * @desc Toggles the active state
     */
    toggleActive() {
      this.active = !this.active;
    },
    /**
     * @name checkAndUpdateLocaleAndFormality
     * @desc Checks and updates locale and formality
     */
    checkAndUpdateLocaleAndFormality() {
      if (this.interviewTemplate.locale === null) {
        this.interviewTemplate.locale = 'fr';
      }
      if (this.interviewTemplate.formality === null) {
        this.interviewTemplate.formality = true;
      }
    },
    /**
     * @name computeAndPushVariables
     * @desc Computes and pushes variables for the template
     * @param {Object} template - The template object
     */
    async computeAndPushVariables(template) {
      let textBase = template.content;
      for (let v of Object.keys(this.variables)) {
        if (v === 'link') {
          const shortLink = await this.$store.dispatch('getShortLink', {
            url: this.variables[v]
          });
          textBase = textBase.split(`{{var:${v}}}`).join(shortLink);
        } else {
          textBase = textBase.split(`{{var:${v}}}`).join(this.variables[v]);
        }
      }
      let subject = template.subject;
      for (let v of Object.keys(this.variables)) {
        subject = subject.split(`{{var:${v}}}`).join(this.variables[v]);
      }
      this.messageData = {
        subject: subject,
        content: textBase
      };
      console.log(this.messageData);
      this.$emit('update', this.messageData);
    },
    /**
     * @name copyInterviewUrl
     * @desc Copies the interview URL
     */
    async copyInterviewUrl() {
      try {
        if (this.variables && this.variables['link']) {
          const shortLink = await this.$store.dispatch('getShortLink', {
            url: this.variables['link']
          });
          if (navigator.clipboard) {
            await navigator.clipboard.writeText(shortLink);
          } else {
            fallbackCopyTextToClipboard(shortLink);
          }
          this.$buefy.toast.open({
            message: this.$t('linkCopied'),
            type: 'is-success',
            position: 'is-bottom-right'
          });
        } else {
          console.error(
            "La variable 'link' n'est pas définie dans this.variables"
          );
        }
      } catch (error) {
        console.error('Erreur lors de la copie du lien:', error);
        this.$buefy.toast.open({
          message: 'Erreur lors de la copie du lien.',
          type: 'is-danger',
          position: 'is-bottom-right'
        });
      }
    },
    /**
     * @name fallbackCopyTextToClipboard
     * @desc Fallback method to copy text to clipboard
     * @param {string} text - The text to copy
     */
    fallbackCopyTextToClipboard(text) {
      const textArea = document.createElement('textarea');
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand('copy');
        if (!successful) {
          console.error('Impossible de copier le texte');
        }
      } catch (err) {
        console.error('Erreur lors de la copie du texte', err);
      }

      document.body.removeChild(textArea);
    }
  },
  mounted() {
    this.checkAndUpdateLocaleAndFormality();

    this.$store.dispatch('getPublicMailTemplates').then((response) => {
      console.log('response', response);
      let mailTemplates = response.data;
      console.log(
        'type de templates',
        this.type,
        'langue',
        this.interviewTemplate.locale,
        'formality',
        this.interviewTemplate.formality
      );
      this.interviewTemplate.formality = this.interviewTemplate.formality
        ? this.interviewTemplate.formality
        : false;
      console.log(this.interviewTemplate);

      this.mailTemplates = mailTemplates.filter(
        (template) =>
          template.tag === this.type &&
          template.locale === this.interviewTemplate.locale &&
          template.formality === this.interviewTemplate.formality
      );

      console.log('this.mailTemplates', this.mailTemplates);
    });
    console.log(this.interviewTemplate);
    if (!this.onBehalf) {
      this.$store
        .dispatch('computeMailVariables', {
          interviewId: this.interviewTemplate.interviews[0].id
        })
        .then((response) => {
          console.log(response);
          this.variables = response.data;
        });
    } else {
      this.$store
        .dispatch('computeAdminMailVariables', {
          interviewId: this.interviewTemplate.interviews[0].id,
          ownerId: this.onBehalf.id
        })
        .then((response) => {
          console.log(response);
          this.variables = response.data;
        });
    }
  },
  watch: {
    selectedTemplate: function (val) {
      console.log('selectedTemplate', val);
      console.log('variables', this.variables);
      this.computeAndPushVariables(val);
    }
  }
};
</script>

<style scoped>
.message-content {
  white-space: pre-wrap;
  font-family: inherit;
  height: fit-content;
}

@media all and (max-width: 790px) {
  .btn-templateSelect {
    font-size: 13px;
  }
}
</style>