<template>
  <div
    class="tile is-ancestor is-vertical"
    style="max-width: 900px; margin: auto;  padding: 50px 15px 0px 15px"
  >
    <div
      class="charlie__message charlie__message_A"
      style="display: flex; justify-content: center;"
    >
      <div class="charlie__content">
        {{ $t('testimonyCollected') }}
      </div>
    </div>
  </div>

</template>

<script>
/**
 * @name alreadyRegistered
 * @desc Component for handling already registered users
 */
export default {
  /**
   * @desc Name of the component
   * @type {string}
   */
  name: 'alreadyRegistered',
  /**
   * @desc Components used within this component
   * @type {object}
   */
  components: {},
  /**
   * @desc Data properties of the component
   * @returns {object}
   */
  data() {
    return {};
  }
};
</script>

<style src="./index.css">
</style>