import { render, staticRenderFns } from "./userClients.vue?vue&type=template&id=21a0202f&scoped=true"
import script from "./userClients.vue?vue&type=script&lang=js"
export * from "./userClients.vue?vue&type=script&lang=js"
import style0 from "./userClients.vue?vue&type=style&index=0&id=21a0202f&prod&scoped=true&lang=css"
import style1 from "../index.css?vue&type=style&index=1&prod&lang=css&external"
import style2 from "./userClients.vue?vue&type=style&index=2&id=21a0202f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21a0202f",
  null
  
)

export default component.exports