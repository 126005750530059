import VueI18n from 'vue-i18n'
import { fr } from './localization/fr.js'
import { en } from './localization/en.js'
import { es } from './localization/es.js'
import { it } from './localization/it.js'
import Vue from 'vue';

Vue.use(VueI18n);
// Create VueI18n instance with options
const i18n = new VueI18n({
    locale: getStartingLocale(), // set locale
    messages: { "fr": fr, "en": en, "es": es, "it": it }, // set locale messages
});

function getStartingLocale() {
    if (localStorage.getItem('last-locale')) {
        return localStorage.getItem('last-locale')
    }
    return 'fr'
}
export default i18n;