
import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store/store';
import Buefy from 'buefy';
import 'buefy/dist/buefy.css';
import VueDashboard from 'vue-dashboard-vd';
import { library } from '@fortawesome/fontawesome-svg-core';
// internal icons
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import * as firebase from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import i18n from './i18n';
import { EmailEditor } from 'vue-email-editor';
import vueCountryRegionSelect from 'vue-country-region-select'
import 'leaflet/dist/leaflet.css';
import VueTour from 'vue-tour';
import Particles from "vue2-particles";
import moment from 'moment';


require('vue-tour/dist/vue-tour.css');
Vue.use(VueTour);
Vue.use(Particles);

Vue.use(VueTelInput);
Vue.use(EmailEditor);

library.add(fas);
Vue.component('vue-fontawesome', FontAwesomeIcon);

Vue.use(VueDashboard);
Vue.config.productionTip = false;
Vue.use(vueCountryRegionSelect)

Vue.use(require('vue-moment'));

Vue.config.ignoredElements = [
  'ultimate-recorder-gis'
]

Vue.use(Buefy, {
  defaultIconComponent: 'vue-fontawesome',
  defaultIconPack: 'fas',
  customIconPacks: {
    fas: {
      sizes: {
        default: 'lg',
        'is-small': '1x',
        'is-medium': '2x',
        'is-large': '3x'
      },
      iconPrefix: ''
    }
  }
});

Sentry.init({
  Vue,
  dsn: 'https://a371140ecdeb4659a3f4d95c8f6b2896@o395072.ingest.sentry.io/6506606',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['charlie-dev.firebaseapp.com', 'charlie-dev.web.app', 'dashboard.yourcharlie.com']
    }),
    new Sentry.Replay()
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 0,
  logErrors: true,
  environment: process.env.VUE_APP_ENV_NAME
});

// Database connection

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID
};

firebase.initializeApp(firebaseConfig);

Vue.filter('momentFilter', function (date, locale) {
  return moment(date).locale(locale).format('LL');
})

onAuthStateChanged(getAuth(), async (user) => {
  await store.dispatch('setUser', user);
  if (user) {
    await store.dispatch('setupAxios');
    store.dispatch('getPlaylists').catch((error) => {
      console.log(error);
    });
    store.dispatch('getClients').catch((error) => {
      console.log(error);
    });
    store.commit('triggerStartTour');
  }
  // window.dataLayer = window.dataLayer || [];
  // window.dataLayer.push({
  //   user_id: store.getters.activeUser.id
  // });
  new Vue({
    router,
    store,
    render: (h) => h(App),
    i18n,
  }).$mount('#app');
});
