
<template>
  <section>

    <b-table
      :data="dataOrders"
      :hoverable="isHoverable"
      :loading="loading"
      paginated
      backend-pagination
      :total="total"
      :per-page="perPage"
      @page-change="onPageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      backend-sorting
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      @sort="onSort"
    >
      <b-table-column
        field="createdAt"
        label="Date"
        sortable
        v-slot="props"
      >
        <span
          class="tag"
          style="background-color: #fbfcfb"
        >
          {{ props.row.createdAt | moment }}
        </span>
      </b-table-column>
      <b-table-column
        field="id"
        label="Ref"
        :searchable="true"
        sortable
        v-slot="props"
      >
        {{ props.row.id }}
      </b-table-column>
      <b-table-column
        field="business"
        label="Business"
        v-slot="props"
      >
        {{ props.row.charlieUser.business }}
      </b-table-column>
      <b-table-column
        field="email"
        label="Email"
        v-slot="props"
      >
        <a @click="onUserClickedMission(props.row.charlieUser)">
          {{ props.row.charlieUser.email }}
        </a>
      </b-table-column>

      <b-table-column
        field="productName"
        label="Description"
        sortable
        v-slot="props"
      >
        {{ props.row.productName }}</b-table-column>
      <b-table-column
        field="productPriceHT"
        label="Amount"
        sortable
        v-slot="props"
      >
        {{
          props.row.productPriceHT / 100 - props.row.productDiscount / 100
        }}
        € ht <span v-if="props.row.productDiscount != 0">🏷️</span>
      </b-table-column>
    </b-table>

    <b-modal :active.sync="showAddInvoiceModal">
      <div class="box">
        <h1 class="title">Add new invoice</h1>
        <b-field type="is-primary">
          <b-input
            v-model="newInvoice.userId"
            placeholder="User ID"
          />
        </b-field>

        <b-field type="is-primary">
          <b-input
            v-model="newInvoice.productName"
            placeholder="Product Name"
          />
        </b-field>

        <b-field type="is-primary">
          <b-input
            v-model="newInvoice.productPriceHT"
            placeholder="Product Price HT"
          />
        </b-field>

        <b-field type="is-primary">
          <b-input
            v-model="newInvoice.productTVA"
            placeholder="Product VAT"
          />
        </b-field>

        <b-field type="is-primary">
          <b-input
            v-model="newInvoice.productDiscount"
            placeholder="Product Discount"
          />
        </b-field>

        <b-button @click="addNewInvoice()">Save</b-button>
        <b-loading
          :is-full-page="false"
          :active.sync="loading"
        />
      </div>
    </b-modal>

    <b-button
      class="is-primary is-floating"
      @click="showAddInvoiceModal = true"
      style="position:fixed; bottom: 20px; right: 20px;"
    >Ajouter invoice</b-button>

  </section>
</template>
  
<script>
import moment from 'moment';

/**
 * @name allOrders
 * @desc Component for displaying and managing all orders in the admin panel
 */
export default {
  name: 'allOrders',
  components: {},
  /**
   * @name filters
   * @desc Filters used in this component
   */
  filters: {
    /**
     * @name moment
     * @desc Formats a date using moment.js with French locale
     * @param {Date} date - The date to format
     * @returns {String} - The formatted date string
     */
    moment: function (date) {
      moment.locale('fr');
      return moment(date).format('LLL');
    }
  },
  /**
   * @name data
   * @desc Component's reactive data
   * @returns {Object} - The data object for the component
   */
  data: () => {
    return {
      /**
       * @desc Controls the visibility of the Add Invoice modal
       * @type {Boolean}
       */
      showAddInvoiceModal: false,
      /**
       * @desc Array of orders to be displayed
       * @type {Array}
       */
      dataOrders: [],
      /**
       * @desc Enables hover effect on table rows
       * @type {Boolean}
       */
      isHoverable: true,
      /**
       * @desc Indicates if the component is in a loading state
       * @type {Boolean}
       */
      loading: false,
      /**
       * @desc Toggles visibility of user information
       * @type {Boolean}
       */
      showUserInfos: false,
      /**
       * @desc Field to sort the orders by
       * @type {String}
       */
      sortField: 'createdAt',
      /**
       * @desc Order direction for sorting (ascending or descending)
       * @type {String}
       */
      sortOrder: 'desc',
      /**
       * @desc Default sort order when the component is loaded
       * @type {String}
       */
      defaultSortOrder: 'desc',
      /**
       * @desc Current page number in pagination
       * @type {Number}
       */
      page: 1,
      /**
       * @desc Number of items per page in pagination
       * @type {Number}
       */
      perPage: 20,
      /**
       * @desc Total number of items across all pages
       * @type {Number}
       */
      total: 0,
      /**
       * @desc Object representing a new invoice to be added
       * @type {Object}
       */
      newInvoice: {
        /**
         * @desc User ID associated with the new invoice
         * @type {String|null}
         */
        userId: null,
        /**
         * @desc Name of the product for the new invoice
         * @type {String|null}
         */
        productName: null,
        /**
         * @desc Price of the product before taxes
         * @type {Number|null}
         */
        productPriceHT: null,
        /**
         * @desc Value-added tax on the product
         * @type {Number|null}
         */
        productTVA: null,
        /**
         * @desc Discount applied to the product
         * @type {Number|null}
         */
        productDiscount: null
      }
    };
  },
  /**
   * @name beforeMount
   * @desc Lifecycle hook that is called before the component is mounted
   */
  beforeMount() {
    this.refreshList();
  },
  /**
   * @name methods
   * @desc Methods for the component
   */
  methods: {
    /**
     * @name addNewInvoice
     * @desc Adds a new invoice using data from the form
     */
    addNewInvoice() {
      this.loading = true;
      this.$store
        .dispatch('adminCreateInvoice', this.newInvoice)
        .then((response) => {
          this.showAddInvoiceModal = false;
          this.newInvoice = {
            userId: '',
            productName: '',
            productPriceHT: '',
            productTVA: '',
            productDiscount: ''
          };
          this.refreshList();
        })
        .catch((error) => {
          console.error(error);
          this.showAddInvoiceModal = false;
          this.loading = false;
          this.$buefy.toast.open({
            message: this.$t('errorOccured'),
            type: 'is-danger',
            queue: false,
            position: 'is-top-right',
            duration: 5000
          });
        });
    },
    /**
     * @name onUserClickedMission
     * @desc Emits an event when a user is clicked
     * @param {Object} charlieUser - The user object
     */
    onUserClickedMission(charlieUser) {
      this.$emit('userClicked', charlieUser.user.id);
    },
    /**
     * @name onPageChange
     * @desc Handles page changes in pagination
     * @param {Number} page - The new page number
     */
    onPageChange(page) {
      this.page = page;
      this.refreshList();
    },
    /**
     * @name onSort
     * @desc Handles sorting changes
     * @param {String} field - The field to sort by
     * @param {String} order - The order of sorting
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.refreshList();
    },
    /**
     * @name refreshList
     * @desc Refreshes the list of orders
     */
    refreshList() {
      this.loading = true;
      this.$store
        .dispatch('getAdminInvoicesPaginated', {
          page: this.page - 1,
          limit: this.perPage,
          sort: this.sortField,
          order: this.sortOrder.toUpperCase()
        })
        .then((response) => {
          this.dataOrders = response.data[0];
          this.loading = false;
          this.total = response.data[1];
        })
        .catch((error) => {
          this.loading = false;
          console.error(error);
          this.$buefy.toast.open({
            message: this.$t('errorOccured'),
            type: 'is-danger',
            queue: false,
            position: 'is-top-right',
            duration: 5000
          });
        });
    }
  }
};
</script>
  
<style src="../index.css"></style>