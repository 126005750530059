var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card",staticStyle:{"height":"550px","padding":"50px !important","overflow-y":"visible"}},[_c('h1',[_vm._v("Add Client")]),_c('br'),_c('div',[_c('div',{key:_vm.index,staticClass:"tile is-ancestor is-vertical",staticStyle:{"margin-top":"20px !important","margin-bottom":"20px !important"},attrs:{"name":_vm.index}},[_c('div',{staticClass:"tile"},[_c('input',{staticClass:"inputC",staticStyle:{"margin-right":"20px"},attrs:{"label":"prenom","placeholder":_vm.$t('fName'),"type":"text","value":""}}),_c('input',{staticClass:"inputC",staticStyle:{"margin-right":"20px"},attrs:{"label":"nom","placeholder":_vm.$t('lName'),"type":"text","value":""}}),_c('input',{staticClass:"inputC",staticStyle:{"margin-right":"20px"},attrs:{"label":"email","placeholder":_vm.$t('email'),"type":"text","value":""}}),_c('input',{staticClass:"inputC",staticStyle:{"margin-right":"20px"},attrs:{"label":"nom de l'entreprise","placeholder":_vm.$t('entreprise'),"type":"text","value":""}}),_c('vue-tel-input',{staticClass:"inputC",staticStyle:{"margin-right":"20px","font-size":"15px !important"},attrs:{"enabledCountryCode":true,"mode":"international","inputOptions":{
          placeholder: 'mobile',
          styleClasses: 'inputCTel',
        },"validCharactersOnly":true,"styleClasses":"inputC","preferredCountries":['FR'],"wrapperClasses":"inputC","disabledFetchingCountry":true,"defaultCountry":"FR"},on:{"enter":function($event){return _vm.addLine()}}}),_c('b-button',{staticStyle:{"margin-top":"10px","border":"0px !important","background-color":"white !important"},attrs:{"type":"is-small","icon-left":"trash"},on:{"click":function($event){return _vm.removeLine(_vm.index)}}})],1)]),_c('div',{key:_vm.index,staticClass:"tile is-ancestor is-vertical",staticStyle:{"margin-top":"20px !important","margin-bottom":"20px !important"},attrs:{"name":_vm.index}},[_c('div',{staticClass:"tile"},[_c('input',{staticClass:"inputC",staticStyle:{"margin-right":"20px"},attrs:{"label":"prenom","placeholder":_vm.$t('fName'),"type":"text","value":""}}),_c('input',{staticClass:"inputC",staticStyle:{"margin-right":"20px"},attrs:{"label":"nom","placeholder":_vm.$t('lName'),"type":"text","value":""}}),_c('input',{staticClass:"inputC",staticStyle:{"margin-right":"20px"},attrs:{"label":"email","placeholder":_vm.$t('email'),"type":"text","value":""}}),_c('input',{staticClass:"inputC",staticStyle:{"margin-right":"20px"},attrs:{"label":"nom de l'entreprise","placeholder":_vm.$t('entreprise'),"type":"text","value":""}}),_c('vue-tel-input',{staticClass:"inputC",staticStyle:{"margin-right":"20px","font-size":"15px !important"},attrs:{"enabledCountryCode":true,"mode":"international","inputOptions":{
          placeholder: 'mobile',
          styleClasses: 'inputCTel',
        },"validCharactersOnly":true,"styleClasses":"inputC","preferredCountries":['FR'],"wrapperClasses":"inputC","disabledFetchingCountry":true,"defaultCountry":"FR"},on:{"enter":function($event){return _vm.addLine()}}}),_c('b-button',{staticStyle:{"margin-top":"10px","border":"0px !important","background-color":"white !important"},attrs:{"type":"is-small","icon-left":"trash"},on:{"click":function($event){return _vm.removeLine(_vm.index)}}})],1)]),_c('div',{key:_vm.index,staticClass:"tile is-ancestor is-vertical",staticStyle:{"margin-top":"20px !important","margin-bottom":"20px !important"},attrs:{"name":_vm.index}},[_c('div',{staticClass:"tile"},[_c('input',{staticClass:"inputC",staticStyle:{"margin-right":"20px"},attrs:{"label":"prenom","placeholder":_vm.$t('fName'),"type":"text","value":""}}),_c('input',{staticClass:"inputC",staticStyle:{"margin-right":"20px"},attrs:{"label":"nom","placeholder":_vm.$t('lName'),"type":"text","value":""}}),_c('input',{staticClass:"inputC",staticStyle:{"margin-right":"20px"},attrs:{"label":"email","placeholder":_vm.$t('email'),"type":"text","value":""}}),_c('input',{staticClass:"inputC",staticStyle:{"margin-right":"20px"},attrs:{"label":"nom de l'entreprise","placeholder":_vm.$t('entreprise'),"type":"text","value":""}}),_c('vue-tel-input',{staticClass:"inputC",staticStyle:{"margin-right":"20px","font-size":"15px !important"},attrs:{"enabledCountryCode":true,"mode":"international","inputOptions":{
          placeholder: 'mobile',
          styleClasses: 'inputCTel',
        },"validCharactersOnly":true,"styleClasses":"inputC","preferredCountries":['FR'],"wrapperClasses":"inputC","disabledFetchingCountry":true,"defaultCountry":"FR"},on:{"enter":function($event){return _vm.addLine()}}}),_c('b-button',{staticStyle:{"margin-top":"10px","border":"0px !important","background-color":"white !important"},attrs:{"type":"is-small","icon-left":"trash"},on:{"click":function($event){return _vm.removeLine(_vm.index)}}})],1),_c('div',{staticClass:"tile",staticStyle:{"display":"flex","justify-content":"center"}},[_c('b-button',{staticStyle:{"margin-right":"10px","margin-top":"20px","background-color":"white","color":"#828282","border":"2px dashed #fbfcfb"},attrs:{"expanded":"","icon-left":"user-plus"},on:{"click":_vm.addLine}},[_vm._v("  "+_vm._s(_vm.$t('addContact')))])],1),_c('div',{staticClass:"tile",staticStyle:{"display":"flex","justify-content":"center"}},[_c('b-button',{staticStyle:{"margin-right":"10px","margin-top":"10px","background-color":"white","color":"#828282","border":"2px dashed #fbfcfb"},attrs:{"expanded":"","icon-left":"upload"},on:{"click":_vm.openFileExplorer}},[_vm._v("  "+_vm._s(_vm.$t('importCSV')))])],1),_c('input',{ref:"csvFiles",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"text/csv"}})]),_vm._m(0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('button',{staticClass:"button-3d-small"},[_vm._v("sauvegarder")])])
}]

export { render, staticRenderFns }