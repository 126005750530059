
<template>
  <div>
    <redoc-wrapper
      :spec-or-spec-url="'/yourcharlie-api.json'"
      :options="redocOptions"
    ></redoc-wrapper>
  </div>
</template>
  
  <script>
import RedocWrapper from '@hnluu8/vue-redoc-wrapper';

export default {
  name: 'apidoc',
  components: {
    RedocWrapper
  },
  data() {
    return {
      redocOptions: {
        hideDownloadButton: true
      }
    };
  }
};
</script>