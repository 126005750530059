<template>
  <div class="container">
    <div
      style="margin: 0 auto"
      class="tile is-ancestor is-vertical"
    >
      <div
        style="right: 30px; top: 15px; position: absolute"
        class="tile is-ancestor is-vertical"
      >
        <MenuLang />
      </div>
      <div class="signbox lightbox">
        <div class="is-centered is-vcentered">
          <div class="is-centered has-text-centered is-vcentered authform">
            <div style="width: 100px">
              <img src="../.././public/img/YourCharlie_logo.png" />
            </div>
            <span v-if="!isReset">
              <h3>{{ $t('resetPassword') }}</h3>
              {{ $t('emailUsed') }}
            </span>
            <br />
            <br />
            <br />
            <form>
              <div class="is-centered has-text-centered is-vcentered">
                <div v-if="!isReset">
                  <div
                    class="field-item"
                    style="width: 400px; margin: 0 auto"
                  >
                    <b-field
                      label="Email"
                      :label-position="labelPosition"
                    >
                      <b-input
                        class="inputC"
                        type="email"
                        v-model="email"
                        name="email"
                      />
                    </b-field>
                  </div>

                  <p>&nbsp;</p>
                  <div class="signInEmailbtn">
                    <button
                      class="button-3d"
                      size="is-medium"
                      expanded
                      primary
                      type="button"
                      @click="emailReminder"
                    >
                      valider
                    </button>
                  </div>
                  <p>&nbsp;</p>
                </div>
                <div v-else>
                  <b-icon
                    icon="email"
                    size="is-medium"
                  ></b-icon>
                  <p>
                    <span>
                      {{ $t('emailSent') }}
                    </span>
                  </p>

                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuLang from '../components/menuLang.vue';

/**
 * @name ResetPwd
 * @desc Vue component for resetting a user's password
 */

export default {
  name: 'ResetPwd',
  head: {
    /**
     * @desc Title of the page
     */
    title: 'Reset your password'
  },
  components: {
    /**
     * @desc Uses MenuLang component for language selection
     */
    MenuLang
  },
  data() {
    return {
      /**
       * @desc Position of the label in the form
       * @type {string}
       */
      labelPosition: 'on-border',
      /**
       * @desc Email input by the user
       * @type {string}
       */
      email: '',
      /**
       * @desc Loading state indicator
       * @type {boolean}
       */
      isLoading: false,
      /**
       * @desc State to indicate if the reset email has been sent
       * @type {boolean}
       */
      isReset: false
    };
  },
  methods: {
    /**
     * @name emailReminder
     * @desc Sends a password reset request to the server
     */
    emailReminder() {
      this.isLoading = true;
      this.$store
        .dispatch('askResetWithEmail', { email: this.email })
        .then((res) => {
          this.isReset = true;
          this.isLoading = false;
        })
        .catch((e) => {
          this.isLoading = false;
          this.$buefy.toast.open({
            message: e.message.toString(),
            type: 'is-danger',
            position: 'is-top'
          });
        });
    }
  }
};
</script>