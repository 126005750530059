
<template>
  <section>
    <b-table
      :data="dataMissions"
      ref="table"
      :show-detail-icon="false"
      :opened-detailed="defaultOpenedDetails"
      v-on:details-open="handleDetailsOpen"
      detailed
      detail-key="id"
      :loading="loading"
      paginated
      backend-pagination
      :total="total"
      :per-page="perPage"
      @page-change="onPageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      backend-sorting
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      @sort="onSort"
    >

      <b-table-column
        field="error"
        label=""
        sortable
        v-slot="props"
      >
        {{ props.row.interviews.some((i) => i.status == -1) ? '🚨' : (props.row.errorCode == 1 ? '⚠️' : '') }}
      </b-table-column>

      <b-table-column
        field="createdAt"
        label="Create"
        sortable
        v-slot="props"
        width=100px
      >
        <b-tag style="background-color: #fbfcfb">{{
          props.row.createdAt | moment
        }}</b-tag>
      </b-table-column>

      <b-table-column
        field="customMedia"
        label=""
        v-slot="props"
      >
        <div v-if="props.row.customMedia == null"></div>
        <div v-else>
          <a
            @click="showModal(props.row.customMedia)"
            style="display:flex; justify-content: center; align-items: center;"
          >
            <b-icon
              pack="fas"
              icon="eye"
              size="is-small"
              custom-class="fa-eye"
            >
            </b-icon>
          </a>
        </div>
      </b-table-column>

      <b-table-column
        field="formality"
        label=""
        v-slot="props"
      >
        <div v-if="props.row.formality">
          <img
            src="/img/cordiale.png"
            style="max-width: 25px!important"
          />
        </div>
        <div v-else>
          <img
            src="/img/amicale.png"
            style="max-width: 25px!important"
          />
        </div>
      </b-table-column>
      <b-table-column
        field="ShooterzSurname"
        label="Prénom"
        sortable
        v-slot="props"
        width="150px"
      >
        <div v-if="props.row.interviews.length === 0">🗑</div>
        <div v-else>{{ props.row.interviews[0].user.surname }}</div>
      </b-table-column>
      <b-table-column
        field="ShooterzName"
        label="Nom"
        sortable
        v-slot="props"
        width="150px"
      >
        <div v-if="props.row.interviews.length === 0">🗑</div>
        <div v-else>{{ props.row.interviews[0].user.name }}</div>

      </b-table-column>

      <b-table-column
        field="interviews.length"
        label=""
        v-slot="props"
        width="30px"
      >

        <div v-if="props.row.interviews.length === 0">🗑</div>

        <div
          v-else
          style="display:flex; flex-direction: row;"
        >
          <div v-if="(
            (props.row.interviews.filter(
              (i) => i.status == 3 || i.status == -1
            ).length *
              100) /
            props.row.interviews.length
          ) > 0">

            <b-tag
              :style="{
              backgroundColor: computeColorFromValue(
                (props.row.interviews.filter(
                  (i) => i.status == 3 || i.status == -1
                ).length *
                  100) /
                props.row.interviews.length
              )
            }"
              size="is-small"
            >
              succes
            </b-tag>
          </div>
          <div v-else>
            <div></div>
          </div>
          <div
            v-if="props.row.interviews[0].unlocked"
            style="width: 100% !important"
          >
            <b-tooltip
              :label="(props.row.interviews[0].lastUserActionAt || props.row.interviews[0].updatedAt) | moment"
              position="is-top"
            >🎉</b-tooltip>
          </div>

          <div
            v-if="props.row.interviews[0].status == 3 && !props.row.interviews[0].unlocked"
            style="width: 100% !important"
          >
            <b-tooltip
              :label="(props.row.interviews[0].updatedAt) | moment"
              position="is-top"
            >🔒</b-tooltip>
          </div>
        </div>
      </b-table-column>

      <b-table-column
        field="Locked"
        label=""
        sortable
        v-slot="props"
        width="150px"
      >
        <div
          v-for="statusInfos of computeStatusInfos(props.row.interviews[0])"
          :key="statusInfos.text"
        >
          <div v-if="statusInfos?.text">
            <p
              v-if="statusInfos.text !== 'seeTestimony'"
              style="
              font-size: 12px !important;
              
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
            "
              :style="{ 'color': statusInfos.color }"
            >
              <b-icon
                pack="fas"
                :icon="statusInfos.icon"
                size="is-small"
              ></b-icon>&nbsp;&nbsp;{{ $t(statusInfos.text) }}
              {{ statusInfos.date }}
            </p>
          </div>
        </div>
        <span v-if="props.row.interviews[0].status == -1">🚨 &nbsp;&nbsp;
          <b-button
            size="is-small"
            type="is-danger is-light"
            @click="retryUnlock(props.row.interviews[0].id)"
          >
            retry
          </b-button>
        </span>

      </b-table-column>

      <b-table-column
        field="separateur"
        label=""
        sortable
        width=80px
      >
        <span style="background-color: rgb(228, 228, 228);">&nbsp;</span>
      </b-table-column>

      <b-table-column
        field="sender"
        label="Sender"
        v-slot="props"
        max-width="100px"
      >
        <div>{{ props.row.senderName }}</div>
      </b-table-column>

      <b-table-column
        field="owner.name"
        label="Makerz"
        sortable
        v-slot="props"
      >
        <a @click="onUserClickedMission(props.row.owner)">{{ props.row.owner.name }} </a>
      </b-table-column>

      <b-table-column
        field="name"
        label="id"
        sortable
        v-slot="props"
        width=100px
      >
        <template>
          <span style="width: 100px !important; font-size: 12px; white-space: nowrap; text-overflow: ellipsis !important;">{{
              props.row.name }}</span>
        </template>
      </b-table-column>

    </b-table>

    <b-modal v-model="showCustomMedia">
      <div style="display: flex; justify-content: center;">
        <video
          :src="customMediaURL"
          controls
          autoplay
        >
        </video>
      </div>
    </b-modal>

  </section>
</template>
  
<script>
import moment from 'moment';

/**
 * @name allMissions
 * @desc Component for managing all missions
 */

export default {
  name: 'allMissions',
  components: {},
  filters: {
    /**
     * @name moment
     * @desc Filter to format date using moment.js
     * @param {Date} date - The date to format
     * @returns {string} - Formatted date
     */
    moment: function (date) {
      moment.locale('fr');
      return moment(date).format('LLL');
    }
  },
  data: () => {
    return {
      /**
       * @type {Array}
       * @desc Data for missions
       */
      dataMissions: [],
      /**
       * @type {Boolean}
       * @desc Flag indicating if hover is enabled
       */
      isHoverable: true,
      /**
       * @type {Array}
       * @desc Default opened details
       */
      defaultOpenedDetails: [],
      /**
       * @type {Boolean}
       * @desc Flag indicating if detail icon is shown
       */
      showDetailIcon: false,
      /**
       * @type {Boolean}
       * @desc Flag indicating if data is loading
       */
      loading: false,
      /**
       * @type {Boolean}
       * @desc Flag indicating if custom media is shown
       */
      showCustomMedia: false,
      /**
       * @type {String}
       * @desc URL for custom media
       */
      customMediaURL: null,
      /**
       * @type {String}
       * @desc Field to sort by
       */
      sortField: 'createdAt',
      /**
       * @type {String}
       * @desc Sort order
       */
      sortOrder: 'desc',
      /**
       * @type {String}
       * @desc Default sort order
       */
      defaultSortOrder: 'desc',
      /**
       * @type {Number}
       * @desc Current page number
       */
      page: 1,
      /**
       * @type {Number}
       * @desc Number of items per page
       */
      perPage: 20,
      /**
       * @type {Number}
       * @desc Total number of items
       */
      total: 200
    };
  },
  beforeMount() {
    this.refreshList();
  },
  methods: {
    /**
     * @name computeStatusInfos
     * @desc Computes status information for an interview
     * @param {Object} interview - The interview object
     * @returns {Array} - Array of status information
     */
    computeStatusInfos(interview) {
      if (!interview) return [];
      let interviewStatus = new Object({
        color: '#828282 !important',
        date: this.daysBetween(
          interview.lastUserActionAt || interview.createdAt
        )
      });
      let lastLog = interview.ownerLogs[0];

      if (interview.lastNotifStatus === 'noAgree') {
        interviewStatus = {
          ...interviewStatus,
          icon: 'xmark',
          text: 'refuse'
        };
      } else if (
        interview.status == 3 &&
        interview.unlocked === false &&
        interview.loading == false
      ) {
        interviewStatus = {
          ...interviewStatus,
          icon: 'warning',
          text: 'received',
          color: '#f1054d !important'
        };
      } else if (
        interview.status == 3 &&
        interview.loading === false &&
        interview.unlocked === true
      ) {
        interviewStatus = {
          ...interviewStatus,
          icon: 'circle-play',
          text: 'seeTestimony',
          color: '#7957d5 !important'
        };
      } else if (
        (!interview.hasOwnerLogs &&
          !lastLog &&
          (interview.status === 1 || interview.status === 2)) ||
        (lastLog?.createdAt < interview.lastUserActionAt &&
          (interview.status === 1 || interview.status === 2))
      ) {
        interviewStatus = {
          ...interviewStatus,
          icon: 'arrow-pointer',
          text: 'clicked',
          date: this.daysBetween(
            lastLog
              ? lastLog.createdAt
              : interview.lastUserActionAt || interview.createdAt
          )
        };
      } else if (
        lastLog &&
        lastLog.createdAt >
          (interview.lastUserActionAt || interview.createdAt) &&
        lastLog.type === 'RESENT'
      ) {
        interviewStatus = {
          ...interviewStatus,
          icon: 'hourglass',
          text: 'resent',
          date: this.daysBetween(lastLog.createdAt)
        };
      } else if (
        (!interview.hasOwnerLogs &&
          ((interview.status === 0 &&
            interview.interviewTemplate.status !== 0 &&
            interview.sentByCharlie) ||
            (interview.status === 0 &&
              interview.interviewTemplate.status == 1 &&
              !interview.sentByCharlie))) ||
        lastLog?.type === 'SENT'
      ) {
        interviewStatus = {
          ...interviewStatus,
          icon: 'hourglass',
          text: 'sent',
          date: this.daysBetween(
            lastLog
              ? lastLog.createdAt
              : interview.lastUserActionAt || interview.createdAt
          )
        };
      }
      return [interviewStatus];
    },
    /**
     * @name retryUnlock
     * @desc Retry unlocking a mission
     * @param {string} id - The ID of the mission to retry unlock
     */
    retryUnlock(id) {
      this.loading = true;
      this.$store
        .dispatch('retryUnlock', id)
        .catch((error) => {
          this.loading = false;
          console.error(error);
          this.$buefy.toast.open({
            message: this.$t('errorOccured'),
            type: 'is-danger',
            queue: false,
            position: 'is-top-right',
            duration: 5000
          });
        })
        .then(() => {
          this.loading = false;
          this.$router.go();
        });
    },
    /**
     * @name showModal
     * @desc Show custom media modal
     * @param {Object} customMedia - The custom media object
     */
    showModal(customMedia) {
      this.customMediaURL = customMedia.previewUrl;
      this.showCustomMedia = true;
    },
    /**
     * @name refreshList
     * @desc Refresh the list of missions
     */
    refreshList() {
      this.loading = true;
      this.$store
        .dispatch('getAdminInterviewTemplatesPaginated', {
          page: this.page - 1,
          limit: this.perPage,
          sort: this.sortField,
          order: this.sortOrder.toUpperCase()
        })
        .then((response) => {
          this.dataMissions = response.data[0];
          this.filteredDataMissions = response.data;
          this.loading = false;
          this.total = response.data[1];
        })
        .catch((error) => {
          this.loading = false;
          console.error(error);
          this.$buefy.toast.open({
            message: this.$t('errorOccured'),
            type: 'is-danger',
            queue: false,
            position: 'is-top-right',
            duration: 5000
          });
        });
    },
    /**
     * @name onPageChange
     * @desc Handle page change event
     * @param {number} page - The page number
     */
    onPageChange(page) {
      this.page = page;
      this.refreshList();
    },
    /**
     * @name onSort
     * @desc Handle sorting event
     * @param {string} field - The field to sort by
     * @param {string} order - The sort order
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.refreshList();
    },
    /**
     * @name daysBetween
     * @desc Calculate days between two dates
     * @param {Date} date1 - The first date
     * @returns {string} - String representation of days between dates
     */
    daysBetween(date1) {
      const momentDate1 = moment(date1);
      const now = moment();
      const days = now.diff(momentDate1, 'days');
      if (momentDate1.isSame(moment(), 'day')) {
        return "aujourd'hui";
      } else if (days === 1 || days === 0) {
        return 'hier';
      } else if (days < 7) {
        return days + ' jours';
      } else if (days < 30) {
        const weeks = Math.floor(days / 7);
        return weeks + (weeks > 1 ? ' semaines' : ' semaine');
      } else {
        const months = now.diff(momentDate1, 'months', true);
        return (
          Math.floor(months) + (Math.floor(months) > 1 ? ' mois' : ' mois')
        );
      }
    },
    /**
     * @name deleteInterviewTemplate
     * @desc Delete an interview template
     * @param {string} id - The ID of the interview template to delete
     */
    deleteInterviewTemplate(id) {
      this.loading = true;
      this.$store
        .dispatch('deleteInterviewTemplate', id)
        .then(() => {
          this.loading = false;
          this.$router.go();
        })
        .catch((error) => {
          this.loading = false;
          console.error(error);
          this.$buefy.toast.open({
            message: this.$t('errorOccured'),
            type: 'is-danger',
            queue: false,
            position: 'is-top-right',
            duration: 5000
          });
        });
    },
    /**
     * @name onUserClickedMission
     * @desc Handle user clicked mission event
     * @param {Object} user - The user object
     */
    onUserClickedMission(user) {
      this.$emit('userClicked', user.id);
    },
    /**
     * @name retryUnlock
     * @desc Retry unlocking a mission
     * @param {string} id - The ID of the mission to retry unlock
     */
    retryUnlock(id) {
      this.loading = true;
      this.$store
        .dispatch('retryUnlock', id)
        .catch((error) => {
          this.loading = false;
          console.error(error);
          this.$buefy.toast.open({
            message: this.$t('errorOccured'),
            type: 'is-danger',
            queue: false,
            position: 'is-top-right',
            duration: 5000
          });
        })
        .then(() => {
          this.loading = false;
          this.$router.go();
        });
    },
    /**
     * @name cancelNotificationsForInterview
     * @desc Cancel notifications for an interview
     * @param {string} id - The ID of the interview to cancel notifications for
     */
    cancelNotificationsForInterview(id) {
      this.loading = true;
      this.$store
        .dispatch('cancelNotificationsForInterview', id)
        .catch((error) => {
          this.loading = false;
          console.error(error);
          this.$buefy.toast.open({
            message: this.$t('errorOccured'),
            type: 'is-danger',
            queue: false,
            position: 'is-top-right',
            duration: 5000
          });
        })
        .then(() => {
          this.loading = false;
          this.$router.go();
        });
    },
    /**
     * @name handleDetailsOpen
     * @desc Handle opening details for a row
     * @param {Object} row - The row object
     */
    handleDetailsOpen(row) {
      this.$store
        .dispatch('getNotificationsForInterviewTemplates', row.id)
        .then((response) => {
          const sentNotifsForInterviewTemplate = response.data;
          for (let interview of row.interviews) {
            const sentNotifs = sentNotifsForInterviewTemplate
              .filter(
                (notif) =>
                  notif.interviewId == interview.id &&
                  notif.interviewTemplateId == null
              )
              .sort((a, b) => b.updatedAt - a.updatedAt);
            if (sentNotifs && sentNotifs.length > 0) {
              const lastNotif = sentNotifsForInterviewTemplate
                .filter(
                  (notif) =>
                    notif.interviewId == interview.id &&
                    notif.status == 1 &&
                    notif.interviewTemplateId == null
                )
                .sort(
                  (a, b) =>
                    new Date(b.updatedAt).getTime() -
                    new Date(a.updatedAt).getTime()
                )[0];
              if (lastNotif) {
                let mailsCount = sentNotifs.filter(
                  (n) => n.type == 'mail' && n.status == 1
                ).length;
                interview.lastNotification = {
                  date: lastNotif.updatedAt,
                  type:
                    lastNotif.type == 'mail'
                      ? mailsCount == 1
                        ? 'mail'
                        : 'relance' + (mailsCount - 1)
                      : lastNotif.type === 'customerServiceCall'
                      ? 'relance nadège'
                      : 'sms'
                };
              }
            }
            interview.remainingNotifications = sentNotifs.filter(
              (n) => n.status == 0
            ).length;
          }
          row.interviews = [...row.interviews];
          this.$forceUpdate();
        });
    },
    /**
     * @name computeColorFromValue
     * @desc Compute color based on value
     * @param {number} value - The value to determine color for
     * @returns {string} - Color code
     */
    computeColorFromValue(value) {
      if (value == 0) {
        return '#ffe9e9';
      } else if (value < 30) {
        return '#ffcbc4';
      } else if (value < 50) {
        return '#f7db5e';
      } else {
        return '#9ffcb0';
      }
    }
  }
};
</script>
  
<style src="../index.css"></style>