<template>
  <section>
    <h2>
      {{ $t('interestingTools') }}
    </h2>
    <br />
    <div class="tile is-ancestor">
      <div class="tile is-parent tool-card_container">
        <div class="tile is-child tool-card">
          <div
            class="card"
            style="position: relative;"
          >
            <figure class="image is-square">
              <img
                src="./../../../public/img/Popup.png"
                alt="Placeholder image"
              />
            </figure>
            <div class="card-content">
              <p
                class="title is-4"
                style="font-size: 20px;"
              >Free Pop-Up</p>
              <div class="content">
                <p style="height: 125px; ">{{ $t('freePopDesc') }}</p>

              </div>
              <div style="display: flex; justify-content: center;">
                <button
                  v-if="!hasFreePopUp"
                  @click="$router.push({ name: 'integrationDetails', params: { id: 'popup' } })"
                  class="button-3d-small"
                >{{ $t('addSmall') }}</button>
                <button
                  v-else
                  disabled
                  class="button-3d-small"
                >{{ $t('alreadyAdded') }}</button>
              </div>
            </div>
          </div>
        </div>
        <div class="tile is-child tool-card">
          <div
            class="card"
            style="position: relative;"
          >
            <figure class="image is-square">
              <img
                src="./../../../public/img/Slider.png"
                alt="Placeholder image"
              />
            </figure>
            <div class="card-content">
              <p
                class="title is-4"
                style="font-size: 20px;"
              >Free Slider</p>
              <div class="content">
                <p style="height: 125px; ">{{ $t('freeSliderDesc') }}</p>

              </div>
              <div style="display: flex; justify-content: center;">
                <button
                  v-if="!hasFreeSlider"
                  @click="$router.push({ name: 'integrationDetails', params: { id: 'slider' } })"
                  class="button-3d-small"
                >{{ $t('addSmall') }}</button>
                <button
                  v-else
                  disabled
                  class="button-3d-small"
                >{{ $t('alreadyAdded') }}</button>
              </div>

            </div>
          </div>
        </div>
        <div class="tile is-child tool-card">
          <div style="width: 100%; text-align: center; position: absolute; top: -10px; z-index: 1;">
            <div style="background-color: #e7e1ff; color: #7957d5; letter-spacing: 2px; width: 60%; margin: 0 auto; font-size: 12px;border-radius: 2px;">
              {{ $t('newTool') }}</div>
          </div>
          <div class="card">

            <figure class="image is-square">
              <img
                src="./../../../public/img/PopupXL.png"
                alt="Placeholder image"
              />
            </figure>
            <div class="card-content">
              <div style=" display: flex; justify-content: space-between;">
                <p
                  class="title is-4"
                  style="font-size: 20px; "
                >Big Pop-Up</p>
                <img
                  src="./../../../public/img/token.png"
                  style="width: 25px;
    height: 25px;"
                />
              </div>
              <div class="content">
                <p style=" height: 125px;">{{ $t('bigPopDesc') }}</p>

              </div>
              <div style="display: flex; justify-content: center;">
                <button
                  @click="$router.push({ name: 'integrationDetails', params: { id: 'bigpopup' } })"
                  class="button-3d-small"
                >{{ $t('addSmall') }}</button>
              </div>
            </div>
          </div>
        </div>
        <div class="tile is-child tool-card">
          <div style="width: 100%; text-align: center; position: absolute; top: -10px; z-index: 1;">
            <div style="background-color: #e7e1ff; color: #7957d5; letter-spacing: 2px; width: 60%; margin: 0 auto;font-size: 12px;border-radius: 2px;">
              {{ $t('newTool') }}</div>
          </div>
          <div class="card">

            <figure class="image is-square">
              <img
                src="./../../../public/img/SliderXL.png"
                alt="Placeholder image"
              />
            </figure>
            <div class="card-content">
              <div style=" display: flex; justify-content: space-between;">
                <p
                  class="title is-4"
                  style="font-size: 20px; "
                >Big Slider</p>
                <img
                  src="./../../../public/img/token.png"
                  style="width: 25px;
    height: 25px;"
                />
              </div>
              <div class="content">
                <p style=" height: 125px;">{{ $t('bigSliderDesc') }}</p>

              </div>
              <div style="display: flex; justify-content: center;">
                <button
                  @click="$router.push({ name: 'integrationDetails', params: { id: 'bigslider' } })"
                  class="button-3d-small"
                >{{ $t('addSmall') }}</button>
              </div>
            </div>
          </div>
        </div>
        <div class="tile is-child tool-card">
          <div style="width: 100%; text-align: center; position: absolute; top: -10px; z-index: 1;">
            <div style="background-color: #7957d5;  color: white; letter-spacing: 2px; width: 60%; margin: 0 auto;font-size: 12px; border-radius: 2px;">
              {{ $t('popular') }}</div>
          </div>

          <div class="card">

            <figure class="image is-square">
              <img
                src="./../../../public/img/WallOfLove.png"
                alt="Placeholder image"
              />
            </figure>
            <div
              class="card-content"
              style="position: relative;"
            >

              <div style=" display: flex; justify-content: space-between;">
                <p
                  class="title is-4"
                  style="font-size: 20px; "
                >Wall of Love</p>
                <img
                  src="./../../../public/img/token.png"
                  style="width: 25px;
    height: 25px;"
                />
              </div>

              <div class="content">
                <p style="height: 125px;">{{ $t('wallDesc') }}</p>

              </div>
              <div style="display: flex; justify-content: center;">
                <button
                  @click="$router.push({ name: 'integrationDetails', params: { id: 'wall' } })"
                  class="button-3d-small"
                >{{ $t('addSmall') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>
</template>
    
  
<script>
/**
 * @name alltools
 * @desc Component for displaying all available tools
 */
export default {
  name: 'alltools',
  props: {
    /**
     * Flag indicating if the free slider tool is available
     * @type {Boolean}
     * @default true
     */
    hasFreeSlider: {
      type: Boolean,
      default: true
    },
    /**
     * Flag indicating if the free pop-up tool is available
     * @type {Boolean}
     * @default true
     */
    hasFreePopUp: {
      type: Boolean,
      default: true
    }
  }
};
</script>
  
  
<style src="./../../components/index.css"></style>

<style>
.tool-card_container {
  gap: 20px;
  width: 100%;
}

.tool-card {
  width: 20%;
  position: relative;
}

@media all and (max-width: 768px) {
  .tool-card {
    width: 100%;
  }

  .tool-card_container {
    gap: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
</style>

