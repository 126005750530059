<template>
  <div>
    <RecorderWrapper
      v-if="interviewData"
      :interviewData="interviewData"
      :externalId="$route.params.extId"
    />
    <AlreadyRegistered v-if="showError" />
    <b-modal
      v-model="isChromeIOS"
      full-screen
      :can-cancel="false"
    >
      <div
        class="notification"
        style="
          text-align: center;
          background-color: #f9664c;
          height: 100vh;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        "
      >
        <p style="color: white !important; font-weight: bold; font-size: 20px">
          {{ $t('navError') }}
        </p>
      </div>
    </b-modal>
    <b-loading
      is-full-page
      :active.sync="loading"
    />
  </div>
</template>

<script>
/**
 * @name charlieWrapper2
 * @desc Vue component for managing Charlie Wrapper 2 functionality
 */
import RecorderWrapper from '../components/recorderWrapper.vue';
import AlreadyRegistered from '../components/alreadyRegistered.vue';
import * as Sentry from '@sentry/vue';

export default {
  name: 'charlieWrapper2',
  components: {
    RecorderWrapper,
    AlreadyRegistered
  },
  props: {},
  data() {
    return {
      /**
       * @desc Flag for identifying Chrome on iOS
       * @type {boolean}
       */
      isChromeIOS: false,
      /**
       * @desc Data related to the interview
       * @type {object}
       */
      interviewData: null,
      /**
       * @desc Flag to indicate if an error should be shown
       * @type {boolean}
       */
      showError: false,
      /**
       * @desc Flag to indicate loading state
       * @type {boolean}
       */
      loading: false
    };
  },
  beforeMount() {
    const replay = Sentry.getCurrentHub()
      .getClient()
      ?.getIntegration(Sentry.Replay);
    if (replay && !replay.getReplayId()) {
      replay.start(); // or whatever
    }
    const extId = this.$route.params.extId;
    this.$store
      .dispatch('getQuestionsForInterview', extId)
      .then((data) => {
        this.interviewData = data;
        console.log('interviewData', this.interviewData);
      })
      .catch((err) => {
        this.showError = true;
      });
  },
  methods: {
    /**
     * @name stopInterview
     * @desc Method to stop the interview process
     */
    stopInterview() {
      this.loading = true;
      this.$store
        .dispatch('cancelInterview', this.$route.params.extId)
        .then(() => {
          this.loading = false;
          this.$buefy.notification.open({
            message: this.$t('recorderNotification'),
            type: 'is-danger',
            position: 'is-bottom',
            duration: 5000,
            queue: false
          });
        });
    }
  }
};
</script>

<style src="./../components/charlie/charlie.css">
</style>