<template>
  <section class="tile is-vertical">
    <draggable
      v-if="localQuestions"
      @start="drag = true"
      @end="drag = false; updateOrder()"
      v-model="localQuestions"
    >
      <div
        class="item-row"
        v-for="(item, index) in localQuestions"
        :key="index"
      >
        <div class="item">
          <label v-if="editedQuestionIndex !== index">{{ index + 1 }} : {{ item.text }}</label>
          <input
            class="add-input"
            autocomplete="off"
            maxlength="83"
            :placeholder="$t('questionHere')"
            v-else
            v-model="item.text"
            @keyup.enter="doneUpdateItem(index)"
          />
          <div
            style="background-color: #12c477; color: white; padding:2px; margin-left: 10px; margin-top:5px; border-radius: 5px; height: 20px; width: 20px"
            v-if="editedQuestionIndex === index"
            @click="doneUpdateItem(index)"
          > <b-icon
              pack="fas"
              icon="check"
              size="is-small"
              style="color: white"
            > </b-icon></div>
          <div
            v-if="showHandle && editedQuestionIndex !== index"
            style="display: flex; justify-content: end; gap: 10px; align-items: center;"
          >
            <div
              @click="updateItem(index)"
              class="question_buttons-display"
            >
              <b-icon
                pack="fas"
                icon="edit"
                size="is-small"
                style="color: #828282"
              > </b-icon>
            </div>
            <div class="question_buttons-display">
              <b-icon
                pack="fas"
                icon="arrows-up-down-left-right"
                size="is-small"
                style="color: #828282"
              >
              </b-icon>
            </div>
          </div>
        </div>
        <div
          v-if="showDelete && editedQuestionIndex !== index"
          class="trash-icon"
          @click="deleteItem(index)"
          style="display:flex"
        >
          <b-icon
            pack="fas"
            icon="trash"
            size="is-small"
            style="color: #828282"
          > </b-icon>
        </div>
      </div>
    </draggable>

    <div
      v-if="showAdd && localQuestions.length <= 4"
      class="tile is-vertical"
      style="max-height: 50px"
    >
      <div
        class="tile"
        style="padding-bottom: 15px"
      >
        <input
          ref="questionInput"
          id="add-input"
          class="add-input"
          autocomplete="off"
          maxlength="100"
          :placeholder="$t('questionHere')"
          v-model="newItem"
          v-on:keyup.enter="addItem(newItem)"
          v-on:focusout="addItem(newItem)"
        />
        <div
          :style="newItem ? 'background-color: #12c477;' : 'background-color: white;'"
          style="color: white; padding:2px; margin-left: 10px; margin-top:5px; border-radius: 5px; height: 20px; width: 20px"
          @click="addItem(newItem)"
        >
          <b-icon
            pack="fas"
            icon="check"
            size="is-small"
            style="color: white"
          > </b-icon>
        </div>
      </div>

      <div
        v-if="localQuestions.length <= 4"
        class="tile"
        style="display: flex; justify-content: center"
      >
        <b-button
          v-if="localQuestions.length < 4"
          expanded
          @click="addItem(newItem)"
          icon-left="add"
          style="
            margin-right: 10px;
    
            background-color: white;
            color: #828282;
            border: 1px dashed #e1e1e1;
            font-size: 14px;
          "
        >&nbsp;&nbsp;{{ $t('addQuestion') }}</b-button>

        <b-button
          v-else
          expanded
          @click="addItem(newItem)"
          icon-left="add"
          style="
            margin-right: 10px;
        
            background-color: white;
            color: #828282;
            border: 1px dashed #e1e1e1;
                font-size: 14px;
          "
        >&nbsp;&nbsp;{{ $t('addLastQuestion') }}</b-button>
      </div>
    </div>
  </section>
</template>

<script>
/**
 * @name QuestionList
 * @desc Component for managing a list of questions with drag-and-drop functionality
 */
import draggable from 'vuedraggable';

export default {
  props: {
    /**
     * Flag to show handle for dragging questions
     * @type {boolean}
     */
    showHandle: {
      default: true
    },
    /**
     * Flag to show delete button for questions
     * @type {boolean}
     */
    showDelete: {
      default: true
    },
    /**
     * Flag to show enumeration for questions
     * @type {boolean}
     */
    showEnumeration: {
      default: true
    },
    /**
     * Flag to show add button for adding new questions
     * @type {boolean}
     */
    showAdd: {
      default: true
    },
    /**
     * Array of questions
     * @type {Array}
     */
    questions: {
      type: Array,
      default: []
    }
  },
  components: {
    draggable
  },
  data() {
    return {
      /**
       * @desc New question item
       * @type {string}
       */
      newItem: '',
      /**
       * @desc Local copy of questions
       * @type {Array}
       */
      localQuestions: [],
      /**
       * @desc Index of the question being edited
       * @type {number}
       */
      editedQuestionIndex: -1
    };
  },
  activated() {
    this.localQuestions = this.questions;
  },
  methods: {
    /**
     * @name updateOrder
     * @desc Updates the order of questions
     */
    updateOrder() {
      this.localQuestions.forEach((item, index) => {
        item.index = index;
      });
      this.$emit('questionChange');
      this.$emit('update:questions', this.localQuestions);
    },
    /**
     * @name deleteItem
     * @desc Deletes a question at the specified index
     * @param {number} index - Index of the question to delete
     */
    deleteItem(index) {
      this.localQuestions.splice(index, 1);
      this.localQuestions.forEach((item, index) => {
        item.index = index;
      });
      this.$emit('questionChange');
      this.$emit('update:questions', this.localQuestions);
    },
    /**
     * @name addItem
     * @desc Adds a new question
     * @param {string} e - New question text
     */
    addItem(e) {
      if (e.trim() !== '') {
        this.localQuestions.push({
          text: e,
          index: this.localQuestions.length
        });
        this.newItem = '';
        this.$emit('questionChange');
        this.$emit('update:questions', this.localQuestions);
      }
    },
    /**
     * @name doneUpdateItem
     * @desc Finishes updating a question
     * @param {number} index - Index of the question being updated
     */
    doneUpdateItem(index) {
      this.editedQuestionIndex = -1;
      this.$emit('questionChange');
      this.$emit('update:questions', this.localQuestions);
    },
    /**
     * @name updateItem
     * @desc Initiates updating a question
     * @param {number} index - Index of the question to update
     */
    updateItem(index) {
      this.editedQuestionIndex = index;
    }
  },
  watch: {
    questions: {
      handler(newValue) {
        this.localQuestions = newValue;
      },
      deep: true
    },
    localQuestions() {
      this.$emit('update:questions', this.localQuestions);
    }
  }
};
</script>

<style >
@import url('https://fonts.googleapis.com/css2?family=Knewave&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;700;900&display=swap');

.component-body {
  width: 100%;
  padding-top: 10px;
}

.draggable-area {
  display: flex;
  flex-direction: column;
}

.item-row {
  font-family: 'Rubik', sans-serif;
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  margin-top: 5px;
  justify-content: space-between;
  align-content: center;
  font-size: 14px !important;
}

.item {
  font-family: 'Rubik', sans-serif;
  color: #343434;
  width: 100%;
  border-radius: 3px;
  border: 1px solid #dadada;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  font-size: 14px !important;
}

.bars-icon {
  color: lightgray;
  cursor: grab;
}

.trash-icon {
  color: #161032;
  cursor: pointer;
  align-self: center;
  margin-left: 12px;
}

.add-container {
  width: 80%;
  display: flex;
  padding: 20px;
  justify-content: space-between;
  align-content: center;
  font-family: 'Rubik', sans-serif;
}

.add-input {
  font-family: 'Rubik', sans-serif;
  border-radius: 0px;
  border-color: transparent;
  border-bottom: 1px solid #828282;
  width: 100%;
  color: #828282;
  padding: 5px;
  font-size: 14px;
}

.add-input:focus {
  outline: none !important;
  border: 2px solid;
  border-color: #d9dd1b;
}

::placeholder {
  color: #aeaeae;
  font-family: 'Rubik', sans-serif;
}
</style>