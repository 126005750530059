<template>
  <section>
    <div class="tile is-parent">
      <router-link
        to="/testimonials"
        style="display: flex; align-items: center;"
      >
        <img
          src="./../../public/img/arrow.png"
          style="height: 15px; "
        />
        <p style="margin-left: 15px; font-weight: 300; color: #828282 !important">{{ $t('back') }}</p>
      </router-link>

      <p style="margin-left: 50px; ">{{ interview.user.surname[0].toUpperCase() + interview.user.surname.slice(1) }} {{
        interview.user.name[0].toUpperCase() + interview.user.name.slice(1) }} - {{
    interview.user.updatedAt | momentFilter($i18n.locale) }}</p>
    </div>
    <hr />

    <b-tabs
      :vertical="isVertical"
      v-model="tabIndex"
    >
      <b-tab-item :label="$t('testimony')">
        <div class="tab-inside_container">
          <div class="video-container">
            <!-- Lecteur vidéo -->
            <video
              ref="videoPlayer"
              width="80%"
              controls
              controlslist="nodownload ; nofullscreen ; noremoteplayback ; noplaybackrate"
              disablePictureInPicture
            >
              <source
                :src="interview.squareMedia.url"
                type="video/mp4"
              />
              Votre navigateur ne supporte pas la balise vidéo.
            </video>
          </div>
        </div>
      </b-tab-item>
      <b-tab-item :label="$t('subtitles')">
        <div>
          <SubEditor
            :interview="interview"
            v-on:doneBurning='afterBurn($event)'
          > </SubEditor>
        </div>
      </b-tab-item>
      <b-tab-item :label="$t('download')">
        <div class="tab-inside_container">
          <div class="button_container">
            <div class="button_display">
              <div class="format_background">
                <div style="background-color: #828282; height: 75%; width: 75%;"><img :src=interview.squareMedia.thumbnailUrl /></div>
              </div>

              <div style="width: 100%;">
                <b-button
                  size="is-small"
                  type="is-dark"
                  icon-left="download"
                  expanded
                  @click="downloadFile(interview.squareMedia.url, 'YourCharlie-square.mp4')"
                >
                  &nbsp; &nbsp;{{ $t('squareVideo') }}
                </b-button>

              </div>
            </div>
          </div>
          <div class="button_container">
            <div class="button_display">
              <div class="format_background">
                <div :style="{
                  backgroundImage: 'url(' + interview.verticalMedia.thumbnailUrl + ')',
                  backgroundSize: 'cover',
                  height: '76%',
                  width: '42%',
                  backgroundPosition: 'center',
                }">
                </div>
              </div>
              <div style="width: 100%;">
                <b-button
                  size="is-small"
                  type="is-dark"
                  icon-left="download"
                  expanded
                  @click="downloadFile(interview.verticalMedia.url, 'YourCharlie-vertical.mp4')"
                >
                  &nbsp; &nbsp;{{ $t('verticalVideo') }}
                </b-button>
              </div>
            </div>
          </div>
          <div class="button_container">
            <div class="button_display">
              <div class="format_background">
                <div :style="{
                  backgroundImage: 'url(' + interview.squareMedia.thumbnailUrl + ')',
                  backgroundSize: '115%',
                  height: '40%',
                  width: '75%',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat'
                }">
                </div>
              </div>

              <div style="width: 100%;">
                <b-button
                  size="is-small"
                  type="is-dark"
                  icon-left="download"
                  expanded
                  @click="downloadOriginals()"
                >
                  &nbsp; &nbsp;{{ $t('downloadRush') }}
                </b-button>
              </div>
            </div>
          </div>
          <div
            class="button_container"
            v-if="interview.squareMediaSubtitledUrl"
          >
            <div class="button_display">
              <div style="position: absolute; top: 18px; right: 18px;"><img
                  src="./../../public/img/ST.png"
                  style="width: 40px;"
                /></div>
              <div class="format_background">

                <div style="background-color: #828282; height: 75%; width: 75%;"><img :src=interview.squareMedia.thumbnailUrl /></div>

              </div>
              <div style="width: 100%;">
                <b-button
                  size="is-small"
                  type="is-dark"
                  icon-left="download"
                  expanded
                  @click="downloadFile(interview.squareMediaSubtitledUrl, 'YourCharlie-square-st.mp4')"
                >
                  &nbsp; &nbsp;{{ $t('squareVideo') }}&nbsp;({{ $t('subtitled') }})
                </b-button>
              </div>
            </div>
          </div>
          <div
            class="button_container"
            v-if="interview.verticalMediaSubtitledUrl"
          >
            <div class="button_display">
              <div style="position: absolute; top: 18px; right: 56px;"><img
                  src="./../../public/img/ST.png"
                  style="width: 40px;"
                /></div>
              <div class="format_background">
                <div :style="{
                  backgroundImage: 'url(' + interview.verticalMedia.thumbnailUrl + ')',
                  backgroundSize: 'cover',
                  height: '76%',
                  width: '42%',
                  backgroundPosition: 'center',
                }">
                </div>
              </div>
              <div style="width: 100%;">
                <b-button
                  size="is-small"
                  type="is-dark"
                  icon-left="download"
                  expanded
                  @click="downloadFile(interview.verticalMediaSubtitledUrl, 'YourCharlie-vertical-st.mp4')"
                >
                  &nbsp; &nbsp;{{ $t('verticalVideo') }}&nbsp;({{ $t('subtitled') }})
                </b-button>
              </div>
            </div>
          </div>

          <div
            class="button_container"
            v-if="interview.subtitles !== null"
          >
            <div class="button_display">
              <div class="format_background">
                <div style=" height: 65%; width: 65%;">
                  <img src="./../../public/img/SRT.png" />
                </div>
              </div>
              <div style="width: 100%;">
                <b-button
                  size="is-small"
                  type="is-dark"
                  icon-left="download"
                  expanded
                  @click="downloadSRT(interview.subtitles)"
                >
                  &nbsp; &nbsp;{{ $t('fichiers srt') }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </b-tab-item>

      <b-tab-item :label="$t('imageRightsS')">
        <div class="tab-inside_container">
          <iframe
            :src="pdfSource"
            width="800px"
            height="800px"
          ></iframe>
        </div>
      </b-tab-item>

    </b-tabs>
  </section>
</template>

<script>
import i18n from '@/i18n';
import SubEditor from './../components/subEditor.vue';
import moment from 'moment';
import { saveAs } from 'file-saver';
import JsZip from 'jszip';
import JSZipUtils from 'jszip-utils';

/**
 * @name testimonials_manager
 * @desc Manages the testimonials, including viewing, downloading, and handling subtitles.
 */

export default {
  name: 'testimonials_manager',
  props: {
    /**
     * @desc The unique identifier for the interview
     * @type Number
     * @required true
     */
    id: {
      type: Number,
      required: true
    },
    /**
     * @desc The interview object containing details like media URLs and subtitles
     * @type Object
     * @required true
     */
    interview: {
      type: Object,
      required: true
    }
  },
  components: {
    SubEditor
  },

  data() {
    return {
      /**
       * @type {string}
       * @desc The PDF source
       */
      pdfSource: '',
      /**
       * @type {number}
       * @desc The screen width
       */
      screenWidth: window.innerWidth,
      /**
       * @type {number}
       * @desc The tab index
       */
      tabIndex: 0
    };
  },
  watch: {
    /**
     * @name watch:interview
     * @desc Watches for changes in the interview prop, specifically for termsUrl updates.
     */
    interview: {
      handler(newVal) {
        if (newVal && newVal.termsUrl) {
          this.pdfSource = newVal.termsUrl;
        }
      },
      immediate: true
    }
  },
  computed: {
    /**
     * @name isVertical
     * @desc Computes if the screen width is greater than 1200px to adjust layout orientation.
     * @returns {Boolean}
     */
    isVertical() {
      return this.screenWidth > 1200;
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.downloadSRT = this.downloadSRT.bind(this);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },

  methods: {
    /**
     * @name afterBurn
     * @desc Updates the interview data and sets the tabIndex to 2 after a burn operation.
     * @param {Object} interview - The updated interview object
     */
    afterBurn(interview) {
      this.interview = interview;
      this.tabIndex = 2;
    },
    /**
     * @name handleResize
     * @desc Updates screenWidth on window resize.
     */
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
    /**
     * @name downloadSRT
     * @desc Downloads the subtitles as an SRT file.
     * @param {String} data - The subtitle data in JSON string format
     */
    downloadSRT(data) {
      let parsedData;
      try {
        parsedData = JSON.parse(data);
      } catch (e) {
        console.error('Error parsing data:', e);
        return;
      }

      if (!Array.isArray(parsedData)) {
        console.error('Parsed data is not an array', parsedData);
        return;
      }

      const srtContent = parsedData
        .map((item, index) => {
          const startTime = this.convertTime(item.start);
          const endTime = this.convertTime(item.end);
          return `${index + 1}\n${startTime} --> ${endTime}\n${item.text}\n`;
        })
        .join('\n\n');

      const blob = new Blob([srtContent], { type: 'text/plain' });
      saveAs(blob, 'YourCharlie.srt');
    },

    /**
     * @name convertTime
     * @desc Converts time in seconds to a formatted string.
     * @param {Number} seconds - The time in seconds
     * @returns {String} - The formatted time string
     */
    convertTime(seconds) {
      const date = new Date(0);
      date.setSeconds(seconds);
      return date
        .toISOString()
        .substr(11, 12)
        .replace('T', ' ')
        .replace('Z', ',');
    },

    /**
     * @name downloadFile
     * @desc Downloads a file from a given URL.
     * @param {String} url - The URL of the file to download
     * @param {String} filename - The name of the file to save as (optional)
     */
    downloadFile(url, filename) {
      if (!url) {
        console.error('URL is not provided');
        return;
      }

      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          saveAs(blob, filename || 'download.mp4');
        })
        .catch((error) => {
          console.error('Error downloading the file:', error);
        });
    },
    /**
     * @name downloadOriginals
     * @desc Downloads all original media files related as a zip file.
     */
    downloadOriginals() {
      const loading = this.$buefy.loading.open({
        message: this.$t('downloading')
      });

      if (this.interview && Array.isArray(this.interview.answers)) {
        const urls = this.interview.answers.map((answer) => answer.media.url);
        console.log('Answers Media URLs:', urls);

        if (urls.length > 0) {
          const zip = new JsZip();
          let count = 0;
          const zipFilename = 'charlie_' + new Date().toISOString() + '.zip';

          urls.forEach((url, index) => {
            const urlArr = url.replaceAll('%2F', '/').split('/');
            const filename = urlArr[urlArr.length - 1].split('?')[0];

            JSZipUtils.getBinaryContent(url, (err, data) => {
              if (err) {
                this.$buefy.notification.open({
                  message: this.$t('downloadingError'),
                  type: 'is-danger',
                  position: 'is-bottom-right',
                  duration: 5000
                });
                throw err; // Vous pouvez également choisir de gérer cette erreur différemment
              }

              zip.file(filename, data, { binary: true });
              count++;

              if (count === urls.length) {
                zip.generateAsync({ type: 'blob' }).then(function (content) {
                  saveAs(content, zipFilename);
                  loading.close();
                });
              }
            });
          });
        } else {
          loading.close();
          this.$buefy.notification.open({
            message: this.$t('noFile'),
            type: 'is-danger',
            position: 'is-bottom-right',
            duration: 5000
          });
        }
      } else {
        loading.close();
        this.$buefy.notification.open({
          message: this.$t('noAnswers'),
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 5000
        });
      }
    }
  }
};
</script>

<style src="./../components/index.css"></style>

<style scoped>
p {
  font-weight: 600;
}

.button_container {
  display: flex;
  margin-bottom: 3%;
}

.button_display {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.format_background {
  height: 250px;
  width: 250px;
  background-color: #f0f0f0;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-spec {
  height: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 6%;
  padding: 10px !important;
}

.tab-title {
  margin: -2% 0 3% 2%;
}

.b-tabs.is-vertical > .tab-content {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.b-tabs .tab-content {
  align-items: center;
}

.tab-inside_container {
  width: 855px !important;
  display: flex;
  gap: 6%;
  margin: 0 0 0 8%;
  flex-wrap: wrap;
}
</style>