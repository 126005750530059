<template>
  <div>
    <div v-if="$i18n.locale == 'fr'">
      <h5 style="text-align: center; font-size: 14px !important">Autorisation de droit à l'image ✍️</h5>
      <br />
      <p class="rightsStyle">
        Je soussigné {{ guestSurName }} {{ guestLastName }}, {{ guestEmail }}
        {{ guestPhone }}, agissant en mon nom personnel ou pour le compte de la
        société que je représente, <br /><br />
        Autorise la société {{ userBusiness }}, à reproduire et exploiter mon
        image fixée dans le cadre de l’enregistrement de mon témoignage vidéo
        qui a lieu le {{ recordDay }} à {{ recordHour }}. <br /><br />
        Je reconnais avoir enregistré mes réponses vidéos dans le respect des
        conditions générales d'utilisation
        du logiciel Charlie, consultables à l'adresse https://www.yourcharlie.com/cgu/?lang=fr.
        <br /><br />
        La fixation de mon image et/ou de ma voix dans le cadre des vidéos
        prises au cours du témoignage précité et la reproduction de mon image
        ainsi fixée par tous procédés techniques connus ou inconnus à ce jour
        (graphique, photographique, numérique, etc.), sur tous supports que le
        cessionnaire serait amenée à utiliser ou créer dans le but de sa
        promotion, etc.) sur tous formats, pour un nombre illimité
        d’utilisations, en intégralité ou en partie, ensemble ou séparément, aux
        fins de communication.
        <br /><br />
        La communication au public de mon image et/ou de ma voix ainsi fixée et
        reproduite, en tout ou en partie, au travers de tout moyen de diffusion,
        connu ou inconnu à ce jour, et notamment communication par voie
        électronique (site internet, Extranet, Intranet, etc., quel qu’en soit
        le format et quel qu’en soit le vecteur et l’appareil de réception,
        ainsi que par mise à la disposition du public quel que soit le procédé
        analogique ou numérique (et notamment downloading, uploading, etc.) ou
        le mode de transmission audiovisuel ou téléphonique mobile ou fixe
        utilisé. <br /><br />
        Les éventuels commentaires et/ou légendes accompagnant le cas échéant la
        reproduction ou la représentation de l’image et/ou de la voix totale ou
        partielle du cédant dans les conditions visées ci-dessus ne porteront
        pas atteinte à la réputation ou à la vie privée du cédant. <br /><br />
        L’exploitation de mon image est uniquement consentie à titre
        promotionnel pour l’entreprise {{ userBusiness }} et aucun usage à titre
        commercial n’est autorisé sans nouvel accord préalable entre le cédant
        et le cessionnaire. <br /><br />
        La présente cession de droits est consentie :
        <br />- Pour une exploitation dans le monde entier <br />- Pour une
        durée limitée à 3 ans à compter de la date de signature du présent
        contrat. <br />- De manière irrévocable durant la durée de ce contrat<br /><br />
        La présente autorisation d’exploitation de mon droit à l’image est
        consentie à titre gratuit et ne pourra pas faire l’objet d’une demande
        de rémunération a posteriori.
        <br /><br />
        Fait le {{ recordDay }} <br /><br />
        {{ guestSurName }} {{ guestLastName }}
      </p>
    </div>
    <div v-if="$i18n.locale == 'en'">
      <h5 style="text-align: center; font-size: 14px !important">Image rights authorization ✍️</h5>
      <br />
      <p class="rightsStyle">
        I, the undersigned {{ guestSurName }} {{ guestLastName }},
        {{ guestEmail }} {{ guestPhone }}, acting on my own behalf or on behalf
        of the company I represent, <br /><br />
        Authorize the company {{ userBusiness }} to reproduce and exploit my
        image captured during the recording of my video testimonial which takes
        place on {{ recordDay }} at {{ recordHour }}. <br /><br />
        I acknowledge that I have recorded my video responses in compliance with
        the
        terms and conditions of the Charlie software, available at https://www.yourcharlie.com/cgu-charlie/.
        <br /><br />
        The capturing of my image and/or voice in the context of the videos
        taken during the aforementioned testimonial and the reproduction of my
        image thus captured by any known or unknown technical means to date
        (graphic, photographic, digital, etc.), on any media that the assignee
        may use or create for promotional purposes, etc.) in any format, for an
        unlimited number of uses, in whole or in part, together or separately,
        for communication purposes.
        <br /><br />
        The public communication of my image and/or voice thus captured and
        reproduced, in whole or in part, through any means of dissemination,
        known or unknown to date, including electronic communication (website,
        Extranet, Intranet, etc., regardless of the format and regardless of the
        receiving device, as well as by making it available to the public,
        regardless of the analog or digital process (including downloading,
        uploading, etc.) or the audiovisual or mobile or fixed telephone
        transmission mode used. <br /><br />
        The public communication of my image and/or voice thus captured and
        reproduced, in whole or in part, through any means of dissemination,
        known or unknown to date, including electronic communication (website,
        Extranet, Intranet, etc., regardless of the format and regardless of the
        receiving device, as well as by making it available to the public,
        regardless of the analog or digital process (including downloading,
        uploading, etc.) or the audiovisual or mobile or fixed telephone
        transmission mode used. <br /><br />
        The present transfer of rights is granted:
        <br />- For worldwide exploitation <br />- For a limited duration of 3
        years from the date of signing of this contract. <br />- In an
        irrevocable manner during the duration of this contract.<br /><br />
        This authorization for the use of my image is granted free of charge and
        cannot be subject to any request for remuneration afterwards.
        <br /><br />
        Done on {{ recordDay }} <br /><br />
        {{ guestSurName }} {{ guestLastName }}
      </p>
    </div>
    <div v-if="$i18n.locale == 'es'">
      <h5 style="text-align: center; font-size: 14px !important">Autorización de derechos de imagen ✍️</h5>
      <br />
      <p class="rightsStyle">
        Yo, el abajo firmante {{ guestSurName }} {{ guestLastName }},
        {{ guestEmail }} {{ guestPhone }}, actuando en mi propio nombre o en
        nombre de la empresa que represento, <br /><br />
        Autorizo a la empresa {{ userBusiness }} a reproducir y explotar mi
        imagen capturada durante la grabación de mi testimonio en video que
        tiene lugar el {{ recordDay }} a las {{ recordHour }}. <br /><br />
        Reconozco que he grabado mis respuestas en video cumpliendo con los
        términos y condiciones
        del software Charlie, disponibles en https://www.yourcharlie.com/cgu-charlie/?lang=es.
        <br /><br />
        La captura de mi imagen y/o voz en el contexto de los videos tomados
        durante el testimonio mencionado anteriormente y la reproducción de mi
        imagen así capturada mediante cualquier medio técnico conocido o
        desconocido hasta la fecha (gráfico, fotográfico, digital, etc.), en
        cualquier medio que el cesionario pueda utilizar o crear con fines
        promocionales, etc.) en cualquier formato, para un número ilimitado de
        usos, en su totalidad o en parte, juntos o por separado, con fines de
        comunicación.
        <br /><br />
        La comunicación al público de mi imagen y/o voz así capturada y
        reproducida, total o parcialmente, a través de cualquier medio de
        difusión, conocido o desconocido hasta la fecha, incluyendo la
        comunicación electrónica (sitio web, Extranet, Intranet, etc., sin
        importar el formato, el medio de recepción o el dispositivo utilizado),
        así como ponerla a disposición del público mediante cualquier proceso
        analógico o digital (incluyendo descarga, carga, etc.) o modo de
        transmisión audiovisual o telefónico móvil o fijo utilizado.
        <br /><br />
        Los posibles comentarios y/o leyendas que acompañen, en su caso, la
        reproducción o representación total o parcial de la imagen y/o voz del
        cedente en las condiciones mencionadas anteriormente no afectarán la
        reputación o la privacidad del cedente. <br /><br />
        La explotación de mi imagen está únicamente autorizada con fines
        promocionales para la empresa {{ userBusiness }} y no se permite ningún
        uso comercial sin un nuevo acuerdo previo entre el cedente y el
        cesionario. <br /><br />
        La presente cesión de derechos se otorga:
        <br />- Para su explotación en todo el mundo <br />- Por un período
        limitado de 3 años a partir de la fecha de firma de este contrato.
        <br />- De manera irrevocable durante la duración de este contrato.<br /><br />
        Esta autorización para la explotación de mi derecho de imagen se otorga
        de forma gratuita y no podrá ser objeto de una solicitud de remuneración
        posterior.
        <br /><br />
        Hecho el {{ recordDay }} <br /><br />
        {{ guestSurName }} {{ guestLastName }}
      </p>
    </div>
    <div v-if="$i18n.locale == 'it'">
      <h5 style="text-align: center; font-size: 14px !important">Autorizzazione dei diritti sull'immagine ✍️</h5>
      <br />
      <p class="rightsStyle">
        Io sottoscritto/a {{ guestSurName }} {{ guestLastName }}, {{ guestEmail }}
        {{ guestPhone }}, agendo in nome proprio o per conto della società che rappresento, <br /><br />
        Autorizzo la società {{ userBusiness }}, a riprodurre e utilizzare la mia
        immagine fissata durante la registrazione della mia testimonianza video
        che avrà luogo il {{ recordDay }} alle {{ recordHour }}. <br /><br />
        Riconosco di aver registrato le mie risposte video nel rispetto delle
        condizioni generali di utilizzo
        del software Charlie, consultabili all'indirizzo https://www.yourcharlie.com/cgu/?lang=it.
        <br /><br />
        La fissazione della mia immagine e/o voce nel contesto dei video
        realizzati durante la suddetta testimonianza e la riproduzione della mia immagine
        così fissata con qualsiasi processo tecnico noto o sconosciuto ad oggi
        (grafico, fotografico, digitale, ecc.), su qualsiasi supporto che il cessionario
        potrà utilizzare o creare a scopo promozionale, ecc.) in qualsiasi formato, per un numero illimitato
        di utilizzi, in tutto o in parte, insieme o separatamente, a fini di comunicazione.
        <br /><br />
        La comunicazione al pubblico della mia immagine e/o voce così fissata e
        riprodotta, in tutto o in parte, attraverso qualsiasi mezzo di diffusione,
        noto o sconosciuto ad oggi, e in particolare comunicazione elettronica (sito web, Extranet, Intranet, ecc., a
        prescindere
        dal formato e dal vettore e dispositivo di ricezione,
        così come la messa a disposizione del pubblico con qualsiasi processo
        analogico o digitale (inclusi downloading, uploading, ecc.) o modalità di trasmissione
        audiovisiva o telefonica mobile o fissa utilizzata. <br /><br />
        Eventuali commenti e/o didascalie che accompagnano la riproduzione o la rappresentazione
        dell'immagine e/o voce totale o parziale del cedente nelle condizioni sopra menzionate non dovranno
        ledere la reputazione o la privacy del cedente. <br /><br />
        L'utilizzo della mia immagine è concesso esclusivamente a scopo promozionale per l'azienda {{ userBusiness }}
        e non è permesso alcun uso commerciale senza un nuovo accordo preventivo tra il cedente
        e il cessionario. <br /><br />
        Il presente trasferimento di diritti è concesso:
        <br />- Per un utilizzo in tutto il mondo <br />- Per un periodo limitato a 3 anni dalla data di firma del
        presente
        contratto. <br />- In modo irrevocabile per la durata di questo contratto<br /><br />
        La presente autorizzazione all'utilizzo del mio diritto all'immagine è concessa a titolo gratuito e non potrà
        essere oggetto di richiesta di remunerazione successiva.
        <br /><br />
        Fatto il {{ recordDay }} <br /><br />
        {{ guestSurName }} {{ guestLastName }}
      </p>
    </div>
  </div>
</template>

<script>
/**
 * @name RightsCession
 * @desc Component for handling the cession of image rights in multiple languages
 */

export default {
  name: 'rightscession',
  props: {
    /**
     * Surname of the guest
     * @type {String}
     * @required
     */
    guestSurName: {
      type: String,
      required: true
    },
    /**
     * Last name of the guest
     * @type {String}
     * @required
     */
    guestLastName: {
      type: String,
      required: true
    },
    /**
     * Email of the guest
     * @type {String}
     * @required
     */
    guestEmail: {
      type: String,
      required: true
    },
    /**
     * Phone number of the guest
     * @type {String}
     * @required
     */
    guestPhone: {
      type: String,
      required: true
    },
    /**
     * Business name of the user
     * @type {String}
     * @required
     */
    userBusiness: {
      type: String,
      required: true
    },
    /**
     * Day of the record
     * @type {String}
     * @required
     */
    recordDay: {
      type: String,
      required: true
    },
    /**
     * Hour of the record
     * @type {String}
     * @required
     */
    recordHour: {
      type: String,
      required: true
    }
  },
  data() {
    return {};
  },
  methods: {}
};
</script>

<style src="./../components/index.css"></style>
