export const fr = {
  //* BUTTON *//

  addContact: 'ajouter une ligne',
  send: 'envoyer',
  sent: 'envoyé',
  validate: 'valider',
  download: 'télécharger',
  importCSV: 'importer un fichier CSV',
  importVideo: 'importer une vidéo',
  addQuestion: 'ajouter une question',
  addLastQuestion: 'ajouter une dernière question',
  videoRecord: 'enregistrer une vidéo',
  previous: 'précédent',
  draft: 'brouillon',
  seeMission: 'voir mes missions',
  confirm: 'confirmer',
  next: 'suivant',
  save: 'sauvegarder',
  logOut: 'Déconnexion',
  buy: 'acheter',
  imageRights: "attestation droits à l'image",
  downloadRush: 'fichiers originaux',
  addWidget: 'ajouter au widget',
  removeWidget: 'supprimer du widget',
  import: 'impoter un fichier',
  tryAgain: 'recommencer',
  continue: 'continuer',
  back: 'retour',
  cancel: 'annuler',
  delete: 'supprimer',
  duplicate: 'dupliquer',
  understood: "j'ai compris",
  yes: 'oui',
  no: 'non',
  submit: 'soumettre',
  embedButton: "intégrer sur mon site",
  exportSubtitled: "exporter la vidéo sous-titrée",
  subtitlesSaved: "Sous-titres sauvegardés",
  errorSubtitles: "Erreur lors de la sauvegardes des sous-titres",

  //* VUE TOUR *//

  buttonSkipTour: "Passer",
  buttonNextTour: "Suivant",
  buttonPreviousTour: "Précédent",
  buttonStopTour: "Terminer",
  content_directory1: "Importez un fichier CSV ou ajoutez manuellement la liste de vos clients.",
  content_directory2: "Retrouvez rapidement vos contacts grâce aux filtres.",
  content_directory3: "Éditez ou supprimez définitivement un contact et toutes ses données.",
  content_directory4: "C'est ici que vous envoyez vos demandes et que les témoignages vidéos collectés s'afficheront.",
  content_dash1: "Gérez vos demandes de témoignages depuis le répertoire clients.",
  content_dash2: "Diffusez vos témoignages vidéos sur votre site grâce à nos outils d’intégration.",
  content_dash3: "Retrouvez vos informations personnelles et vos factures dans votre espace compte.",
  content_dash4: "Achetez des packs de crédits pour pouvoir débloquer vos témoignages reçus.",

  //* TOAST MESSAGES *//

  clientDeleted: 'Client supprimé avec succès.',
  errorFormat: "Le format du fichier n'est pas pris en charge.",
  errorDuration: 'La durée de la vidéo est trop longue.',
  errorAnalysis: "Une erreur est survenue lors de l'analyse de la vidéo.",
  errorOccured: 'Une erreur est survenue !',
  errorOccuredForm: 'Une erreur est survenue ! Il manque des informations sur votre client !',
  errorIpnut: 'Veuillez remplir tous les champs',
  errorEmail: 'Un client avec cet email existe déjà',
  errorInfo: 'Vérifiez les coordonnées de vos invités, elles sont peut-être incomplètes ou incorrectes',
  sequenceLaunched: 'La séquence a été démarrée.',
  sequenceStopped: 'La séquence a été mise en pause.',
  validReminder: 'Les rappels ont été validés.',
  updateDone: 'Mise à jour effectuée',
  saved: 'sauvegardé',

  //* DASHBOARD *//

  hello: 'Bonjour',
  invites: 'invitations',
  welcomeWords:
    'J’existe pour t’aider à obtenir des témoignages vidéos de tes clients. Dis moi qui tu veux contacter et quelles sont tes questions, et dans l’instant, je te crée un lien unique que tu peux partager par mail ou whatsapp.',
  welcomeWords2:
    " Tu ne prends aucun risque à essayer mes services. Je suis payé uniquement si tu décides de débloquer les témoignages que j'aurai obtenu pour toi.",
  testimonials: 'témoignages',
  testimony: 'témoignage',
  videoTestimonials: 'témoignages vidéos',
  welcome: 'Bienvenue chez YourCharlie',
  explore: 'explorer le logiciel',
  askForTestimonial: 'demander un témoignage',
  onboardingMessage:
    'Avec ces quelques informations, vos demandes de témoignages seront plus personnalisées pour plus de résultats !',
  onboardingMessage2:
    'Passez à l’action maintenant et ajoutez un client pour lui demander un témoignage vidéo.',
  success: 'réussite',
  clients: "clients",
  goodStart: 'Bien démarrer avec YourCharlie :',
  infoChecked: 'Remplissez vos informations',
  infoChecked2: 'Pour des invitations plus personnalisées',
  addChecked: 'Ajoutez 3 clients',
  addChecked2: 'Pour commencer à remplir votre répertoire.',
  sendChecked: 'Envoyez 3 demandes',
  sendChecked2: 'Pour avoir 3x plus de chance de réussir.',
  unlockChecked: 'Débloquez 1 témoignage',
  unlockChecked2: 'Pour découvrir la puissance de YourCharlie.',
  publishChecked: 'Diffusez avec la popup',
  publishChecked2: 'Pour booster vos conversions.',
  add1st: 'Ajoutez votre premier client',

  //* SIDE BAR *//

  missions: 'missions',
  newMission: 'nouvelle mission',
  help: 'aide',
  sponsoring: 'parrainage',
  selection: 'diffuser',

  //* SPONSORING *//

  satisfaction: "Qu'est ce que tu penses de mon job",
  sponsorWords:
    "Comme tu le sais peut-être, je fonctionne à la recommandation ! Alors je te propose un deal : à chaque fois que tu invites une entreprise et qu'elle s'inscrit, je vous offre 25 crédits à tous les deux.",
  oneMoment:
    "Un instant, je m'occupe d'envoyer les invitations de parrainage 😉",
  onGoingSponsor: 'Parrainage en cours, merci de patienter 😎',
  fName: 'prénom',
  lName: 'nom',
  email: 'email',
  phone: 'téléphone',
  yourName: 'votre prénom',
  yourLastName: 'votre nom',
  sponsoringSuccess:
    "🟢 L'inscription de {name} {surname} est validée. 25 crédits ont été rajoutés sur ton compte et le sien 🎉",
  sponsoringWait: "🟡  L'inscription de {name} {surname} est en attente.",
  sponsoringFail:
    "🔴 L'inscription de {name} {surname} n'a pas aboutie et le lien est arrivé à expiration.",

  //* SPONSORED *//

  godfather: 'tu es parrainé par',
  reward:
    'ta récompense est de 25 crédits offerts une fois ton inscription validée',
  createAccount: 'crée un compte gratuit',
  invalidMail: 'Adresse email invalide.',

  //* SHOP *//
  '3videos': '3',
  '10videos': '10',
  '20videos': '20',
  '75credits': '75 crédits',
  '250credits': '250 crédits',
  '500credits': '500 crédits',
  '75credPrice': '60€ ht',
  '250credPrice': '150€ ht',
  '500credPrice': '250€ ht',
  shop: 'La boutique',
  shopDesc: '1 témoignage vidéo = 25 crédits',
  shopMessage:
    'Achète un pack de crédits pour débloquer les témoignages obtenus. Plus le pack de crédits est important, plus le coût du témoignage sera économique pour toi.',
  save25: 'économisez 25%',
  save37: 'économisez 37,5%',

  //* MISSIONS *//

  step1: 'Etape 1/4',
  step2: 'Etape 2/4',
  step3: 'Etape 3/4',
  step4: 'Etape 4/4',
  unlockError:
    "Oups ! Il semble qu'il y a eu un problème avec le montage vidéo.",
  unlockError2: "Pour obtenir de l'aide, contactez le support.",
  addMissionName: 'témoignage client pour {business}',
  missionName: 'Nom de la mission',
  missionFormality: 'Tonalité des échanges avec vos clients',
  missionLang: 'Langue parlée par vos clients',
  formelChoice: 'courtois',
  amicalChoice: 'convivial',
  formelText: "Utilisation du vouvoiement et d'un ton conventionnel.",
  amicalText: "Utilisation du tutoiement et d'un ton chaleureux.",
  language: 'langue',
  missionLaunch: 'lancer une mission',
  whoContact: 'Liste de vos clients à inviter',
  columnFit: 'Fais correspondre les colonnes',
  ignoreFirstLine: 'Ignorer la première ligne',
  tooManyLines: 'Votre fichier doit contenir moins de 50 lignes.',
  choose: 'choisir',
  ask: 'Liste de vos questions',
  questionHere: 'écrivez votre question ici',
  customizeSenderName: 'Expéditeur',
  inviteText: 'Récompense pour les participants',
  rewardBase: 'Pour vous remercier je vous offre ',
  rewardGift: 'ma reconnaissance éternelle',
  scriptDL: 'télécharger le guide',
  welcomeVideo: 'Vidéo de motivation pour vos clients',
  congrats: 'Félicitations',
  missionLaunchSuccess: 'Je viens de lancer la mission.',
  missionLaunchSuccess2:
    "J'enverrai les invitations au meilleur moment pour tes destinataires.",
  missionLaunchSuccess3:
    'Avec cette méthode, je maximise les chances que les messages soient ouverts ! 😎',
  noMissionLaunched: "Je n'ai pas encore de missions en cours",
  started: 'démarrée',
  vertical: 'format vertical',
  square: 'format carré',
  verticalVideo: 'vidéo verticale',
  squareVideo: 'vidéo carrée',
  subtitled: "sous-titrée",
  montage:
    "C'est parti ! Le montage est en cours. Il sera dispo d'ici quelques minutes 😉",
  unlockCredits: 'débloquer avec 25 crédits',
  notEnoughCredits:
    "Vous n'avez pas assez de crédits pour débloquer ce témoignage",
  waitingDraft: "un instant, j'enregistre ton brouillon",
  errorSavingDraft: "Erreur lors de l'enregistrement du brouillon",
  errorSavingMission: "Erreur lors de l'enregistrement de la mission",
  deleteMission: 'Veux-tu vraiment supprimer cette mission ?',
  unlock: 'à débloquer',
  toUnlock: 'à débloquer',
  locked: 'bloqué',
  locked2: 'bloqués',
  myTestimonial: 'mes témoignages',
  onHold: 'en attente',
  refuse: 'refus',
  maxCharacter: 'Maximum 100 caractères par question',
  maxGuests: 'Maximum 50 clients par mission',
  maxQuestions: 'Vos clients auront 30s par question pour y répondre.',
  maxVideo: 'Maximum 1min30',
  phoneEmptyAdvice1: 'Il manque le numéro de téléphone !',
  phoneEmptyAdvice2:
    'En l’ajoutant, tu pourras profiter de plusieurs fonctionnalités de personnalisation.',
  QuestionsTitle: 'exemples',
  Sample_naming: 'conseils',
  Sample_invitations: 'astuces',
  Sample_customize: 'idées',
  Sample_invit4:
    "Utilisez une seule adresse mail par client. Spammer vos clients, c'est mal !",
  Sample_invit1:
    'Invitez en priorité les clients qui vous ont exprimé leur satisfaction par écrit ou de vive voix.',
  Sample_invit2:
    'Sélectionnez de préférence les clients avec qui vous avez eu un contact récent. Sinon, profitez de l’occasion pour les prévenir.',
  Sample_invit3:
    "Si un client a refusé dans une précédente mission de YourCharlie, patientez un peu avant de l'inviter à nouveau.",
  Sample_naming1:
    'Vous devez créer une mission par catégorie de client pour personnaliser au maximum vos demandes de témoignages. Vous optimiserez votre taux de réponse !',
  Sample_naming2: 'Voici quelques exemples de segmentation de client : ',
  Sample_naming3: '🗣️ par langue parlée',
  Sample_naming4: '🫶 par affinité',
  Sample_naming5: '🧠 par type expérience ',
  Sample_naming6: '💵 par volume de transaction',
  Sample_question:
    'Nous vous proposons 5 questions pour vous suggérer des idées mais vous pouvez les modifier comme bon vous semble.',
  Sample_question1:
    '⏱ Pour chacune des questions, votre client aura 30 secondes pour y répondre.',
  Sample_question2: '❓ Vous pouvez écrire maximum 5 questions.',
  Sample_custom_video1:
    'un cadeau : produit/service en lien avec votre entreprise ou territoire.',
  Sample_custom_video2:
    'un code promo : sur un produit/service qui a un intérêt particulier pour vos clients.',
  Sample_custom_video3:
    'une bouteille : Un bon vin ou champagne fera toujours plaisir à vos clients.',
  Sample_custom_video4:
    'un remerciement : efficace uniquement si vous avez une bonne relation avec vos clients.',
  Sample_custom_video:
    'Mettez en jeu une récompense pour remercier vos clients d’avoir pris le temps de faire un témoignage pour vous. Voici quelques idées :',
  tooltip_model:
    'Ces modèles sont là pour vous aider à écrire vos demandes de témoignages.',
  tooltip_model2:
    'Vous pouvez les modifier juste avant de les envoyer sur le service de votre choix.',
  tooltip_questions:
    'Votre client(e) aura 30 secondes pour répondre à chaque question.',
  tooltip_video:
    'Cette vidéo est recommandée. Elle sera affichée au début de votre questionnaire.',
  tooltip_video2:
    ' Pour l’importation, maximum 1min30 et formats .MOV ou .MP4 acceptés.',
  tooltip_reward: "Ajouter une récompense n'est pas obligatoire,",
  tooltip_reward2: "mais parfois ça peut faire la différence ! 😉",
  emailAlreadyUsed: 'Cet email existe déjà',

  //* DIRECTORY *//

  directory: 'clients',
  addClients: 'ajouter des clients',
  add: 'Ajouter',
  addSmall: 'ajouter',
  actionFilter: 'Action',
  askFilter: 'Demander',
  recontactFilter: 'Relancer',
  unlockFilter: 'Débloquer',
  thankFilter: 'Remercier',
  actions: 'actions à faire',
  relaunch: 'relancer la personne',
  contactSupport: 'Contacter le support',
  askTestimony: 'demander un témoignage',
  thanks: 'remercier la personne',
  unlockTestimony: 'débloquer le témoignage',
  mounting: 'montage vidéo en cours',
  status: 'statut',
  seeTestimony: 'voir le témoignage vidéo',
  sentDemande: 'envoyer la demande',
  received: 'reçu depuis',
  filterBy: 'Filtrer par',
  tagName: 'ajouter un tag',
  resent: 'relancé',
  editClient: 'Modifier les informations du client',
  deleteClient: 'Supprimer le client',
  deleteClient1: 'Êtes-vous sûr de vouloir supprimer définitivement ',
  deleteClient2:
    "et toutes ses données ? Cette opération est irréversible et supprimera ses coordonnées, ses droits à l'image ainsi que tous les fichiers vidéos.",
  actionSent1: 'Avez-vous bien envoyé votre demande de témoignage ?',
  actionSent2: 'Avez-vous bien relancé votre client ?',
  actionSent3: 'Avez-vous bien remercié votre client ?',
  today: "aujourd'hui",
  yesterday: 'hier',
  days: ' jours',
  weeks: ' semaines',
  week: ' semaine',
  months: ' mois',
  month: ' mois',
  unlockSuccess: 'Témoignage débloqué !',

  //* INVITATION CUSTOM *//

  newTestimony: 'nouveau témoignage',
  formal: '🤝 Vouvoiement',
  amical: '🫶 Tutoiement',
  customMessage: 'personnalisation du message',
  from: 'De la part de',
  destination: 'Destinataire',
  homeVideo: "Vidéo d'accueil",
  invitLink: 'Récupérer le lien',
  copyLink: 'copier le lien',
  sendWith: 'Envoyer avec',
  selectModel: 'Sélectionner un modèle de message',
  linkCopied: 'Lien copié dans le presse-papier !',
  addIncentive: "Récompense",
  addRewardInput: "Offrez à vos clients une récompense",

  //* SELECT MODEL *//

  slectModel: 'Choisir un modèle',
  select: 'sélectionner',

  //* RECORDER *//

  sendingVideo: 'Envoi de votre video en cours...',
  testimonialSent: 'Votre témoignage a bien été envoyé !',
  unexpectedError: 'Erreur inatendue',

  //* MESSAGE CHARLIE *//

  waitMission: 'Création de la mission {getInterviewTemplate} en cours.',
  readyToLaunch: 'Je suis prêt pour lancer la mission {missionName}.',
  askedLaunch: 'Tu as posé {asked} question.',
  askedLaunch2: 'Tu as posé {asked} questions.',
  invitedLaunch: 'Tu as {invited} invité.',
  invitedLaunch2: 'Tu as {invited} invités.',
  finalLaunch: "C'est tout bon pour toi ?",
  forgotNameMission: 'Oups, vous avez oublié de donner un nom à la mission.',
  needName: 'Il me faut un prénom, un nom, un mail',
  needQuestion: 'Il me faut au minimum une question pour continuer.',
  needInviteText:
    "Il me faut une vidéo d'invitation ainsi qu'une récompense pour continuer.",
  missionError: 'Erreur lors de la création',
  launchSuccess: 'Demande créée !',
  draftRegistered: 'Brouillon enregistré',
  waitingAnswer: 'en attente de réponse',
  decline: 'a refusé de participer le',
  clicked: 'cliqué',
  downloading: 'Téléchargement en cours...',
  noFile: 'Aucun fichier à télécharger',
  hiCharlie: "Bonjour {userSurname}, c'est YourCharlie 👋",
  questionToWitness:
    "Alors c'est parti, voici la première question de {ownerSurname} :",
  beforeStartingMessage:
    "C'est super de vous retrouver ici, {ownerSurname} a hâte de voir votre témoignage ! D'ailleurs, {ownerSurname} m'a laissé un petit message pour vous :",
  acceptImageRights:
    "Une dernière petite chose avant de terminer, pouvez-vous confirmer que vous acceptez de céder vos droits d'images à {ownerBusiness}",
  seeYouSoon:
    'Parfait ! Merci pour votre participation. Vous vous en êtes sorti à merveille ! A bientôt 😉',
  testimonyCollected: 'Ce témoignage a déjà été recueilli.',
  welcomingCharlie: "Bonjour et bienvenue. Moi c'est YourCharlie 👋",
  andYou: 'Et toi ?',
  enterFirstName: 'saisis ton prénom ici',
  niceToMeet: 'Enchanté {userSurname}  😉',
  goodWork: 'On va faire du bon boulot ensemble !',
  experienceEnhancer:
    "Avant de commencer, j'ai quelques questions pour améliorer ton expérience",
  enterLastName: 'saisis ton nom de famille ici',
  companyName: 'Quel est le nom de ton entreprise ?',
  enterCompanyName: 'saisis le nom ici',
  phoneNumber: 'Quel est ton numéro de téléphone mobile ?',
  enterPhoneNumber: 'saisis ton numéro ici',
  companyColor: 'Quelle est la couleur principale de {ownerBusiness} ?',
  pickColor: 'choisis la couleur ici',
  wellDone: 'Au top ! Tout est ok de mon côté.',
  navError:
    "Oups... 😣 La fonction d'enregistrement ne fonctionne pas avec ce navigateur.. Essaie avec un autre !",
  provideEmail: 'Veuillez fournir un email',

  //* PROFILE *//

  profile: 'Mon profil',
  entreprise: 'entreprise',
  invoice: 'Factures',
  date: 'Date',
  description: 'Description',
  amount: 'Montant',
  color: 'couleur des vidéos',
  newColor: 'Nouvelle couleur enregistrée',
  invalidColor: 'Couleur invalide',
  infoRegistered: 'Informations enregistrées',

  // APPSUMO PROFILE

  dealSentence: "Vous bénéficiez actuellement d'une offre à vie AppSumo 👏",
  codeRedeemed: 'Codes utilisés',
  monthCredits: 'Crédits par mois',
  purchaseSentence: "Si vous faites partie des chanceux qui ont acheté plus d'un code AppSumo, vous êtes au bon endroit. Veuillez ajouter ici votre code supplémentaire.",
  applyCode: 'appliquer le code',
  appsumoCodeError: 'Malheureusement, ce code est invalide 😕',
  codeStacked: 'Félicitations, le code a été ajouté avec succès !',

  //* SETTINGS *//

  audioSource: 'Source audio',

  //* LOGIN/INSCRIPTION *//

  password: 'mot de passe',
  lostPassword: "j'ai perdu mon mot de passe",
  alreadyRegistered: 'Tu as déjà un compte ? Connecte-toi maintenant',
  noAccount: "Tu n'as pas de compte ? Inscris-toi maintenant",
  serviceConnection: "Un instant, je m'occupe de te connecter au service",
  invalidEmail: 'Adresse email invalide.',
  passwordRequirement:
    'Votre mot de passe doit contenir au moins 8 caractères dont une majuscule, une minuscule, un chiffre et un caractère spécial.',
  alreadyUsedEmail:
    'Adresse email déjà utilisée. Peut être êtes vous inscrit via Google ?',
  invalidPassword: 'Erreur de mot de passe',
  connectionError: 'Erreur de connexion',
  connectionCancelled: 'Connexion annulée',
  connection: 'Connexion',
  register: 'Inscription',
  unknownError: 'Erreur inconnue',
  blockedPopup: "Popup bloquée. Merci de l'autoriser",
  googleRegister: "s'inscrire avec google",
  googleConnect: 'se connecter avec google',
  or: 'OU',
  logIn: 'se connecter',
  resetPassword: 'Réinitialisation du mot de passe',
  emailUsed:
    "Entre l'adresse email associée à ton compte, et nous t'enverrons un lien pour réinitialiser ton mot de passe.",
  emailSent:
    "Un email a été envoyé à l'adresse indiquée. Tu n'as plus qu'à suivre les instructions pour réinitialiser ton mot de passe 😎",
  registerGCU: 'En vous inscrivant, vous confirmez avoir lu et accepté nos',
  GCU: "CGU",
  registerGCU2: 'ainsi que notre ',
  PDC: 'Politique de confidentialité.',
  seeGCU: 'Consulter nos',
  loginListBold1: 'Payez à la performance',
  loginList1: 'Pas d’abonnement ou d’engagement.',
  loginListBold2: 'Lancez des missions gratuitement',
  loginList2: 'Pas de carte de crédit requise.',
  loginListBold3: "L'équipe de YourCharlie vous accompagne",
  loginList3: 'Nous prenons soin de vous 5/7j de 9h à 17h.',
  new: 'NOUVEAU',
  display: 'Affichez',
  trusted: 'le badge de confiance',
  onyourwebsite: 'sur votre site pour rassurer vos visiteurs',
  passwordRepeat: 'répéter le mot de passe',
  passwordsDontMatch: 'Les mots de passe ne correspondent pas',

  //* IMPORT *//

  chooseFile: 'Ok, choisis un fichier ...',
  acceptedFormat: 'formats acceptés :',
  mediaDuration: 'durée maxi vidéo {mediaDuration} secondes',
  videoImportModal1:
    'importez une vidéo de motivation pour inciter vos clients à enregistrer un témoignage pour vous',
  videoImportModal2: 'durée vidéo : 1min30 maximum',
  videoImportModal3: 'formats acceptés : mp4, mov, avi',

  //* STOP PAGE *//

  stop_title: 'Dommage {surname}, je suis tellement triste 😢',
  stop_text1: 'Je demande seulement 3 minutes de temps additionel,',
  stop_text2: 'pour garder un joli souvenir de notre parcours ensemble',
  recorderNotification:
    'tant pis.. ce sera peut-être pour une prochaine fois ?',

  //* VIDEO *//

  urlVideoOnboarding: 'https://www.youtube.com/embed/xtBvLvQJ36c',
  urlVideoLogin: 'https://player.vimeo.com/video/773707744',
  urlVideoAffiliation: 'https://www.youtube.com/embed/qr3cMP2MsAs?si=LY6BWqPRK1QG9UwA',

  //* RECORDER V2 *//

  actualQuestion: 'question',
  helloThere1: 'Bienvenue',
  helloThere2: 'Vous êtes sur le point d\'enregistrer un témoignage vidéo.',
  recordingTips: "Voir les astuces YourCharlie",
  recordingTips1: '30 secondes par questions',
  recordingTips1Q: "questions",
  recordingTips2: 'Recommencez ou validez pour passer à la question suivante.',
  recordingTips3: 'Améliorez la qualité audio avec un “kit mains libres”.',
  recordingTips4: "N'oubliez pas de sourire, c'est pour le plaisir",
  homeRecorderReward: "Récompense :",
  begin: 'faire mon témoignage',
  MThanks: 'Merci d\'avoir témoigné !',
  endingCongrats1: 'Réalisé avec YourCharlie, le logiciel qui simplifie les témoignages vidéo.',
  endingRewards: 'Chose promise chose due 🎁',
  iOffer: 'est en chemin !',
  referalCharlie: 'collecter des témoignages vidéo',
  discoverCharlie: 'Découvrez YourCharlie',
  modalRetry: 'Hello 👋',
  modalRetry2:
    'Vous avez déjà répondu à des questions. Souhaitez-vous recommencer depuis le début ou reprendre où vous en étiez ?',
  fatalErrorRecord: "Oups ! Un souci technique semble empêcher l'enregistrement de votre témoignage. Ce problème pourrait être lié à l'appareil que vous utilisez actuellement. Pourriez-vous tenter l'expérience depuis un autre appareil, s'il vous plaît ? Votre contribution compte beaucoup pour nous. Merci ! 🌟",
  tooShortRecord: "Oups ! Il semblerait que votre réponse soit trop courte. Souhaitez-vous la ré-enregistrer ? Votre contribution compte beaucoup pour nous. Merci ! 🌟",

  //* INTEGRATION *//

  addedToPlaylist: 'Témoignage vidéo ajouté au widget',
  removedFromPlaylist: ' Témoignage vidéo retiré du widget',
  copyCode: 'copier le code',
  widgetPres1: "Le widget YourCharlie sur ton site c'est le top pour",
  widgetPres1b: 'augmenter ton taux de conversion',
  widgetPres2:
    'Découvre les deux étapes pour ajouter simplement les témoignages vidéos sur ton site.',
  widgetStep1: 'Etape 1 - Sélectionne 5 vidéos maximum',
  Step1expA: 'Pour ajouter une vidéo au widget, va dans',
  Step1expB: 'A côté de chaque témoignages reçus tu as le bouton ',
  Step1expC: 'ajouter au widget',
  widgetStep2: 'Etape 2 - Ajoute le widget sur ton site',
  Step2expA: 'Pour ajouter ce widget sur toutes les pages de ton site,',
  Step2expB: 'clique sur ',
  Step2expC: 'copier le code',
  Step2expD: 'et colle-le dans la section < HEAD> de ton site web.',
  WordpressWidget:
    'Pour ajouter le widget à ton site WordPress rien de plus simple :',
  wpDownload: "télécharger l'extension",
  wpStep1a: "Télécharge l'extension ",
  wpStep1c: ' en cliquant sur le bouton ci-contre',
  wpStep2a: 'Dans ton tableau de bord ',
  wpStep2b: ', rends-toi dans Extensions > Ajouter',
  wpStep3:
    "Clique sur le bouton ''Téléverser une extension'' et télécharge l'archive zip",
  wpStep4: "Clique sur ''Installer maintenant'' puis active l'extension",
  wpInstallEnd:
    "Une fois l'extension installée et activée, tu peux accéder à la page des paramètres en allant dans le menu à gauche > YourCharlie",
  chart_title: 'Charte de confiance',
  chart_purpose:
    'Afin d’obtenir le badge d’authenticité délivré par YourCharlie, je m’engage à respecter les points suivants : ',
  chart_list_1:
    'J’invite de vrais clients de mon entreprise avec qui j’ai eu au moins une transaction financière en échange d’un produit ou service.',
  chart_list_2:
    'J’atteste que mes clients n’ont pas négocié de rémunération en échange de l’enregistrement d’un témoignage vidéo.',
  chart_list_3:
    "Je n’ai pas tenté d'influencer les propos du client en fournissant un scénario ou des éléments de réponse.",
  chart_list_4:
    'Je comprends que je ne peux pas modifier la sélection des témoignages vidéos afin de ne pas tromper mes visiteurs.',
  chart_list_5:
    'J’accepte l’authenticité de mes clients et donc la possibilité que les témoignages ne soient pas parfaits sur l’image ou la manière de s’exprimer.',
  integ_page_title: "Montrez que l'on peut vous faire confiance",
  integ_purpose1: 'Il est parfois difficile de distinguer le vrai du faux.',
  integ_purpose2:
    'Avec le badge YourCharlie, gagnez en crédibilité et rassurez vos prospects',
  integ_purpose3:
    'en montrant des preuves sociales authentiques de vos clients.',
  popup_title: 'Intégrez la pop-up YourCharlie sur votre site',
  popup_1:
    "La pop-up s'affiche en sur-impression dans le coin gauche de votre site. Vos visiteurs cliquent dessus pour l'afficher en grand et écouter vos témoignages.",
  popup_2:
    'Pour ajouter la pop-up sur toutes les pages de votre site, cliquez sur ',
  popup_3: 'et collez le dans la section "<head>" de votre site web',
  slider_title: 'Intégrez le carrousel YourCharlie sur votre site',
  slider_1:
    "Le carrousel s'affiche où vous le souhaitez sur votre site, sur la page de votre choix. Vos visiteurs cliquent sur les vidéos pour activer le son et peuvent les faire défiler.",
  slider_2:
    'Pour ajouter le carrousel sur une page de votre site, cliquez sur ',
  slider_3: ' et collez le dans une balise HTML de votre site web',
  slider: 'carrousel',

  //* INTEGRATION V2 *//

  interestingTools: 'outils qui peuvent vous intéresser',
  myTools: "mes outils",
  freePopDesc: "Vos 5 derniers témoignages dans un lecteur en sur-impression.",
  freeSliderDesc: "Vos 5 derniers témoignages dans un carrousel sur une page.",
  bigPopDesc: "Jusqu’à 15 témoignages dans un lecteur en sur-impression sur votre site.",
  bigSliderDesc: "Jusqu’à 15 témoignages dans un carrousel facile à intégrer sur une page.",
  wallDesc: "Des témoignages vidéo en illimité dans une galerie sur votre site internet.",
  lockedPopSliderText: "Pour créer une sélection personnalisée de témoignages, ajoutez une Big Pop-up ou un Big Slider",
  builderWallDesc: "Glissez vos témoignages depuis la liste à gauche et déposez les ci-dessous pour les organiser. Vous pouvez ajouter autant de vidéos que vous le souhaitez.",
  builderBigPopDesc: "Glissez vos témoignages depuis la liste à gauche et déposez ci-dessous pour organiser les vidéos de votre Big Pop-Up.",
  builderBigPopDesc2: "Vous pouvez ajouter jusqu’à 15 témoignages vidéos.",
  builderBigSliderDesc: "Glissez vos témoignages depuis la liste à gauche et déposez ci-dessous pour organiser les vidéos de votre Big Slider.",
  builderBigSliderDesc2: "Vous pouvez ajouter jusqu’à 15 témoignages vidéos.",
  builderFreePopDesc: "Avec la Free Pop-Up, vous affichez automatiquement vos 5 derniers témoignages vidéos débloqués dans un lecteur vidéo en sur-impression de votre site internet.",
  builderFreeSliderDesc: "Avec le Free Slider, vous affichez automatiquement vos 5 derniers témoignages vidéos débloqués dans un carrousel à intégrer facilement sur une page de votre site internet.",
  builderWallExemple: "Bienvenue sur le Wall of Love ! Regardez les {X} témoignages vidéos authentiques de {Business}.",
  sorry: "Désolé.",
  sorryText1: "Cette fonctionnalité n'est pas accessible sur mobile ou tablette.",
  sorryText2: "Veuillez vous rendre sur un ordinateur pour créer votre widget.",
  integDesc: "Vous n'avez pas encore d'outil configuré. Choisissez-en un dans la liste ci-dessous",
  remainingDays: "{count}j restants",
  alreadyAdded: 'déja ajouté',

  //* MODAL INTEGRATION V2 *//

  installWall: 'Intégrez le Wall of Love directement sur une page de votre site internet grâce à ce code.',
  installPopup: 'Intégrez cette popup directement sur votre site internet grâce au code.',
  installSlider: 'Intégrez ce slider directement sur une page de votre site internet grâce au code.',
  codeCreation1: 'est prêt à diffuser.',
  codeCreation2: 'Copiez le code ci-dessous pour l’intégrer sur votre site.',
  modalNeedHelp: "Consulter le tutoriel pour l’intégration.",
  modalLinkTitle: 'Partagez le lien de votre page dédiée sur les réseaux sociaux ou dans un mail. ',
  modalShopTitle: 'Votre sélection est prête à être diffusée 🔥',
  modalShopDesc1: 'Vous avez sélectionné un widget de diffusion payant. Choisissez la durée d’affichage que vous souhaitez.',
  modalShopDesc2: 'Vous pouvez augmenter la durée à tout moment.',
  chooseDuration: "choisir une durée",
  inviteNow: 'invitez maintenant',
  customize: 'Personnaliser',
  addImage: 'Ajouter une image',
  sliderSettingsTitle: "Titre",
  sliderSettingsDesc: "Description",
  sliderSettingsWebsite: "www.monsite.com",
  playlistName: "nom de la playlist",
  notEnoughCreditsDuration: "Oups, vous n'avez pas assez de crédits pour acheter cette option.",
  popular: "POPULAIRE",
  newTool: "NOUVEAU",

  //* AFFILIATION *//

  becomeAffiliate: "Devenez ambassadeur de YourCharlie",
  affiliateDesc1: "Recommandez YourCharlie et ",
  affiliateDesc2: "gagnez 10% à vie",
  affiliateDesc3: " sur les ventes de vos affiliés.",
  affiliateDesc4: "Vos affiliés bénéficient de ",
  affiliateDesc5: "10€ de remise",
  affiliateDesc6: " sur leur premier achat de crédits.",
  learnMore: 'en savoir plus',
  affiliateAccount: "Créez votre compte ",
  affiliateAccount2: " sur FirstPromoter",
  affiliateAccountDesc1: "En créant votre compte sur Firstpromoter, vous accédez à un outil complet pour gérer et suivre les inscriptions de vos affiliés automatiquement.",
  affiliateAccountDesc2: "Grâce à votre lien d’affiliation disponible sur FirstPromoter, vos affiliés s’inscrivent sur YourCharlie. Ils bénéficient de 10€ de remise sur leur premier achat.",
  affiliateAccountDesc3: "Chaque fois qu’un de vos affiliés effectue un achat sur YourCharlie, vous gagnez 10% de commission. Et cela, sans limite de temps !",
  affiliateLink1: "Les affiliés s’inscrivent ",
  affiliateLink2: "avec votre lien",
  affiliateReward1: "Vous recevez ",
  affiliateReward2: "votre commission de 10%",
  becomeAffiliateSmall: "devenir ambassadeur",

  //* SUBEDITOR *//

  Génération: "Génération",
  Incrustation: "Incrustation",
  onGoingSub: "des sous-titres en cours. Merci de patienter.",
  subtitles: "sous-titres",
  imageRightsS: "droits d'images",

  //* APPSUMO *//

  appsumo1: "Il vous suffit de partager votre nom, votre email et votre code Appsumo pour vous inscrire à YourCharlie. Après avoir soumis votre demande, vous serez prêt à collecter vos preuves sociales sur vidéo. ",

  //* PREMIUMVUE *//

  premiumTitle1: "YourCharlie Premium",
  premiumTitle2: "Découvrez YourCharlie Premium",
  premiumSentence1: "Vous êtes actuellement membre premium, statut exclusif et réservé qu’à très peu de nos clients. ",
  premiumSentence2: "Votre chef de projet dédié est Axel.",
  premiumSentence3: "Vous voulez des témoignages vidéos mais vous ne pouvez pas vous en occuper ? Vous manquez de temps ? ",
  premiumSentence4: "Vous manquez de temps ou vous ne savez pas comment vous y prendre ?",
  premiumSentence5: "Nous sommes là pour vous simplifier la vie en gérant votre compte de A à Z. Notre équipe dédiée saura poser les bonnes questions aux bons clients, motiver et convaincre vos clients d’enregistrer un témoignage vidéo.",
  premiumSentence6: "Plus besoin de vous soucier des relances, nous nous en occupons manuellement pour maximiser le taux de réponse.",
  premiumSentence7: "Avec nous, vous pouvez vous concentrer sur le développement de votre entreprise et laisser l’équipe de YourCharlie faire le reste.",
  premiumSentence8: "Attention, notre offre est exclusive et limitée à seulement 10 entrepreneurs par mois.",
  premiumSentence9: "À partir de ",
  premiumSentence10: "89,99€ ht / mois",
  premiumButton1: "contacter Axel",
  premiumButton2: "gérer mon abonnement",
  premiumButton3: "découvrir YourCharlie Premium",
  tooltipApi: "voir la documentation API",

  /** API BLOCK **/

  apiSentence1: "Cette clé vous permet d'utiliser YourCharlie avec des outils d’automatisation comme Make ou Zapier. Vous pouvez aussi en avoir besoin pour des développements sur-mesure basés sur notre API.",
  apiSentence2: "Attention :",
  apiSentence3: "Pour des raisons de sécurité, chaque clé est unique. Il vous faudra en générer une nouvelle",
  apiSentence4: "si vous perdez la première clé générée.",
  seeApiDoc: "voir la documentation API",

};
