<template>
  <div>
    <div>
      <div
        class="tile is-ancestor dashboard_container-display"
        style="align-items: center; margin: 0 auto !important; "
      >
        <div class="tile is-vertical is-4">
          <p class="welcomeWords">
            <span style="font-weight: 700; font-size: 30px">{{ $t('hello') }} {{ this.$store.getters.activeUser.surname
            }}</span> 👋
            <br /><br />
            {{ $t('welcomeWords') }} <br />
            <span style="font-family: Knewave !important; font-size: 24px; ">charlie</span>
            <router-link to="/directory">
              <button
                class="button-3d button-3d-spec"
                style="display: flex;
    align-items: center;
    justify-content: center;
    margin-top:25px; 
    gap: 5px;"
              >
                <b-icon
                  v-intro="'The content of tooltip'"
                  pack="fas"
                  icon="arrow-right"
                  size="is-small"
                >
                </b-icon> {{ $t('askForTestimonial') }}
              </button>
            </router-link>
          </p>
          <!-- <b-modal
          v-model="sendRequest"
          :width="1000"
          scroll="keep"
          style="background-color: white!important"
        >
          <InvitationCustom />
        </b-modal> -->
        </div>
        <div
          class="tile is-4 is-parent is-vertical"
          style="margin-left: 15px; padding-top: 20px;"
        >
          <p style="font-weight: 600; font-size: 18px">{{ $t('goodStart') }}</p> <br />

          <div
            id=checklist_1
            class="tile is-child"
            style="display: flex; align-items: center;"
          >
            <span style="margin-right: 15px;">
              <b-icon
                v-if="$store.getters.activeUser.name && $store.getters.activeUser.phoneNumber && $store.getters.activeUser.email && $store.getters.activeUser.surname"
                pack="fas"
                icon="circle-check"
                size="is-medium"
                style="color: #12c477"
              ></b-icon>
              <b-icon
                v-else
                pack="fas"
                icon="circle-notch"
                size="is-medium"
                style="color: #828282"
              >
              </b-icon>
            </span>

            <span v-if="$store.getters.activeUser.name && $store.getters.activeUser.phoneNumber && $store.getters.activeUser.email && $store.getters.activeUser.surname">
              <p class="checklist_step checklist_done ">{{ $t('infoChecked') }}</p>
              <p class="checklist_done">{{ $t('infoChecked2') }}</p>
            </span>
            <span v-else>
              <p class="checklist_step">{{ $t('infoChecked') }}</p>
              <p>{{ $t('infoChecked2') }}</p>
            </span>
          </div>
          <div
            id=checklist_2
            class="tile is-child"
            style="display: flex; align-items: center;"
          >
            <span style="margin-right: 15px;">
              <b-icon
                v-if="$store.getters.clients.length >= 3"
                pack="fas"
                icon="circle-check"
                size="is-medium"
                style="color: #12c477"
              ></b-icon>
              <b-icon
                v-else
                pack="fas"
                icon="circle-notch"
                size="is-medium"
                style="color: #828282"
              >
              </b-icon>
            </span>

            <span v-if="$store.getters.clients.length >= 3">
              <p class="checklist_step checklist_done ">{{ $t('addChecked') }}</p>
              <p class="checklist_done">{{ $t('addChecked2') }}</p>
            </span>
            <span v-else>
              <p class="checklist_step">{{ $t('addChecked') }}</p>
              <p>{{ $t('addChecked2') }}</p>
            </span>
          </div>
          <div
            id=checklist_3
            class="tile is-child"
            style="display: flex; align-items: center;"
          >
            <span style="margin-right: 15px;">
              <b-icon
                v-if="stats.nbInterviews >= 3"
                pack="fas"
                icon="circle-check"
                size="is-medium"
                style="color: #12c477"
              ></b-icon>
              <b-icon
                v-else
                pack="fas"
                icon="circle-notch"
                size="is-medium"
                style="color: #828282"
              >
              </b-icon>
            </span>

            <span v-if="stats.nbInterviews >= 3">
              <p class="checklist_step checklist_done ">{{ $t('sendChecked') }}</p>
              <p class="checklist_done">{{ $t('sendChecked2') }}</p>
            </span>
            <span v-else>
              <p class="checklist_step">{{ $t('sendChecked') }}</p>
              <p>{{ $t('sendChecked2') }}</p>
            </span>
          </div>
          <div
            id=checklist_4
            class="tile is-child"
            style="display: flex; align-items: center;"
          >
            <span style="margin-right: 15px;">
              <b-icon
                v-if="hasUnlockedInterviews()"
                pack="fas"
                icon="circle-check"
                size="is-medium"
                style="color: #12c477"
              ></b-icon>
              <b-icon
                v-else
                pack="fas"
                icon="circle-notch"
                size="is-medium"
                style="color: #828282"
              >
              </b-icon>
            </span>

            <span v-if="hasUnlockedInterviews()">
              <p class="checklist_step checklist_done ">{{ $t('unlockChecked') }}</p>
              <p class="checklist_done">{{ $t('unlockChecked2') }}</p>
            </span>
            <span v-else>
              <p class="checklist_step">{{ $t('unlockChecked') }}</p>
              <p>{{ $t('unlockChecked2') }}</p>
            </span>
          </div>
          <!-- <div id=checklist_5 class="tile is-child" style="display: flex; align-items: center;">

          <div
            id=checklist_5
            class="tile is-child"
            style="display: flex; align-items: center;"
          >
            <span style="margin-right: 15px;">
              <b-icon v-if="$store.getters.playlist.widgetReadedAt != null" pack="fas" icon="circle-check"
                size="is-medium" style="color: #12c477"></b-icon>
              <b-icon
                pack="fas"
                icon="circle-notch"
                size="is-medium"
                style="color: #828282"
              >
              </b-icon>
            </span>

            <span v-if="$store.getters.playlist.widgetReadedAt != null">
              <p class="checklist_step checklist_done ">{{ $t('publishChecked') }}</p>
              <p class="checklist_done">{{ $t('publishChecked2') }}</p>
            </span>
            <span>
              <p class="checklist_step">{{ $t('publishChecked') }}</p>
              <p>{{ $t('publishChecked2') }}</p>
            </span>
          </div> -->

        </div>
        <div
          class="tile is-3"
          v-intro="'The content of tooltip'"
        >
          <div
            class="tile is-vertical playerMel"
            style="padding-top: 40px"
          >
            <iframe
              width="300"
              height="300"
              :src="$t('urlVideoOnboarding')"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;"
              allowfullscreen
            ></iframe>

          </div>
        </div>
      </div>
      <br />
      <hr />
      <div class="tile is-parent generalStatsHome">
        <div
          class="tile"
          style="
              background-color: white;
              box-shadow: none;
              border-radius: 0px;
              width: 100%;
            "
        >

          <div class="tile is-4 statsBorder">
            <div
              class="box"
              style="
                  padding: 10px;

                  box-shadow: none;
                  margin: auto;
                "
            >
              <span style="
                    font-size: 40px;
                    font-weight: 900;
                    color: #161032;
                    vertical-align: middle;
                  ">😎&nbsp;{{ stats ? $store.getters.clients.length : '-' }}</span>
              <span style="color: #161032; font-size: 20px; vertical-align: middle">&nbsp;{{ $t('clients') }}</span>
            </div>
          </div>
          <div class="tile is-4 statsBorder">
            <div
              class="box"
              style="
                  padding: 10px;
                  margin: auto;
 
                  box-shadow: none;
                "
            >
              <span style="
                    font-size: 40px;
                    font-weight: 900;
                    color: #161032;
                    vertical-align: middle;
                  ">🎥&nbsp;{{ stats ? stats.nbInterviewsDone : '-' }}</span>
              <span style="color: #161032; font-size: 20px; vertical-align: middle">&nbsp;{{ $t('testimonials') }}</span>
            </div>
          </div>
          <div class="tile is-4">
            <div
              class="box"
              style="
                  padding: 10px;
       
                  margin: auto;
                  box-shadow: none;
                "
            >
              <span
                v-if="(stats.nbInterviewsDone * 100 / $store.getters.clients.length) > 0"
                style="
                    font-size: 40px;
                    font-weight: 900;
                    color: #161032;
                    vertical-align: middle;
                  "
              >⚡️&nbsp;{{ (stats.nbInterviewsDone * 100 / $store.getters.clients.length).toFixed(0) }} % </span>
              <span
                v-else
                style="
                    font-size: 40px;
                    font-weight: 900;
                    color: #161032;
                    vertical-align: middle;
                  "
              >⚡️&nbsp;- </span>
              <span style="color: #161032; font-size: 20px; vertical-align: middle">&nbsp;{{ $t('success') }}</span>
            </div>
          </div>

        </div>
      </div>
      <hr />

      <hr />

      <div
        data-tour-step="4"
        class="tile is-parent box"
        style="box-shadow: none; margin-top: 20px"
      >
        <Shop />
      </div>

      <div>
        <PremiumVue />
      </div>

      <b-modal
        v-model="user"
        :can-cancel="false"
        :width="800"
        scroll="keep"
      >
        <Onboarding2
          :userFirebaseId="$store.getters.activeUser.firebaseId"
          :sponsorshipId="$route.query.sponsorship"
          v-on:save="validateOnboarding()"
        />
      </b-modal>
      <br />
    </div>
    <div>

      <v-tour
        name="dashboardTour"
        :steps="steps"
        :options="options"
        :callbacks="tourCallbacks"
      >

      </v-tour>

    </div>
  </div>
</template>

<script>
/**
 * @name Dashboard
 * @desc Vue component for managing the dashboard functionality
 */
import Onboarding2 from './Onboarding2.vue';
import Shop from './../components/shop.vue';
import PremiumVue from './../components/premiumVue.vue';
import InvitationCustom from '../components/InvitationCustom.vue';
import { mapState } from 'vuex';
import i18n from '@/i18n';

export default {
  components: { Onboarding2, Shop, InvitationCustom, PremiumVue },

  data: () => {
    return {
      /**
       * @desc Flag for indicating if user is in the onboarding process
       * @type {boolean}
       */
      isInscription: true,
      /**
       * @desc Statistics related to the user
       * @type {object}
       */
      stats: null,
      /**
       * @desc Array of last interviews
       * @type {array}
       */
      lastInterviews: [],
      /**
       * @desc Flag to indicate if a request has been sent
       * @type {boolean}
       */
      sendRequest: false,
      /**
       * Counter for tracking onboarding steps
       * @type {number}
       */
      cpt: 0,
      /**
       * @desc Callbacks for the tour functionality
       * @type {object}
       */
      tourCallbacks: {
        onSkip: () => {
          localStorage.setItem('tour-dashboard', 'ok');
        },
        onFinish: () => {
          localStorage.setItem('tour-dashboard', 'ok');
        },
        onStop: () => {
          localStorage.setItem('tour-dashboard', 'ok');
        }
      },
      /**
       * @desc Steps for the tour
       * @type {array}
       */
      steps: [
        {
          target: '[data-tour-step="1"]',
          content: i18n.tc('content_dash1')
        },
        {
          target: '[data-tour-step="2"]',
          content: i18n.tc('content_dash2')
        },
        {
          target: '[data-tour-step="3"]',
          content: i18n.tc('content_dash3')
        },
        {
          target: '[data-tour-step="4"]',
          content: i18n.tc('content_dash4')
        }
      ],
      /**
       * @desc Options for the tour
       * @type {object}
       */
      options: {
        labels: {
          buttonSkip: i18n.tc('buttonSkipTour'),
          buttonPrevious: i18n.tc('buttonPreviousTour'),
          buttonNext: i18n.tc('buttonNextTour'),
          buttonStop: i18n.tc('buttonStopTour')
        },
        highlight: true
      }
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (
        this.startTour &&
        this.$tours['dashboardTour'] &&
        localStorage.getItem('tour-dashboard') !== 'ok'
      ) {
        this.$tours['dashboardTour'].start();
      }
    });
  },
  methods: {
    /**
     * @name validateOnboarding
     * @desc Method to validate the onboarding process
     */
    validateOnboarding() {
      localStorage.setItem(
        this.$store.getters.activeUser.id + '-onboarding',
        'ok'
      );
    },
    /**
     * @name hasUnlockedInterviews
     * @desc Method to check if there are unlocked interviews
     * @returns {boolean} - true if there are unlocked interviews
     */
    hasUnlockedInterviews() {
      return this.$store.getters.clients.some((client) =>
        client.interviews.some((interview) => interview.unlocked)
      );
    }
  },
  beforeMount() {
    this.$store.dispatch('getCharlieUserStats').then((data) => {
      this.stats = data.data;
    });
    this.$store.dispatch('getCharlieUserLastInterviewsPreview').then((data) => {
      this.lastInterviews = data.data;
    });
    this.$store.dispatch('getClients').then(() => {
      if (this.$store.getters.clients.length > 0) {
        this.validateOnboarding();
        this.cpt++;
      }
    });
  },

  computed: {
    ...mapState(['startTour']),

    user: function () {
      this.cpt;
      let user = this.$store.getters.activeUser;
      return !(
        user.name &&
        user.name !== '' &&
        user.business &&
        user.business !== '' &&
        localStorage.getItem(
          this.$store.getters.activeUser.id + '-onboarding'
        ) !== null
      );
    }
  },
  watch: {
    startTour: {
      handler(newValue, oldValue) {
        console.log(
          'startTour in component changed from',
          oldValue,
          'to',
          newValue
        );
        if (
          newValue &&
          localStorage.getItem(
            this.$store.getters.activeUser.id + '-onboarding'
          ) !== null &&
          localStorage.getItem('tour-dashboard') !== 'ok'
        ) {
          setTimeout(() => {
            if (this.$tours['dashboardTour']) {
              this.$tours['dashboardTour'].start();
            }
          }, 1000); // wait for 1 second
        }
      },
      immediate: true
    }
  }
};
</script>

<style src="./../components/index.css"></style>
