import Vue from 'vue';
import VueRouter from 'vue-router';
import Dashboard from '../views/Dashboard.vue';
import Profile from '../views/Profile.vue';
import Integration from '../views/Integration.vue';
import Affiliation from '../views/Affiliation.vue';
import Logged from '../layouts/logged.vue';
import Guest from '../layouts/guest.vue';
import CharlieWrapper2 from '../views/CharlieWrapper2.vue';
import Login from '../views/Login.vue';
import Inscription from '../views/Inscription.vue';
import Appsumo from '../views/appsumo.vue';
import Admin from '../views/Admin.vue';
import Dev from '../layouts/dev.vue';
import Invoice from '../views/Invoice.vue';
import Sponsoring from '../views/Sponsoring.vue';
import ResetPwd from '../views/ResetPwd.vue';
import store from '../store/store';
import Directory from '../views/Directory.vue';
import Testimonials from '../views/Testimonials.vue';
import PlaylistDetails from '../views/PlaylistDetails.vue';
import TestimonialsManager from './../views/testimonialsManager.vue'
import InscriptionSocial from '../views/InscriptionSocial.vue';
import LoginSocial from '../views/LoginSocial.vue';
import PremiumVue from '../components/premiumVue.vue';
import Api from '../views/Api.vue';

Vue.use(VueRouter);

const checkUser = (user, store) => {
  return user.name &&
    user.name !== '' &&
    user.business &&
    user.business !== '' &&
    localStorage.getItem(
      store.getters.activeUser.id + '-onboarding'
    ) !== null;
}

export default new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: Logged,
      beforeEnter: async (to, from, next) => {
        if (store.state.loaded) {
          //while (!store.state.loaded);
          if (!store.state.user) {

            next('/login');
          } else {
            if (!checkUser(store.state.user, store) && to.name !== 'dashboard') {
              next('/');
            } else {
              next();
            }
          }
        } else {
          store.watch(state => state.loaded, loaded => {
            if (loaded) {
              if (!store.state.user) {
                next('/login');
              } else {
                if (!checkUser(store.state.user, store) && to.name !== 'dashboard') {
                  next('/');
                } else {
                  next();
                }
              }
            }
          });
        }
      },

      children: [
        {
          path: '/',
          name: 'dashboard',
          component: Dashboard
        },
        {
          path: '/directory',
          name: 'directory',
          component: Directory,
          props: route => {
            if (route.query?.onboarding === 'true') {
              return { onboarding: true }
            } else {
              return { onboarding: false }
            }
          },
        },
        {
          path: '/welcome',
          name: 'welcome',
          component: Dashboard
        },
        {
          path: '/testimonials',
          name: 'testimonials',
          component: Testimonials,
        },
        {
          path: '/testimonials_manager/:id',
          name: 'testimonials_manager',
          component: TestimonialsManager,
          props: true,
        },
        {
          path: '/integration',
          name: 'integration',
          component: Integration,
        },
        {
          path: '/integration/:id',
          name: 'integrationDetails',
          component: PlaylistDetails
        },
        {
          path: '/affiliation',
          name: 'affiliation',
          component: Affiliation,
        },
        {
          path: '/profile',
          name: 'profile',
          component: Profile
        },
      ]
    },
    {
      path: '/invoice',
      name: 'invoice',
      props: true,
      component: Invoice
    },

    {
      path: '/charlie/:extId',
      name: 'charlie',
      component: CharlieWrapper2,
    },
    {
      path: '/sponsoring/:id',
      name: 'sponsoring',
      component: Sponsoring
    },
    {
      path: '/premiumVue',
      name: 'premiumVue',
      component: PremiumVue
    },
    {
      path: '/api',
      name: 'api',
      component: Api
    },
    {
      path: '/login',
      name: 'login',
      props: route => {
        if (route.query?.registered === 'true') {
          return { registered: true }
        } else {
          return { registered: false }
        }
      },
      component: Login
    },
    {
      path: '/inscription',
      name: 'inscription',
      props: route => {
        if (route.query?.registered === 'true') {
          return { registered: true }
        } else {
          return { registered: false }
        }
      },
      component: Inscription
    },
    {
      path: '/inscriptionSocial',
      name: 'inscriptionSocial',
      props: route => {
        if (route.query?.registered === 'true') {
          return { registered: true }
        } else {
          return { registered: false }
        }
      },
      component: InscriptionSocial
    },
    {
      path: '/loginSocial',
      name: 'loginSocial',
      props: route => {
        if (route.query?.registered === 'true') {
          return { registered: true }
        } else {
          return { registered: false }
        }
      },
      component: LoginSocial
    },
    {
      path: '/appsumo',
      name: 'appsumo',
      props: route => {
        if (route.query?.registered === 'true') {
          return { registered: true }
        } else {
          return { registered: false }
        }
      },
      component: Appsumo
    },
    {
      path: '/resetpwd',
      name: 'resetpwd',
      component: ResetPwd
    },
    {
      path: '/logout',
      name: 'logout',
      component: Login
    },
    {
      path: '/admin',
      component: Dev,
      children: [
        {
          path: '/',
          name: 'admin',
          component: Admin
        },
      ]
    },

  ]
});
