<template>
  <section>
    <div>
      <br />
      <div class="tile is-parent">
        <div class="tile is-child is-8">
          <b-field
            label="Nom de la séquence"
            horizontal
          >
            <b-input
              style="border-bottom: 1px solid #161032;"
              v-model="sequence.name"
            >Nom de la séquence</b-input>
          </b-field>
          <hr />
        </div>
        <div class="tile is-child is-3">

          <b-field>
            <b-select
              placeholder="Langue de la sequence"
              v-model=sequence.locale
            >
              <option value="fr">🇫🇷&nbsp;&nbsp;français</option>
              <option value="en">🇬🇧&nbsp;&nbsp;english</option>
              <option value="es">🇪🇸&nbsp;&nbsp;español</option>
              <option value="it">🇮🇹&nbsp;&nbsp;italiano</option>
            </b-select>
            <b-select
              placeholder="tu/vous"
              v-model=sequence.formality
            >
              <option :value="false">tu</option>
              <option :value="true">vous</option>
            </b-select>
          </b-field>
        </div>
      </div>

      <div>
        <div
          class="tile box"
          style="box-shadow: 0 1px 2px rgb(0 0 0 / 0.2); background-color: #fbfcfb; justify-content: center; "
        >
          J0 - lancement de la mission
        </div>

        <div
          v-for="sequenceStep in sequence.sequenceSteps"
          :key="sequenceStep.template"
          class="tile box"
          style="box-shadow: 0 1px 2px rgb(0 0 0 / 0.2); background-color: #fbfcfb; position: relative"
        >
          <b-field
            label="délai (en jours)"
            style="width: 180px; padding-right: 20px;"
          >
            <b-numberinput
              min="0"
              max="28"
              type="is-light"
              controls-alignment="left"
              controls-position="compact"
              v-model="sequenceStep.delayFromStartInDays"
            ></b-numberinput>
          </b-field>
          <b-field
            label="type"
            style="width:150px"
          >
            <b-select
              placeholder=""
              v-model="sequenceStep.type"
            >
              <option value="mail">✉️ Mail</option>
              <option value="sms">💬 SMS</option>
              <option value="customerServiceCall">Rappel Nadège</option>
            </b-select>
          </b-field>
          <b-field
            label="template"
            v-if="sequenceStep.type == 'mail'"
          >
            <b-select
              placeholder="Selectionner un template"
              expanded
              v-model="sequenceStep.mailTemplate"
            >
              <option
                v-for="template in templates"
                :key="template.id"
                :value="template"
              >
                {{ template.locale == 'fr' ? '🇫🇷' : (template.locale == 'en' ? '🇬🇧' : (template.locale == 'es' ?
                  '🇪🇸' : (template.locale == 'it' ? '🇮🇹' : ''))) }} {{ template.name }}
              </option>
            </b-select>
          </b-field>

          <b-button
            @click="removeLine(sequenceStep)"
            type="is-small"
            icon-left="trash"
            style="
                  margin-top: 30px;
                  border: 0px !important;
                  background-color: #fbfcfb !important;
                  right: 20px;
                  position: absolute;"
          >
          </b-button>
        </div>
        <div
          class="tile"
          style="display: flex; justify-content: center"
        >
          <b-button
            expanded
            icon-left="add"
            @click="addStep()"
            style="
                margin-right: 10px;
                margin-top: 10px;
                background-color: white;
                color: #828282;
                border: 2px dashed #fbfcfb;
              "
          >&nbsp;&nbsp;{{ $t('ajouter une action') }}</b-button>
        </div>
      </div>
      <br /><br /> <br /><br />
      <b-button @click="save()">Sauvegarder</b-button>
    </div>
    <b-loading
      :is-full-page="true"
      :active.sync="loading"
    ></b-loading>
  </section>
</template>

<script>
/**
 * @name editSequence
 * @desc Component for editing a sequence
 */
export default {
  name: 'editSequence',
  components: {},
  props: {
    /**
     * Sequence object containing name, locale, and sequence steps
     */
    sequence: {
      type: Object,
      default: () => {
        return {
          name: null,
          locale: 'fr',
          sequenceSteps: [
            {
              delayFromStartInDays: 1,
              type: 'mail',
              mailTemplate: null
            }
          ]
        };
      }
    }
  },
  data() {
    return {
      /**
       * Array of templates
       * @type {Array}
       */
      templates: [],
      /**
       * Loading indicator
       * @type {Boolean}
       */
      loading: false
    };
  },
  beforeMount() {
    this.getTemplates();
    if (!this.sequence) {
      this.sequence = {
        name: null,
        locale: 'fr',
        formality: false,
        sequenceSteps: [
          {
            delayFromStartInDays: 1,
            type: 'mail',
            mailTemplate: null
          }
        ]
      };
    }
    console.log(this.sequence);
  },
  methods: {
    /**
     * @name addStep
     * @desc Adds a new step to the sequence
     */
    addStep() {
      this.sequence.sequenceSteps.push({
        delayFromStartInDays: 1,
        type: 'mail',
        mailTemplate: null
      });
    },
    /**
     * @name getTemplates
     * @desc Retrieves all mail templates
     */
    getTemplates() {
      this.$store.dispatch('getAllMailTemplates').then((result) => {
        this.templates = result.data[0].sort((a, b) => a.id - b.id);
      });
    },
    /**
     * @name removeLine
     * @desc Removes a sequence step
     * @param {Object} sequenceStep - The sequence step to remove
     */
    removeLine(sequenceStep) {
      this.sequence.sequenceSteps = this.sequence.sequenceSteps.filter(
        (item) => item !== sequenceStep
      );
    },
    /**
     * @name save
     * @desc Saves the sequence
     */
    save() {
      let promise;
      this.loading = true;
      if (this.sequence.id) {
        promise = this.$store.dispatch('updateSequence', this.sequence);
      } else {
        promise = this.$store.dispatch('createSequence', this.sequence);
      }
      promise
        .then((result) => {
          this.loading = false;
          this.$buefy.toast.open({
            message: this.$t('saved'),
            type: 'is-success'
          });
          this.$emit('updateSequence', this.sequence);
        })
        .catch((error) => {
          this.loading = false;
          this.$buefy.toast.open({
            message: this.$t('errorOccured'),
            type: 'is-danger'
          });
          console.log(error);
        });
    }
  }
};
</script>

<style src="../index.css"></style>