<template>
  <div class="csv">
    <div class="csv-column-header">
      <div
        v-for="column in columns"
        :key="column"
        class="csv-items"
      >
        {{ getColumnName(column) }}
      </div>
    </div>
    <div class="csv-row-header">
      <div
        v-for="row in rows"
        :key="row"
        class="csv-items"
      >{{ row }}</div>
    </div>
    <div class="csv-container">
      <div
        v-for="row in rows"
        :key="row"
        class="csv-row"
      >
        <div
          v-for="column in columns"
          :key="column"
          class="csv-column"
          :style="{ backgroundColor: getZoneColor(row, column) }"
        >
          {{ getLabel(row, column) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @name csvContainer
 * @desc Component for displaying CSV data in a grid format
 */
export default {
  name: 'csvContainer',
  props: {
    /**
     * @type {number}
     * @desc Number of rows in the CSV
     */
    rows: Number,
    /**
     * @type {number}
     * @desc Number of columns in the CSV
     */
    columns: Number,
    /**
     * @type {array}
     * @desc Data array for the CSV
     */
    data: [],
    /**
     * @type {array}
     * @desc Array of zones for highlighting specific areas in the CSV
     */
    zones: []
  },
  computed: {
    /**
     * @name formattedZones
     * @desc Formats the zones based on row and column values
     */
    formattedZones: function () {
      return this.zones.map(({ end: { row, column }, ...rest }) => ({
        ...rest,
        end: {
          row: row > -1 ? row : this.rows,
          column: column > -1 ? column : this.columns
        }
      }));
    }
  },
  methods: {
    /**
     * @name getColumnName
     * @desc Gets the column name based on the index
     * @param {number} index - Index of the column
     * @returns {string} - Column name
     */
    getColumnName(index) {
      return [...'abcdefghijklmnopqrstuvwxyz'.toUpperCase()][index - 1];
    },
    /**
     * @name getZone
     * @desc Gets the zone based on row and column values
     * @param {number} row - Row index
     * @param {number} column - Column index
     * @returns {object} - Zone object
     */
    getZone(row, column) {
      for (const zone of this.formattedZones) {
        if (
          row >= zone.start.row &&
          row <= zone.end.row &&
          column >= zone.start.column &&
          column <= zone.end.column
        ) {
          return zone;
        }
      }
    },
    /**
     * @name getZoneColor
     * @desc Gets the color of the zone based on row and column values
     * @param {number} row - Row index
     * @param {number} column - Column index
     * @returns {string} - Color of the zone
     */
    getZoneColor(row, column) {
      return this.getZone(row, column)?.color ?? 'white';
    },
    /**
     * @name getLabel
     * @desc Gets the label at a specific row and column in the CSV data
     * @param {number} row - Row index
     * @param {number} column - Column index
     * @returns {string} - Label at the specified row and column
     */
    getLabel(row, column) {
      if (
        this.data.length - 1 < row - 1 ||
        this.data[row - 1].length - 1 < column - 1
      )
        return '...';
      return this.data[row - 1][column - 1];
    }
  }
};
</script>

<style>
.csv {
  display: grid;
  grid-template-rows: 25px 1fr;
  grid-template-columns: 20px 1fr;
}

.csv-row-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-column: 1;
  grid-row: 2;
}

.csv-column-header {
  display: flex;
  align-items: center;
  grid-column: 2;
  grid-row: 1;
}

.csv-items {
  text-align: center;
  flex-basis: 100%;
  font-family: 'Rubik';
  color: #161032;
  font-weight: bold;
  padding-bottom: 5px;
  padding-right: 5px;
}

.csv-container {
  border-top: solid 1px black;
  border-right: solid 1px black;
  grid-column: 2;
  grid-row: 2;
}

.csv-row {
  display: flex;
  width: 100%;
  border-bottom: solid 1px #989898;
}

.csv-column {
  overflow: hidden;
  text-overflow: ellipsis;
  flex-basis: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-left: solid 1px #989898;
  transition: background-color 300ms;
}

.selected {
  background: red;
}
</style>