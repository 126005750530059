<template>
  <section>
    <div
      id="section-large"
      v-show="isLargeScreen"
    >
      <div
        v-if="displaySettings"
        style="z-index: 9999;"
        class="slider"
      >
        <SliderSettings
          :infos="currentWallInfos"
          v-on:save="saveSettings($event)"
          style="position: relative; "
        >
        </SliderSettings>
      </div>
      <div
        v-if="!free"
        class="tile"
        style="margin-bottom: 15px; align-items: center;;"
      >
        <div
          class="tile is-7"
          style="flex-direction: column;"
        >
          <p
            v-if="type == 'wall'"
            style="font-size: 11px; color: #828282 !important;margin:0 0 0 1.3%; padding-bottom: 5px;"
          >WALL OF
            LOVE</p>
          <p
            v-if="type == 'slider'"
            style="font-size: 11px; color: #828282 !important;margin:0 0 0 1.3%; padding-bottom: 5px;"
          >BIG
            SLIDER</p>
          <p
            v-if="type == 'popup'"
            style="font-size: 11px; color: #828282 !important;margin:0 0 0 1.3%; padding-bottom: 5px;"
          >BIG
            POP-UP</p>
          <b-input
            class="tile is-10"
            v-model="currentTitle"
            maxlength="42"
            :placeholder="$t('playlistName')"
            style="height: 40px !important; background-color: #f8f8f8; z-index: 0; "
          ></b-input>
        </div>
        <div
          class="tile is-5"
          style="display:flex; justify-content: flex-end"
        >
          <div
            class="tile is-8"
            style="display: flex; justify-content: flex-end;"
            v-if="!this.free && this.playlistId"
          >
            <div style="display: flex; align-items: center;">
              <p class="flip-horizontal">⏳</p>
              <p
                style="font-size: 12px; font-weight: 500;"
                :style="{ color: computeRemainingDaysColor(expiresAt) }"
              >{{
                computePlaylistRemainingDays(expiresAt) }}</p>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <button
                style="height: 40px !important;"
                class="button-3d-small-revert"
                @click="addDuration()"
              >{{
                $t('prolonger') }}</button>
            </div>
          </div>
          <div
            class="tile "
            style="justify-content: flex-end; padding-right: 5px;"
          >
            <button
              style="height: 40px !important;"
              class="button-3d-small"
              @click="save()"
            >{{ $t('save') }}</button>
          </div>
        </div>
      </div>
      <div
        v-else
        class="tile"
        style="align-items: center; padding-left: 20px; font-weight: 600; font-size: 18px;"
      >
        <div
          v-if="type == 'slider' && free"
          class="tile is-10"
        >Free Slider </div>
        <div
          v-if="type == 'popup' && free"
          class="tile is-10"
        >Free Pop-Up </div>
        <div
          class="tile is-2"
          style="display: flex; justify-content: flex-end; padding-right: 5px;"
        >
          <button
            @click="$emit('save', { title: currentTitle, medias: currentMedias })"
            style="height: 40px !important; width: 200px;"
            class="button-3d-small"
          >{{ $t('embedButton') }}</button>
        </div>
        <br />
      </div>
      <div
        v-if="type == 'slider' && free"
        style="background-color: #e7e1ff ; color:#7957d5; padding: 20px; margin-bottom: 10px; margin-top: 26px;"
      >{{
          $t('builderFreeSliderDesc') }}</div>
      <div
        v-if="type == 'popup' && free"
        style="background-color: #e7e1ff ; color:#7957d5; padding: 20px; margin-bottom: 10px;margin-top: 26px;"
      >{{
          $t('builderFreePopDesc') }}</div>
      <div
        v-if="type == 'wall'"
        style="background-color: #e7e1ff ; color:#7957d5; padding: 20px; margin-bottom: 10px; margin-top: 26px;"
      >{{
          $t('builderWallDesc') }}</div>
      <div
        v-if="type == 'slider' && !free"
        style="background-color: #e7e1ff ; color:#7957d5; padding: 20px; margin-bottom: 10px; margin-top: 26px;"
      >{{
          $t('builderBigSliderDesc') }}<br />{{ $t('builderBigSliderDesc2') }}</div>
      <div
        v-if="type == 'popup' && !free"
        style="background-color: #e7e1ff ; color:#7957d5; padding: 20px; margin-bottom: 10px; margin-top: 26px;"
      >{{
          $t('builderBigPopDesc') }}<br />{{ $t('builderBigPopDesc2') }}</div>
      <br />
      <div class="drag-wrapper">
        <div v-if="!free">
          <div class="leftBuilder">
            <draggable
              :move="onMove"
              class="finder"
              :list="unlockedInterviews"
              group="medias"
            >
              <img
                :src="media.thumbnailUrl"
                class="finder-el"
                v-for="media of unlockedInterviews"
                :key="media.interviewId"
              />
            </draggable>
          </div>
        </div>
        <div v-else>
          <div
            class="leftBuilder"
            style="overflow:hidden; margin-top: 10px;"
          >
            <div class="dessous">
              <img
                :src="media.thumbnailUrl"
                class="finder-el"
                v-for="media of unlockedInterviews"
                :key="media.interviewId"
              />
            </div>
            <div class="dessous overlay"> </div>
            <div class="dessous overlay2">
              <p>{{ $t('lockedPopSliderText') }}</p>
              <br />
              <router-link :to="'/integration/'"> <button class="button-3d-small-revert">{{ $t('addSmall')
              }}</button></router-link>
            </div>
          </div>
        </div>
        <div
          class="rightBuilder"
          v-if="!free"
        >
          <div
            v-if="type == 'wall'"
            class="card"
            style="position: relative; height: 210px; padding: 10px; width: 98% ;"
          >
            <b-button
              @click="displaySettings = true"
              size="is-small"
              icon-left="edit"
              style="border:none;position: absolute; top: 10px; right: 10px; background: none;"
            >
            </b-button>
            <div style="display: flex; gap: 5%;">
              <div>
                <figure style="width: 150px; height: 150px; margin: 15% 0 5% 10%">
                  <img :src="imageBlobURL ? imageBlobURL : currentWallInfos.wallImageUrl ? currentWallInfos.wallImageUrl : 'https://firebasestorage.googleapis.com/v0/b/get-in-shoot.appspot.com/o/public%2FPlaceHolder.png?alt=media&token=b41d0194-7f97-45cf-aa42-b194c9b913d7'" />
                </figure>
              </div>
              <div>
                <div style="position: absolute; top: 10%;">
                  <p
                    v-if="currentWallInfos.wallName == null"
                    style="font-weight: 600; font-size: 22px; text-transform : capitalize;"
                  >{{
                      $store.getters.activeUser.business }}</p>
                  <p style="font-weight: 600; font-size: 22px;">{{ currentWallInfos.wallName }}</p>
                </div>
                <div style="position: absolute; top: 30%; padding-right: 10px;">
                  <p v-if="currentWallInfos.wallDescription == null">{{ $t('builderWallExemple') }}</p>
                  <p v-else>{{ currentWallInfos.wallDescription }}</p>
                </div>
                <div style="position: absolute; bottom: 5%;">
                  <div style="display: flex; gap: 10px;">
                    <div v-if="currentWallInfos.wallLinkedinUrl == null || currentWallInfos.wallLinkedinUrl == ''"></div>
                    <div v-else>
                      <a
                        target="_blank"
                        :href="currentWallInfos.wallLinkedinUrl"
                      ><img
                          src="./../../../public/img/linkedin_noir.png"
                          style="width: 20px; height: 20px;"
                        /></a>
                    </div>
                    <div v-if="currentWallInfos.wallYoutubeUrl == null || currentWallInfos.wallYoutubeUrl == ''"></div>
                    <div v-else>
                      <a
                        target="_blank"
                        :href="currentWallInfos.wallYoutubeUrl"
                      ><img
                          src="./../../../public/img/youtube_noir.png"
                          style="width: 20px; height: 20px;"
                        /></a>
                    </div>
                    <div v-if="currentWallInfos.wallInstagramUrl == null || currentWallInfos.wallInstagramUrl == ''">
                    </div>
                    <div v-else>
                      <a
                        target="_blank"
                        :href="currentWallInfos.wallInstagramUrl"
                      ><img
                          src="./../../../public/img/instagram_noir.png"
                          style="width: 20px; height: 20px;"
                        /></a>
                    </div>
                    <div v-if="currentWallInfos.wallTiktokUrl == null || currentWallInfos.wallTiktokUrl == ''"></div>
                    <div v-else>
                      <a
                        target="_blank"
                        :href="currentWallInfos.wallTiktokUrl"
                      ><img
                          src="./../../../public/img/tiktok_noir.png"
                          style="width: 20px; height: 20px;"
                        /></a>
                    </div>
                    <div v-if="currentWallInfos.wallFacebookUrl == null || currentWallInfos.wallFacebookUrl == ''"></div>
                    <div v-else>
                      <a
                        target="_blank"
                        :href="currentWallInfos.wallFacebookUrl"
                      ><img
                          src="./../../../public/img/facebook_noir.png"
                          style="width: 20px; height: 20px;"
                        /></a>
                    </div>
                    <p style="font-size: 11px;"><a>{{ currentWallInfos.wallWebsiteUrl }}</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <draggable
            :list="currentMedias"
            group="medias"
            class="zone"
          >
            <div
              v-for="( media, index ) of  currentMedias "
              :key="media.interviewId || media.id"
              style="position: relative; float:left"
            >
              <img
                class="zone-el"
                :src="media.thumbnailUrl"
              />
              <div style="background: white; font-size: 20px; font-weight: 600;  width: 30px; height: 30px; border-radius: 50%; position: absolute; top: 12px; right: 12px; display: flex; justify-content: center; align-items: center;">
                {{ index + 1 }}
              </div>
              <p style="padding-left: 10px; font-size:14px; font-weight: 500;">{{ media.surname[0].toUpperCase() +
                media.surname.slice(1) }}&nbsp;{{ media.name.charAt(0).toUpperCase() }}.</p>
              <p style="padding-left: 10px; font-size:14px">{{ media.creationDate | momentFilter($i18n.locale) }}</p>
            </div>
            <button
              slot="header"
              v-if="unlockedInterviews.length > 0 && (type == 'wall' || currentMedias.length <= 14)"
              @click="addMedia"
              class="zone-el"
            ><b-icon
                pack="fas"
                icon="square-plus"
                style="height: 60px !important; color: #d9d2d2"
              >
              </b-icon>
            </button>
          </draggable>
        </div>
        <div
          class="rightBuilder"
          v-else
        >
          <div
            v-for="( media, index ) of  currentMedias "
            :key="media.interviewId || media.id"
          >
            <span style="position: relative; float:left">
              <img
                class="zone-el"
                :src="media.thumbnailUrl"
              />
              <div style="background: white; font-size: 20px; font-weight: 600;  width: 30px; height: 30px; border-radius: 50%; position: absolute; top: 12px; right: 12px; display: flex; justify-content: center; align-items: center;">
                {{ index + 1 }}
              </div>
              <p style="padding-left: 10px; font-size:14px; font-weight: 500;">{{ media.surname[0].toUpperCase() +
                media.surname.slice(1) }}&nbsp;{{ media.name.charAt(0).toUpperCase() }}.</p>
              <p style="padding-left: 10px; font-size:14px">{{ media.creationDate | momentFilter($i18n.locale) }}</p>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div
      id="section-medium"
      v-show="isMediumScreen"
    >
      <div style="display: flex; justify-content: center; background-color: #f2f2f2; padding: 15px; border-radius: 5px;">
        <div style="text-align: center;">
          <h2>{{ $t('sorry') }}</h2><br />
          {{ $t('sorryText1') }}<br /><br />
          {{ $t('sorryText2') }}
        </div>
      </div>
    </div>
  </section>
</template>


<script>
import { mapState } from 'vuex';
import draggable from 'vuedraggable';
import moment from 'moment';
import SliderSettings from './sliderSettings.vue';

/**
 * @name builder
 * @desc Component for managing media content and settings
 */
export default {
  name: 'builder',
  components: {
    draggable,
    SliderSettings
  },
  props: {
    /**
     * Title of the media content
     */
    title: {
      type: String,
      required: false
    },
    /**
     * Array of media content
     */
    medias: {
      type: Array | undefined,
      required: true,
      default: () => []
    },
    /**
     * Boolean indicating if content is free
     */
    free: {
      type: Boolean,
      required: false
    },
    /**
     * Type of media content
     */
    type: {
      type: String,
      required: false
    },
    /**
     * Information about the wall
     */
    wallInfos: {
      type: Object,
      required: false
    },
    /**
     * ID of the playlist
     */
    playlistId: {
      type: Number | undefined,
      required: false
    },
    /**
     * Expiry date of the content
     */
    expiresAt: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      /**
       * @desc List of unlocked interviews
       * @type {Array}
       */
      unlockedInterviews: [],
      /**
       * @desc Flag to display settings
       * @type {Boolean}
       */
      displaySettings: false,
      /**
       * @desc URL of the image blob
       * @type {String | null}
       */
      imageBlobURL: null,
      /**
       * @desc Current title of the component
       * @type {String}
       */
      currentTitle: this.title,
      /**
       * @desc Array of current media content
       * @type {Array}
       */
      currentMedias: this.medias,
      /**
       * @desc Information about the current wall
       * @type {Object}
       */
      currentWallInfos: this.wallInfos,
      /**
       * @desc Flag indicating if the screen is large
       * @type {Boolean}
       */
      isLargeScreen: false,
      /**
       * @desc Flag indicating if the screen is medium
       * @type {Boolean}
       */
      isMediumScreen: false
    };
  },
  methods: {
    /**
     * @name addMedia
     * @desc Add media content to the current list
     */
    addMedia() {
      this.currentMedias.push(this.unlockedInterviews[0]);
      this.unlockedInterviews.splice(0, 1);
    },
    /**
     * @name addDuration
     * @desc Emit event to add duration
     */
    addDuration() {
      this.$emit('addDuration');
    },
    /**
     * @name getUnlockedInterviews
     * @desc Retrieve unlocked interviews for the current user
     * @returns {Array} - List of unlocked interviews
     */
    getUnlockedInterviews() {
      const clients = this.$store.getters.clients;
      let interviews = [];
      for (const client of clients) {
        for (const interview of client.interviews) {
          if (
            interview.unlocked &&
            !this.currentMedias.find((media) => {
              return media.interviewId == interview.id;
            })
          ) {
            interviews.push({
              interviewId: interview.id,
              thumbnailUrl: interview.thumbnailUrl,
              name: client.user.name,
              surname: client.user.surname,
              creationDate: new Date(interview.updatedAt)
            });
          }
        }
      }
      if (this.free && this.currentMedias.length == 0) {
        this.currentMedias = interviews.splice(0, 5);
      }
      return interviews;
    },
    /**
     * @name saveSettings
     * @desc Save settings for the wall
     * @param {Object} infos - Information about the wall
     */
    async saveSettings(infos) {
      this.currentWallInfos = infos;
      this.displaySettings = false;
      if (infos.wallImageBlob) {
        this.imageBlobURL = URL.createObjectURL(infos.wallImageBlob);
      }
    },
    /**
     * @name save
     * @desc Save the current state
     */
    save() {
      this.$emit('save', {
        title: this.currentTitle,
        medias: this.currentMedias,
        wallInfos: this.currentWallInfos
      });
    },
    /**
     * @name computePlaylistRemainingDays
     * @desc Calculate remaining days for the playlist
     * @param {String} expiresAt - Expiry date of the playlist
     * @returns {String} - Formatted remaining days message
     */
    computePlaylistRemainingDays(expiresAt) {
      const now = new Date();
      const diff = new Date(expiresAt) - now;
      const days = Math.ceil(diff / (1000 * 60 * 60 * 24));
      return this.$t('remainingDays', { count: days });
    },
    /**
     * @name computeRemainingDaysColor
     * @desc Compute color based on remaining days
     * @param {String} expiresAt - Expiry date of the content
     * @returns {String} - Color code
     */
    computeRemainingDaysColor(expiresAt) {
      const now = new Date();
      const diff = new Date(expiresAt) - now;
      const days = Math.ceil(diff / (1000 * 60 * 60 * 24));
      return days <= 30 ? '#f1054d !important' : '#161032';
    },
    /**
     * @name checkScreenSize
     * @desc Check the screen size and set flags accordingly
     */
    checkScreenSize() {
      this.isLargeScreen = window.innerWidth >= 1240;
      this.isMediumScreen = window.innerWidth < 1240;
    },
    /**
     * @name onMove
     * @desc Handle move event
     * @param {Event} event - Move event
     * @returns {Boolean} - Whether to allow the move
     */
    onMove(event) {
      return !(this.type != 'wall' && this.currentMedias.length > 14);
    }
  },
  computed: {
    ...mapState(['clients'])
  },
  beforeMount() {
    this.unlockedInterviews = this.getUnlockedInterviews();
  },
  mounted() {
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkScreenSize);
  },
  watch: {
    clients(val, oldVal) {
      this.unlockedInterviews = this.getUnlockedInterviews();
    }
  },
  filters: {}
};
</script>

<style>
.drag-wrapper {
  display: flex;
  justify-content: center;
}

.leftBuilder {
  width: 370px !important;
  height: 800px;
  border: 2px solid #f4f4f4;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  position: sticky;
  background: #f4f4f4;
}

.rightBuilder {
  width: 800px !important;
  height: 800px !important;
  margin-left: 30px;
  overflow-y: scroll;
}

.finder {
  width: 350px !important;
  height: 770px !important;
  overflow-y: scroll;
  scrollbar-width: thin;
}

.zone {
  width: 780px !important;
  height: 700px !important;
}

.finder-el {
  width: 150px !important;
  height: 150px !important;
  display: flex;
  float: left;
  margin: 5px;
}

.zone-el {
  width: 250px !important;
  height: 250px !important;
  display: flex;
  float: left;
  margin: 5px;
  justify-content: center;
  align-items: center;
  border: 2px solid #f4f4f4;
}

.zone-el:hover {
  border: none;
}

/* drop target state */
ul[aria-dropeffect='move'] {
  background: #fff;
}

/* drop target focus and dragover state */
ul[aria-dropeffect='move']:focus,
ul[aria-dropeffect='move'].dragover {
  outline: none;
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px #d9dd1b;
}

/* draggable items */
li {
  display: block;
  list-style-type: none;
  margin: 0 0 2px 0;
  padding: 0.2em 0.4em;
  border-radius: 0.2em;
  line-height: 1.3;
}

li:hover {
  box-shadow: 0 0 0 2px #d9dd1b, inset 0 0 0 1px #ddd;
}

/* items focus state */
li:focus {
  outline: none;
  box-shadow: 0 0 0 2px #d9dd1b, inset 0 0 0 1px #ddd;
}

/* items grabbed state */
li[aria-grabbed='true'] {
  background: #5cc1a6;
  color: #fff;
}

@keyframes nodeInserted {
  from {
    opacity: 0.2;
  }

  to {
    opacity: 0.8;
  }
}

.item-dropzone-area {
  width: 250px !important;
  height: 250px !important;
  background: #888;
  opacity: 0.8;
  animation-duration: 0.5s;
  animation-name: nodeInserted;
}

.dessous {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.overlay {
  z-index: 9;
  background: #201e31;
  opacity: 0.7;
}

.overlay2 {
  z-index: 10;
  position: absolute;
  height: 200px;
  top: 70% !important;
  background: #d9dd1b;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@keyframes slideIn {
  0% {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

.slider {
  animation: 0.8s slideIn ease-in;
}

.flip-horizontal {
  -webkit-animation: flip-horizontal 1.5s
    cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite both;
  animation: flip-horizontal 1.5s cubic-bezier(0.455, 0.03, 0.515, 0.955)
    infinite both;
}

@keyframes flip-horizontal {
  0% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
  }

  50% {
    -webkit-transform: rotateX(-180deg);
    transform: rotateX(-180deg);
  }

  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
  }
}

@media all and (max-width: 1400px) {
  .leftBuilder {
    height: 600px !important;
  }

  .rightBuilder {
    height: 600px !important;
  }

  .finder {
    height: 570px !important;
  }
}
</style>

