<template>
  <div>
    <Builder
      v-if="playlist && playlist.medias"
      :title="playlist?.title"
      :medias="playlist?.medias"
      :free="playlist?.free"
      :expiresAt="playlist?.expiresAt"
      :type="playlist?.type"
      :playlistId="playlist?.id"
      :wallInfos="{
        wallName: playlist?.wallName,
        wallDescription: playlist?.wallDescription,
        wallImageUrl: playlist?.wallImageUrl,
        wallWebsiteUrl: playlist?.wallWebsiteUrl,
        wallLinkedinUrl: playlist?.wallLinkedinUrl,
        wallYoutubeUrl: playlist?.wallYoutubeUrl,
        wallInstagramUrl: playlist?.wallInstagramUrl,
        wallFacebookUrl: playlist?.wallFacebookUrl,
        wallTiktokUrl: playlist?.wallTiktokUrl,
      }"
      v-on:save="save($event)"
      v-on:addDuration="showCreditsModal = true"
      ref="builderRef"
    ></Builder>
    <b-modal :active.sync="showCreditsModal">
      <ModalShop
        :playlistType="playlist?.type"
        :savedDuration="playlist?.duration"
        v-on:validate="validatePayment($event)"
        v-on:saveToStorage="saveToStorage($event)"
      ></ModalShop>
    </b-modal>
    <b-modal
      :active.sync="showSuccessModal"
      :can-cancel="true"
      style="background-color: #1610321c;"
    >
      <div
        class="card box"
        style="background-color: white; width: 100%; padding: 50px;"
      > <span style="text-align: center;">
          <h2>{{ $t('congrats') }}, {{ playlist?.title }} {{ $t('codeCreation1') }} 🎉</h2>
        </span>
        <ModalLink
          v-if="playlist?.type === 'wall'"
          :playlist="playlist"
        ></ModalLink>
        <br />
        <ModalCode :playlist="playlist"></ModalCode>

      </div>
    </b-modal>
    <b-loading
      :active="!playlist || loading"
      :can-cancel="false"
    ></b-loading>

  </div>
</template>
<script>
import Builder from './../components/diffusion/builder.vue';
import ModalShop from './../components/diffusion/modalShop.vue';
import ModalCode from './../components/diffusion/modalCode.vue';
import ModalLink from './../components/diffusion/modalLink.vue';
import { mapState } from 'vuex';

/**
 * @name PlaylistDetails
 * @desc Component for managing playlist details including CRUD operations and interactions with modals.
 */

export default {
  name: 'PlaylistDetails',
  components: { Builder, ModalShop, ModalCode, ModalLink },
  data: function () {
    return {
      /**
       * @type {Object|null}
       * @desc The current playlist object
       */
      playlist: null,
      /**
       * @type {Boolean}
       * @desc Flag to show or hide the credits modal
       */
      showCreditsModal: false,
      /**
       * @type {Boolean}
       * @desc Flag to show or hide the success modal
       */
      showSuccessModal: false,
      /**
       * @type {Boolean}
       * @desc Flag to check if data was loaded from storage
       */
      loadedFromStorage: false,
      /**
       * @type {Boolean}
       * @desc Flag to show or hide the loading state
       */
      loading: false
    };
  },
  methods: {
    /**
     * @name getPlaylist
     * @desc Fetches the playlist based on the ID from the store or creates a dummy based on type.
     */
    getPlaylist() {
      let playlist = null;
      const index = this.$store.getters.playlists?.findIndex(
        (playlist) => playlist.id == this.playlistId
      );
      if (index !== undefined && index !== -1) {
        playlist = this.$store.getters.playlists[index];
      } else {
        switch (this.playlistId) {
          case 'popup':
            playlist = {
              name: 'Popup',
              medias: [],
              free: true,
              type: 'popup'
            };
            break;
          case 'bigpopup':
            playlist = {
              name: 'Big Pop-Up',
              medias: [],
              free: false,
              type: 'popup'
            };
            break;
          case 'slider':
            playlist = {
              name: 'Slider',
              medias: [],
              free: true,
              type: 'slider'
            };
            break;
          case 'bigslider':
            playlist = {
              name: 'Big Slider',
              medias: [],
              free: false,
              type: 'slider'
            };
            break;
          case 'wall':
            playlist = {
              name: 'Wall',
              medias: [],
              free: false,
              type: 'wall'
            };
            break;
          default:
            break;
        }
      }
      if (!playlist) return;
      playlist.medias = playlist.medias.map((media) => {
        return JSON.parse(media);
      });
      this.playlist = playlist;
    },

    /**
     * @name save
     * @desc Saves the playlist either by creating a new one or updating an existing one.
     * @param {Object} event - The event object containing playlist details.
     */
    async save(event = undefined) {
      this.playlist.medias = event.medias.map((media) => {
        return JSON.stringify(media);
      });
      this.playlist.title =
        event.title || this.playlist.title || this.playlist.name;
      this.playlist = {
        ...this.playlist,
        ...(event.wallInfos ? event.wallInfos : {})
      };
      if (this.playlist.free) {
        if (this.playlist.id) {
          this.showSuccessModal = true;
        } else {
          this.loading = true;
          this.$store
            .dispatch('createPlaylist', this.playlist)
            .then((result) => {
              this.loading = false;
              this.playlist = result.data;
              this.showCreditsModal = false;
              this.showSuccessModal = true;
            });
        }
      } else if (this.playlist.id) {
        this.loading = true;
        this.$store.dispatch('updatePlaylist', this.playlist).then((result) => {
          this.loading = false;
          this.playlist = result.data;
          this.showCreditsModal = false;
          this.showSuccessModal = true;
        });
      } else {
        this.showCreditsModal = true;
      }
    },

    /**
     * @name validatePayment
     * @desc Validates payment and updates the playlist duration.
     * @param {Object} data - The data object containing duration details.
     */
    validatePayment(data) {
      this.loading = true;
      this.playlist.duration = data.duration;
      if (this.playlist.id) {
        let payload = new Object();
        payload = {
          id: this.playlist.id,
          duration: data.duration
        };
        this.$store.dispatch('updatePlaylist', payload).then((result) => {
          this.$store.dispatch('refreshUser');
          this.loading = false;
          this.playlist = result.data;
          this.showCreditsModal = false;
          this.showSuccessModal = true;
        });
      } else {
        this.$store.dispatch('createPlaylist', this.playlist).then((result) => {
          this.$store.dispatch('refreshUser');
          this.loading = false;
          this.playlist = result.data;
          this.showCreditsModal = false;
          this.showSuccessModal = true;
        });
      }
    },
    /**
     * @name saveToStorage
     * @desc Saves the current playlist to local storage.
     * @param {Object} event - The event object containing duration details.
     */ saveToStorage(event) {
      this.playlist.duration = event.duration;
      localStorage.setItem(
        'currentPlaylist-' + this.playlistId,
        JSON.stringify(this.playlist)
      );
    },

    /**
     * @name readFromStorage
     * @desc Reads the playlist from local storage and updates the component state.
     * @returns {Boolean} - Returns true if the playlist was successfully retrieved from storage.
     */
    readFromStorage() {
      const playlist = localStorage.getItem(
        'currentPlaylist-' + this.playlistId
      );
      if (playlist) {
        let parsedPlaylist = JSON.parse(playlist);
        parsedPlaylist.medias = parsedPlaylist.medias.map((media) => {
          return JSON.parse(media);
        });

        this.playlist = parsedPlaylist;
        localStorage.removeItem('currentPlaylist-' + this.playlistId);
        return true;
      }
      return false;
    }
  },
  mounted() {
    this.playlistId = this.$route.params.id;
    if (this.readFromStorage()) {
      this.loadedFromStorage = true;
    } else {
      this.getPlaylist();
    }
  },
  updated() {
    if (this.loadedFromStorage && this.$refs.builderRef) {
      this.loadedFromStorage = false;
      this.$refs.builderRef.save();
    }
  },
  computed: {
    ...mapState(['playlists'])
  },
  watch: {
    playlists(newVal, oldVal) {
      if (!this.playlist) {
        this.getPlaylist();
      }
    }
  }
};
</script>


<style src="./../components/index.css"></style>
