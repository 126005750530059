<template>
  <div><router-view></router-view></div>
</template>

<script>
export default {
  name: "guest",
  components: {},
};
</script>

<style >
</style>