<template>
  <l-map
    style="height: 600px; width: 50%"
    :zoom="2"
    ref="myMap"
    @ready="onMapReady"
  >
    <l-tile-layer
      :url="'https://tile.openstreetmap.org/{z}/{x}/{y}.png'"
      :attribution="'attribution'"
    ></l-tile-layer>
  </l-map>
</template>

<script>
/**
 * @name clientsMap
 * @desc Component for displaying a map with client locations
 */

import L, { Marker } from 'leaflet';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';

import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { Icon } from 'leaflet';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

export default {
  name: 'clientsMap',
  components: { LMap, LTileLayer, LMarker },
  props: {
    /**
     * Array of regions to display on the map
     */
    regions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      /**
       * @desc Attribution for the map
       * @type {String}
       */
      attribution:
        '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    };
  },
  mounted() {},
  methods: {
    /**
     * @name getLocationFromRegion
     * @desc Get location data for each region and add markers to the map
     */
    async getLocationFromRegion() {
      const provider = new OpenStreetMapProvider();
      for (let region of this.regions) {
        let storedValue = localStorage.getItem(
          region.businessRegion + ', ' + region.businessCountry
        );
        let data;
        if (!storedValue || storedValue == '') {
          try {
            let result = await provider.search({
              query: region.businessRegion + ', ' + region.businessCountry
            });
            data = result[0];
            this.addLocation(
              region.businessRegion + ', ' + region.businessCountry,
              data
            );
          } catch (error) {
            console.error(error);
          }
        } else {
          data = JSON.parse(storedValue);
        }
        this.addMarker(data.label, region.businessRegionsCount, data.y, data.x);
      }
    },
    /**
     * @name addMarker
     * @desc Add a marker to the map with custom icon and tooltip
     * @param {string} label - Marker label
     * @param {number} count - Marker count
     * @param {number} lat - Latitude
     * @param {number} long - Longitude
     */
    addMarker(label, count, lat, long) {
      L.marker([lat, long], {
        icon: new L.DivIcon({
          className: 'my-div-icon',
          html: `<div class="custom-icon"><span class="custom-icon-text">${count}</span></div>`
        })
      })
        .bindTooltip(label)
        .addTo(this.$refs.myMap.mapObject);
    },
    /**
     * @name onMapReady
     * @desc Perform actions when the map is ready
     */
    onMapReady() {
      this.$refs.myMap.mapObject.invalidateSize();
      this.getLocationFromRegion();
    },
    /**
     * @name addLocation
     * @desc Add location data to local storage
     * @param {string} name - Location name
     * @param {object} data - Location data
     */
    addLocation(name, data) {
      localStorage.setItem(name, JSON.stringify(data));
    }
  }
};
</script>
<style src="./index.css">
</style>
