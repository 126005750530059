
<template>
  <section>
    <b-button
      size="is-small"
      type="is-success"
      @click="showEditSequences = true"
    >Créer une nouvelle séquence</b-button>
    <br /><br />
    <b-table
      :data="data"
      ref="table"
      :opened-detailed="defaultOpenedDetails"
      detailed
      detail-key="id"
      :show-detail-icon="showDetailIcon"
      :loading="loading"
      backend-sorting
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      @sort="onSort"
    >
      <b-table-column
        field="id"
        label="ID"
        width="40"
        numeric
        v-slot="props"
      >
        {{ props.row.id }}
      </b-table-column>
      <b-table-column
        field="locale"
        label="Lang"
        width="40"
        numeric
        sortable
        v-slot="props"
      >
        <span v-if="props.row.locale == 'fr'"> 🇫🇷 </span>
        <span v-if="props.row.locale == 'en'"> 🇬🇧 </span>
        <span v-if="props.row.locale == 'es'"> 🇪🇸 </span>
        <span v-if="props.row.locale == 'it'"> 🇮🇹 </span>
      </b-table-column>
      <b-table-column
        field="tu/vous"
        label="Formality"
        v-slot="props"
      >
        <span> {{ props.row.formality ? "vous" : "tu" }} </span>
      </b-table-column>
      <b-table-column
        field="name"
        label="Sequence name"
        sortable
        v-slot="props"
      >
        <template v-if="showDetailIcon">
          {{ props.row.name }}
        </template>
        <template v-else>
          <a @click="props.toggleDetails(props.row)">
            {{ props.row.name }}
          </a>
        </template>
      </b-table-column>
      <b-table-column
        field="usageCount"
        label="Used"
        sortable
        v-slot="props"
      >
        {{ props.row.usageCount }}
      </b-table-column>
      <b-table-column
        label="Success Count"
        v-slot="props"
      >
        <span>
          {{ props.row.usageCount ? Math.round((props.row.successCount / props.row.usageCount) * 100) : 0 }}%
        </span>&nbsp;|&nbsp;
        <span>
          {{ props.row.successCount }}
        </span>
      </b-table-column>
      <b-table-column
        label="Status"
        sortable
        v-slot="props"
      >
        <span>
          <span v-if="props.row.status == 1">
            <b-tag style="background-color: #d9dd1b">{{
              'Running'
            }}</b-tag>
          </span>
          <span v-if="props.row.status == 0">
            <b-tag style="background-color: #828282 ;color: white;">{{
              'Draft'
            }}</b-tag>
          </span>
          <span v-if="props.row.status == 2">
            <b-tag style="background-color: #fbfcfb; ">{{
              'Paused'
            }}</b-tag>
          </span>
          <span v-if="props.row.status == 3">
            <b-tag style="background-color: #323232 ;color: white;">{{
              'Stopped'
            }}</b-tag>
          </span>
        </span>
      </b-table-column>
      <b-table-column v-slot="props">
        <span v-if="props.row.status == 1">
          <b-button
            style="border: 0px !important"
            @click="pauseSequence(props.row)"
          >
            <b-icon
              pack="fas"
              icon="pause"
              style="color: #8c78d6"
            >
            </b-icon>
          </b-button>
          <!-- <b-button style="border: 0px !important">
            <b-icon
              pack="fas"
              icon="stop"
              style="color: #8c78d6"
            >
            </b-icon>
                                                                      </b-button> -->
        </span>
        <span v-if="props.row.status == 0">
          <b-button
            @click="editSequence(props.row)"
            style="border: 0px !important"
          >
            <b-icon
              pack="fas"
              icon="pen-to-square"
              style="color: #8c78d6"
            >
            </b-icon>
          </b-button>
        </span>
        <span v-if="props.row.status == 2 || props.row.status == 0">
          <b-button
            style="border: 0px !important"
            @click="startSequence(props.row)"
          >
            <b-icon
              pack="fas"
              icon="play"
              style="color: #8c78d6"
            >
            </b-icon>
          </b-button>
        </span>
        <span v-if="props.row.status == 3"> </span>
      </b-table-column>
      <b-table-column v-slot="props">
        <b-button
          style="border: 0px !important"
          @click=duplicateSequence(props.row)
        >
          <b-icon
            pack="fas"
            icon="clone"
            style="color: #8c78d6"
          >
          </b-icon>
        </b-button>
      </b-table-column>
      <template #detail="props">
        <div class="tile is-parent">
          <div class="tile is-child is-1"><small><b>delay</b></small></div>
          <div class="tile is-child is-1"><small><b>type</b></small></div>
          <div class="tile is-child is-1"><small><b>id</b></small></div>
          <div class="tile is-child is-4"><small><b>nom du template</b></small></div>
          <div class="tile is-child is-1"><small><b>nbr envoi<br />/template</b></small></div>
          <div class="tile is-child is-1"><small><b>winrate<br />/template</b></small></div>
          <div class="tile is-child is-1"><small><b>nbr envoi<br />/sequence</b></small></div>
          <div class="tile is-child is-1"><small><b>winrate<br />/sequence</b></small></div>
        </div>
        <div
          class="tile is-parent"
          v-for="step in props.row.sequenceSteps"
          :key="step.id"
        >
          <div class="tile is-child is-1">J +{{ step.delayFromStartInDays }}</div>
          <div class="tile is-child is-1"> {{ step.type === 'mail' ? '✉️ mail' : step.type === 'customerServiceCall' ?
            'rappel Nadège' : '💬 sms' }}</div>
          <div class="tile is-child is-1"> <span v-if="step.mailTemplate"> {{ step.mailTemplate.id }}</span></div>
          <div class="tile is-child is-4"> <span v-if="step.mailTemplate"> {{ step.mailTemplate.name }}</span></div>
          <div class="tile is-child is-1"> <span v-if="step.mailTemplate"> {{ step.mailTemplate.usageCount }}</span>
          </div>
          <div class="tile is-child is-1"> <span v-if="step.mailTemplate"> {{
            step.mailTemplate.usageCount ? Math.round((step.mailTemplate.successCount / step.mailTemplate.usageCount) *
              100) : 0 }}%</span></div>
          <div class="tile is-child is-1"> {{ step.usageCount }} </div>
          <div class="tile is-child is-1"> {{
            step.usageCount ? Math.round((step.successCount / step.usageCount) * 100) : 0 }}%
            <span v-if="step.successCount == 0"></span>
            <span v-else>
              &nbsp;|&nbsp;{{ step.successCount }}
            </span>
          </div>
        </div>
      </template>
    </b-table>
    <b-modal
      :active.sync="showEditSequences"
      :has-modal-card="true"
    >
      <div
        class="card box"
        style="max-width: 1060px !important ; width: 1060px !important; height:90vh !important; overflow:scroll"
      >
        <editSequence
          :sequence=currentSequence
          v-on:updateSequence="onClosedModal()"
        ></editSequence>
      </div>
    </b-modal>
  </section>
</template>

<script>
import editSequence from './editSequence.vue';

/**
 * @name allSequences
 * @desc Component for managing all sequences including creating, editing, duplicating, and updating sequences.
 */
export default {
  name: 'allSequences',
  components: { editSequence },

  data: () => {
    return {
      /**
       * @type {boolean}
       * @desc Controls the visibility of the edit sequences modal
       */
      showEditSequences: false,
      /**
       * @type {array}
       * @desc Default IDs of sequences whose details are opened initially
       */
      defaultOpenedDetails: [1],
      /**
       * @type {boolean}
       * @desc Controls the visibility of detail icons in the table
       */
      showDetailIcon: false,
      /**
       * @type {boolean}
       * @desc Determines if transitions are used
       */
      useTransition: false,
      /**
       * @type {string}
       * @desc Field name to sort by default
       */
      sortField: 'createdAt',
      /**
       * @type {string}
       * @desc Default sort order
       */
      sortOrder: 'desc',
      /**
       * @type {string}
       * @desc Default sort order, redundant but may be used for reset
       */
      defaultSortOrder: 'desc',
      /**
       * @type {number}
       * @desc Current page number in pagination
       */
      page: 1,
      /**
       * @type {number}
       * @desc Number of items per page in pagination
       */
      perPage: 20,
      /**
       * @type {number}
       * @desc Total number of sequences available
       */
      total: 0,
      /**
       * @type {boolean}
       * @desc Loading state indicator for asynchronous operations
       */
      loading: false,
      /**
       * @type {array}
       * @desc Data array holding sequences
       */
      data: [
        // {
        //   id: 4,
        //   locale: 'fr',
        //   sequenceName: 'Sequence de janvier test',
        //   totalSend: '4',
        //   winRate: '2%',
        //   status: 'paused'
        // },
        // {
        //   id: 3,
        //   locale: 'fr',
        //   sequenceName: 'Sequence de janvier',
        //   totalSend: '112',
        //   winRate: '12%',
        //   status: 'running'
        // },
        // {
        //   id: 2,
        //   locale: 'fr',
        //   sequenceName: 'Sequence de test',
        //   totalSend: '-',
        //   winRate: '-',
        //   status: 'draft'
        // },
        // {
        //   id: 1,
        //   locale: 'en',
        //   sequenceName: 'Sequence de décembre',
        //   totalSend: '86',
        //   winRate: '8%',
        //   status: 'stopped'
        // }
      ],
      /**
       * @type {object|null}
       * @desc Holds the current sequence being edited or duplicated
       */
      currentSequence: null
    };
  },
  beforeMount() {
    /**
     * @name beforeMount
     * @desc Lifecycle hook that refreshes the list of sequences before the component mounts
     */
    this.refreshList();
  },
  methods: {
    /**
     * @name refreshList
     * @desc Fetches the list of sequences from the server and updates the component's data property
     */
    refreshList() {
      this.loading = true;
      this.$store
        .dispatch('getAdminSequences', {
          page: this.page - 1,
          limit: this.perPage,
          sort: this.sortField,
          order: this.sortOrder.toUpperCase()
        })
        .then((response) => {
          this.data = response.data[0].map((s) => {
            s.sequenceSteps = s.sequenceSteps.sort(
              (a, b) => a.delayFromStartInDays - b.delayFromStartInDays
            );
            return s;
          });
          this.total = response.data[1];
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$buefy.toast.open({
            message: this.$t('errorOccured'),
            type: 'is-danger'
          });
          console.log(error);
        });
    },
    onPageChange(page) {
      /**
       * @name onPageChange
       * @desc Updates the current page and refreshes the list when pagination changes
       * @param {number} page - The new page number
       */
      this.page = page;
      this.refreshList();
    },
    onSort(field, order) {
      /**
       * @name onSort
       * @desc Updates sorting parameters and refreshes the list
       * @param {string} field - The field to sort by
       * @param {string} order - The order of sorting (asc or desc)
       */
      this.sortField = field;
      this.sortOrder = order;
      this.refreshList();
    },
    duplicateSequence(sequence) {
      /**
       * @name duplicateSequence
       * @desc Prepares a sequence for duplication by resetting relevant fields and showing the edit modal
       * @param {object} sequence - The sequence to duplicate
       */
      this.currentSequence = sequence;
      this.currentSequence.id = null;
      this.currentSequence.usageCount = 0;
      this.currentSequence.successCount = 0;
      for (let i = 0; i < this.currentSequence.sequenceSteps.length; i++) {
        this.currentSequence.sequenceSteps[i].id = null;
        this.currentSequence.sequenceSteps[i].usageCount = 0;
        this.currentSequence.sequenceSteps[i].successCount = 0;
      }
      this.showEditSequences = true;
    },
    editSequence(sequence) {
      /**
       * @name editSequence
       * @desc Sets the current sequence to the one provided and shows the edit modal
       * @param {object} sequence - The sequence to edit
       */
      this.currentSequence = sequence;
      this.showEditSequences = true;
    },
    startSequence(sequence) {
      /**
       * @name startSequence
       * @desc Starts a sequence by sending a start request to the server
       * @param {object} sequence - The sequence to start
       */
      this.loading = true;
      this.$store
        .dispatch('startSequence', { id: sequence.id })
        .then((response) => {
          this.$buefy.toast.open({
            message: this.$t('sequenceLaunched'),
            type: 'is-success'
          });
          this.refreshList();
        })
        .catch((error) => {
          this.$buefy.toast.open({
            message: this.$t('errorOccured'),
            type: 'is-danger'
          });
          console.error(error);
        });
    },
    pauseSequence(sequence) {
      /**
       * @name pauseSequence
       * @desc Pauses a sequence by sending a pause request to the server
       * @param {object} sequence - The sequence to pause
       */
      this.loading = true;
      this.$store
        .dispatch('pauseSequence', { id: sequence.id })
        .then((response) => {
          this.$buefy.toast.open({
            message: this.$t('sequencePaused'),
            type: 'is-success'
          });
          this.refreshList();
        })
        .catch((error) => {
          this.$buefy.toast.open({
            message: this.$t('errorOccured'),
            type: 'is-danger'
          });
          console.error(error);
        });
    },
    onClosedModal() {
      /**
       * @name onClosedModal
       * @desc Resets the edit modal state and refreshes the sequence list when the modal is closed
       */
      this.showEditSequences = false;
      this.refreshList();
      this.currentSequence = null;
    }
  }
};
</script>

<style src="../index.css"></style>