<template>
  <div style="width: 100%;">
    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="publishableKey"
      :line-items="lineItems"
      :success-url="successURL"
      :cancel-url="cancelURL"
      :session-id="sessionId"
      @loading="(v) => (loading = v)"
    />
    <div
      class="tile  shopComponent"
      style="box-shadow: none; border: 2px solid #F0F0F0; padding:20px; margin: 10px !important; background-color: #fbfcfb; "
    >
      <div style="margin:auto; text-align: center; width: 100%;">
        <div style=" font-size: 18px !important; text-align: center;  margin-left: 6px; margin-bottom: 0px !important; justify-content: center;
                                display: flex;  padding: 5px;">
          <p style="font-weight: 500;">
            <img
              src="../../public/img/token.png"
              style="width: 28px; float: left !important"
            /> &nbsp; {{ name }}

          </p>
        </div>
        <hr style="background-color: #f0f0f0;" />
        <h3 style="font-size: 60px !important; color: #3f3f3f !important; text-align: center !important; margin-top: 30px !important; line-height: 50px;">
          {{ desc }}
        </h3>
        <div style="font-size: 18px; margin-bottom: 20px; margin-top: 0px !important; font-weight: bold; color: #3f3f3f">{{ type }}</div>

        <div style="display:flex;align-items: center;justify-content: center;">

          <div>
            <div
              class="shopNoSave"
              v-if="save == null"
            ></div>
            <div
              class="shopSave"
              v-else
            >{{ save }}</div>

            <span style="font-size: 18px; font-weight: 400; color: #161032">{{ price }}</span>
          </div>

        </div>
        <br />
        <button
          class="button-3d-small-revert buttonShop"
          @click="submit"
        >
          {{ $t('buy') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { StripeCheckout } from '@vue-stripe/vue-stripe';

/**
 * @name Product
 * @desc Represents a product component for checkout
 */
export default {
  props: {
    /**
     * The price of the product
     */
    stripePrice: {
      type: String,
      required: true
    },
    /**
     * The name of the product
     */
    name: {
      type: String,
      required: true
    },
    /**
     * The type of the product
     */
    type: {
      type: String,
      required: true
    },
    /**
     * The price of the product
     */
    price: {
      type: String,
      required: true
    },
    /**
     * The amount saved on the product
     */
    save: {
      type: String,
      required: false
    },
    /**
     * The description of the product
     */
    desc: {
      type: String,
      required: true
    },
    /**
     * The diffusion type of the product
     */
    diffusionType: {
      type: String,
      required: false
    }
  },
  components: {
    StripeCheckout
  },
  data() {
    /**
     * @desc Publishable key for Stripe
     * @type {string}
     */
    this.publishableKey = process.env.VUE_APP_STRIPE_PK;
    return {
      /**
       * @desc Loading state
       * @type {boolean}
       */
      loading: false,
      /**
       * @desc Line items for the checkout
       * @type {array}
       */
      lineItems: [
        {
          /**
           * @desc Price of the item
           * @type {string}
           */
          price: this.stripePrice, // The id of the one-time price you created in your Stripe dashboard
          /**
           * @desc Quantity of the item
           * @type {number}
           */
          quantity: 1,
          /**
           * @desc Tax rates for the item
           * @type {array}
           */
          tax_rates: [process.env.VUE_APP_PRODUCT_TAX] // The tax rates that apply to this line item
        }
      ],
      /**
       * @desc Success URL for the checkout
       * @type {string}
       */
      successURL:
        process.env.VUE_APP_SELF_URL +
        '?utm_source=' +
        this.name
          .replaceAll(' ', '_')
          .normalize('NFD')
          .replace(/\p{Diacritic}/gu, ''),
      /**
       * @desc Cancel URL for the checkout
       * @type {string}
       */
      cancelURL: process.env.VUE_APP_SELF_URL,
      /**
       * @desc Session ID for the checkout
       * @type {string}
       */
      sessionId: null
    };
  },
  methods: {
    /**
     * @name submit
     * @desc Submits the product for purchase
     */
    async submit() {
      if (this.diffusionType) {
        this.$emit('buyClicked');
        (this.successURL =
          process.env.VUE_APP_SELF_URL +
          '/integration/' +
          this.diffusionType +
          '?utm_source=' +
          this.name
            .replaceAll(' ', '_')
            .normalize('NFD')
            .replace(/\p{Diacritic}/gu, '')),
          (this.cancelURL =
            process.env.VUE_APP_SELF_URL +
            '/integration/' +
            this.diffusionType);
      }
      const session = (
        await this.$store.dispatch('createStripeSession', {
          lineItems: this.lineItems,
          successUrl: this.successURL,
          cancelUrl: this.cancelURL,
          paymentMethodTypes: ['card'],
          mode: 'payment'
        })
      ).data;
      this.sessionId = session.id;
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();

      fbq('track', 'Purchase', { value: [this.price], currency: ['€'] });

      window.fpr('referral', { email: this.$store.getters.activeUser.email });
    }
  }
};
</script>

<style src="./index.css"></style>