<template>
  <div class="container">
    <div v-if="!loading">
      <div
        v-if="hasSponsorShip"
        style="margin: 0 auto"
        class="tile is-ancestor is-vertical"
      >
        <div style="width: 100px">
          <img src="../.././public/img/YourCharlie_logo.png" />
        </div>
        <div
          style="background-color: #fbfcfb; padding-top: 10px; padding-bottom: 10px"
          class="has-text-centered content"
        >
          {{ $t('godfather') }}&nbsp;<strong>{{ sponsorship.sender.surname }} {{ sponsorship.sender.name }}</strong> de
          <strong>{{ sponsorship.sender.business }}</strong><br />
          {{ $t('reward') }}
        </div>

        <br />
        <div
          class="tile"
          style="margin: 0 auto; justify-content: center"
        >
          <div
            style="background-color: white"
            class="has-text-centered content"
          >
            <form @submit.prevent="submit">
              <b-button
                size="is-default"
                style="color: #161032; border: 1px solid #161032 !important"
                expanded
                class="mb-2"
                @click.native="googleSignUp"
              ><img
                  style="padding: 15px; vertical-align: middle"
                  alt="Getinshoot logo"
                  src="../../public/img/google.png"
                />{{ "s'inscrire avec google" }}</b-button>

              <div style="
              width: 100%;
              height: 15px;
              border-bottom: 1px solid #f3f5f6;
              text-align: center;
            ">
                <span style="
                font-size: 10px;
                color: #161032;
                background-color: white;
                padding: 0 10px;
              ">
                  {{ $t('or') }}
                </span>
              </div>
              <br />

              <p
                v-if="errors.length"
                class="notification"
                style="
              font-size: 12px;
              font-weight: bold;
              border: 1px solid #f14668;
              background-color: white;
              color: #f14668 !important;
            "
              >
                <list-group>
                  <list-group-item
                    v-for="error in errors"
                    :key="error"
                    class="list-group-item-danger"
                  >
                    {{ error }}
                  </list-group-item>
                </list-group>
              </p>

              <b-field
                :label="this.$t('email')"
                :label-position="labelPosition"
              >
                <b-input
                  class="inputC"
                  v-model="email"
                  type="email"
                  size="is-small"
                  required
                  :invalidFeedback="$t('provideEmail')"
                />
              </b-field>
              <br />
              <b-field
                :label="this.$t('password')"
                :label-position="labelPosition"
              >
                <b-input
                  class="inputC"
                  v-model="password"
                  password-reveal
                  type="password"
                  size="is-small"
                  required
                  :invalidFeedback="$t('passwordRequirement')"
                />
              </b-field>

              <br />
              <div class="text-center">
                <button
                  class="button-3d"
                  native-type="submit"
                >
                  {{ $t('createAccount') }}
                </button>
              </div>
            </form>
          </div>
        </div>
        <br />
      </div>
    </div>

    <b-loading
      :is-full-page="false"
      v-model="loading"
      :can-cancel="false"
      style="height: fit-content"
    >
      <div
        class="charlie__message charlie__message_A"
        style="margin-top: 15px"
      >
        <div
          class="is-flex is-flex-direction-column m-4 p-3"
          style="
          background-color: #fbfcfb;
          justify-content: center !important;
          align-items: center !important;
          border-radius: 20px 20px 20px 0px;"
        >
          <span>{{ $t('onGoingSponsor')}}</span>
          <b-icon
            pack="fas"
            icon="spinner"
            size="is-small"
            custom-class="fa-spin"
            style="--fa-animation-duration:0.8s; margin-top: -25px;
          margin-left: -110px;
          margin-bottom: 15px;"
          >
          </b-icon>
        </div>
      </div>
    </b-loading>

  </div>
</template>
<script>
import { mapState } from 'vuex';
/*
  envoyer une requête d'inscription à /sponsor/confirm
*/

/**
 * @name Sponsoring
 * @desc Handles user login and sponsorship related functionalities
 */

export default {
  name: 'Login',
  props: {
    /**
     * Determines the return type after operations
     * @type {String}
     * @default 'root'
     */
    returnType: {
      type: String,
      default: 'root'
    },
    /**
     * Controls the loading state of the component
     * @type {Boolean}
     * @default false
     */
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      /**
       * @type {string}
       * @desc Position of the label
       */
      labelPosition: 'on-border',
      /**
       * @type {string}
       * @desc User's email
       */
      email: '',
      /**
       * @type {string}
       * @desc User's password
       */
      password: '',
      /**
       * @type {string}
       * @desc User's password confirmation
       */
      confirmPassword: '',
      /**
       * @type {array}
       * @desc Array to store errors
       */
      errors: [],
      /**
       * @type {object}
       * @desc Sponsorship details
       */
      sponsorship: null,
      /**
       * @type {boolean}
       * @desc Loading state
       */
      loading: true
    };
  },
  computed: {
    ...mapState(['loaded']),
    /**
     * Checks if there is an ongoing sponsorship that is not complete
     * @returns {Boolean}
     */
    hasSponsorShip: function () {
      return Boolean(this.sponsorship && !this.sponsorship?.complete);
    }
  },
  watch: {
    /**
     * Watch for changes in 'loaded' state and navigate to dashboard if conditions meet
     */
    loaded(newValue, oldValue) {
      if (newValue && this.$store.getters.activeUser) {
        this.$router.push({ name: 'dashboard' });
      } else {
        this.loading = false;
      }
    }
  },
  beforeMount() {
    /**
     * Fetches sponsorship details based on the route parameter 'id'
     */
    const id = this.$route.params.id;
    if (!id) return;

    this.$store
      .dispatch('getSponsorship', {
        id
      })
      .then(({ data }) => {
        this.sponsorship = data;
        console.log(this.sponsorship);
      });
  },
  methods: {
    /**
     * Handles form submission for login with email and password
     * @param {Event} event - The submit event
     */
    submit(event) {
      this.errors = [];
      if (!this.email) {
        this.errors.push(this.$t('invalidMail'));
      }
      if (!this.password) {
        this.errors.push(this.$t('passwordRequirement'));
      }

      if (!this.errors.length) {
        this.loading = true;
        event.target.classList.add('was-validated');
        this.$store
          .dispatch('signUpWithEmail', {
            email: this.email,
            pwd: this.password
          })
          .then(({ user }) => {
            this.handleResult(user);
          })
          .catch((err) => {
            console.log(err);
            this.errors.push(err.message);
          });
      }
    },

    /**
     * Initiates Google sign-up process
     */
    googleSignUp() {
      this.loading = true;
      console.log('googlesignup');
      this.$store
        .dispatch('signInWithGoogle')
        .then(({ user }) => {
          this.handleResult(user);
        })
        .catch((error) => {
          console.error(error);
          this.errors.push(error.message);
        });
    },

    /**
     * Initiates Facebook sign-up process
     */
    facebookSignUp() {
      this.loading = true;
      this.$store
        .dispatch('signInWithFacebook')
        .then(({ user }) => {
          this.handleResult(user);
        })
        .catch((error) => {
          console.error(error);
          this.errors.push(error.message);
        });
    },

    /**
     * Initiates Apple sign-up process
     */
    appleSignUp() {
      this.loading = true;
      this.$store
        .dispatch('signInWithApple')
        .then(({ user }) => {
          this.handleResult(user);
        })
        .catch((error) => {
          console.error(error);
          this.errors.push(error.message);
        });
    },

    /**
     * Handles the result after successful authentication
     */
    async handleResult() {
      const intervalId = setInterval(async () => {
        const user = this.$store.getters.activeUser;
        if (user) {
          clearInterval(intervalId);
          this.$router.push('/?sponsorship=' + this.sponsorship.id);
        }
      }, 1000);
    },

    /**
     * Handles errors during authentication process
     * @param {Object} error - The error object
     */
    catchError(error) {
      this.loading = false;
      console.error(error);
      if (error.code === 'auth/user-not-found') {
        this.errors.push(this.$t('invalidEmail'));
      } else if (error.code === 'auth/wrong-password') {
        this.errors.push(this.$t('invalidPassword'));
      } else if (error.code === 'auth/email-already-in-use') {
        this.errors.push(this.$t('alreadyUsedEmail'));
      } else if (error.code === 'auth/weak-password') {
        this.errors.push(this.$t('passwordRequirement'));
      } else if (error.code === 'auth/invalid-email') {
        this.errors.push(this.$t('invalidEmail'));
      } else if (error.code === 'auth/network-request-failed') {
        this.errors.push(this.$t('connectionError'));
      } else if (error.code === 'auth/popup-closed-by-user') {
        this.errors.push(this.$t('connectionCancelled'));
      } else if (error.code == 'auth/popup-blocked') {
        /*this.$store
          .dispatch('signInWithGoogleRedirect')
          .then(() => {
            this.handleResult();
          })
          .catch(this.catchError);*/
        this.errors.push(this.$t('blockedPopup'));
      } else {
        this.errors.push(this.$t('unknownError'));
      }
    }
  }
};
</script>

<style src="../components/index.css">
</style>
