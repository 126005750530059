<template>
  <div style="
        background-color: #d9dd1b;
        margin-bottom: 40px;
        height: 65px !important;
        border-bottom: 3px solid #fbfcfb;
      ">
    <b-navbar style="
          background-color: #d9dd1b;
          max-width: 1200px;
          margin: auto;
          height: 65px !important;
          border-bottom: 3px solid #fbfcfb;
        ">
      <template #brand>
        <router-link to="/">
          <div
            class="appsumo-logo"
            style="width: 150px;padding-top:12px; margin-right:20px"
          >
            <img src="../../public/img/YourCharlie_logo.png" />
          </div>
        </router-link>
      </template>
      <template #start>
        <b-navbar-item
          tag="router-link"
          :to="{ path: '/directory' }"
          data-tour-step="1"
        >
          <img
            src="../../public/img/add.png"
            style="width: 25px; height: 25px; vertical-align: middle"
          />
          <span> &nbsp;{{ $t('directory') }}&nbsp;<span style="font-size:11px">({{ $store.getters.clients.length
          }})</span></span>

        </b-navbar-item>
        <b-navbar-item
          tag="router-link"
          :to="{ path: '/testimonials' }"
          data-tour-step="1"
        >
          <img
            src="../../public/img/mes_temoignages.png"
            style="width: 25px; height: 25px; vertical-align: middle"
          />
          <span> &nbsp;{{ $t('testimonials') }}&nbsp;<span style="font-size:11px">({{ testimoniesWithStatusThree.length
          }})</span></span>

        </b-navbar-item>
        <b-navbar-item
          tag="router-link"
          :to="{ path: '/integration' }"
          data-tour-step="2"
        >
          <img
            src="../../public/img/icons/share.png"
            style="width: 25px; height: 25px; vertical-align: middle"
          />
          &nbsp; {{ $t('selection') }}
        </b-navbar-item>
        <b-navbar-item
          tag="router-link"
          :to="{ path: '/affiliation' }"
          data-tour-step="2"
        >
          <img
            src="../../public/img/icons/sponsor.png"
            style="width: 25px; height: 25px; vertical-align: middle"
          />
          &nbsp; {{ $t('sponsoring') }}
        </b-navbar-item>
        <!-- <b-navbar-item
          tag="router-link"
          :to="{ path: '/sponsor' }"
        >
          <img
            src="../../public/img/sponsor.png"
            style="width: 25px; height: 25px; vertical-align: middle"
          />
          &nbsp; {{ $t('sponsoring') }}
        </b-navbar-item> -->
        <b-navbar-item
          v-if="$i18n.locale == 'fr'"
          target="_blank"
          href="https://www.yourcharlie.com/centre-daide-yourcharlie/?lang=fr"
        >
          <img
            src="../../public/img/help.png"
            style="width: 25px; height: 25px; vertical-align: middle"
          />
          &nbsp; {{ $t('help') }}
        </b-navbar-item>
        <b-navbar-item
          v-if="$i18n.locale == 'es'"
          target="_blank"
          href="https://www.yourcharlie.com/centro-de-ayuda-yourcharlie/?lang=es"
        >
          <img
            src="../../public/img/help.png"
            style="width: 25px; height: 25px; vertical-align: middle"
          />
          &nbsp; {{ $t('help') }}
        </b-navbar-item>
        <b-navbar-item
          v-if="$i18n.locale == 'en'"
          target="_blank"
          href="https://www.yourcharlie.com/help/"
        >
          <img
            src="../../public/img/help.png"
            style="width: 25px; height: 25px; vertical-align: middle"
          />
          &nbsp; {{ $t('help') }}
        </b-navbar-item>
      </template>

      <template #end>
        <div style="margin-top: 12px">
          <MenuLang />
        </div>
        <b-navbar-item
          v-if="$store.state.user !== null"
          tag="router-link"
          :to="{ path: '/profile' }"
          data-tour-step="3"
        >
          <img
            src="../../public/img/token.png"
            style="width: 25px; height: 25px; float: left !important"
          />&nbsp;&nbsp;{{
            $store.state.user.credits
          }}&nbsp;&nbsp;|&nbsp;&nbsp;
          <img
            src="../../public/img/account.png"
            style="width: 25px; height: 25px; vertical-align: middle"
          />
          &nbsp; {{ $store.state.user.surname }}
        </b-navbar-item>
      </template>
    </b-navbar>
  </div>
</template>

<script>
import MenuLang from '../components/menuLang.vue';

/**
 * @name header
 * @desc Vue component for the header section
 */
export default {
  name: 'header',
  components: { MenuLang },
  data: () => {
    return {
      /**
       * @desc List of testimonies with status three
       * @type {array}
       */
      testimoniesWithStatusThree: []
    };
  },
  methods: {
    /**
     * @name getTestimoniesWithStatusThree
     * @desc Retrieves testimonies with status three from clients
     * @returns {array} - List of testimonies
     */
    getTestimoniesWithStatusThree() {
      const clients = this.$store.getters.clients;
      const testimonies = clients
        .filter((client) => {
          return (
            client.interviews &&
            client.interviews.length > 0 &&
            client.interviews[0].status === 3
          );
        })
        .map((client) => {
          return { user: { ...client.user }, ...client.interviews[0] };
        });
      return testimonies;
    }
  },
  created() {
    this.testimoniesWithStatusThree = this.getTestimoniesWithStatusThree();
    this.$watch('$store.getters.clients', () => {
      this.testimoniesWithStatusThree = this.getTestimoniesWithStatusThree();
    });
  }
};
</script>

<style src="./index.css"></style>
