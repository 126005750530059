<template>
  <div>
    <h1>
      {{ $t('directory')
        }}<span style="font-size: 16px !important">
        ({{ rootClients.length }})</span>
    </h1>
    <br />
    <div class="directory_add_filter-display">
      <button
        data-tour-directory-step="1"
        class="button-3d-small-revert"
        style="width: 250px"
        @click="
          addClient = true;
        resetModalData();
        "
      >
        <b-icon
          pack="fas"
          icon="user-plus"
          size="is-small"
        ></b-icon>
        &nbsp;&nbsp;{{ $t('addClients') }}
      </button>
      <div
        style="display: flex; padding-left: 50px;"
        data-tour-directory-step="2"
      >
        <div
          class="display-filter"
          style="display: flex; align-items: center; padding: 15px"
        >
          <label for="filter"><strong><b-icon
                pack="fas"
                icon="search"
                size="is-small"
                style="height: 14px"
              >
              </b-icon>&nbsp;&nbsp;{{ $t('filterBy') }} :</strong></label><br />
          <b-select
            style="margin-left: 25px"
            id="filterValueAction"
            @input="handleFilterChange"
            v-model="filterValue.action"
          >
            <option :value="null">{{ $t('actionFilter') }}</option>
            <option value="ask">{{ $t('askFilter') }}</option>
            <option value="retry">{{ $t('recontactFilter') }}</option>
            <option value="unlock">{{ $t('unlockFilter') }}</option>
            <option value="thanks">{{ $t('thankFilter') }}</option>
          </b-select>
          <b-select
            style="margin-left: 25px"
            id="filterValueTag"
            @input="handleFilterChange"
            v-model="filterValue.tag"
          >
            <option :value="null">Tag</option>
            <option
              v-for="tag of tags"
              :value="tag"
              :key="tag"
            >
              {{ tag }}
            </option>
          </b-select>
          <b-select
            style="margin-left: 25px"
            id="filterValueTag"
            @input="handleFilterChange"
            v-model="filterValue.adminManaged"
          >
            <option :value="null">Mode de gestion</option>
            <option :value="true">Gestion</option>
            <option :value="false">Self service</option>
          </b-select>
        </div>
      </div>
    </div>

    <br />
    <br />

    <b-modal
      :can-cancel="['x']"
      v-model="addClient"
      :width="850"
      scroll="keep"
      style="background-color: white !important"
    >
      <div
        class="card"
        style="
            padding: 40px;
            width: 100%;
            height: 100%;
            overflow-x: hidden;
            overflow-y: scroll;
            position: relative;
          "
      >
        <br />
        <div>
          <InvitationList
            :invitations="invitations"
            :userTags="tags"
            @save="
              invitationTags = $event;
            addClients();
            "
          />
        </div>
      </div>
    </b-modal>

    <b-modal
      v-model="sendRequest"
      :width="650"
      scroll="keep"
      style="background-color: white!important;"
    >
      <InvitationCustom
        :clients="missionData.clients"
        :interviewTemplate="missionData.interviewTemplate"
        :type="missionData.type"
        v-on:updateCurrentMissionData="updateCurrentMissionData"
        v-on:started="handleSentRequest()"
        :onBehalf="charlieUser"
      />
    </b-modal>

    <b-table
      :data="clients"
      :loading="clients === undefined"
      searchable
      :focusable="true"
      style="line-height: 30px; font-size: 14px;"
    >
      <b-table-column
        field="tags"
        label="tag"
        v-slot="props"
        width="10"
      >
        <div v-if="!props.row.tags.join(', ')"></div>
        <b-tag v-else>{{ props.row.tags.join(', ') }}</b-tag>
      </b-table-column>

      <b-table-column
        field="user.surname"
        :label="$t('fName')"
        sortable
        v-slot="props"
      >
        {{
            props.row.user.surname[0].toUpperCase() +
            props.row.user.surname.slice(1) || 'prénom'
          }}
      </b-table-column>
      <b-table-column
        field="user.name"
        :label="$t('lName')"
        sortable
        v-slot="props"
      >
        {{
            props.row.user.name[0].toUpperCase() + props.row.user.name.slice(1) ||
            'nom'
          }}
      </b-table-column>

      <b-table-column
        field="user.email"
        :label="$t('email')"
        v-slot="props"
      >
        {{ props.row.user.email || 'email' }}
      </b-table-column>

      <b-table-column
        field="user.phoneNumber"
        :label="$t('phone')"
        v-slot="props"
      >
        {{ props.row.user.phoneNumber || $t('phone') }}
      </b-table-column>

      <b-table-column
        field="infos"
        v-slot="props"
        width="10"
      >
        <div v-if="props.row.adminManaged">
          <!-- Vérifie si clientLogs est non vide -->
          <div v-if="props.row.clientLogs && props.row.clientLogs.length">
            <b-tooltip
              :position="tooltipPosition(getOrderedLogs(props.row.clientLogs)[0].type)"
              :type="tooltipType(getOrderedLogs(props.row.clientLogs)[0].type)"
              multilined
            >
              <b-icon
                :pack="'fas'"
                :icon="iconType(getOrderedLogs(props.row.clientLogs)[0].type)"
                :type="iconPackType(getOrderedLogs(props.row.clientLogs)[0].type)"
                size="is-small"
                style="position: absolute; top: -13px; left: -10px;"
              >
              </b-icon>
              <template v-slot:content>
                <div
                  style="line-height: 13px;"
                  v-for="log in getOrderedLogs(props.row.clientLogs)"
                  :key="log.id"
                >
                  <span style="font-size: 11px; color: white !important">
                    <strong style="color: white !important">{{ log.createdAt | momentFilter($i18n.locale) }}</strong> - {{ log.content }}
                    <br><br>
                  </span>
                </div>
              </template>
            </b-tooltip>
          </div>
          <div v-else>
          </div>
        </div>
        <div v-else></div>

      </b-table-column>
      <b-table-column
        field="
                    action"
        :label="$t('actions')"
        v-slot="props"
        centered
      >

        <div
          v-if="props.row.interviews.length > 0"
          data-tour-directory-step="4"
        >
          <span v-if="!props.row.interviews[0].hasOwnerLogs || (props.row.interviews[0].ownerLogs.length > 0 && (props.row.interviews[0].ownerLogs[0].type == 'SENT' || props.row.interviews[0].ownerLogs[0].type == 'RESENT'))">
            <b-button
              v-if="((props.row.interviews[0].status === 0 && props.row.interviews[0].interviewTemplate.status !== 0) ||
                  (props.row.interviews[0].status === 1 || props.row.interviews[0].status === 2) ||
                  (props.row.interviews[0].lastNotifStatus === 'noAgree' && props.row.interviews[0].status !== 3) ||
                  (props.row.interviews[0].status !== 3 && props.row.interviews[0].status === 0 && props.row.interviews[0].interviewTemplate.status == 1))"
              @click="askForTestimonial([props.row], 'relance')"
              expanded
              size="is-small"
              icon-left="bell"
              icon-pack="fas"
              style="background-color: #f1f6b8; color: #161032; font-weight: 500; height: 35px; border: 0px !important"
            >
              &nbsp;&nbsp;{{ $t('relaunch') }}
            </b-button>
          </span>
          <span v-else-if="props.row.interviews[0].hasOwnerLogs">
            <b-button
              v-if="((props.row.interviews[0].status === 0 && props.row.interviews[0].interviewTemplate.status !== 0) ||
                  (props.row.interviews[0].status === 1 || props.row.interviews[0].status === 2) ||
                  (props.row.interviews[0].lastNotifStatus === 'noAgree' && props.row.interviews[0].status !== 3) ||
                  (props.row.interviews[0].status !== 3 && props.row.interviews[0].status === 0 && props.row.interviews[0].interviewTemplate.status == 1))"
              @click="askForTestimonial([props.row], 'invitation')"
              expanded
              size="is-small"
              icon-left="paper-plane"
              icon-pack="fas"
              style="background-color: #161032; color: #d9dd1b; font-weight: 500; height: 35px; border: 0px !important"
            >
              &nbsp;&nbsp;{{ $t('sentDemande') }}
            </b-button>
          </span>
          <div
            v-if="props.row.interviews[0].status === -1"
            style="display: flex; align-items: center;
                  font-size: 12px !important;
                  color: #828282 !important;"
          >
            <b-icon
              pack="fas"
              icon="xmark"
              size="is-small"
            ></b-icon>
            <p style="
                  font-size: 12px !important;
                  color: #828282 !important;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                ">&nbsp;&nbsp;{{ $t('errorOccured') }}
              <a href="mailto:help@yourcharlie.com">[{{ $t('contactSupport') }}]</a>
            </p>
          </div>
          <b-button
            v-if="props.row.interviews[0].status === 0 && props.row.interviews[0].interviewTemplate.status == 0"
            @click="askForTestimonial([props.row], 'invitation')"
            expanded
            size="is-small"
            icon-left="arrow-right"
            icon-pack="fas"
            style="background-color: #d9DD1B; color: #161032;font-weight: 500;height:35px; border: 0px !important"
          >
            &nbsp;&nbsp;{{ $t('askTestimony') }}
          </b-button>

          <div v-if="props.row.interviews.length > 0 && props.row.interviews[0].status == 3 && props.row.interviews[0].loading === false && props.row.interviews[0].unlocked === true && !(props.row.interviews[0].ownerLogs.length > 0 && props.row.interviews[0].ownerLogs[0].type == 'THANKS')
              ">
            <b-button
              v-if="props.row.interviews[0].status === 3 &&
                props.row.interviews[0].loading === false &&
                props.row.interviews[0].unlocked === true
                "
              expanded
              @click="askForTestimonial([props.row], 'remerciement')"
              size="
              is-small"
              icon-left="heart"
              icon-pack="fas"
              style="
                  background-color: #7857d514;
                  color: #7957d5;
                  border: 0px solid #d7d7ff;
                  font-weight: 500;
                  height: 35px;
                "
            >
              &nbsp;&nbsp;<span style="color: #161032">{{ $t('thanks') }}</span>
            </b-button>

          </div>

          <div v-if="(props.row.interviews[0].ownerLogs.length > 0 && props.row.interviews[0].ownerLogs[0].type == 'THANKS')">
            <span style="font-size: 14px; font-weight: 500;">{{ $t('congrats') }} 🎉</span>
          </div>

          <b-button
            v-if="props.row.interviews.length > 0 && props.row.interviews[0].status == 3 && props.row.interviews[0].unlocked === false && props.row.interviews[0].loading == false"
            @click="selectedInterview = props.row.interviews[0]; unlock()"
            expanded
            size="is-small"
            icon-left="lock"
            icon-pack="fas"
            style="background-color: #f1054d; color: white;font-weight: 500; height:35px; border: 0px !important"
          >
            &nbsp;&nbsp;{{ $t('unlockTestimony') }}
          </b-button>

          <div
            style="
                display: flex;
                justify-content: space-around;
                align-items: center;
              "
            v-if="props.row.interviews[0].status == 3 &&
                props.row.interviews[0].unlocked === false &&
                props.row.interviews[0].loading == true
                "
          >
            <p style="
                  font-size: 12px !important;
                  color: #161032 !important;
                  font-weight: bold;
                ">
              <img
                style="width: 20px; margin-right: 10px; vertical-align: middle"
                alt="Charlie Credits"
                src="../../../public/img/loading.gif"
              />
              {{ $t('mounting') }}
            </p>
          </div>
        </div>

        <b-button
          data-tour-directory-step="4"
          v-else
          @click="askForTestimonial([props.row], 'invitation')"
          expanded
          size="is-small"
          icon-left="arrow-right"
          icon-pack="fas"
          style="background-color: #d9DD1B; color: #161032;font-weight: 500;height:35px; border: 0px !important"
        >
          &nbsp;&nbsp;{{ $t('askTestimony') }}
        </b-button>
      </b-table-column>

      <b-table-column
        field="updatedAt"
        :label="$t('status')"
        v-slot="props"
        centered
      >
        <div
          v-for="statusInfos of computeStatusInfos(props.row.interviews[0])"
          :key="statusInfos.text"
        >
          <div v-if="statusInfos?.text">
            <p
              v-if="statusInfos.text !== 'seeTestimony'"
              style="
                font-size: 12px !important;
                
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
              "
              :style="{ 'color': statusInfos.color }"
            >
              <b-icon
                pack="fas"
                :icon="statusInfos.icon"
                size="is-small"
              ></b-icon>&nbsp;&nbsp;{{ $t(statusInfos.text) }}
              {{ statusInfos.date }}
            </p>

            <b-button
              v-else
              expanded
              @click="show(props.row.interviews[0], true)"
              size="is-small"
              icon-left="circle-play"
              icon-pack="fas"
              style="
                background-color: white;
                color: #7957d5;
                border: 1px solid #ebebff;
                font-weight: 500;
                height: 35px;
              "
            >
              &nbsp;&nbsp;<span style="color: #161032">{{
                  $t('seeTestimony')
                }}</span>
            </b-button>
          </div>
        </div>
      </b-table-column>

      <b-table-column
        field="createdAt"
        label="created"
        v-slot="props"
      >
        {{ props.row.user.createdAt | momentFilter($i18n.locale) }}
      </b-table-column>

      <b-table-column
        field="edit"
        width="20px"
        v-slot="props"
      >
        <div
          data-tour-directory-step="3"
          style="display: flex; gap: 10px; padding: 0 5px 0 5px"
        >
          <div @click="showEditClientForm(props.row)">
            <b-icon
              pack="fas"
              icon="pen-to-square"
              size="is-small"
              style="height: 14px; color: #d9d2d2"
            >
            </b-icon>
          </div>
          <br />
          <div @click="
              deleteClient(
                props.row.id,
                props.row.user.name,
                props.row.user.surname
              )
              ">
            <b-icon
              pack="fas"
              icon="trash-alt"
              size="is-small"
              style="height: 14px; color: #d9d2d2"
            >
            </b-icon>
          </div>
          <div @click="openStorage(props.row, 'original')">
            <b-icon
              pack="fas"
              icon="folder-open"
              size="is-small"
              style="height: 14px; color: #d9d2d2"
            >
            </b-icon>
          </div>
          <div @click="openStorage(props.row, 'computed')">
            <b-icon
              pack="fas"
              icon="folder"
              size="is-small"
              style="height: 14px; color: #d9d2d2"
            >
            </b-icon>
          </div>
        </div>

      </b-table-column>

    </b-table>

    <b-modal
      v-model="showLocked"
      :width="600"
      scroll="keep"
      style="background-color: white !important"
    >
      <div
        class="card"
        v-if="selectedInterview && showLocked"
      >
        <div
          class="card-image responsiveCard"
          :style="[
            selectedInterview && selectedInterview.thumbnailGifUrl
              ? {
                'background-image': `url(${selectedInterview.thumbnailGifUrl})`
              }
              : selectedInterview && selectedInterview.thumbnailUrl
                ? {
                  'background-image': `url(${selectedInterview.thumbnailUrl})`
                }
                : {
                  'background-image': 'url(../../public/img/thumbPreview.png)'
                }
          ]"
        >
          <div
            class="block"
            style="
                height: 600px;
                display: flex;
                align-items: center;
                justify-content: center;
              "
          >

            <button
              class="button-3d responsiveButton"
              style="width: 400px !important"
              @click="unlock()"
            >
              <img
                style="padding: 15px; vertical-align: middle"
                alt="Charlie Credits"
                src="../../../public/img/token.png"
              />{{ $t('unlockCredits') }}
            </button>
          </div>
        </div>
        <div class="card-content">
          <div class="media">
            <div class="media-content">
              <p class="title is-4">
                {{ selectedInterview.user.surname }}&nbsp;{{
                    selectedInterview.user.name
                  }}
              </p>
              <p class="subtitle is-6">
                {{ selectedInterview.updatedAt | momentFilter($i18n.locale) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      v-model="showUnlocked"
      :width="600"
      style="width: 100%; height: 100%; overflow: scroll;"
    >
      <div
        class="card"
        style="display: flex !important"
        v-if="selectedInterview && showUnlocked"
      >
        <div
          class="card-image"
          style="width: 57% !important"
        >
          <video
            v-if="selectedInterview.squareMedia"
            controls
            :src="selectedInterview.squareMedia.url"
          ></video>
        </div>
      </div>
    </b-modal>

    <b-modal
      v-model="showUnlocked"
      :width="800"
      style="width: 100%; height: 100%; overflow: scroll"
    >
      <div
        class="card"
        style="display: flex !important; overflow: hidden;"
        v-if="selectedInterview && showUnlocked"
      >
        <div
          class="card-image"
          style="width: 57% !important; margin-bottom: -8px;"
        >
          <video
            v-if="selectedInterview.squareMedia"
            controls
            :src="selectedInterview.squareMedia.url"
          ></video>
        </div>
        <div class="card-content">
          <div
            class="tile is-parent"
            style="display: flex !important;flex-direction: column !important;gap: 15px !important;"
          >
            <div class="tile is-child">
              <div class="media">
                <div class="media-content">
                  <p class="title is-4">
                    {{ selectedInterview.user.surname }}&nbsp;{{
                        selectedInterview.user.name
                      }}
                  </p>
                  <p class="subtitle is-6">
                    {{
                        (selectedInterview.lastUserActionAt ||
                          selectedInterview.squareMedia.createdAt) | momentFilter($i18n.locale)
                      }}
                  </p>
                </div>
              </div>
            </div>
            <div class="tile is-child">
              <DownloadTestimonial
                :square-url="selectedInterview.squareMedia.url"
                :vertical-url="selectedInterview.verticalMedia.url"
                :terms-url="selectedInterview.termsUrl"
                :answers="selectedInterview.answers"
              ></DownloadTestimonial>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      :active.sync="showEditForm"
      :can-cancel="['outside', 'escape']"
      @cancel="showEditForm = false"
      :width="550"
    >
      <b-loading :active="isWaiting"></b-loading>
      <div
        v-if="editedClient"
        class="box"
        style="height: 550px"
      >
        <h3>{{ $t('editClient') }}</h3>
        <br />
        <b-field
          horizontal
          :label="$t('lName')"
        >
          <b-input
            class="inputC"
            v-model="editedClient.user.name"
            required
          ></b-input>
        </b-field>
        <b-field
          horizontal
          :label="$t('fName')"
        >
          <b-input
            class="inputC"
            v-model="editedClient.user.surname"
            required
          ></b-input>
        </b-field>
        <b-field
          horizontal
          :label="$t('email')"
        >
          <b-input
            class="inputC"
            type="email"
            v-model="editedClient.user.email"
          ></b-input>
        </b-field>
        <b-field
          horizontal
          :label="$t('phone')"
        >
          <b-input
            class="inputC"
            type="tel"
            v-model="editedClient.user.phoneNumber"
          ></b-input>
        </b-field>
        <div style="width: 100%; display: flex; align-items: center;">
          <div>
            <b-field
              horizontal
              :label="$t('tag')"
            >
              <b-taginput
                ref="tagInput"
                v-model="editedClient.tags"
                :data="tags"
                maxlength="20"
                :has-counter="false"
                maxtags="1"
                autocomplete
                :allow-new="true"
                :open-on-focus="true"
                icon="label"
                placeholder="Ajouter"
                @focus="handleFocus"
                @input="handleInput"
              >
              </b-taginput>
            </b-field>
          </div>
          <div
            v-if="showValidateButton && editedClient.tags.length === 0"
            @click="addTag"
            style="color: white; background-color: #12c477; padding:2px; margin-left: 10px; margin-top:-5px; border-radius: 5px; height: 20px; width: 20px"
          >
            <b-icon
              pack="fas"
              icon="check"
              size="is-small"
              style="color: white"
            > </b-icon>
          </div>
        </div>

        <div style="
              padding-top: 70px;
              display: flex;
              justify-content: center;
              width: 100%;
              align-items: flex-end;
            ">
          <b-button
            type="is-success"
            @click="updateClient(editedClient)"
          >
            {{ $t('save') }}
          </b-button>
        </div>
      </div>
    </b-modal>
    <b-modal
      :active.sync="askForValidation"
      :can-cancel="false"
      :width="550"
    >
      <CharlieUserActionValidation
        :interviewId="currentInterviewId"
        :actionType="currentActionType"
        @close="doneValidation()"
      />
    </b-modal>
    <b-loading
      :is-full-page="true"
      :active.sync="loading"
      :can-cancel="false"
    />
  </div>
</template>


<script>
import AddClientList from '../../components/AddClientList.vue';
import InvitationCustom from '../../components/InvitationCustom.vue';
import InvitationList from '../../components/invitationList.vue';
import DownloadTestimonial from '../../components/downloadTestimonial.vue';
import CharlieUserActionValidation from '../../components/charlieUserActionValidation.vue';
import moment from 'moment';
import i18n from '@/i18n';

/**
 * @name userclients
 * @desc Component for managing user clients
 */
export default {
  name: 'userclients',
  components: {
    AddClientList,
    InvitationCustom,
    InvitationList,
    DownloadTestimonial,
    CharlieUserActionValidation
  },
  computed: {
    /**
     * @name tags
     * @desc Computes unique tags from root clients
     * @returns {array} - Unique tags
     */
    tags() {
      const allTags = this.rootClients.map((client) => client.tags).flat();
      // Remove duplicates in array
      return [...new Set(allTags)];
    }
  },
  props: {
    charlieUser: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      /**
       * @desc Filter values for clients
       * @type {object}
       */
      filterValue: {
        tag: null,
        action: null,
        adminManaged: true
      },
      /**
       * @desc List of clients
       * @type {array}
       */
      clients: [],
      /**
       * @desc Root clients data
       * @type {array}
       */
      rootClients: [],
      /**
       * @desc Flag to show validation button
       * @type {boolean}
       */
      showValidateButton: false,
      /**
       * @desc Flag to ask for validation
       * @type {boolean}
       */
      askForValidation: false,
      /**
       * @desc ID of the current interview
       * @type {string}
       */
      currentInterviewId: null,
      /**
       * @desc Type of current action
       * @type {string}
       */
      currentActionType: null,
      /**
       * @desc Tags for invitations
       * @type {array}
       */
      invitationTags: [],
      /**
       * @desc Flag to show locked status
       * @type {boolean}
       */
      showLocked: false,
      /**
       * @desc Flag to add a client
       * @type {boolean}
       */
      addClient: false,
      /**
       * @desc Flag for sending a request
       * @type {boolean}
       */
      sendRequest: false,
      /**
       * @desc Flag for loading state
       * @type {boolean}
       */
      loading: false,
      /**
       * @desc Flag for waiting state
       * @type {boolean}
       */
      isWaiting: false,
      /**
       * @desc Status flag
       * @type {boolean}
       */
      status: true,
      /**
       * @desc Mission data object
       * @type {object}
       */
      missionData: {},
      /**
       * @desc List of invitations
       * @type {array}
       */
      invitations: [],
      /**
       * @desc Selected interview object
       * @type {object}
       */
      selectedInterview: null,
      /**
       * @desc Flag to show unlocked status
       * @type {boolean}
       */
      showUnlocked: false,
      /**
       * @desc Flag to show edit form
       * @type {boolean}
       */
      showEditForm: false,
      /**
       * @desc Edited client object
       * @type {object}
       */
      editedClient: null,
      /**
       * @desc Columns configuration for the table
       * @type {array}
       */
      columns: [
        {
          field: 'business',
          label: 'Business'
        },
        {
          field: 'surname',
          label: 'Prénom'
        },
        {
          field: 'name',
          label: 'Nom'
        },
        {
          field: 'email',
          label: 'Email'
        },
        {
          field: 'phoneNumber',
          label: 'Téléphone',
          numeric: true
        },
        {
          field: 'tags',
          label: 'Tags'
        },
        {
          field: 'status',
          label: ''
        }
      ]
    };
  },
  methods: {
    tooltipPosition(type) {
      return 'is-bottom';
    },
    tooltipType(type) {
      const types = {
        REFUS: 'is-danger',
        RELANCE: 'is-success',
        ABANDON: 'is-dark'
      };
      return types[type] || '';
    },
    iconType(type) {
      const icons = {
        REFUS: 'ban',
        RELANCE: 'clock',
        ABANDON: 'phone-slash'
      };
      return icons[type];
    },
    iconPackType(type) {
      const iconTypes = {
        REFUS: 'is-danger',
        RELANCE: 'is-success',
        ABANDON: 'is-dark'
      };
      return iconTypes[type] || '';
    },

    getOrderedLogs(clientLogs) {
      return [...clientLogs].sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    },
    /**
     * @name addTag
     * @desc Adds a tag
     */
    addTag() {
      this.$refs.tagInput.addTag();
      this.showValidateButton = false;
    },

    /**
     * @name handleFocus
     * @desc Handles focus
     */
    handleFocus() {
      this.showValidateButton = true;
    },

    /**
     * @name handleInput
     * @desc Handles input
     * @param {string} value - Input value
     */
    handleInput(value) {
      this.showValidateButton = value.length > 0;
    },

    /**
     * @name doneValidation
     * @desc Completes validation and refreshes clients
     */
    doneValidation() {
      this.askForValidation = false;
      this.refreshClients();
    },

    /**
     * @name computeStatusInfos
     * @desc Computes status information for an interview
     * @param {object} interview - Interview object
     * @returns {array} - Array of status information
     */
    computeStatusInfos(interview) {
      if (!interview) return [];
      let interviewStatus = new Object({
        color: '#828282 !important',
        date: this.daysBetween(
          interview.lastUserActionAt || interview.createdAt
        )
      });
      let lastLog = interview.ownerLogs[0];

      if (
        interview.status == 3 &&
        interview.unlocked === false &&
        interview.loading == false
      ) {
        interviewStatus = {
          ...interviewStatus,
          icon: 'warning',
          text: 'received',
          color: '#f1054d !important'
        };
      } else if (
        interview.status == 3 &&
        interview.loading === false &&
        interview.unlocked === true
      ) {
        interviewStatus = {
          ...interviewStatus,
          icon: 'circle-play',
          text: 'seeTestimony',
          color: '#7957d5 !important'
        };
      } else if (
        (!interview.hasOwnerLogs &&
          !lastLog &&
          (interview.status === 1 || interview.status === 2)) ||
        (lastLog?.createdAt < interview.lastUserActionAt &&
          (interview.status === 1 || interview.status === 2))
      ) {
        interviewStatus = {
          ...interviewStatus,
          icon: 'arrow-pointer',
          text: 'clicked',
          date: this.daysBetween(
            lastLog
              ? lastLog.createdAt
              : interview.lastUserActionAt || interview.createdAt
          )
        };
      } else if (
        lastLog &&
        lastLog.createdAt >
          (interview.lastUserActionAt || interview.createdAt) &&
        lastLog.type === 'RESENT'
      ) {
        interviewStatus = {
          ...interviewStatus,
          icon: 'hourglass',
          text: 'resent',
          date: this.daysBetween(lastLog.createdAt)
        };
      } else if (
        (!interview.hasOwnerLogs &&
          ((interview.status === 0 &&
            interview.interviewTemplate.status !== 0 &&
            interview.sentByCharlie) ||
            (interview.status === 0 &&
              interview.interviewTemplate.status == 1 &&
              !interview.sentByCharlie))) ||
        lastLog?.type === 'SENT'
      ) {
        interviewStatus = {
          ...interviewStatus,
          icon: 'hourglass',
          text: 'sent',
          date: this.daysBetween(
            lastLog
              ? lastLog.createdAt
              : interview.lastUserActionAt || interview.createdAt
          )
        };
      }
      return [interviewStatus];
    },

    /**
     * @name updateCurrentMissionData
     * @desc Updates current mission data with interview template
     * @param {object} interviewTemplate - Interview template object
     */
    updateCurrentMissionData(interviewTemplate) {
      this.missionData.interviewTemplate = interviewTemplate;
    },

    /**
     * @name handleSentRequest
     * @desc Handles sent request for validation
     */
    handleSentRequest() {
      this.currentInterviewId =
        this.missionData.interviewTemplate?.interviews[0]?.id;
      this.currentActionType = this.missionData.type;
      if (this.missionData && this.missionData.interviewTemplate) {
        this.askForValidation = true;
      }
    },
    /**
     * @name handleFilterChange
     * @desc Filters clients based on filter values
     */ handleFilterChange() {
      this.clients = this.rootClients.filter((client) => {
        let condition = true;
        if (this.filterValue.tag != null) {
          condition = condition && client.tags.includes(this.filterValue.tag);
        }
        if (this.filterValue.adminManaged != null) {
          condition =
            condition && client.adminManaged === this.filterValue.adminManaged;
        }
        switch (this.filterValue.action) {
          case 'ask':
            condition =
              (condition && client.interviews.length == 0) ||
              (client.interviews.length != 0 &&
                client.interviews[0].status === 0 &&
                client.interviews[0].interviewTemplate.status == 0);
            break;
          case 'retry':
            condition =
              condition &&
              client.interviews &&
              client.interviews.length != 0 &&
              ((client.interviews[0].status === 0 &&
                client.interviews[0].interviewTemplate.status !== 0) ||
                client.interviews[0].status === 1 ||
                client.interviews[0].status === 2 ||
                (client.interviews[0].lastNotifStatus === 'noAgree' &&
                  client.interviews[0].status !== 3));
            break;
          case 'unlock':
            condition =
              condition &&
              client.interviews.length != 0 &&
              client.interviews[0].status === 3 &&
              client.interviews[0].unlocked === false &&
              client.interviews[0].loading === false;
            break;
          case 'thanks':
            condition =
              condition &&
              client.interviews.length != 0 &&
              client.interviews[0].status === 3 &&
              client.interviews[0].unlocked === true &&
              client.interviews[0].loading === false;
            break;
          default:
            break;
        }
        return condition;
      });
    },

    /**
     * @name resetModalData
     * @desc Resets invitations data in modal
     */
    resetModalData() {
      this.invitations = [];
    },

    /**
     * @name unlock
     * @desc Initiates unlocking process for selected interview
     */
    unlock() {
      this.$store
        .dispatch('retryUnlock', this.selectedInterview.id)
        .catch((error) => {
          this.loading = false;
          console.error(error);
          this.$buefy.toast.open({
            message: this.$t('errorOccured'),
            type: 'is-danger',
            queue: false,
            position: 'is-top-right',
            duration: 5000
          });
        })
        .then(() => {
          this.loading = false;
        });
      this.waitForUnlock(this.selectedInterview);
      this.showLocked = false;
      this.selectedInterview = false;
    },

    /**
     * @name waitForUnlock
     * @desc Waits for the selected interview to be unlocked
     * @param {object} interviewToWait - Interview to wait for
     */
    waitForUnlock(interviewToWait) {
      const interviewId = interviewToWait.id;
      this.rootClients.forEach((client) => {
        client.interviews.forEach((interview) => {
          if (interview.id == interviewId) {
            interview['loading'] = true;
          }
        });
      });
      this.checkInterval = setInterval(() => {
        this.refreshClients();
        this.rootClients.forEach((client) => {
          client.interviews.forEach((interview) => {
            if (interview.id == interviewId && interview.unlocked) {
              clearInterval(this.checkInterval);
              this.$buefy.notification.open({
                message: this.$t('unlockSuccess'),
                type: 'is-success',
                position: 'is-bottom-right',
                duration: 5000
              });
            }
          });
        });
      }, 10000);
    },

    /**
     * @name daysBetween
     * @desc Calculates the number of days between two dates
     * @param {Date} date1 - First date
     * @returns {string} - Number of days between the dates
     */
    daysBetween(date1) {
      const momentDate1 = moment(date1);
      const now = moment();
      const days = now.diff(momentDate1, 'days');
      if (momentDate1.isSame(moment(), 'day')) {
        return this.$t('today');
      } else if (days === 1 || days === 0) {
        return this.$t('yesterday');
      } else if (days < 7) {
        return days + this.$t('days');
      } else if (days < 30) {
        const weeks = Math.floor(days / 7);
        return weeks + (weeks > 1 ? this.$t('weeks') : this.$t('week'));
      } else {
        const months = now.diff(momentDate1, 'months', true);
        return (
          Math.floor(months) +
          (Math.floor(months) > 1 ? this.$t('months') : this.$t('month'))
        );
      }
    },
    /**
     * @name show
     * @desc Displays interview details and handles navigation based on the interview status
     * @param {object} inputInterview - The interview object
     * @param {boolean} unlocked - Indicates if the interview is unlocked
     */ show(inputInterview, unlocked) {
      this.loading = true;
      const index = this.rootClients.findIndex(
        (item) =>
          item.interviews.length > 0 &&
          item.interviews[0].id === inputInterview.id
      );
      this.selectedRowIndex = index;
      this.$store
        .dispatch('getInterviewTemplate', inputInterview.interviewTemplate.id)
        .then((result) => {
          let itwTemplate = result.data;
          itwTemplate.interviews.forEach((interview) => {
            if (
              interview.id == inputInterview.id &&
              interview.unlocked == unlocked
            ) {
              this.selectedInterview = interview;
              if (unlocked) {
                this.$router.push({
                  name: 'testimonials_manager',
                  params: {
                    id: interview.id,
                    interview: interview
                  }
                });
              } else {
                this.showLocked = true;
                this.loading = false;
              }
            }
          });
        });
    },

    /**
     * @name askForTestimonial
     * @desc Initiates the process of asking for a testimonial
     * @param {array} clients - Array of clients
     * @param {string} type - Type of testimonial request
     */
    askForTestimonial(clients, type) {
      let interviewTemplate = null;

      if (clients[0] && clients[0].interviews && clients[0].interviews[0]) {
        if (type === 'invitation') {
          if (
            clients[0].interviews[0].invitation &&
            clients[0].interviews[0].invitation.interview
          ) {
            this.sendRequest = true;
            return;
          } else {
            interviewTemplate = clients[0].interviews[0].interviewTemplate;
            interviewTemplate.interviews = clients[0].interviews;
          }
        } else if (type === 'relance' || type === 'remerciement') {
          interviewTemplate = clients[0].interviews[0].interviewTemplate;
          interviewTemplate.interviews = clients[0].interviews;
        }
      }

      this.missionData = {
        clients: clients,
        type: type,
        interviewTemplate: interviewTemplate
      };
      this.showUnlocked = false;
      this.sendRequest = true;
    },

    /**
     * @name addClients
     * @desc Adds new clients based on the provided invitations
     */
    async addClients() {
      this.loading = true;
      this.trimEmptyLines();
      this.trimSpaces();
      if (
        this.rootClients.some((client) =>
          this.invitations
            .map((invitation) => invitation.email)
            .includes(client.user.email)
        )
      ) {
        this.loading = false;
        const notif = this.$buefy.notification.open({
          duration: 5000,
          message: this.$t('errorEmail'),
          position: 'is-bottom-right',
          style: 'background-color: #f1054d!important; color: white!important',
          hasIcon: true
        });
        return;
      }
      if (this.checkInvitations()) {
        this.trimPhones();

        let clients = [];
        for (let invitation of this.invitations) {
          clients.push({
            ownerId: this.charlieUser?.user.id,
            business: invitation.business,
            email: invitation.email,
            phoneNumber: invitation.phone ? invitation.phone : '',
            name: invitation.name,
            surname: invitation.surname,
            tags: this.invitationTags,
            adminManaged: true
          });
        }
        await this.$store
          .dispatch('createAdminClients', {
            data: clients,
            id: this.charlieUser?.user.id
          })
          .catch((err) => {
            const notif = this.$buefy.notification.open({
              duration: 5000,
              message: 'messagerreur',
              position: 'is-bottom-right',
              style:
                'background-color: #f1054d!important; color: white!important',
              hasIcon: true
            });
            this.loading = false;
          });

        this.loading = false;
        this.addClient = false;
        this.refreshClients();
      } else {
        this.loading = false;
        const notif = this.$buefy.notification.open({
          duration: 5000,
          message: this.$t('errorInfo'),
          position: 'is-bottom-right',
          style: 'background-color: #f1054d!important; color: white!important',
          hasIcon: true
        });
      }
    },
    /**
     * @name showEditClientForm
     * @desc Displays the edit form for a client
     * @param {object} client - The client object to edit
     */
    showEditClientForm(client) {
      this.editedClient = { ...client };
      this.showEditForm = true;
    },

    /**
     * @name updateClient
     * @desc Updates a client's information
     * @param {object} client - The client object to update
     */
    updateClient(client) {
      let editedClient = client;
      if (!editedClient.user.name || !editedClient.user.surname) {
        console.error('Error updating client: Name and surname required');
        this.$buefy.toast.open({
          message: this.$t('errorOccuredForm'),
          type: 'is-danger',
          queue: false,
          duration: 5000
        });
        this.isWaiting = false;
        return;
      } else {
        this.isWaiting = true;
        this.$store
          .dispatch('updateClient', client)
          .then(() => {
            this.isWaiting = false;
            this.showEditForm = false;
            this.refreshClients();
          })
          .catch((error) => {
            console.error('Error updating client:', error);
            this.isWaiting = false;
          });
      }
    },

    /**
     * @name deleteClient
     * @desc Deletes a client
     * @param {string} clientId - The ID of the client to delete
     * @param {string} userName - The user's name
     * @param {string} userSurname - The user's surname
     */
    async deleteClient(clientId, userName, userSurname) {
      const confirmDelete = await this.$buefy.dialog.confirm({
        title: this.$t('deleteClient'),
        message:
          this.$t('deleteClient1') +
          '<strong>' +
          userSurname +
          ' ' +
          userName +
          ' ' +
          '</strong>' +
          this.$t('deleteClient2'),
        confirmText: this.$t('delete'),
        cancelText: this.$t('cancel'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          this.loading = true;
          await this.$store
            .dispatch('deleteClient', { id: clientId })
            .then(() => {
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
          this.$buefy.toast.open({
            message: this.$t('clientDeleted'),
            type: 'is-success',
            position: 'is-bottom-right'
          });
          this.refreshClients();
        }
      });
    },

    /**
     * @name trimPhones
     * @desc Removes spaces from phone numbers in invitations
     */
    trimPhones() {
      this.invitations.forEach((invitation) => {
        invitation.phone = invitation.phone.replace(/\s/g, '');
      });
    },

    /**
     * @name trimSpaces
     * @desc Removes spaces from email, surname, and name fields in invitations
     */
    trimSpaces() {
      this.invitations.forEach((invitation) => {
        invitation.email = invitation.email?.replace(/\s/g, '');
        invitation.surname = invitation.surname?.replace(/\s/g, '');
        invitation.name = invitation.name?.replace(/\s/g, '');
      });
    },
    /**
     * @name trimEmptyLines
     * @desc Deletes empty lines in invitations fields
     */
    trimEmptyLines() {
      this.invitations.forEach((invitation) => {
        if (invitation.email == null) {
          invitation.email = '';
        }
        if (invitation.surname == null) {
          invitation.surname = '';
        }
        if (invitation.name == null) {
          invitation.name = '';
        }
        if (invitation.phone == null) {
          invitation.phone = '';
        }
      });
      if (this.invitations.length > 1) {
        this.invitations = this.invitations.filter((invitation) => {
          return (
            invitation.email != '' ||
            invitation.surname != '' ||
            invitation.name != '' ||
            invitation.phone != ''
          );
        });
      }
    },
    /**
     * @name checkInvitations
     * @desc Check if invitations are valid
     * @returns {Boolean} - true if invitations are valid
     */
    checkInvitations() {
      const emailPattern =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let checkEmptyinvitations = this.invitations.filter(
        (line) =>
          line.surname === null ||
          line.email === null ||
          line.name === null ||
          line.surname == '' ||
          line.email == '' ||
          line.name === '' ||
          !emailPattern.test(line.email)
      );
      return !(
        checkEmptyinvitations.length >= 1 && this.invitations.length > 0
      );
    },
    /**
     * @name checkPhone
     * @desc Check if phone numbers are valid
     * @returns {Boolean} - true if phone numbers are valid
     */
    checkPhone() {
      const phonePattern = /^\+33[0-9]{9}$/;
      let checkPhone = this.invitations.filter(
        (line) =>
          line.phone && line.phone.length > 0 && !phonePattern.test(line.phone)
      );
      return !(checkPhone.length >= 1 && this.invitations.length > 0);
    },
    /**
     * @name refreshClients
     * @desc Refreshes the list of admin clients
     */
    refreshClients() {
      this.$store
        .dispatch('getAdminClients', { id: this.charlieUser?.user.id })
        .then((res) => {
          console.log(res);
          this.rootClients = res.data;
          this.handleFilterChange();
        });
    },
    openStorage(client, type) {
      console.log(client);
      let rootUrl =
        'https://console.cloud.google.com/storage/browser/getinshoot-api';
      if (type == 'original') {
        window.open(
          `${rootUrl}/incoming/interviews/${client.interviews[0].externalId}?project=get-in-shoot&prefix=&forceOnBucketsSortingFiltering=true`,
          '_blank'
        );
      } else {
        window.open(
          `${rootUrl}/charlie/${this.charlieUser?.user.id}/${client.user.id}?project=get-in-shoot&prefix=&forceOnBucketsSortingFiltering=true`,
          '_blank'
        );
      }
    }
  },

  /**
   * Lifecycle hook: mounted
   */
  mounted() {
    this.refreshClients();
  },
  watch: {
    /**
     * Watcher for sendRequest property
     * @param {boolean} val - The new value of sendRequest
     */
    sendRequest: function (val) {
      if (!val) {
        this.handleSentRequest();
        //this.refreshClients();
      }
    },
    /**
     * Watcher for charlieUser property
     * @param {object} newValue - The new value of charlieUser
     * @param {object} oldValue - The old value of charlieUser
     */
    charlieUser: function (newValue, oldValue) {
      if (newValue) {
        this.refreshClients();
      }
    }
  }
};
</script>

<style scoped>
.directory_container {
  padding: 10px;
}

.modal-content {
  overflow: hidden !important;
}

.directory_add_filter-display {
  display: flex;
  justify-content: flex-start;
  gap: 25px;
  align-items: center;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0.4s;
}

@media all and (max-width: 1100px) {
  .directory_container {
    width: 95vw;
  }
}

@media all and (max-width: 700px) {
  .directory_add_filter-display {
    flex-direction: column !important;
  }

  .display-filter {
    flex-direction: column;
    align-items: flex-start !important;
  }
}
</style>


<style src="../index.css"></style>

<style scoped>
p {
  font-size: 12px;
  margin-bottom: 5px;
  font-weight: 600;
}
</style>
