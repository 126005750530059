<template>
  <div class="tile is-ancestor is-vertical mainContainer">
    <span
      class="centerPosition"
      style="font-size:10px; align-items: center; padding-bottom: 5px;"
    >
      <img
        src="./../../public/img/YourCharlie_logo.png"
        style="width: 100px"
      >
    </span>

    <div v-if="currentQuestion"></div>
    <div v-else>
      <b-progress
        v-if="showProgressBar"
        :rounded="false"
        :value="((currentQuestion + 2) * 100) / (interviewData.questions.length + 2)
        "
        type="is-dark"
        format="percent"
      ></b-progress>
    </div>

    <div v-if="currentQuestion == -1">
      <div
        class="tile is-parent is-vertical"
        style="margin: auto; text-align: center; font-size: 15px"
      >
        <h2 style="font-size: 20px !important;">{{ $t('helloThere1') }} {{ interviewData.user.surname[0].toUpperCase() +
          interviewData.user.surname.slice(1)
        }},</h2>
        <p class="helloDesc">
          {{ $t('helloThere2') }} <br />
        </p>
      </div>
      <div
        class="tile is-parent"
        style="display: flex;align-items: center; justify-content: center;flex-direction: column; gap: 10px;"
      >
        <div
          class="recorderContainer"
          v-if="interviewData.customMedia && interviewData.customMedia.previewUrl"
        >
          <video
            controls
            preload="metadata"
            controlslist="nodownload ; nofullscreen ; noremoteplayback ; noplaybackrate"
            disablePictureInPicture
          >
            <source
              :src="interviewData.customMedia.previewUrl + '#t=0.001'"
              type="video/mp4"
            />
          </video>
        </div>

        <div
          v-if="interviewData.customMessage != null && interviewData.customMessage != ''"
          class="homeRecorder_reward"
        >
          <div style="text-align: start; color: #161032;font-size: 14px; display: flex; align-items: center; gap: 15px;">
            <img
              src="./../../public/img/iconincentive.png"
              style="width: 35px;"
            >
            <p style=" color: #c19c00 !important;"><b style="color: #161032;">{{ $t('homeRecorderReward') }}</b> {{
              interviewData.customMessage }}</p>
          </div>
        </div>
        <div v-else>
        </div>

        <span class="customMessage">
          <div
            class="tile is-vertical"
            style="border: 1px #d7d8d9 solid;"
          >
            <button
              v-if="isMobile"
              @click="toggleDropdown"
              class="dropdown-button"
            >
              <span style="display: flex;align-items: center; justify-content: center; gap: 5px">{{ $t('recordingTips') }}
                <img
                  src="../../public/img/help.png"
                  style="width: 20px;"
                /></span>
            </button>
            <div v-if="!isMobile || dropdownOpen">
              <div class="homeRecorder-tips_div">
                <img
                  src="./../../public/img/clock_google_black.png"
                  alt="white clock"
                  class="homeRecorder-tips_img"
                >
                <p> {{ $t('recordingTips1') }} ({{ interviewData.questions.length }} {{ $t('recordingTips1Q') }})</p>
              </div>
              <div class="homeRecorder-tips_div">
                <img
                  src="./../../public/img/retry_google_black.png"
                  alt="white recycle circle"
                  class="homeRecorder-tips_img"
                >
                <p> {{ $t('recordingTips2') }}</p>
              </div>
              <div class="homeRecorder-tips_div">
                <img
                  src="./../../public/img/headphone_google_black.png"
                  alt="white headphones"
                  class="homeRecorder-tips_img"
                >
                <p> {{ $t('recordingTips3') }}</p>
              </div>
              <div class="homeRecorder-tips_div">
                <img
                  src="./../../public/img/smiley_black.png"
                  alt="white headphones"
                  class="homeRecorder-tips_img"
                >
                <p> {{ $t('recordingTips4') }}</p>
              </div>
            </div>
          </div>
        </span>
      </div>
      <div class="tile is-parent rightsContainer">
        <RightsCession
          ref="rights"
          :guestSurName="interviewData.user.surname"
          :guestLastName="interviewData.user.name"
          :guestEmail="interviewData.user.email"
          :guestPhone="interviewData.user.phoneNumber"
          :userBusiness="interviewData.owner.business"
          :recordDay="new Date().toLocaleDateString()"
          :recordHour="new Date().toLocaleTimeString()"
        />
      </div>

      <div class="tile is-parent centerPosition">
        <button
          class="homeRecorder-button"
          @click="start"
        >{{ $t('begin') }}<img
            src="../../public/img/check.png"
            style="width: 25px;"
          /></button>

      </div>
    </div>

    <div
      v-else-if="currentQuestion == interviewData.questions.length"
      style="display: flex; align-items: center; justify-content: center;"
    >
      <div
        class="tile is-parent is-vertical"
        style="margin: auto; text-align: center; font-size: 18px"
      >
        <br />
        <div style="display: flex; justify-content: center;">
          <img
            src="./../../public/img/validate.png"
            style="width: 15%;"
          >
        </div><br /><br />
        <p style="font-size: 22px; font-weight: 600; color: #161032;">
          {{ $t('MThanks') }}
          <br />
          <span style="font-size: 16px; color: #828282; font-weight: 400;">{{ $t('endingCongrats1') }}</span>

          <br />
          <br />
        <div v-if="$i18n.locale == 'fr'">
          <a
            target="_blank"
            style="color: #161032 ; font-size: 16px !important; display: flex; justify-content: center;"
            href="https://www.yourcharlie.com"
          >
            <div style="border:2px solid #161032; width: 45%; font-weight: 600; padding: 10px;">
              {{ $t('referalCharlie') }}
            </div>
          </a>
        </div>
        <div v-if="$i18n.locale == 'en'">
          <a
            target="_blank"
            style="color: #161032 ; font-size: 16px !important; display: flex; justify-content: center;"
            href="https://www.yourcharlie.com/?lang=en"
          >
            <div style="border:2px solid #161032; width: 45%; font-weight: 600; padding: 10px;">
              {{ $t('referalCharlie') }}
            </div>
          </a>
        </div>
        <div v-if="$i18n.locale == 'es'">
          <a
            target="_blank"
            style="color: #161032 ; font-size: 16px !important; display: flex; justify-content: center;"
            href="https://www.yourcharlie.com/?lang=es"
          >
            <div style="border:2px solid #161032; width: 45%; font-weight: 600; padding: 10px;">
              {{ $t('referalCharlie') }}
            </div>
          </a>
        </div>

        </p>
      </div>
    </div>

    <div v-else>
      <div
        class="tile is-parent is-vertical"
        style="margin: auto; text-align: center; font-size: 18px"
      >
        <span>
          <b-tag
            :rounded="false"
            style="max-width: 200px; background-color: #d9dd1b"
          >{{ $t('actualQuestion') }} {{
            currentQuestion + 1
          }}/{{
  interviewData.questions.length
}}</b-tag>
        </span>
        <br />

        <p style="font-size: 22px">
          {{ interviewData.questions[currentQuestion].text }}
        </p>
      </div>

      <Recorder
        ref="recorder"
        :interviewExternalId="externalId"
        :questionId="interviewData.questions[currentQuestion].id"
        :signedUrl="interviewData.questions[currentQuestion].signedUrl"
        @done="nextQuestion"
        @error="handleRecorderError($event)"
        @tooShort="handleTooShort()"
      />
    </div>

    <b-modal
      :active="showRetry"
      :can-cancel="false"
    >
      <div class="modalRetry__container-position">
        <div class="modalRetry__container">
          <section class="charlie__message charlie__message_A">
            <p class="charlie__content">{{ $t('modalRetry') }}<br />{{ $t('modalRetry2') }}</p>
          </section><br /><br /><br />
          <footer class="modal-card-foot centerPosition">
            <button
              class="button-3d-revert"
              @click="showRetry = false"
            >{{ $t('tryAgain') }}</button>
            <button
              class="button-3d"
              @click="continueItw"
            >{{ $t('continue') }}</button>
          </footer>
        </div>
      </div>
    </b-modal>

    <b-modal
      :active="fatalError"
      :can-cancel="false"
    >
      <div class="modalRetry__container-position">
        <div class="modalRetry__container">
          <img
            src="./../../public/img/oops.png"
            style="width: 90px"
          ><br>
          <h3>{{ interviewData.user.surname[0].toUpperCase() + interviewData.user.surname.slice(1)
          }},</h3>
          <p class="charlie__content">{{ $t('fatalErrorRecord') }}</p>
        </div>
      </div>
    </b-modal>

    <b-modal
      :active="fatalError"
      :can-cancel="false"
    >
      <div class="modalRetry__container-position">
        <div class="modalRetry__container">
          <img
            src="./../../public/img/oops.png"
            style="width: 90px"
          ><br>
          <h3>{{ interviewData.user.surname[0].toUpperCase() + interviewData.user.surname.slice(1)
          }},</h3>
          <p class="charlie__content">{{ $t('fatalErrorRecord') }}</p>
        </div>
      </div>
    </b-modal>

    <b-modal
      :active="tooShort"
      :can-cancel="false"
    >
      <div class="modalRetry__container-position">
        <div class="modalRetry__container">
          <img
            src="./../../public/img/oops.png"
            style="width: 90px"
          >
          <br>
          <h3>{{ interviewData.user.surname[0].toUpperCase() + interviewData.user.surname.slice(1)
          }},</h3>
          <p class="charlie__content">{{ $t('tooShortRecord') }}</p>
          <button
            class="button-3d"
            @click="forceRetry()"
          >Recommencer</button>
        </div>
      </div>
    </b-modal>

  </div>
</template>

  


<script>
import Recorder from './recorder.vue';
import RightsCession from '../views/RightsCession.vue';

export default {
  name: 'recorderwrapper',
  components: {
    Recorder,
    RightsCession
  },
  props: {
    interviewData: {
      type: Object,
      required: true
    },
    externalId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      currentQuestion: -1,
      showRetry: false,
      fatalError: false,
      tooShort: false,
      dropdownOpen: false,
      isMobile: window.innerWidth < 1420,
      showProgressBar: false
    };
  },
  mounted() {
    if (this.interviewData.currentStep != -1) {
      this.showRetry = true;
    }
    window.addEventListener('resize', this.checkScreenSize);
    if (this.interviewData.currentStep != -1) {
      this.showRetry = true;
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkScreenSize);
  },
  methods: {
    handleRecorderError(error) {
      console.error(error);
      this.$store.dispatch('recorderError', {
        externalId: this.externalId,
        error: error
      });
      this.fatalError = true;
    },
    handleTooShort() {
      this.tooShort = true;
    },
    forceRetry() {
      this.tooShort = false;
      this.$refs.recorder.reset();
    },
    continueItw() {
      this.currentQuestion = this.interviewData.currentStep;
      this.showRetry = false;
    },
    start() {
      this.$store
        .dispatch('signTerms', {
          externalId: this.externalId,
          terms: this.$refs.rights.$el.innerHTML
        })
        .catch((error) => {
          console.error(error);
          this.$buefy.toast.open({
            message: this.$t('errorOccured'),
            type: 'is-danger',
            queue: false,
            duration: 5000
          });
        });
      this.nextQuestion();
    },
    nextQuestion() {
      this.currentQuestion++;
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    checkScreenSize() {
      this.isMobile = window.innerWidth < 1420;
    }
  }
};
</script>

<style src="./charlie/charlie.css"></style>

<style scoped>
.modalRetry__container {
  height: 400px !important;
  width: 765px !important;
}

.dropdown-button {
  background-color: #f9f9f9;
  border: none;
  padding: 10px;
  cursor: pointer;
  width: 100%;
}

@media (min-width: 1420px) {
  .dropdown-button {
    display: none;
  }
}
</style>