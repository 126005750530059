<template>
  <section>
    <div>
      <br />
      <b-field
        label="Nom du template"
        horizontal
      >
        <b-input
          style="border-bottom: 1px solid #161032;"
          v-model="template.name"
        ></b-input>
      </b-field>
      <div class="tile is-parent">
        <div class="tile">
          <b-field>
            <b-select
              :placeholder="$t('langue du template')"
              v-model=template.locale
            >
              <option value="fr">🇫🇷&nbsp;&nbsp;français</option>
              <option value="en">🇬🇧&nbsp;&nbsp;english</option>
              <option value="es">🇪🇸&nbsp;&nbsp;español</option>
              <option value="it">🇮🇹&nbsp;&nbsp;italiano</option>
            </b-select>
            <b-select
              placeholder="tonalité"
              v-model=template.formality
            >
              <option :value="false">tu</option>
              <option :value="true">vous</option>
            </b-select>
            <b-select
              placeholder="type"
              v-model=template.tag
            >
              <option value="invitation">invitation</option>
              <option value="relance">relance</option>
              <option value="remerciement">remerciement</option>
            </b-select>
            <b-select
              placeholder="visibilité"
              v-model=template.public
            >
              <option :value="false">🚫</option>
              <option :value="true">✅</option>
            </b-select>
          </b-field>
        </div>

      </div>
      <hr />
      <div class="tile is-parent">
        <div class="tile is-child box">
          <b-field
            label="Objet du mail"
            horizontal
          >
            <b-input
              style="background-color: #d1d1d1;"
              v-model="template.subject"
            ></b-input>
          </b-field>
          <b-field label="Contenu">

            <b-input
              type="textarea"
              style="background-color: #d1d1d1; max-height: 300px; height: 300px !important"
              v-on:load="loadTemplate()"
              v-model="template.content"
              required
            />
          </b-field>
        </div>

      </div>

      <div
        class="container"
        v-if="showEditor"
      >
        <!-- 
        <EmailEditor
          style="height:60vh !important; width: 800px !important"
          :appearance="appearance"
          v-on:load="loadTemplate()"
          :min-height="minHeight"
          :project-id="projectId"
          :locale="locale"
          :tools="tools"
          :options="options"
          ref="emailEditor"
        /> -->

        <div id="bar">
          <b-button v-on:click="saveTemplate">Save template</b-button>
        </div>
      </div>
    </div>
    <b-loading
      :is-full-page="false"
      :active.sync="isLoading"
    ></b-loading>
  </section>
</template>

<script>
import { EmailEditor } from 'vue-email-editor';

/**
 * @name editTemplate
 * @desc Component for editing email templates
 */
export default {
  name: 'editTemplate',
  components: { EmailEditor },
  props: {
    /**
     * Template object to edit
     */
    template: {
      type: Object,
      default: {}
    }
  },
  data: () => {
    return {
      /**
       * @type {string}
       * @desc Minimum height of the editor
       */
      minHeight: '60vh',
      /**
       * @type {boolean}
       * @desc Flag to show/hide the editor
       */
      showEditor: false,
      /**
       * @type {string}
       * @desc Default locale for the template
       */
      locale: 'fr',
      /**
       * @type {boolean}
       * @desc Flag to indicate loading state
       */
      isLoading: false,
      /**
       * @type {number}
       * @desc Project ID for the template
       */
      projectId: 0,

      tools: {
        image: {
          enabled: true
        },
        heading: {
          enabled: false
        },
        html: {
          enabled: true
        },
        menu: {
          enabled: false
        }
      },
      options: {
        tabs: {
          blocks: {
            enabled: false
          }
        },
        mergeTags: {}
      },
      appearance: {
        theme: 'light',

        panels: {
          tools: {
            dock: 'right'
          }
        }
      }
    };
  },
  beforeMount() {
    this.$store.dispatch('getAllMailVariables').then((result) => {
      console.log(result.data);
      const variables = result.data;
      this.options.mergeTags = {
        ...variables
      };
      this.showEditor = true;
    });
  },
  methods: {
    /**
     * @name loadTemplate
     * @desc Loads the template design
     */
    loadTemplate() {
      if (this.template.jsonContent) {
        console.log(this.template.jsonContent);
        this.$refs.emailEditor.editor.loadDesign(
          JSON.parse(this.template.jsonContent)
        );
      }
    },

    /**
     * @name saveTemplate
     * @desc Saves the template
     */
    saveTemplate() {
      if (
        !this.template.name ||
        !this.template.subject ||
        !this.template.locale ||
        this.template.name == '' ||
        this.template.subject == '' ||
        this.template.locale == ''
      ) {
        this.$buefy.toast.open({
          message: this.$t('errorInput'),
          type: 'is-danger',
          position: 'is-bottom',
          duration: 5000,
          queue: false
        });
        return;
      }
      this.isLoading = true;
      this.template.jsonContent = JSON.stringify({
        data: this.template.content
      });
      if (this.template.id) {
        this.$store
          .dispatch('updateMailTemplate', this.template)
          .then((result) => {
            this.isLoading = false;
            this.$emit('updateTemplate', this.template);
          });
      } else {
        this.$store
          .dispatch('createMailTemplate', this.template)
          .then((result) => {
            this.isLoading = false;
            this.$emit('updateTemplate', this.template);
          });
      }
      console.log('exportHtml', data);
    }
  }
};
</script>

<style src="../index.css"></style>
