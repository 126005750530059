<template>
  <div>
    <MyTools></MyTools>
    <hr />
    <AllTools
      :hasFreeSlider="!$store.getters.playlists || $store.getters.playlists.filter(p => p.type == 'slider' && p.free == true).length > 0"
      :hasFreePopUp="!$store.getters.playlists || $store.getters.playlists.filter(p => p.type == 'popup' && p.free == true).length > 0"
    >
    </AllTools>
  </div>
</template>



<script>
import i18n from '@/i18n';
import moment from 'moment';
import MyTools from './../components/diffusion/myTools.vue';
import AllTools from '../components/diffusion/allTools.vue';
import Builder from './../components/diffusion/builder.vue';
import ModalCode from './../components/diffusion/modalCode.vue';
import ModalLink from './../components/diffusion/modalLink.vue';
import ModalShop from './../components/diffusion/modalShop.vue';
import SliderSettings from './../components/diffusion/sliderSettings.vue';

/**
 * @name integration
 * @desc Component for integration
 */
export default {
  name: 'integration',
  components: {
    MyTools,
    AllTools,
    Builder,
    ModalCode,
    ModalLink,
    ModalShop,
    SliderSettings
  },

  data: () => ({
    /**
     * URL pour charliePop
     * @type {string}
     * @desc URL de base pour le script charliePop
     */
    charliePopUrl: process.env.VUE_APP_SELF_URL + '/charliePop.js',

    /**
     * URL pour charlieSlider
     * @type {string}
     * @desc URL de base pour la page charlieSlider, incluant un paramètre d'identification
     */
    charlieSliderUrl:
      process.env.VUE_APP_SELF_URL + '/slider/charlieSlider.html?id=',

    /**
     * URL de la playlist
     * @type {string|null}
     * @desc URL pour accéder aux détails de la playlist, initialisée à null
     */
    playlistUrl: null,

    /**
     * URL du slider
     * @type {string|null}
     * @desc URL pour accéder au slider, initialisée à null
     */
    sliderUrl: null,

    /**
     * Script du widget
     * @type {undefined}
     * @desc Script pour le widget, non défini initialement
     */
    widgetScript: undefined,

    /**
     * Vue actuelle
     * @type {null}
     * @desc Vue actuellement affichée, initialisée à null
     */
    currentView: null
  }),

  /**
   * @name beforeMount
   * @desc Lifecycle hook before component is mounted
   */
  beforeMount() {
    if (!this.$store.getters.playlist) {
    } else {
      this.playlistUrl =
        process.env.VUE_APP_API_URL +
        '/charlie/playlists/id/' +
        this.$store.getters.playlist.externalId +
        '?type=widget';
      this.sliderUrl =
        process.env.VUE_APP_SELF_URL +
        '/slider/charlieSlider.html?id=' +
        this.$store.getters.playlist.externalId +
        '&type=slider';
    }
  }
};
</script>

<style src="./../components/index.css"></style>