<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
/**
 * @name Home
 * @desc Component responsible for handling locale changes
 */
export default {
  name: 'Home',
  watch: {
    /**
     * Watcher for locale changes
     * @param {string} newVal - The new locale value
     * @param {string} oldVal - The old locale value
     */
    '$i18n.locale': function (newVal, oldVal) {
      localStorage.setItem('last-locale', newVal);
      if (
        this.$store.getters.activeUser &&
        this.$store.getters.activeUser.name &&
        newVal !== this.$store.getters.activeUser.locale
      ) {
        this.$store.dispatch('updateCharlieUser', {
          ...this.$store.getters.activeUser,
          locale: newVal
        });
      }
    }
  }
};
</script>

<style>
</style>
