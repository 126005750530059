<template>
  <div style="box-shadow: none; border: 2px solid #F0F0F0; padding: 20px ; margin-right: 35px !important; background-color: #fbfcfb; ">
    <div v-if="this.$store.getters.activeUser.premium">
      <br>
      <p style="font-size: 30px; font-weight: 600; text-align: center;">{{ $t('premiumTitle1') }}</p>
      <div style="width: 70%;display: flex;margin: 0 auto; align-items: center; flex-direction: column"><br>
        <p style="text-align: center;">{{ $t('premiumSentence1') }}<br>{{ $t('premiumSentence2') }}</p><br>
        <div style="margin: 0 auto;display: flex;">
          <div style="margin: 0 10px">
            <a
              href="https://wa.me/33744300013"
              target="_blank"
            >
              <button
                class="button-3d-small"
                style="width: 200px !important;"
              >{{ $t('premiumButton1') }}</button>
            </a>
          </div>
          <div style="margin: 0 10px">
            <button
              class="button-3d-small-revert"
              style="width: 200px !important; border: 3px solid #161032"
              @click="goToSubscription"
            >{{$t('premiumButton2')}}</button>
          </div>
        </div>
      </div>
    </div>

    <div
      v-else
      style="display: flex; flex-direction: column;"
    >
      <h3>{{ $t('premiumSentence3') }}</h3>
      <div style="display: flex;">
        <div style="width: 70%;display: flex;flex-direction: column;margin: 0 auto;"><br>

          <p>{{ $t('premiumSentence5') }}
            <br>{{ $t('premiumSentence6') }}
            {{ $t('premiumSentence7') }}
            <br>{{ $t('premiumSentence8') }}
            <br>{{ $t('premiumSentence9') }}<strong>{{ $t('premiumSentence10') }}</strong>.
          </p><br>

        </div>
        <div style="width: 30%; display: flex; justify-content: center; align-items: center;margin: 0 auto;">
          <img
            src="./../../public/img/premium_question.jpg"
            style="width: 280px;"
          />
        </div>
      </div>
      <div style="margin: 0 auto;">
        <a
          target="_blank"
          href="https://www.yourcharlie.com/premium?utm=dash"
        >
          <button class="button-3d">{{ $t('premiumButton3') }}</button>
        </a>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: 'PremiumVue',
  methods: {
    goToSubscription() {
      const userEmail = this.$store.getters.activeUser.email;
      const encodedEmail = encodeURIComponent(userEmail);
      const url = `https://billing.stripe.com/p/login/6oEaGy06j2Tn1fGdQQ?prefilled_email=${encodedEmail}`;
      window.location.href = url;
    }
  }
};
</script>
  
  <style src="./index.css"></style>