
<template>
  <section>
    <b-field class="searchWrapper">
      <b-input
        v-model="filter"
        placeholder="Email, nom, prénom"
        icon="
        magnify"
      ></b-input>
      <b-button
        type="is-primary"
        icon-left="search"
        @click="refreshList(true)"
        :loading="loading"
      >
        {{ $t('recherche') }}
      </b-button>
    </b-field>
    <b-table
      :data="dataUsers"
      :hoverable="isHoverable"
      :loading="loading"
      paginated
      backend-pagination
      :current-page="page"
      :total="total"
      :per-page="perPage"
      detailed
      @page-change="onPageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      backend-sorting
      :checked-rows.sync="validateGroup"
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      @sort="onSort"
      :row-class="(row, index) => computeColorFromDateLine(row.clientLogs[0]?.nextActionDate)"
    >

      <b-table-column
        field="rappel"
        label="Date"
        v-slot="props"
      >

        <b-tag :style="computeColorFromDate(props.row.clientLogs[0]?.nextActionDate)">
          {{ props.row.clientLogs[0]?.nextActionDate | momentDate }}</b-tag>

      </b-table-column>

      <b-table-column
        field="makerz"
        label="makerz"
        v-slot="props"
      >
        <span
          @click="onUserClicked(props.row.owner)"
          style="font-size:15px; cursor: pointer;"
        >{{ props.row.owner.surname }} {{ props.row.owner.name }}</span><br>
        <span
          @click="onUserClicked(props.row.owner)"
          style="font-size: 10px; cursor: pointer;"
        >{{props.row.owner.email}}</span>
      </b-table-column>
      <b-table-column
        field="tag"
        label="tag"
        v-slot="props"
      >
        <b-tag>{{ props.row.tags[0] }}</b-tag>
      </b-table-column>
      <b-table-column
        field="link"
        label="Itw"
        v-slot="props"
      >
        <b-button
          size="is-small"
          style="border: none"
          @click="copyInterviewUrl(props.row.interviews[0])"
        >
          <b-icon
            pack="fas"
            icon="link"
            size="is-small"
          ></b-icon>
        </b-button>

      </b-table-column>
      <b-table-column
        field="link"
        label="Q°"
        v-slot="props"
      >
        <b-tooltip>
          <template v-slot:content>
            <div
              v-for="question of props.row.interviews[0].interviewTemplate.questions"
              :key="question.id"
            >
              <p style="color: white !important">{{question.text}}</p>
            </div>
          </template>
          <b-icon
            pack="
          fas"
            icon="fa-question"
            size="is-small"
          >
          </b-icon>
        </b-tooltip>

      </b-table-column>
      <b-table-column
        field="link"
        label="incentive"
        v-slot="props"
      >
        <b-tooltip
          v-if="props.row.interviews[0].interviewTemplate.customMessage"
          :label="props.row.interviews[0].interviewTemplate.customMessage"
          position="is-right"
          type="is-primary is-light"
        >
          <b-icon
            pack="fas"
            icon="dollar-sign"
            size="is-small"
          >
          </b-icon>
        </b-tooltip>
      </b-table-column>

      <b-table-column
        field="surname"
        label="Prénom"
        v-slot="props"
      >
        <strong>{{ props.row.user.surname }}</strong>
      </b-table-column>
      <b-table-column
        field="name"
        label="Nom"
        v-slot="props"
      >
        {{ props.row.user.name }}
      </b-table-column>

      <b-table-column
        field="phoneNumber"
        label="Phone"
        v-slot="props"
      >
        <span> {{ props.row.user.phoneNumber }}</span>

      </b-table-column>

      <template #detail="props">

        <div class="content">

          <div class="tile">
            <div class="tile is-4">

              <textarea
                rows="4"
                cols="50"
                v-model="shooterzLog.content"
                style="background-color: white; border: 1px solid #828282; width: 450px"
              >

              ></textarea>

            </div>
            <div class="tile is-2 is-vertical">

              <b-select
                v-model="shooterzLog.type"
                placeholder="action"
              >
                <option value="RELANCE">relance</option>
                <option value="REFUS">refus</option>
                <option value="ABANDON">abandon</option>
              </b-select>

              <b-field v-if="shooterzLog.type == 'RELANCE'">
                <b-datepicker
                  v-model="shooterzLog.nextActionDate"
                  placeholder="date de relance"
                  editable
                  :first-day-of-week="1"
                >
                </b-datepicker>
              </b-field>

              <b-button
                size="is-small"
                @click="saveShooterzLog(props.row.id)"
              >enregistrer</b-button>
            </div>
            <div
              class="tile is-6 is-vertical"
              style="padding-left: 20px;"
            >

              <div><strong>historique</strong></div>
              <div
                v-for="log of getOrderedLogs(props.row.clientLogs)"
                :key="log.id"
              >
                <span style="font-size: 11px;"> {{ log.createdAt | momentDate }} - {{ log.content }}</span>
              </div>

            </div>
          </div>
        </div>

      </template>
    </b-table>
    <br>

  </section>
</template>
  
<script>
import moment from 'moment';

export default {
  name: 'allTasksPremium',
  components: {},
  filters: {
    momentDate: function (date) {
      moment.locale('fr');
      return moment(date).format('LL');
    }
  },
  data: () => {
    return {
      validateGroup: [],
      dataUsers: [],
      isHoverable: true,
      loading: false,
      shooterzLog: {
        type: 'RELANCE',
        nextActionDate: new Date(),
        content: ''
      },
      sortField: 'createdAt',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      page: 1,
      perPage: 20,
      total: 0,
      filter: null
    };
  },
  beforeMount() {
    this.refreshList(true);
  },
  methods: {
    resetChecked() {
      this.validateGroup = [];
    },
    saveShooterzLog(id) {
      console.log(this.shooterzLog);
      this.shooterzLog.operator = this.$store.state.user.surname;
      this.$store
        .dispatch('createAdminClientLog', {
          id: id,
          ...this.shooterzLog
        })
        .then(() => {
          this.refreshList();
          this.shooterzLog = {
            type: 'RELANCE',
            nextActionDate: new Date(),
            content: '',
            operator: this.$store.state.user.surname
          };
        });
    },
    computeColorFromDate(date) {
      if (moment(date).isSame(moment(), 'day')) {
        return { backgroundColor: 'green', color: 'white' };
      } else if (moment(date).isBefore(moment())) {
        return { backgroundColor: 'red', color: 'white' };
      }
    },
    computeColorFromDateLine(date) {
      if (moment(date).isSame(moment(), 'day')) {
        return 'greenLine';
      } else if (moment(date).isBefore(moment())) {
        return 'redLine';
      }
    },
    refreshList(goToLastPage = false) {
      this.loading = true;
      this.$store
        .dispatch('getAdminClientsByLogPaginated', {
          page: this.page - 1,
          limit: this.perPage,
          clientLogType: 'RELANCE',
          filter: this.filter && this.filter != '' ? this.filter : undefined
        })
        .then((response) => {
          this.dataUsers = response.data[0];
          this.loading = false;
          this.total = response.data[1];
          if (this.total > this.perPage && goToLastPage) {
            this.page = Math.ceil(this.total / this.perPage);
            this.refreshList();
          }
        })
        .catch((error) => {
          console.error(error);
          this.loading = false;
          this.$buefy.toast.open({
            message: this.$t('errorOccured'),
            type: 'is-danger',
            queue: false,
            position: 'is-top-right',
            duration: 5000
          });
        });
    },
    getOrderedLogs(clientLogs) {
      return [...clientLogs].sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    },
    async copyInterviewUrl(interview) {
      let longUrl =
        process.env.VUE_APP_SELF_URL + '/charlie/' + interview.externalId;
      const url = await this.$store.dispatch('getShortLink', { url: longUrl });
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(url);
        return;
      }
      navigator.clipboard.writeText(url).then(
        () => {
          this.$buefy.toast.open({
            message: this.$t('Lien copié dans le presse-papier !'),
            type: 'is-success',
            position: 'is-bottom-right'
          });
        },
        (err) => {
          console.error('Async: Could not copy text: ', err);
        }
      );
    },

    onPageChange(page) {
      this.page = page;
      this.refreshList();
    },
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.refreshList();
    },
    onUserClicked(user) {
      this.$emit('userClicked', user.id);
    }
  }
};
</script>
  
<style src="../index.css"></style>