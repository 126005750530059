<template>
  <div>
    <div
      v-if="subtitles"
      style="background-color: white !important; padding-top: 30px"
    >
      <h3 style="text-align: center">Éditeur de sous titre</h3>
      <br />

      <div style="display: flex; justify-content: space-between">
        <div>
          <div style="
              width: 600px;
              height: 600px;
              overflow-y: scroll;
              overflow-x: hidden;
              background-color: white !important;
            ">
            <draggable
              @end="rearrangeTimes($event)"
              v-model="subtitles"
            >
              <div
                class="tile is-parent"
                v-for="subtitle of subtitles"
                :key="subtitle.startTime"
              >
                <b-icon
                  pack="fas"
                  icon="arrows-up-down-left-right"
                  size="is-small"
                >
                </b-icon>
                <div
                  class="tile is-vertical is-3"
                  style="flex-direction: inherit;"
                >
                  <div style="font-size: 14px">
                    {{ subtitle.startTime | time }}
                    <br />
                    {{ (subtitle.startTime + subtitle.duration) | time }}
                  </div>
                  <b-icon
                    pack="fas"
                    icon="trash-alt"
                    class="trashStyle"
                  />
                </div>

                <div class="tile is-9">
                  <b-input
                    type="textarea"
                    rows="2"
                    v-model="subtitle.text"
                    style="width: 100%; background-color: #f2f2f2"
                  >
                  </b-input>
                </div>
              </div>
            </draggable>
            <div
              class="tile"
              style="display: flex; justify-content: center"
            >
              <button
                class="button-3d-small-revert"
                @click="addSubtitle()"
              >
                Ajouter un sous titre
              </button>
            </div>
          </div>
        </div>
        <div class="videoSubFlex">
          <video
            controls
            ref="video"
          >
            <source
              :src="interview.squareMedia.url"
              type="video/mp4"
            />
            <track
              id="subtitle"
              default
              :src="vttSubtitles"
              kind="subtitles"
              srclang="fr"
              label="Francais"
            />
          </video>
        </div>
      </div>

      <div
        class="tile"
        style="display: flex; justify-content: center"
      >
        <button
          class="button-3d-small"
          @click="saveSubtitles()"
        >
          valider
        </button>
      </div>
    </div>
    <div
      v-else
      style="background-color: white !important; padding-top: 30px"
    >
      <div class="card">
        <div
          class="card-image responsiveCard"
          :style="[
          interview && interview.thumbnailUrl
            ? {
              'background-image': `url(${interview.thumbnailUrl})`
            }
            : {
              'background-image': 'url(../../public/img/thumbPreview.png)'
            }
        ]"
        >
          <div
            class="block"
            style="
              height: 300px !important;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >
            <button
              class="button-3d responsiveButton"
              style="width: 400px !important"
              @click="generateSubtitles()"
            >
              <b-loading
                :active.sync="loading"
                type="primary"
              />
              <img
                style="padding: 15px; vertical-align: middle"
                alt="Charlie Credits"
                src="../../public/img/token.png"
              />{{ $t('subtitleCredits') }}
            </button>
          </div>
        </div>
        <div class="card-content">
          <div class="media">
            <div class="media-content">
              <p class="title is-4">
                {{ interview.user.surname }}&nbsp;{{ interview.user.name }}
              </p>
              <p class="subtitle is-6">
                {{ interview.updatedAt | moment }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
/**
 * @name Subtitles
 * @desc Component for managing subtitles
 */
export default {
  filters: {
    /**
     * @name time
     * @desc Filter to format time
     * @param {number} value - Time value in seconds
     * @returns {string} - Formatted time string
     */
    time: (value) => {
      const date = new Date(null);
      date.setSeconds(value);
      return date.toISOString().substr(11, 8);
    }
  },
  props: {
    /**
     * @name interview
     * @desc Interview object
     */
    interview: {
      type: Object,
      required: true
    }
  },
  components: {
    draggable
  },
  data() {
    return {
      /**
       * @type {boolean}
       * @desc Loading flag
       */
      loading: false,
      /**
       * @type {array|null}
       * @desc Subtitles array
       */
      subtitles: null,
      /**
       * @type {string}
       * @desc VTT subtitles
       */
      vttSubtitles: ''
    };
  },
  mounted() {
    if (this.interview && this.interview.subtitles) {
      console.log(this.interview.subtitles);
      this.subtitles = JSON.parse(this.interview.subtitles);
      console.log(this.subtitles);
    }
  },
  methods: {
    /**
     * @name generateSubtitles
     * @desc Generates subtitles
     */
    generateSubtitles() {
      this.loading = true;
      this.$store
        .dispatch('generateSubtitles', {
          id: this.interview.id,
          mediaType: 'square'
        })
        .then((response) => {
          console.log('response', response);
          this.loading = false;
          this.interview = response.data;
          this.subtitles = JSON.parse(this.interview.subtitles);
        });
    },
    /**
     * @name saveSubtitles
     * @desc Saves subtitles
     */
    saveSubtitles() {
      this.interview.subtitles = JSON.stringify(this.subtitles);
      this.$store.dispatch('saveSubtitles', {
        id: this.interview.id,
        subtitles: this.subtitles
      });
      this.close();
    },
    /**
     * @name refreshSubtitles
     * @desc Refreshes subtitles
     */
    refreshSubtitles() {
      var subtitle = this.generateVTT(this.subtitles);
      var subBlob = new Blob([subtitle], { type: 'text/vtt' });
      var subURL = URL.createObjectURL(subBlob);
      console.log(subtitle);
      this.vttSubtitles = subURL;
    },
    /**
     * @name generateVTT
     * @desc Generates VTT subtitles
     * @param {array} subtitles - Array of subtitles
     * @returns {string} - VTT subtitles string
     */
    generateVTT(subtitles) {
      var vtt = 'WEBVTT\n\n';
      for (var i = 0; i < subtitles.length; i++) {
        var subtitle = subtitles[i];
        vtt +=
          this.formatTime(subtitle.startTime) +
          ' --> ' +
          this.formatTime(subtitle.startTime + subtitle.duration) +
          '\n';
        vtt += subtitle.text + '\n\n';
      }
      console.log(unescape(encodeURIComponent(vtt)));
      return vtt;
    },
    /**
     * @name formatTime
     * @desc Formats time
     * @param {number} time - Time value in seconds
     * @returns {string} - Formatted time string
     */
    formatTime(time) {
      var date = new Date(null);
      date.setSeconds(time);
      return date.toISOString().substr(11, 8) + '.000';
    },
    /**
     * @name rearrangeTimes
     * @desc Rearranges subtitle times
     * @param {object} event - Event object
     */
    rearrangeTimes(event) {
      console.log('rearrangeTimes', event);

      for (let i = 0; i < this.subtitles.length; i++) {
        if (i === 0) {
          this.subtitles[i].startTime = 0;
        } else if (i === this.subtitles.length - 1) {
          if (
            this.subtitles[i].startTime <
            this.subtitles[i - 1].startTime + this.subtitles[i - 1].duration
          ) {
            this.subtitles[i].startTime =
              this.subtitles[i - 1].startTime + this.subtitles[i - 1].duration;
          }
        } else {
          if (
            this.subtitles[i].startTime <
            this.subtitles[i - 1].startTime + this.subtitles[i - 1].duration
          ) {
            this.subtitles[i].startTime =
              this.subtitles[i - 1].startTime + this.subtitles[i - 1].duration;
          }
        }
      }
      for (let i = 0; i < this.subtitles.length; i++) {
        if (
          i === 0 &&
          this.subtitles[i].startTime + this.subtitles[i].duration >
            this.subtitles[i + 1].startTime
        ) {
          this.subtitles[i].duration =
            this.subtitles[i + 1].startTime - this.subtitles[i].startTime;
        } else if (
          i < this.subtitles.length - 1 &&
          this.subtitles[i].startTime + this.subtitles[i].duration >
            this.subtitles[i + 1].startTime
        ) {
          this.subtitles[i].duration =
            this.subtitles[i + 1].startTime - this.subtitles[i].startTime;
        }
      }
    },
    /**
     * @name addSubtitle
     * @desc Adds a new subtitle
     */
    addSubtitle() {
      this.subtitles.push({
        startTime:
          this.subtitles[this.subtitles.length - 1].startTime +
          this.subtitles[this.subtitles.length - 1].duration,
        duration: 5,
        text: ''
      });
    }
  },
  watch: {
    subtitles: {
      handler: function () {
        if (this.subtitles && this.subtitles.length > 0) {
          this.refreshSubtitles();
        }
      },
      deep: true
    }
  }
};
</script>

<style src="./index.css"></style>