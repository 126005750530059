
<template>
  <section>

    <div class="tile is-ancestor">
      <div class="tile is-parent">
        <div
          class="tile is-child has-text-centered"
          :class="isClient ? 'is-2' : 'is-5'"
        >
        </div>
        <div class="tile is-child is-2 has-text-centered">
          <h3>{{ totalUsersCount }}</h3>
          <p>people</p>
        </div>
        <div
          v-if="isClient"
          class="tile is-child is-2 has-text-centered"
        >
          <h3>{{ totalInvoicesCount }}</h3>
          <p>ventes</p>
        </div>
        <!-- <div
          v-if="isClient"
          class="tile is-child is-2 has-text-centered"
        >
          <h3>{{amountPerUserMean}} €</h3>
          <p>panier client</p>
        </div> -->
        <div
          v-if="isClient"
          class="tile is-child is-2 has-text-centered"
        >
          <h3>{{ invoicesPerUserMean }}</h3>
          <p>achat par clients</p>
        </div>
        <div
          class="tile is-child has-text-centered"
          :class="isClient ? 'is-2' : 'is-5'"
        >
        </div>
      </div>

    </div>

    <hr />
    <div
      class="tile is-ancestor"
      style="width: 1400px; margin:auto"
    >
      <div class="tile is-parent is-3 is-vertical has-text-centered">
        <p>source</p>
        <CustomChart
          v-if=userSource
          :chartData=userSource
        ></CustomChart>

      </div>
      <div class="tile is-parent is-3 is-vertical has-text-centered">
        <p>taille d'entreprise</p>
        <CustomChart
          v-if=businessSize
          :chartData=businessSize
        ></CustomChart>

      </div>

      <div class="tile is-parent is-3 is-vertical has-text-centered">
        <p>cible</p>
        <CustomChart
          v-if=businessTarget
          :chartData=businessTarget
        ></CustomChart>

      </div>
      <div class="tile is-parent is-3 is-vertical has-text-centered">
        <p>position</p>
        <CustomChart
          v-if=positionInCompany
          :chartData=positionInCompany
        ></CustomChart>

      </div>
    </div>
    <hr />

    <div
      class="tile is-ancestor"
      style="width: 1600px; margin:auto"
    >

      <div class="tile is-parent ">

        <div
          class="tile is-vertical is-5"
          style="padding-right: 20px;height: 100%"
        >
          <p>secteurs d'activités</p>
          <Sector
            :chartData=businessSector
            style="height:90%"
          ></Sector>
        </div>
        <div
          class="tile is-vertical"
          v-if="businessRegions && businessRegions.length > 0"
        >
          <p>Carte</p>
          <ClientsMap
            :regions="businessRegions"
            style="width:800px"
          ></ClientsMap>
        </div>
      </div>

    </div>
    <b-loading
      :is-full-page="true"
      :active.sync="loading"
    ></b-loading>
  </section>
</template>

<script>
import moment from 'moment';
import Sector from './chart/sector.vue';
import CustomChart from './chart/customChart.vue';
import ClientsMap from './chart/clientsMap.vue';

/**
 * @name allOrders
 * @desc Component for displaying all client data
 */

export default {
  name: 'allOrders',
  components: { Sector, CustomChart, ClientsMap },
  props: {
    /**
     * Flag indicating if the user is a client
     */
    isClient: {
      type: Boolean,
      default: true
    }
  },
  filters: {
    /**
     * Filter to format dates using moment.js
     * @param {Date} date - The date to format
     * @returns {String} - Formatted date
     */
    moment: function (date) {
      moment.locale('fr');
      return moment(date).format('LLL');
    }
  },
  data: () => {
    return {
      /**
       * @desc Flag indicating if data is loading
       * @type {boolean}
       */
      loading: false,
      /**
       * @desc Size of the business
       * @type {null}
       */
      businessSize: null,
      /**
       * @desc Target of the business
       * @type {null}
       */
      businessTarget: null,
      /**
       * @desc Position in the company
       * @type {null}
       */
      positionInCompany: null,
      /**
       * @desc Total count of invoices
       * @type {null}
       */
      totalInvoicesCount: null,
      /**
       * @desc Mean of invoices per user
       * @type {null}
       */
      invoicesPerUserMean: null,
      /**
       * @desc Mean amount per user
       * @type {null}
       */
      amountPerUserMean: null,
      /**
       * @desc Total count of users
       * @type {null}
       */
      totalUsersCount: null,
      /**
       * @desc Business sector data
       * @type {null}
       */
      businessSector: null,
      /**
       * @desc Regions of the business
       * @type {null}
       */
      businessRegions: null,
      /**
       * @desc Source of the user
       * @type {null}
       */
      userSource: null
    };
  },
  beforeMount() {
    this.refreshData();
  },
  methods: {
    /**
     * @name refreshData
     * @desc Refreshes the data by fetching admin business statistics
     */
    refreshData() {
      this.loading = true;
      this.$store
        .dispatch('getAdminBusinessStats', { isClient: this.isClient })
        .then((res) => {
          this.loading = false;
          let stats = res.data;
          this.businessSize = this.computeBusinessSizeChart(stats.businessSize);
          this.businessTarget = this.computeBusinessTargetChart(
            stats.businessTarget
          );
          this.positionInCompany = this.computePositionInCompanyChart(
            stats.positionInCompany
          );
          this.businessSector = this.computebusinessSectorChart(
            stats.businessSector
          );
          this.totalInvoicesCount = stats.totalInvoicesCount;
          this.invoicesPerUserMean = stats.invoicesPerUserMean;
          this.amountPerUserMean = stats.amountPerUserMean;
          this.totalUsersCount = stats.totalUsersCount;
          this.businessRegions = stats.businessRegions;
          this.userSource = this.computeSourceChart(stats.userSource);
        })
        .catch((err) => {
          this.loading = false;
          console.error(err);
        });
    },
    /**
     * @name computebusinessSectorChart
     * @desc Computes data for business sector chart
     * @param {Array} businessSector - Array of business sector data
     * @returns {Object} - Formatted data for chart
     */
    computebusinessSectorChart(businessSector) {
      let data = businessSector.map((item) => {
        return {
          label: item.businessSector,
          value: item.businessSectorCount
        };
      });
      return {
        labels: data.map((item) => {
          return item.label;
        }),
        datasets: [
          {
            backgroundColor: '#9A9AF4',
            data: data.map((item) => {
              return item.value;
            })
          }
        ]
      };
    }
  }
};
</script>
  
<style src="../index.css"></style>