export const en = {
  //* BUTTON *//

  addContact: 'add a row',
  send: 'send',
  sent: 'sent',
  validate: 'confirm',
  download: 'download',
  importCSV: 'import a CSV file',
  importVideo: 'import a video file',
  addQuestion: 'add a new question',
  addLastQuestion: 'add a last question',
  videoRecord: 'record video',
  previous: 'previous',
  draft: 'draft',
  seeMission: 'see my missions',
  confirm: 'confirm',
  next: 'next',
  save: 'save',
  logOut: 'Logout',
  buy: 'buy',
  imageRights: 'image rights certificate',
  downloadRush: 'original files',
  addWidget: 'add to my widget',
  removeWidget: 'remove from my widget',
  import: 'import a file',
  tryAgain: 'retry',
  continue: 'continue',
  back: 'back',
  cancel: 'cancel',
  delete: 'delete',
  duplicate: 'duplicate',
  understood: 'understood',
  yes: 'yes',
  no: 'no',
  submit: 'submit',
  embedButton: "embed on my site",
  exportSubtitled: "export the subtitled video",
  subtitlesSaved: "Subtitles saved",
  errorSubtitles: "Error while saving subtitles",

  //* VUE TOUR *//

  buttonSkipTour: "Skip",
  buttonNextTour: "Next",
  buttonPreviousTour: "Previous",
  buttonStopTour: "Finish",
  content_directory1: "Import a CSV file or manually add your list of clients.",
  content_directory2: "Quickly find your contacts using filters.",
  content_directory3: "Edit or permanently delete a contact and all its data.",
  content_directory4: "This is where you send your requests and where collected video testimonials will be displayed.",
  content_dash1: "Manage your testimonial requests from the client directory.",
  content_dash2: "Broadcast your video testimonials on your website using our integration tools.",
  content_dash3: "Find your personal information and invoices in your account.",
  content_dash4: "Buy credit packs to unlock your received testimonials.",

  //* TOAST MESSAGES *//

  clientDeleted: 'Client successfully deleted',
  errorFormat: 'The file format is not supported.',
  errorDuration: 'The video duration is too long.',
  errorAnalysis: 'An error occurred during video analysis.',
  errorOccured: 'An error has occurred!',
  errorOccuredForm: 'An error occurred! Missing information about your client!',
  errorIpnut: 'Please complete all fields',
  errorEmail: 'A customer with this email already exists',
  errorInfo: "Check the contact details of your guests, they may be incomplete or incorrect.",
  sequenceLaunched: 'The sequence has been started.',
  sequenceStopped: 'The sequence has been paused.',
  validReminder: 'The reminders have been validated.',
  updateDone: 'Update done',
  saved: 'saved',

  //* DASHBOARD *//

  hello: 'Hello',
  invites: 'invites',
  welcomeWords:
    'I exist to help you get video testimonials from your customers. Just tell me who you want to contact and what your questions are. In the moment, I create a unique link for you to share with a few clicks.',
  welcomeWords2:
    "You don't take any chances trying my services. I only get paid if you decide to unlock the testimonials I get for you.",
  testimonials: 'testimonials',
  testimony: 'testimonial',
  videoTestimonials: 'video testimonials',
  welcome: 'welcome',
  askForTestimonial: 'ask a testimonial',
  explore: 'explore software',
  onboardingMessage:
    'We need these few informations in order to customize as much as possible your experience :',
  onboardingMessage2: 'Use YourCharlie to ask testimonials :',
  success: 'success',
  clients: "clients",
  goodStart: 'Good start with YourCharlie:',
  infoChecked: 'Fill out your information',
  infoChecked2: 'For more personalized invitations',
  addChecked: 'Add 3 customers',
  addChecked2: 'To start filling in your directory',
  sendChecked: 'Send 3 requests',
  sendChecked2: 'To have 3x more chance of success',
  unlockChecked: 'Unlock 1 testimonial',
  unlockChecked2: 'Discover the power of YourCharlie',
  publishChecked: 'Diffuse with popup',
  publishChecked2: 'Boost your conversions',
  add1st: 'Add your first client',

  //* SIDE BAR *//

  missions: 'missions',
  newMission: 'launch mission',
  help: 'help',
  sponsoring: 'sponsorship',
  selection: 'share',

  //* SPONSORING *//

  satisfaction: 'What do you think of my job',
  sponsorWords:
    "As you may know, I work on recommendation! So I'll make you a deal: every time you invite a company and they sign up, I'll give you both 25 credits.",
  oneMoment:
    "Just a moment, I'll take care of sending out the sponsorship invitations 😉",
  onGoingSponsor: 'Sponsoring in progress, please wait 😎',
  fName: 'first name',
  lName: 'last name',
  email: 'email',
  phone: 'mobile',
  yourName: 'your first name',
  yourLastName: 'your last name',

  sponsoringSuccess:
    "🟢 {name} {surname}'s registration is validated. 25 credits have been added to your account and hers 🎉",
  sponsoringWait: '🟡 The registration of {name} {surname} is pending.',
  sponsoringFail:
    '🔴 The registration of {name} {surname} was not successful and the link has expired.',

  //* SPONSORED *//

  godfather: 'you are being sponsored by',
  reward:
    'your reward is 25 credits offered once your registration is validated',
  createAccount: 'create a free account',
  invalidMail: 'Invalid email address',

  //* SHOP *//

  '3videos': '3',
  '10videos': '10',
  '20videos': '20',
  '75credits': '75 credits',
  '250credits': '250 credits',
  '500credits': '500 credits',
  '75credPrice': '60€ vat',
  '250credPrice': '150€ vat',
  '500credPrice': '250€ vat',
  shop: 'Shop',
  shopDesc: 'Unlock a testimonial = 25 credits',
  shopMessage:
    'Buy a credit pack to unlock the testimonials you get. The bigger the pack, the cheaper the cost of testimonials will be for you.',
  save25: 'save 25%',
  save37: 'save 37,5%',

  //* MISSIONS *//

  step1: 'Step 1/4',
  step2: 'Step 2/4',
  step3: 'Step 3/4',
  step4: 'Step 4/4',
  unlockError:
    "Oops ! It seems an error occured when mounting the video",
  unlockError2: "To get help, contact the support.",
  addMissionName: 'customer testimonial for {business}',
  missionName: 'Mission name',
  missionFormality: 'Tone of the exchanges with your customers',
  missionLang: 'What language do your customers speak?',
  formelChoice: 'courteous',
  amicalChoice: 'friendly',
  formelText: 'Use of formal address and a conventional tone.',
  amicalText: 'Use of first name and a warm tone of voice',
  language: 'language',
  missionLaunch: 'launch mission',
  whoContact: 'Who are the people I should contact?',
  columnFit: 'match the columns',
  ignoreFirstLine: 'Ignore first line',
  tooManyLines: 'Your file must not exceed 50 lines',
  choose: 'choose',
  ask: 'Questions list',
  questionHere: 'Ask your question here',
  customizeSenderName: 'Who is the sender of the testimonial request?',
  inviteText: 'How do you thank each customer for their testimonial?',
  rewardBase: 'To thank you, let me gift you',
  rewardGift: 'my eternal gratitude',
  scriptDL: 'download guide',
  explainTestimonials:
    'Time to explain and encourage people to leave a testimonial for you. This message will be included in the first invitation email.',
  welcomeVideo: 'Record a motivation video for your customers:',
  welcomeVideoText:
    'This video will be played just before your guests testify. Take advantage of this moment to reassure them, to thank them or to give them your final tips to succeed in their testimonies.',
  congrats: 'congratulations',
  missionLaunchSuccess: 'I just started the mission.',
  missionLaunchSuccess2:
    'I will send the invitations at the best time for your customers.',
  missionLaunchSuccess3:
    'With this method, I maximize the chances that messages will be opened! 😎',
  noMissionLaunched: "I don't have any missions in progress yet",
  started: 'started',
  vertical: 'vertical video',
  square: 'square video',
  verticalVideo: 'vertical video',
  squareVideo: 'square video',
  subtitled: "subtitled",
  montage:
    "Come on, let's go! I'll take care of the editing. In a few minutes your testimonial will be available 😉",
  unlockCredits: 'unlock with 25 credits',
  notEnoughCredits: "You don't have enough credits to release this testimonial",
  waitingDraft: "one moment, i'm saving your draft",
  errorSavingDraft: 'Error while saving the draft',
  errorSavingMission: 'Error while registering the mission',
  deleteMission: 'Do you really want to delete this mission?',
  unlock: 'unlock',
  toUnlock: 'to unlock',
  locked: 'locked',
  locked2: 'locked',
  myTestimonial: 'my testimonials',
  onHold: 'on hold',
  refuse: 'declined',
  sentDemande: 'send invitation',
  maxCharacter: 'Maximum of 100 characters per question',
  maxGuests: 'Maximum of 50 customers per mission',
  maxQuestions: 'Your customers will have 30s per question to answer',
  maxVideo: 'Maximum 1min30',
  phoneEmptyAdvice1: 'The phone number is missing!',
  phoneEmptyAdvice2:
    "By adding it, you'll be able to enjoy several customization features.",
  QuestionsTitle: ' examples',
  Sample_naming: 'Find the right name',
  Sample_invitations: 'tips',
  Sample_customize: 'ideas',
  Sample_invit4:
    'Use only one email address per customer. Spamming your customers is bad!',
  Sample_invit1:
    'Prioritize inviting customers who have expressed their satisfaction to you in writing or verbally.',
  Sample_invit2:
    'Preferably select customers with whom you have had recent contact. If not, take the opportunity to let them know',
  Sample_invit3:
    'If a customer has declined in a previous YourCharlie assignment, wait a bit before inviting them again.',
  Sample_naming1:
    'You should create an assignment per client category to personalize your testimonial requests as much as possible. This will maximize your response rate!',
  Sample_naming2: 'Here are some examples of customer segmentation: ',
  Sample_naming3: '🗣️ by language spoken',
  Sample_naming4: '🫶 by affinity',
  Sample_naming5: '🧠 by experience type ',
  Sample_naming6: '💵 by transaction volume',
  Sample_question:
    'We offer 5 questions to suggest ideas but you can change them as you see fit.',
  Sample_question1:
    '⏱ For each question, your client will have 30 seconds to answer.',
  Sample_question2: '❓ You can write maximum 5 questions.',
  Sample_custom_text:
    'the reason we chose you to record a video testimonial is because your feedback is very important to us. Your words will reassure our potential clients and testify to the quality of our services. In advance, we thank you for taking this time for our company',
  Sample_custom_video1:
    'a gift: product/service related to your company or territory',
  Sample_custom_video2:
    'a promo code: on a product/service that is of particular interest to your customers.',
  Sample_custom_video3:
    'a bottle: a good wine or champagne will always please your customers',
  Sample_custom_video4:
    'a thank you: only effective if you have a good relationship with your customers',
  Sample_custom_video:
    'Put a reward in play to thank your customers for taking the time to do a testimonial for you. Here are some ideas:',
  tooltip_model:
    'These templates are here to help you write your testimonial requests',
  tooltip_model2:
    'You can edit them just before sending them to the service of your choice.',
  tooltip_questions:
    'Your customer will have 30 seconds to answer each question.',
  tooltip_video:
    'This video is recommended. It will be displayed at the beginning of your questionnaire',
  tooltip_video2:
    'For import, maximum 1min30 and .MOV or .MP4 formats accepted.',
  tooltip_reward: "Adding a reward is not mandatory,",
  tooltip_reward2: "but sometimes it can make a difference! 😉",
  emailAlreadyUsed: 'Email already exists in your client list',

  //* DIRECTORY *//

  directory: 'clients',
  addClients: 'add clients',
  add: 'Add',
  addSmall: 'add',
  actionFilter: 'Action',
  askFilter: 'Ask',
  recontactFilter: 'Follow up',
  unlockFilter: 'Unlock',
  thankFilter: 'Thank',
  actions: 'actions to do',
  relaunch: 'relaunch the person',
  contactSupport: 'contact support',
  askTestimony: 'ask for a testimony',
  thanks: 'thank the person',
  unlockTestimony: 'unlock the testimony',
  mounting: 'video editing in progress',
  status: 'status',
  seeTestimony: 'see the video testimony',
  received: 'received since',
  filterBy: 'Filter by',
  tagName: 'add a tag',
  resent: 'resent',
  editClient: 'Edit client information',
  deleteClient: 'Delete this client',
  deleteClient1: 'Are you sure you want to permanently delete? ',
  deleteClient2:
    'and all its data? This operation is irreversible and will delete its contact information, image rights, and all video files.',
  actionSent1: 'Did you send your testimonial request successfully?',
  actionSent2: 'Did you follow up with your client successfully?',
  actionSent3: 'Did you thank your client successfully?',
  today: "today",
  yesterday: 'yesterday',
  days: ' days',
  weeks: ' weeks',
  week: ' week',
  months: ' months',
  month: ' month',
  unlockSuccess: 'Testimony unlocked!',

  //* CUSTOM INVITATION *//

  newTestimony: 'new testimony',
  formal: '🤝 Formal address',
  friendly: '🫶 Friendly address',
  customMessage: 'message customization',
  from: 'From',
  destination: 'Recipient',
  homeVideo: 'Welcome video',
  invitLink: 'retrieve link',
  copyLink: 'copy the link',
  sendWith: 'Send with',
  selectModel: 'Select a message template',
  linkCopied: 'Link copied to clipboard!',
  addIncentive: "Reward",
  addRewardInput: "Offer a reward to your clients",

  //* SELECT MODEL *//

  slectModel: 'Select a template',
  select: 'select',

  //* RECORDER *//

  sendingVideo: 'Sending your video in progress...',
  testimonialSent: 'Your testimonial has been sent!',
  unexpectedError: 'Unexpected error',

  //* MESSAGE CHARLIE *//

  waitMission:
    'The {getInterviewTemplate} mission is ready. It will be sent to the best slot very soon.',
  readyToLaunch: "I'm ready to launch the mission {missionName}",
  askedLaunch: 'Here are your questions : {asked}.',
  askedLaunch2: 'Here are your questions : {asked}.',
  invitedLaunch: 'You have {invited} guest.',
  invitedLaunch2: 'You have {invited} guests.',
  finalLaunch: 'Is that right?',
  forgotNameMission: 'Oops, you forgot to name the mission.',
  needName: 'I need a first name, a last name, and an email',
  needQuestion: 'I need at least one question to continue.',
  needInviteText:
    'I need at least an invitation video and a reward to continue.',
  missionError: 'Error during invitation creation',
  launchSuccess: 'Invitation created !',
  draftRegistered: 'draft saved',
  waitingAnswer: 'waiting for an answer',
  decline: 'refused to participate at ',
  clicked: 'clicked',
  downloading: 'Download in progress...',
  noFile: 'No file to download',
  hiCharlie: "Hi {userSurname}, I'm YourCharlie 👋",
  questionToWitness: "So here goes, {ownerSurname}'s first question:",
  beforeStartingMessage:
    "It's great to have you back here, {ownerSurname} can't wait to see your testimony! By the way, {ownerSurname} left me a little message for you:",
  acceptImageRights:
    'One last thing before we finish, can you confirm that you agree to give your image rights to {ownerBusiness}',
  seeYouSoon:
    'Perfect! Thank you for your participation. You did great! See you soon 😉',
  testimonyCollected: 'This testimonial has already been collected.',
  welcomingCharlie: "Hello and welcome. I'm YourCharlie 👋",
  andYou: 'What about you ?',
  enterFirstName: 'enter your first name here',
  niceToMeet: 'Nice to meet you {userSurname}  😉',
  goodWork: "We're going to do a great job together!",
  experienceEnhancer:
    'Before we start, I have few questions to improve your experience',
  phoneNumber: "What's your phone number ?",
  enterPhoneNumber: 'enter your phone number here',
  enterLastName: 'enter your last name here',
  companyName: 'What is the name of your company ?',
  enterCompanyName: 'enter the name here',
  companyColor: 'What is the main color of {ownerBusiness}?',
  pickColor: 'choose the color here',
  wellDone: 'Great! Everything is ok on my end.',
  navError:
    "Oops... 😣 The registration function doesn't work with this browser... Try with another one !",
  provideEmail: 'Please provide an email',

  //* PROFILE *//

  profile: 'My profile',
  entreprise: 'business',
  invoice: 'Invoices',
  date: 'Date',
  description: 'Description',
  amount: 'Amount',
  color: 'color in videos',
  newColor: 'New color registered',
  invalidColor: 'Invalid color',
  infoRegistered: 'Information saved',

  //APPSUMO PROFILE

  dealSentence: 'You are currently on an AppSumo Lifetime Deal 👏',
  codeRedeemed: 'Codes Redeemed',
  monthCredits: 'Credits per month',
  purchaseSentence: "If you're one of the lucky that purchased more than one AppSumo code, you're on the right place. Please add here your additional code.",
  applyCode: 'apply code',
  appsumoCodeError: 'Unfortunately, this code is invalid 😕',
  codeStacked: 'Congrats, the code has been added successfully',

  //* SETTINGS *//

  audioSource: 'Audio source',

  //* LOGIN/INSCRIPTION *//

  password: 'password',
  lostPassword: 'I lost my password',
  alreadyRegistered: 'Already have an account ? Sign in now',
  noAccount: "Don't have an account? Sign up now",
  serviceConnection: "Just a moment, I'll connect you to the service",
  invalidEmail: 'Invalid email address.',
  passwordRequirement:
    'Your password must contain at least 8 characters including one upper case, one lower case, one number and one special character.',
  alreadyUsedEmail:
    'Email address already used. Maybe you registered via Google?',
  invalidPassword: 'Password error',
  connectionError: 'Login error',
  connecctionCancelled: 'Login Cancelled',
  connection: 'Login',
  register: 'SignUp',
  unknownError: 'Unknown error',
  blockedPopup: 'Popup blocked. Please allow it',
  googleRegister: 'register with google',
  googleConnect: 'sign in with google',
  or: 'OR',
  logIn: 'log in',
  resetPassword: 'Reset password',
  emailUsed:
    'Enter the email address associated with your account, and we will send you a link to reset your password.',
  emailSent:
    'An email has been sent to the address provided. All you have to do is follow the instructions to reset your password 😎',
  registerGCU:
    'By registering, you confirm that you have read and agree to our',
  GCU: 'Terms',
  registerGCU2: 'and our ',
  PDC: 'Privacy policy.',
  seeGCU: 'Check our',
  loginListBold1: 'Pay for performance.',
  loginList1: 'No subscription or commitment.',
  loginListBold2: 'Run missions for free',
  loginList2: 'No credit card required.',
  loginListBold3: "YourCharlie's team is here to help you",
  loginList3: 'We take care of you 5/7 from 9am to 5pm.',
  new: 'NEW',
  display: 'Display',
  trusted: 'trusted badge',
  onyourwebsite: 'on your website to reassure visitors',
  passwordRepeat: 'repeat password',
  passwordsDontMatch: 'Passwords do not match',

  //* IMPORT *//

  chooseFile: 'Ok, choose a file...',
  accpetedFormat: 'accepted formats :',
  mediaDuration: 'maximum video length {mediaDuration} seconds',
  videoImportModal1:
    'import a motivational video to encourage your customers to record a testimonial for you',
  videoImportModal2: 'video length: 1min30 maximum',
  videoImportModal3: 'accepted formats: mp4, mov, avi',

  //* STOP PAGE *//

  stop_title: 'Too bad {surname}, I am so sad 😢',
  stop_text1: 'I only ask for 3 minutes of additional time,',
  stop_text2: 'to keep a nice memory of our journey together',
  recorderNotification: 'too bad... maybe next time?',

  //* VIDEO *//

  urlVideoOnboarding: 'https://www.youtube.com/embed/-B9Mi0c_oKo',
  urlVideoLogin: 'https://player.vimeo.com/video/773707744',
  urlVideoAffiliation: 'https://www.youtube.com/embed/qr3cMP2MsAs?si=LY6BWqPRK1QG9UwA',

  //* RECORDER V2 *//

  actualQuestion: 'question',
  helloThere1: 'Welcome',
  helloThere2: 'You are about to record a video testimonial.',
  recordingTips: "See YourCharlie tips",
  recordingTips1: '30 seconds per question',
  recordingTips1Q: "questions",
  recordingTips2: 'Restart or validate to move to the next question.',
  recordingTips3: 'Improve audio quality with a "hands-free kit".',
  recordingTips4: "Don't forget to smile, it's for fun",
  homeRecorderReward: "Reward:",
  begin: 'record my testimonial',
  MThanks: 'Thank you for testifying !',
  endingCongrats1: 'Made with YourCharlie, the software that simplifies video testimonials.',
  endingRewards: 'Promise made, promise kept 🎁',
  iOffer: 'is on its way!',
  referalCharlie: 'collect video testimonials',
  discoverCharlie: 'Discover YourCharlie',
  modalRetry: 'Hello 👋',
  modalRetry2: 'You have already answered some questions. Do you want to start over or continue where you left off?',
  fatalErrorRecord: "Oops! A technical issue seems to be preventing the recording of your testimonial. This problem could be related to the device you are currently using. Could you try the experience from another device, please? Your contribution is highly valued by us. Thank you! 🌟",
  tooShortRecord: "Oops! It seems like your response is too short. Would you like to re-record it? Your contribution is highly valued by us. Thank you! 🌟",


  //* INTEGRATION *//

  addedToPlaylist: 'Video testimony added to the playlist',
  removedFromPlaylist: 'Video testimony removed from the playlist',
  copyCode: 'copy your code',
  widgetPres1: 'The YourCharlie widget on your site is top for',
  widgetPres1b: 'increase your conversion rate',
  widgetPres2:
    'Discover the two steps to add video testimonials on your website',
  widgetStep1: 'Step 1 - Select up to 5 videos',
  Step1expA: 'To add a video to your widget, go to',
  Step1expB: 'Next to each received testimonial you have the button ',
  Step1expC: 'add to my widget',
  widgetStep2: 'Step 2 - Add the widget to your site',
  Step2expA: 'To add this widget on all pages of your site,',
  Step2expB: 'click on ',
  Step2expC: 'copy the code',
  Step2expD: 'and paste it in the < HEAD> section of your website.',
  WordpressWidget:
    'To add the widget to your WordPress site nothing could be easier:',
  wpDownload: 'download the extension',
  wpStep1a: 'Download the extension',
  wpStep1c: 'by clicking on the button opposite',
  wpStep2a: 'In your dashboard',
  wpStep2b: ', go to Extensions > Add',
  wpStep3:
    "Click on the button ''Upload an extension'' and download the zip archive",
  wpStep4: "Click on ''Install now'' and activate the extension",
  wpInstallEnd:
    'Once the extension is installed and activated, you can access the settings page by going to the left menu > YourCharlie',
  chart_title: 'Trust Charter',
  chart_purpose:
    'In order to obtain the authenticity badge issued by YourCharlie, I agree to comply with the following:',
  chart_list_1:
    'I invite real customers of my company with whom I have had at least one financial transaction in exchange for a product or service.',
  chart_list_2:
    'I certify that my clients have not negotiated compensation in exchange for recording a video testimonial.',
  chart_list_3:
    "I did not attempt to influence the client's words by providing a script or response material.",
  chart_list_4:
    'I understand that I cannot change the selection of video testimonials so as not to mislead my visitors.',
  chart_list_5:
    'I accept the authenticity of my clients and therefore the possibility that the testimonials may not be perfect in image or manner of expression.',
  integ_page_title: 'Show that you can be trusted',
  integ_purpose1: "Sometimes it's hard to tell the real from the fake.",
  integ_purpose2:
    'With the YourCharlie badge, gain credibility and reassure your prospects',
  integ_purpose3: 'by showing authentic social proof of your customers',
  popup_title: 'Integrate the YourCharlie popup on your site',
  popup_1:
    'The pop-up is displayed as an overlay in the left corner of your site. Your visitors click on it to see it in large size and listen to your testimonials.',
  popup_2: 'To add the pop-up on all pages of your site, click ',
  popup_3: 'and paste it into the "<head>" section of your website.',
  slider_title: "Integrate YourCharlie's carousel on your site",
  slider_1:
    'The carousel displays wherever you want on your site, on the page of your choice. Your visitors click on the videos to activate the sound and can scroll through them.',
  slider_2: 'To add the carousel to a page on your site, click ',
  slider_3: 'and paste it into an HTML tag on your website',
  slider: 'slider',

  //* INTEGRATION V2 *//

  interestingTools: 'tools that may interest you',
  myTools: "my tools",
  freePopDesc: "Your last 5 testimonials in an overlay player on your site.",
  freeSliderDesc: "Your last 5 testimonials in a carousel on a page of your site.",
  bigPopDesc: "Up to 15 testimonials in an overlay player on your site.",
  bigSliderDesc: "Up to 15 testimonials in an easy-to-integrate carousel on a page.",
  wallDesc: "Unlimited video testimonials in a gallery on your website.",
  lockedPopSliderText: "To create a customised selection of testimonials, add a Big Pop-up or a Big Slider.",
  builderWallDesc: "Drag your testimonials from the list on the left and drop them below to organise the videos in your Wall of Love. You can add as many videos as you like.",
  builderBigPopDesc: "Drag your testimonials from the list on the left and drop below to organise your videos.",
  builderBigPopDesc2: "You can add up to 15 video testimonials.",
  builderBigSliderDesc: "Drag your testimonials from the list on the left and drop below to organise your videos.",
  builderBigSliderDesc2: "You can add up to 15 video testimonials.",
  builderFreePopDesc: "With the Free Pop-Up, you can automatically display your last 5 unblocked video testimonials in a video player overlaying your website.",
  builderFreeSliderDesc: "With the Free Slider, you can automatically display your last 5 unblocked video testimonials in a carousel that can be easily integrated on a page of your website.",
  builderWallExemple: "Welcome to the Wall of Love! Watch the {X} authentic video testimonials from {Business}.",
  sorry: "Sorry.",
  sorryText1: "This feature is not accessible on mobile or tablet.",
  sorryText2: "Please access from a computer to create your widget.",
  remainingDays: "{count}d remaining",
  alreadyAdded: 'already added',

  //* MODAL INTEGRATION V2 *//

  installWall: 'Integrate the Wall of Love directly onto a page of your website using this code.',
  installPopup: 'Integrate this popup directly onto your website using the code.',
  installSlider: 'Integrate this slider directly onto a page of your website using the code.',
  codeCreation1: 'is ready to broadcast.',
  codeCreation2: 'Copy the code below to integrate it into your site.',
  modalNeedHelp: "See the integration tutorial.",
  modalLinkTitle: 'Share the link to your dedicated page on social networks or in an email.',
  modalShopTitle: 'Your selection is ready for display 🔥',
  modalShopDesc1: 'You have selected a paid display widget. Choose the display duration you want.',
  modalShopDesc2: 'You can increase the duration at any time.',
  chooseDuration: "choose a duration",
  inviteNow: 'invite now',
  customize: 'Customize',
  addImage: 'Add an image',
  sliderSettingsTitle: "Title",
  sliderSettingsDesc: "Description",
  sliderSettingsWebsite: "www.mywebsite.com",
  playlistName: "playlist name",
  notEnoughCreditsDuration: "Oops, you don't have enough credits to purchase this option.",
  popular: "POPULAR",
  newTool: "NEW",

  //* AFFILIATION *//

  becomeAffiliate: "Become an ambassador for YourCharlie",
  affiliateDesc1: "Recommend YourCharlie and ",
  affiliateDesc2: "earn 10% for life",
  affiliateDesc3: " on your affiliates' sales.",
  affiliateDesc4: "Your affiliates get ",
  affiliateDesc5: "€10 off",
  affiliateDesc6: " on their first credit purchase.",
  learnMore: 'learn more',
  affiliateAccount: "Create your account ",
  affiliateAccount2: " on FirstPromoter",
  affiliateAccountDesc1: "By creating your account on Firstpromoter, you get access to a complete tool to manage and track your affiliates' sign-ups automatically.",
  affiliateAccountDesc2: "Thanks to your affiliate link available on FirstPromoter, your affiliates sign up on YourCharlie. They get €10 off on their first purchase.",
  affiliateAccountDesc3: "Every time one of your affiliates makes a purchase on YourCharlie, you earn a 10% commission. And this, with no time limit!",
  affiliateLink1: "Affiliates sign up ",
  affiliateLink2: "with your link",
  affiliateReward1: "You receive ",
  affiliateReward2: "your 10% commission",
  becomeAffiliateSmall: "become an ambassador",

  //* SUBEDITOR *//

  Génération: "Generation",
  Incrustation: "Inlay",
  onGoingSub: "of subtitles in progress. Please wait.",
  subtitles: "subtitles",
  imageRightsS: "image rights",

  //* APPSUMO *//

  appsumo1: "Just share your name, email and Appsumo code to get enrolled in YourCharlie. After submit you will you will be ready to collect your social proofs on video ",

  //* PREMIUMVUE *//

  premiumTitle1: "YourCharlie Premium",
  premiumTitle2: "Discover YourCharlie Premium",
  premiumSentence1: "You are currently a premium member, an exclusive status reserved for very few of our clients.",
  premiumSentence2: "Your dedicated project manager is Axel.",
  premiumSentence3: "Do you want video testimonials but can't handle them yourself? Are yous short on time?",
  premiumSentence4: "Are you short on time or unsure how to proceed?",
  premiumSentence5: "We are here to simplify your life by managing your account from A to Z. Our dedicated team will ask the right questions to the right clients, motivate and convince them to record a video testimonial.",
  premiumSentence6: "No need to worry about follow-ups, we handle them manually to maximize response rates.",
  premiumSentence7: "With us, you can focus on growing your business and let the YourCharlie team handle the rest.",
  premiumSentence8: "Please note, our offer is exclusive and limited to only 10 entrepreneurs per month.",
  premiumSentence9: "Starting from ",
  premiumSentence10: "€89.99 excl. VAT / month",
  premiumButton1: "Contact Axel",
  premiumButton2: "Manage my subscription",
  premiumButton3: "Discover YourCharlie Premium",
  tooltipApi: "view API documentation",

  /** API BLOCK **/

  apiSentence1: "This key allows you to use YourCharlie with automation tools like Make or Zapier. You may also need it for custom developments based on our API.",
  apiSentence2: "Attention:",
  apiSentence3: "For security reasons, each key is unique. You will need to generate a new one",
  apiSentence4: "if you lose the first key generated.",
  seeApiDoc: "view API documentation",

};
