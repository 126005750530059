<template>
  <section>
    <div>
      <h3>Destinataires:</h3>
      <h6
        v-for="itw of interviews"
        :key="itw.id"
      > - {{ itw.user.surname }} {{ itw.user.name }} {{ itw.user.email }} </h6>
      <h3>Sélectionner un template</h3>
      <div style="display: flex; flex-direction: column;">
        <b-field>
          <b-select
            placeholder="Select a name"
            v-model="template"
            expanded
          >
            <option
              v-for="option in data"
              :value="option"
              :key="option.id"
            >
              <span v-if="option.locale == 'fr'"> 🇫🇷 </span>
              <span v-if="option.locale == 'en'"> 🇬🇧 </span>
              <span v-if="option.locale == 'es'"> 🇪🇸 </span>
              <span v-if="option.locale == 'it'"> 🇮🇹 </span>
              &nbsp;{{ option.name }}
            </option>
          </b-select>
        </b-field>
        <div
          v-if="template"
          style="overflow: scroll; max-height: 50vh"
        >
          <h5>Objet :</h5>
          <span>{{ template.subject }}</span>
          <br />
          <code
            :key="template.id"
            :value="template"
            v-html="template.content"
          ></code>
        </div>
      </div>
      <b-button v-on:click="sendMailFromTemplate">Envoyer le mail</b-button>
      <b-loading
        :is-full-page="false"
        :active.sync="isLoading"
      ></b-loading>
    </div>
  </section>
</template>


<script>
/**
 * @name selectTemplate
 * @desc Component for selecting and sending email templates
 */
export default {
  /**
   * Component name
   */
  name: 'selectTemplate',
  components: {},
  props: {
    /**
     * List of interviews
     */
    interviews: {
      default: null,
      required: true
    }
  },
  data() {
    return {
      /**
       * @desc Loading state
       * @type {Boolean}
       */
      isLoading: false,
      /**
       * @desc List of mail templates
       * @type {Array}
       */
      data: [],
      /**
       * @desc Selected template
       * @type {Object}
       */
      template: null
    };
  },
  beforeMount() {
    /**
     * Refreshes the list of mail templates
     */
    this.refreshList();
  },
  methods: {
    /**
     * @name refreshList
     * @desc Refreshes the list of mail templates
     */
    refreshList() {
      this.$store.dispatch('getAllMailTemplates').then((result) => {
        this.data = result.data[0].sort((a, b) => a.id - b.id);
      });
    },
    /**
     * @name sendMailFromTemplate
     * @desc Sends email using selected template
     */
    sendMailFromTemplate() {
      this.isLoading = true;
      this.$store
        .dispatch('sendMailTemplate', {
          id: this.template.id,
          interviewIds: this.interviews.map((itw) => itw.id)
        })
        .then((result) => {
          this.isLoading = false;
          this.$emit('sentMail');
        });
    }
  }
};
</script>

<style src="../index.css"></style>