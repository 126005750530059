<template>
  <div>
    <MyTestimony></MyTestimony>
  </div>

</template>
    <script>
import MyTestimony from './../components/diffusion/myTestimony.vue';

/**
 * @name Testimonials
 * @desc Component for displaying testimonials using the MyTestimony component
 */

export default {
  name: 'testimonials',
  components: {
    MyTestimony
  }
};
</script>


<style src="./../components/index.css"></style>