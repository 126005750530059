<template>
  <section
    class="box"
    v-if="$store.getters.activeUser &&
    ($store.getters.activeUser.role.name === 'ADMIN' ||
      $store.getters.activeUser.role.name === 'SUPERADMIN')
    "
  >
    <div class="charlie__message charlie__message_A">
      <div class="charlie__content">Bienvenue dans l'admin de Charlie 👋</div>
    </div>
    <br />
    <allStats
      :loading=loading
      :stats=filteredStats
      v-on:filterStats=refreshStats($event)
    ></allStats>

    <b-tabs
      type="is-boxed"
      :destroy-on-hide="true"
    >
      <b-tab-item>
        <template #header>
          <span>
            <b-icon
              pack="fas"
              icon="square-check"
              size="is-small"
            >
            </b-icon> tasks
          </span>
        </template>
        <allTasks v-on:userClicked="openUserDetails($event)"></allTasks>
      </b-tab-item>
      <b-tab-item>
        <template #header>
          <span>
            <b-icon
              pack="fas"
              icon="user"
              size="is-small"
            >
            </b-icon> users <b-tag rounded> {{ stats.totalUsers }} </b-tag>
          </span>
        </template>
        <allUsers v-on:userClicked="openUserDetails($event)"></allUsers>
      </b-tab-item>
      <b-tab-item>
        <template #header>
          <span>
            <b-icon
              pack="fas"
              icon="star"
              size="is-small"
            >
            </b-icon> premium
          </span>
        </template>
        <allUsers
          v-on:userClicked="openUserDetails($event)"
          :premium="true"
        ></allUsers>
      </b-tab-item>
      <b-tab-item>
        <template #header>
          <span>
            <b-icon
              pack="fas"
              icon="phone"
              size="is-small"
            >
            </b-icon> calls premium
          </span>
        </template>
        <allTasksPremium v-on:userClicked="openUserDetails($event)"></allTasksPremium>
      </b-tab-item>

      <b-tab-item>
        <template #header>
          <span>
            <b-icon
              pack="fas"
              icon="paper-plane"
              size="is-small"
            >
            </b-icon> invitations <b-tag rounded> {{ stats.totalInterviews }} </b-tag>
          </span>
        </template>
        <allInvitations v-on:userClicked="openUserDetails($event)"></allInvitations>
      </b-tab-item>
      <!-- <b-tab-item>
        <template #header>
          <span>
            missions <b-tag rounded> {{ stats.totalInterviewTemplates }} </b-tag>
          </span>
        </template>
        <allMissions v-on:userClicked="openUserDetails($event)"></allMissions>
      </b-tab-item> -->
      <!-- <b-tab-item>
        <template #header>
          <span>
            waiting list
            <b-tag rounded> {{ stats.totalUnrespondedInterviews}} </b-tag>
          </span>
        </template>
        <allWaiting
          v-on:sendMail=openResendPopUp($event)
          ref="allWaiting"
        ></allWaiting>
      </b-tab-item> -->
      <b-tab-item>
        <template #header>
          <span>
            <b-icon
              pack="fas"
              icon="cart-shopping"
              size="is-small"
            >
            </b-icon> orders <b-tag rounded> {{ stats.totalInvoices }} </b-tag>
          </span>
        </template>
        <allOrders v-on:userClicked="openUserDetails($event)"></allOrders>
      </b-tab-item>
      <!-- <b-tab-item>
        <template #header>
          <span>
            <b-icon
              pack="fas"
              icon="star"
              size="is-small"
            >
            </b-icon> sponsorship <b-tag rounded>{{ stats.totalSponsorships }} </b-tag>
          </span>
        </template>
        <allSponsoring></allSponsoring>
      </b-tab-item> -->
      <b-tab-item>
        <template #header>
          <span>
            <b-icon
              pack="fas"
              icon="envelope"
              size="is-small"
            >
            </b-icon> templates <b-tag rounded>{{ stats.totalMailTemplates }} </b-tag>
          </span>
        </template>
        <allTemplates></allTemplates>
      </b-tab-item>
      <!-- <b-tab-item>
        <template #header>
          <span>
            sequences <b-tag rounded></b-tag>
          </span>
        </template>
        <allSequences></allSequences>
      </b-tab-item> -->
      <b-tab-item>
        <template #header>
          <span>
            <b-icon
              pack="fas"
              icon="database"
              size="is-small"
            >
            </b-icon>prospects
          </span>
        </template>
        <allDataClient :isClient="false"></allDataClient>
      </b-tab-item>
      <b-tab-item>
        <template #header>
          <span>
            <b-icon
              pack="fas"
              icon="database"
              size="is-small"
            >
            </b-icon>clients
          </span>
        </template>
        <allDataClient :isClient="true"></allDataClient>
      </b-tab-item>
    </b-tabs>

    <b-modal
      :active.sync="showValidationModal"
      :has-modal-card="true"
      :can-cancel="true"
    >
      <div
        class="card box"
        style="height: 600px"
      >
        <div class="charlie__message charlie__message_A">
          <div class="charlie__content">
            Es-tu sur de vouloir supprimer la mission ?
          </div>
        </div>
        <br />

        <button
          class="button-3d"
          @click="deleteInterviewTemplate(currentId)"
        >
          confirmer
        </button>
      </div>
    </b-modal>
    <b-modal
      :active.sync="showUserInfos"
      :has-modal-card="true"
      full-screen
    >
      <user-details
        :userDetail="userDetail"
        :missions="userMissions"
      ></user-details>
    </b-modal>
    <b-modal
      :active.sync="showTemplates"
      :has-modal-card="true"
    >
      <div
        class="card box"
        style="width: 1000px"
      >
        <selectTemplate
          v-on:sentMail="showTemplates = false; resetChecked()"
          :interviews="currentTemplateInterview"
        >
        </selectTemplate>
      </div>
    </b-modal>
  </section>
</template>

<script>
import moment from 'moment';
import userDetails from '../components/admin/userDetails.vue';
import selectTemplate from '../components/admin/selectTemplate.vue';
import allTemplates from '../components/admin/allTemplates.vue';
import allSponsoring from '../components/admin/allSponsoring.vue';
import allOrders from '../components/admin/allOrders.vue';
import allUsers from '../components/admin/allUsers.vue';
import allTasks from '../components/admin/allTasks.vue';
import allTasksPremium from '../components/admin/allTasksPremium.vue';
// import allWaiting from '../components/admin/allWaiting.vue';
import allMissions from '../components/admin/allMissions.vue';
import allInvitations from '../components/admin/allInvitations.vue';
import allStats from '../components/admin/allStats.vue';
import allSequences from '../components/admin/allSequences.vue';
import allDataClient from '../components/admin/allDataClient.vue';

/**
 * @name Admin
 * @desc Component for the admin page
 */
export default {
  name: 'admin',
  components: {
    userDetails,
    selectTemplate,
    allTemplates,
    allSponsoring,
    allOrders,
    allUsers,
    allTasks,
    allTasksPremium,
    // allWaiting,
    allMissions,
    allInvitations,
    allSequences,
    allStats,
    allDataClient
  },
  data() {
    return {
      stats: {},
      filteredStats: {},
      loading: false,
      showValidationModal: false,
      showUserInfos: false,
      showTemplates: false,
      currentId: null,
      currentTemplateInterview: [],
      userDetail: {},
      userMissions: []
    };
  },

  beforeMount() {
    // Lifecycle hook
    this.refreshStats();
  },

  filters: {
    /**
     * Filter to format dates using moment.js
     * @param {Date} date - The date to format
     * @returns {string} The formatted date
     */
    moment: function (date) {
      moment.locale('fr');
      return moment(date).format('LLL');
    }
  },

  methods: {
    /**
     * @name resetChecked
     * @desc Reset checked items
     */
    resetChecked() {
      this.$refs.allWaiting.resetChecked();
    },

    /**
     * @name openResendPopup
     * @desc Open resend popup for interviews
     * @param {Array} interviews - The interviews to resend
     */
    openResendPopUp(interviews) {
      this.currentTemplateInterview = interviews;
      this.showTemplates = true;
    },

    /**
     * @name openUserDetails
     * @desc Open user details modal
     * @param {number} id - The user ID
     */
    openUserDetails(id) {
      this.loading = true;
      this.$store
        .dispatch('getAdminUserDetails', { id })
        .then((res) => {
          this.loading = false;
          this.userDetail = res.data.details;
          this.userMissions = res.data.interviewTemplates;
          this.showUserInfos = true;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    /**
     * @name refreshStats
     * @desc Refresh statistics data
     * @param {Object} filter - Optional filter object
     */
    refreshStats(filter = undefined) {
      this.loading = true;
      let payload = new Object();
      if (filter != undefined) {
        payload = {
          fromDate: filter.from,
          toDate: filter.to
        };
      }
      this.$store
        .dispatch('getAdminStats', payload)
        .then((result) => {
          if (filter != undefined) {
            this.filteredStats = result.data;
          } else {
            this.stats = result.data;
            this.filteredStats = result.data;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.error(err);
          this.loading = false;
        });
    }
  }
};
</script>

<style src="./../components/index.css"></style>
