export const it = {

    //* BUTTON *//

    addContact: 'aggiungi una riga',
    send: 'inviare',
    sent: 'inviato',
    validate: 'validare',
    download: 'scaricare',
    importCSV: 'importa un file CSV',
    importVideo: 'importa un video',
    addQuestion: 'aggiungi una domanda',
    addLastQuestion: 'aggiungi un ultima domanda',
    videoRecord: 'registra un video',
    previous: 'precedente',
    draft: 'bozza',
    seeMission: 'vedi le mie missioni',
    confirm: 'confermare',
    next: 'successivo',
    save: 'salvare',
    logOut: 'Disconnessione',
    buy: 'acquistare',
    imageRights: "certificato diritti d'immagine",
    downloadRush: 'file originali',
    addWidget: 'aggiungi al widget',
    removeWidget: 'rimuovi dal widget',
    import: 'importa un file',
    tryAgain: 'riprova',
    continue: 'continuare',
    back: 'indietro',
    cancel: 'annullare',
    delete: 'eliminare',
    duplicate: 'duplicare',
    understood: "ho capito",
    yes: 'sì',
    no: 'no',
    submit: 'sottomettere',
    embedButton: "integrare sul mio sito",
    exportSubtitled: "esporta il video sottotitolato",
    subtitlesSaved: "Sottotitoli salvati",
    errorSubtitles: "Errore durante il salvataggio dei sottotitoli",


    //* VUE TOUR *//

    buttonSkipTour: "Passa",
    buttonNextTour: "Successivo",
    buttonPreviousTour: "Precedente",
    buttonStopTour: "Termina",
    content_directory1: "Importa un file CSV o aggiungi manualmente l'elenco dei tuoi clienti.",
    content_directory2: "Trova rapidamente i tuoi contatti grazie ai filtri.",
    content_directory3: "Modifica o elimina definitivamente un contatto e tutti i suoi dati.",
    content_directory4: "Qui invii le tue richieste e i testimonianze video raccolti verranno visualizzati.",
    content_dash1: "Gestisci le tue richieste di testimonianze dal repertorio dei clienti.",
    content_dash2: "Diffondi i tuoi testimonianze video sul tuo sito grazie ai nostri strumenti di integrazione.",
    content_dash3: "Trova le tue informazioni personali e le tue fatture nel tuo spazio account.",
    content_dash4: "Acquista pacchetti di crediti per poter sbloccare le tue testimonianze ricevute.",

    //* TOAST MESSAGES *//

    clientDeleted: 'Cliente eliminato con successo.',
    errorFormat: "Il formato del file non è supportato.",
    errorDuration: 'La durata del video è troppo lunga.',
    errorAnalysis: "Si è verificato un errore durante l'analisi del video.",
    errorOccured: 'Si è verificato un errore!',
    errorOccuredForm: 'Si è verificato un errore! Mancano informazioni sul tuo cliente!',
    errorIpnut: 'Si prega di compilare tutti i campi',
    errorEmail: 'Un cliente con questa email esiste già',
    errorInfo: 'Controlla le coordinate dei tuoi ospiti, potrebbero essere incomplete o errate',
    sequenceLaunched: 'La sequenza è stata avviata.',
    sequenceStopped: 'La sequenza è stata messa in pausa.',
    validReminder: 'I promemoria sono stati validati.',
    updateDone: 'Aggiornamento completato',
    saved: 'salvato',

    //* DASHBOARD *//

    hello: 'Ciao',
    invites: 'inviti',
    welcomeWords:
        'Esisto per aiutarti a ottenere testimonianze video dai tuoi clienti. Dimmi chi vuoi contattare e quali sono le tue domande, e in un istante, creo un link unico che puoi condividere via email o whatsapp.',
    welcomeWords2:
        "Non corri alcun rischio a provare i miei servizi. Vengo pagato solo se decidi di sbloccare le testimonianze che avrò ottenuto per te.",
    testimonials: 'testimonianze',
    testimony: 'testimonianza',
    videoTestimonials: 'testimonianze video',
    welcome: 'Benvenuto a YourCharlie',
    explore: 'esplora il software',
    askForTestimonial: 'chiedi una testimonianza',
    onboardingMessage:
        'Con queste poche informazioni, le tue richieste di testimonianze saranno più personalizzate per ottenere più risultati!',
    onboardingMessage2:
        'Passa all’azione ora e aggiungi un cliente per chiedergli una testimonianza video.',
    success: 'successo',
    clients: "clienti",
    goodStart: 'Inizia bene con YourCharlie:',
    infoChecked: 'Compila le tue informazioni',
    infoChecked2: 'Per inviti più personalizzati',
    addChecked: 'Aggiungi 3 clienti',
    addChecked2: 'Per iniziare a riempire il tuo repertorio.',
    sendChecked: 'Invia 3 richieste',
    sendChecked2: 'Per avere 3 volte più possibilità di successo.',
    unlockChecked: 'Sblocca 1 testimonianza',
    unlockChecked2: 'Per scoprire la potenza di YourCharlie.',
    publishChecked: 'Pubblica con il popup',
    publishChecked2: 'Per aumentare le tue conversioni.',
    add1st: 'Aggiungi il tuo primo cliente',

    //* SIDE BAR *//

    missions: 'missioni',
    newMission: 'nuova missione',
    help: 'aiuto',
    sponsoring: 'affiliazione',
    selection: 'condividere',

    //* SPONSORING *//

    satisfaction: "Cosa ne pensi del mio lavoro",
    sponsorWords:
        "Come forse sai, funziono a raccomandazione! Quindi ti propongo un affare: ogni volta che inviti un'azienda e si iscrive, offro 25 crediti a entrambi.",
    oneMoment:
        "Un attimo, sto inviando gli inviti di affiliazione 😉",
    onGoingSponsor: 'Affiliazione in corso, grazie per la pazienza 😎',
    fName: 'nome',
    lName: 'cognome',
    email: 'email',
    phone: 'telefono',
    yourName: 'il tuo nome',
    yourLastName: 'il tuo cognome',
    sponsoringSuccess:
        "🟢 L'iscrizione di {name} {surname} è confermata. 25 crediti sono stati aggiunti al tuo conto e al suo 🎉",
    sponsoringWait: "🟡  L'iscrizione di {name} {surname} è in attesa.",
    sponsoringFail:
        "🔴 L'iscrizione di {name} {surname} non è riuscita e il link è scaduto.",

    //* SPONSORED *//

    godfather: 'sei sponsorizzato da',
    reward:
        'la tua ricompensa è di 25 crediti offerti una volta che la tua registrazione è confermata',
    createAccount: 'crea un account gratuito',
    invalidMail: 'Indirizzo email non valido.',

    //* SHOP *//
    '3videos': '3',
    '10videos': '10',
    '20videos': '20',
    '75credits': '75 crediti',
    '250credits': '250 crediti',
    '500credits': '500 crediti',
    '75credPrice': '60€ IVA esclusa',
    '250credPrice': '150€ IVA esclusa',
    '500credPrice': '250€ IVA esclusa',
    shop: 'Il negozio',
    shopDesc: '1 testimonianza video = 25 crediti',
    shopMessage:
        'Acquista un pacchetto di crediti per sbloccare le testimonianze ottenute. Più il pacchetto di crediti è grande, più il costo della testimonianza sarà economico per te.',
    save25: 'risparmia il 25%',
    save37: 'risparmia il 37,5%',

    //* MISSIONS *//

    step1: 'Fase 1/4',
    step2: 'Fase 2/4',
    step3: 'Fase 3/4',
    step4: 'Fase 4/4',
    unlockError:
        "Ops! Sembra che ci sia stato un problema con il montaggio del video.",
    unlockError2: "Per ottenere aiuto, contatta il supporto.",
    addMissionName: 'testimonianza del cliente per {business}',
    missionName: 'Nome della missione',
    missionFormality: 'Tonalità degli scambi con i tuoi clienti',
    missionLang: 'Lingua parlata dai tuoi clienti',
    formelChoice: 'cortese',
    amicalChoice: 'conviviale',
    formelText: "Uso del lei e di un tono convenzionale.",
    amicalText: "Uso del tu e di un tono caloroso.",
    language: 'lingua',
    missionLaunch: 'lancia una missione',
    whoContact: 'Elenco dei tuoi clienti da invitare',
    columnFit: 'Fai corrispondere le colonne',
    ignoreFirstLine: 'Ignora la prima riga',
    tooManyLines: 'Il tuo file deve contenere meno di 50 righe.',
    choose: 'scegliere',
    ask: 'Elenco delle tue domande',
    questionHere: 'scrivi la tua domanda qui',
    customizeSenderName: 'Mittente',
    inviteText: 'Ricompensa per i partecipanti',
    rewardBase: 'Per ringraziarti ti offro ',
    rewardGift: 'la mia eterna gratitudine',
    scriptDL: 'scarica la guida',
    welcomeVideo: 'Video di motivazione per i tuoi clienti',
    congrats: 'Congratulazioni',
    missionLaunchSuccess: 'Ho appena lanciato la missione.',
    missionLaunchSuccess2:
        "Invierò gli inviti al momento migliore per i tuoi destinatari.",
    missionLaunchSuccess3:
        'Con questo metodo, massimizzo le possibilità che i messaggi vengano aperti! 😎',
    noMissionLaunched: "Non ho ancora missioni in corso",
    started: 'iniziato',
    vertical: 'formato verticale',
    square: 'formato quadrato',
    verticalVideo: 'video verticale',
    squareVideo: 'video quadrato',
    subtitled: "sottotitolato",
    montage:
        "E' partito! Il montaggio è in corso. Sarà disponibile tra qualche minuto 😉",
    unlockCredits: 'sblocca con 25 crediti',
    notEnoughCredits:
        "Non hai abbastanza crediti per sbloccare questa testimonianza",
    waitingDraft: "un attimo, sto salvando la tua bozza",
    errorSavingDraft: "Errore durante il salvataggio della bozza",
    errorSavingMission: "Errore durante il salvataggio della missione",
    deleteMission: 'Vuoi davvero cancellare questa missione?',
    unlock: 'da sbloccare',
    toUnlock: 'da sbloccare',
    locked: 'bloccato',
    locked2: 'bloccati',
    myTestimonial: 'le mie testimonianze',
    onHold: 'in attesa',
    refuse: 'rifiuto',
    maxCharacter: 'Massimo 100 caratteri per domanda',
    maxGuests: 'Massimo 50 clienti per missione',
    maxQuestions: 'I tuoi clienti avranno 30 secondi per rispondere a ogni domanda.',
    maxVideo: 'Massimo 1min30',
    phoneEmptyAdvice1: 'Manca il numero di telefono!',
    phoneEmptyAdvice2:
        'Aggiungendolo, potrai usufruire di diverse funzionalità di personalizzazione.',
    QuestionsTitle: 'esempi',
    Sample_naming: 'consigli',
    Sample_invitations: 'suggerimenti',
    Sample_customize: 'idee',
    Sample_invit4:
        "Usa un solo indirizzo email per cliente. Spammare i tuoi clienti, non è bello!",
    Sample_invit1:
        'Invita prioritariamente i clienti che ti hanno espresso la loro soddisfazione per iscritto o di persona.',
    Sample_invit2:
        'Seleziona preferibilmente i clienti con cui hai avuto un contatto recente. Altrimenti, cogli l’occasione per avvisarli.',
    Sample_invit3:
        "Se un cliente ha rifiutato in una precedente missione di YourCharlie, aspetta un po' prima di invitarlo di nuovo.",
    Sample_naming1:
        'Devi creare una missione per categoria di cliente per personalizzare al massimo le tue richieste di testimonianze. Ottimizzerai il tuo tasso di risposta!',
    Sample_naming2: 'Ecco alcuni esempi di segmentazione del cliente:',
    Sample_naming3: '🗣️ per lingua parlata',
    Sample_naming4: '🫶 per affinità',
    Sample_naming5: '🧠 per tipo di esperienza',
    Sample_naming6: '💵 per volume di transazione',
    Sample_question:
        'Ti proponiamo 5 domande per suggerirti delle idee ma puoi modificarle come preferisci.',
    Sample_question1:
        '⏱ Per ciascuna delle domande, il tuo cliente avrà 30 secondi per rispondere.',
    Sample_question2: '❓ Puoi scrivere al massimo 5 domande.',
    Sample_custom_video1:
        'un regalo: prodotto/servizio legato alla tua azienda o territorio.',
    Sample_custom_video2:
        'un codice promozionale: su un prodotto/servizio che ha un interesse particolare per i tuoi clienti.',
    Sample_custom_video3:
        'una bottiglia: Un buon vino o champagne farà sempre piacere ai tuoi clienti.',
    Sample_custom_video4:
        'un ringraziamento: efficace solo se hai un buon rapporto con i tuoi clienti.',
    Sample_custom_video:
        'Metti in gioco una ricompensa per ringraziare i tuoi clienti di aver preso il tempo di fare una testimonianza per te. Ecco alcune idee:',
    tooltip_model:
        'Questi modelli sono qui per aiutarti a scrivere le tue richieste di testimonianze.',
    tooltip_model2:
        'Puoi modificarli proprio prima di inviarli sul servizio di tua scelta.',
    tooltip_questions:
        'Il tuo cliente avrà 30 secondi per rispondere a ogni domanda.',
    tooltip_video:
        'Questo video è consigliato. Sarà mostrato all\'inizio del tuo questionario.',
    tooltip_video2:
        ' Per l’importazione, massimo 1min30 e formati .MOV o .MP4 accettati.',
    tooltip_reward: "Aggiungere una ricompensa non è obbligatorio,",
    tooltip_reward2: "ma a volte può fare la differenza! 😉",
    emailAlreadyUsed: 'Questa email esiste già',

    //* DIRECTORY *//

    directory: 'clienti',
    addClients: 'aggiungi clienti',
    add: 'Aggiungi',
    addSmall: 'aggiungi',
    actionFilter: 'Azione',
    askFilter: 'Chiedi',
    recontactFilter: 'Rilancia',
    unlockFilter: 'Sblocca',
    thankFilter: 'Ringrazia',
    actions: 'azioni da fare',
    relaunch: 'rilancia la persona',
    contactSupport: 'Contatta il supporto',
    askTestimony: 'chiedi una testimonianza',
    thanks: 'ringrazia la persona',
    unlockTestimony: 'sblocca la testimonianza',
    mounting: 'montaggio video in corso',
    status: 'stato',
    seeTestimony: 'vedi la testimonianza video',
    sentDemande: 'invia la richiesta',
    received: 'ricevuto da',
    filterBy: 'Filtra per',
    tagName: 'aggiungi un tag',
    resent: 'rilanciato',
    editClient: 'Modifica le informazioni del cliente',
    deleteClient: 'Elimina il cliente',
    deleteClient1: 'Sei sicuro di voler eliminare definitivamente ',
    deleteClient2:
        "e tutti i suoi dati? Questa operazione è irreversibile e cancellerà i suoi contatti, i suoi diritti all'immagine e tutti i file video.",
    actionSent1: 'Hai inviato correttamente la tua richiesta di testimonianza?',
    actionSent2: 'Hai rilanciato correttamente il tuo cliente?',
    actionSent3: 'Hai ringraziato correttamente il tuo cliente?',
    today: "oggi",
    yesterday: 'ieri',
    days: ' giorni',
    weeks: ' settimane',
    week: ' settimana',
    months: ' mesi',
    month: ' mese',
    unlockSuccess: 'Testimonianza sbloccata!',

    //* INVITATION CUSTOM *//

    newTestimony: 'nuova testimonianza',
    formal: '🤝 Formalità',
    amical: '🫶 Amichevole',
    customMessage: 'personalizzazione del messaggio',
    from: 'Da parte di',
    destination: 'Destinatario',
    homeVideo: "Video di benvenuto",
    invitLink: 'Recupera il link',
    copyLink: 'copia il link',
    sendWith: 'Invia con',
    selectModel: 'Seleziona un modello di messaggio',
    linkCopied: 'Link copiato negli appunti!',
    addIncentive: "Ricompensa",
    addRewardInput: "Offri ai tuoi clienti una ricompensa",

    //* SELECT MODEL *//

    slectModel: 'Scegli un modello',
    select: 'seleziona',

    //* RECORDER *//

    sendingVideo: 'Invio del tuo video in corso...',
    testimonialSent: 'La tua testimonianza è stata inviata con successo!',
    unexpectedError: 'Errore inaspettato',

    //* MESSAGE CHARLIE *//

    waitMission: 'Creazione della missione {getInterviewTemplate} in corso.',
    readyToLaunch: 'Sono pronto per lanciare la missione {missionName}.',
    askedLaunch: 'Hai posto {asked} domanda.',
    askedLaunch2: 'Hai posto {asked} domande.',
    invitedLaunch: 'Hai {invited} invitato.',
    invitedLaunch2: 'Hai {invited} invitati.',
    finalLaunch: "È tutto a posto per te?",
    forgotNameMission: 'Ops, hai dimenticato di dare un nome alla missione.',
    needName: 'Ho bisogno di un nome, un cognome, una mail',
    needQuestion: 'Ho bisogno di almeno una domanda per continuare.',
    needInviteText:
        "Ho bisogno di un video d'invito e di una ricompensa per continuare.",
    missionError: 'Errore durante la creazione',
    launchSuccess: 'Richiesta creata!',
    draftRegistered: 'Bozza registrata',
    waitingAnswer: 'in attesa di risposta',
    decline: 'ha rifiutato di partecipare il',
    clicked: 'cliccato',
    downloading: 'Download in corso...',
    noFile: 'Nessun file da scaricare',
    hiCharlie: "Ciao {userSurname}, sono YourCharlie 👋",
    questionToWitness:
        "Allora cominciamo, ecco la prima domanda di {ownerSurname}:",
    beforeStartingMessage:
        "È fantastico ritrovarti qui, {ownerSurname} non vede l'ora di vedere la tua testimonianza! Infatti, {ownerSurname} mi ha lasciato un piccolo messaggio per te:",
    acceptImageRights:
        "Un'ultima piccola cosa prima di finire, puoi confermare che accetti di cedere i tuoi diritti d'immagine a {ownerBusiness}",
    seeYouSoon:
        'Perfetto! Grazie per la tua partecipazione. Te la sei cavata alla grande! A presto 😉',
    testimonyCollected: 'Questa testimonianza è già stata raccolta.',
    welcomingCharlie: "Ciao e benvenuto. Io sono YourCharlie 👋",
    andYou: 'E tu?',
    enterFirstName: 'inserisci il tuo nome qui',
    niceToMeet: 'Piacere di conoscerti {userSurname}  😉',
    goodWork: 'Faremo un ottimo lavoro insieme!',
    experienceEnhancer:
        "Prima di iniziare, ho alcune domande per migliorare la tua esperienza",
    enterLastName: 'inserisci il tuo cognome qui',
    companyName: 'Qual è il nome della tua azienda?',
    enterCompanyName: 'inserisci il nome qui',
    phoneNumber: 'Qual è il tuo numero di cellulare?',
    enterPhoneNumber: 'inserisci il tuo numero qui',
    companyColor: 'Qual è il colore principale di {ownerBusiness}?',
    pickColor: 'scegli il colore qui',
    wellDone: 'Ottimo! Tutto è a posto da parte mia.',
    navError:
        "Ops... 😣 La funzione di registrazione non funziona con questo browser.. Prova con un altro!",
    provideEmail: 'Per favore, fornisci un email',

    //* PROFILE *//

    profile: 'Il mio profilo',
    entreprise: 'azienda',
    invoice: 'Fatture',
    date: 'Data',
    description: 'Descrizione',
    amount: 'Importo',
    color: 'colore dei video',
    newColor: 'Nuovo colore registrato',
    invalidColor: 'Colore non valido',
    infoRegistered: 'Informazioni registrate',

    // APPSUMO PROFILE

    dealSentence: "Attualmente stai beneficiando di un'offerta a vita AppSumo 👏",
    codeRedeemed: 'Codici utilizzati',
    monthCredits: 'Crediti al mese',
    purchaseSentence: "Se sei tra i fortunati che hanno acquistato più di un codice AppSumo, sei nel posto giusto. Per favore, aggiungi qui il tuo codice supplementare.",
    applyCode: 'applica il codice',
    appsumoCodeError: 'Purtroppo, questo codice non è valido 😕',
    codeStacked: 'Congratulazioni, il codice è stato aggiunto con successo!',

    //* SETTINGS *//

    audioSource: 'Sorgente audio',

    //* LOGIN/INSCRIPTION *//

    password: 'password',
    lostPassword: "ho perso la mia password",
    alreadyRegistered: 'Hai già un account? Accedi ora',
    noAccount: "Non hai un account? Iscriviti ora",
    serviceConnection: "Un attimo, sto provvedendo a connetterti al servizio",
    invalidEmail: 'Indirizzo email non valido.',
    passwordRequirement:
        'La tua password deve contenere almeno 8 caratteri tra cui una maiuscola, una minuscola, un numero e un carattere speciale.',
    alreadyUsedEmail:
        'Indirizzo email già utilizzato. Forse ti sei registrato tramite Google?',
    invalidPassword: 'Errore di password',
    connectionError: 'Errore di connessione',
    connectionCancelled: 'Connessione annullata',
    connection: 'Connessione',
    register: 'Iscrizione',
    unknownError: 'Errore sconosciuto',
    blockedPopup: "Popup bloccato. Per favore, autorizzalo",
    googleRegister: "registrati con google",
    googleConnect: 'connettiti con google',
    or: 'O',
    logIn: 'accedi',
    resetPassword: 'Reimposta la password',
    emailUsed:
        "Inserisci l'indirizzo email associato al tuo account, e ti invieremo un link per reimpostare la tua password.",
    emailSent:
        "È stata inviata un'email all'indirizzo indicato. Devi solo seguire le istruzioni per reimpostare la tua password 😎",
    registerGCU: 'Iscrivendoti, confermi di aver letto e accettato i nostri',
    GCU: "Termini e condizioni",
    registerGCU2: 'così come la nostra ',
    PDC: 'Politica sulla privacy.',
    seeGCU: 'Consulta i nostri',
    loginListBold1: 'Paga per le prestazioni',
    loginList1: 'Nessun abbonamento o impegno.',
    loginListBold2: 'Lancia missioni gratuitamente',
    loginList2: 'Non è richiesta la carta di credito.',
    loginListBold3: "Il team di YourCharlie è al tuo fianco",
    loginList3: 'Ci prendiamo cura di te 5/7 dalle 9 alle 17.',
    new: 'NUOVO',
    display: 'Mostra',
    trusted: 'il badge di fiducia',
    onyourwebsite: 'sul tuo sito per rassicurare i tuoi visitatori',
    passwordRepeat: 'ripeti la password',
    passwordsDontMatch: 'Le password non corrispondono',

    //* IMPORT *//

    chooseFile: 'Ok, scegli un file...',
    acceptedFormat: 'formati accettati:',
    mediaDuration: 'durata massima video {mediaDuration} secondi',
    videoImportModal1:
        'importa un video motivazionale per incoraggiare i tuoi clienti a registrare una testimonianza per te',
    videoImportModal2: 'durata video: massimo 1min30',
    videoImportModal3: 'formati accettati: mp4, mov, avi',

    //* STOP PAGE *//

    stop_title: 'Peccato {surname}, sono così triste 😢',
    stop_text1: 'Chiedo solo 3 minuti di tempo aggiuntivo,',
    stop_text2: 'per conservare un bel ricordo del nostro percorso insieme',
    recorderNotification:
        'peccato.. forse sarà per la prossima volta?',

    //* VIDEO *//

    urlVideoOnboarding: 'https://www.youtube.com/embed/xtBvLvQJ36c',
    urlVideoLogin: 'https://player.vimeo.com/video/773707744',
    urlVideoAffiliation: 'https://www.youtube.com/embed/qr3cMP2MsAs?si=LY6BWqPRK1QG9UwA',

    //* RECORDER V2 *//

    actualQuestion: 'domanda',
    helloThere1: 'Benvenuto',
    helloThere2: 'Stai per registrare una testimonianza video.',
    recordingTips: "Vedi i suggerimenti di YourCharlie",
    recordingTips1: '30 secondi per domanda',
    recordingTips1Q: "domande",
    recordingTips2: 'Ricomincia o conferma per passare alla domanda successiva.',
    recordingTips3: 'Migliora la qualità audio con un "kit vivavoce".',
    recordingTips4: "Non dimenticare di sorridere, è per divertimento",
    homeRecorderReward: "Ricompensa:",
    begin: 'fai la mia testimonianza',
    MThanks: 'Grazie per aver testimoniato!',
    endingCongrats1: 'Realizzato con YourCharlie, il software che semplifica le testimonianze video.',
    endingRewards: 'Promesso è dovuto 🎁',
    iOffer: 'è in arrivo!',
    referalCharlie: 'raccogliere testimonianze video',
    discoverCharlie: 'Scopri YourCharlie',
    modalRetry: 'Ciao 👋',
    modalRetry2:
        'Hai già risposto a delle domande. Vuoi ricominciare dall\'inizio o riprendere da dove ti sei fermato?',
    fatalErrorRecord: "Ops! Un problema tecnico sembra impedire la registrazione del tuo testimonial. Questo problema potrebbe essere legato al dispositivo che stai utilizzando attualmente. Potresti provare l'esperienza da un altro dispositivo, per favore? La tua contribuzione è molto importante per noi. Grazie! 🌟",
    tooShortRecord: "Ops! Sembra che la tua risposta sia troppo breve. Vorresti registrare di nuovo? La tua contribuzione è molto importante per noi. Grazie! 🌟",

    //* INTEGRATION *//

    addedToPlaylist: 'Testimonianza video aggiunta al widget',
    removedFromPlaylist: ' Testimonianza video rimossa dal widget',
    copyCode: 'copia il codice',
    widgetPres1: "Il widget YourCharlie sul tuo sito è l'ideale per",
    widgetPres1b: 'aumentare il tuo tasso di conversione',
    widgetPres2:
        'Scopri i due passaggi per aggiungere semplicemente le testimonianze video al tuo sito.',
    widgetStep1: 'Passaggio 1 - Seleziona un massimo di 5 video',
    Step1expA: 'Per aggiungere un video al widget, vai in',
    Step1expB: 'Accanto a ogni testimonianza ricevuta hai il pulsante ',
    Step1expC: 'aggiungi al widget',
    widgetStep2: 'Passaggio 2 - Aggiungi il widget al tuo sito',
    Step2expA: 'Per aggiungere questo widget a tutte le pagine del tuo sito,',
    Step2expB: 'clicca su ',
    Step2expC: 'copia il codice',
    Step2expD: 'e incollalo nella sezione < HEAD> del tuo sito web.',
    WordpressWidget:
        'Per aggiungere il widget al tuo sito WordPress è molto semplice:',
    wpDownload: "scarica l'estensione",
    wpStep1a: "Scarica l'estensione ",
    wpStep1c: ' cliccando sul pulsante a fianco',
    wpStep2a: 'Nel tuo cruscotto ',
    wpStep2b: ', vai in Estensioni > Aggiungi',
    wpStep3:
        "Clicca sul pulsante ''Carica estensione'' e carica l'archivio zip",
    wpStep4: "Clicca su ''Installa ora'' e poi attiva l'estensione",
    wpInstallEnd:
        "Una volta installata e attivata l'estensione, puoi accedere alla pagina delle impostazioni andando nel menu a sinistra > YourCharlie",
    chart_title: 'Carta di fiducia',
    chart_purpose:
        'Per ottenere il badge di autenticità rilasciato da YourCharlie, mi impegno a rispettare i seguenti punti: ',
    chart_list_1:
        'Invito veri clienti della mia azienda con cui ho avuto almeno una transazione finanziaria in cambio di un prodotto o servizio.',
    chart_list_2:
        'Attesto che i miei clienti non hanno negoziato una remunerazione in cambio della registrazione di una testimonianza video.',
    chart_list_3:
        "Non ho tentato di influenzare le dichiarazioni del cliente fornendo uno scenario o degli elementi di risposta.",
    chart_list_4:
        'Capisco che non posso modificare la selezione delle testimonianze video per non ingannare i miei visitatori.',
    chart_list_5:
        'Accetto l’autenticità dei miei clienti e quindi la possibilità che le testimonianze non siano perfette nell’immagine o nel modo di esprimersi.',
    integ_page_title: "Mostra che si può avere fiducia in te",
    integ_purpose1: 'A volte è difficile distinguere il vero dal falso.',
    integ_purpose2:
        'Con il badge YourCharlie, guadagna credibilità e rassicura i tuoi potenziali clienti',
    integ_purpose3:
        'mostrando prove sociali autentiche dei tuoi clienti.',
    popup_title: 'Integra il pop-up YourCharlie sul tuo sito',
    popup_1:
        "Il pop-up si visualizza in sovraimpressione nell'angolo sinistro del tuo sito. I tuoi visitatori cliccano sopra per visualizzarlo in grande e ascoltare le tue testimonianze.",
    popup_2:
        'Per aggiungere il pop-up a tutte le pagine del tuo sito, clicca su ',
    popup_3: 'e incollalo nella sezione "<head>" del tuo sito web',
    slider_title: 'Integra il carosello YourCharlie sul tuo sito',
    slider_1:
        "Il carosello si visualizza dove desideri sul tuo sito, sulla pagina che preferisci. I tuoi visitatori cliccano sui video per attivare l'audio e possono farli scorrere.",
    slider_2:
        'Per aggiungere il carosello su una pagina del tuo sito, clicca su ',
    slider_3: ' e incollalo in un tag HTML del tuo sito web',
    slider: 'carosello',

    //* INTEGRATION V2 *//

    interestingTools: 'strumenti che potrebbero interessarti',
    myTools: "i miei strumenti",
    freePopDesc: "I tuoi ultimi 5 testimonial in un lettore pop-up.",
    freeSliderDesc: "I tuoi ultimi 5 testimonial in un carosello su una pagina.",
    bigPopDesc: "Fino a 15 testimonial in un lettore pop-up sul tuo sito.",
    bigSliderDesc: "Fino a 15 testimonial in un carosello facile da integrare su una pagina.",
    wallDesc: "Testimonial video illimitati in una galleria sul tuo sito web.",
    lockedPopSliderText: "Per creare una selezione personalizzata di testimonial, aggiungi una Big Pop-up o un Big Slider",
    builderWallDesc: "Trascina i tuoi testimonial dall'elenco a sinistra e rilasciali qui sotto per organizzarli. Puoi aggiungere quanti video vuoi.",
    builderBigPopDesc: "Trascina i tuoi testimonial dall'elenco a sinistra e rilasciali qui sotto per organizzare i video della tua Big Pop-Up.",
    builderBigPopDesc2: "Puoi aggiungere fino a 15 testimonial video.",
    builderBigSliderDesc: "Trascina i tuoi testimonial dall'elenco a sinistra e rilasciali qui sotto per organizzare i video del tuo Big Slider.",
    builderBigSliderDesc2: "Puoi aggiungere fino a 15 testimonial video.",
    builderFreePopDesc: "Con la Free Pop-Up, mostri automaticamente i tuoi ultimi 5 testimonial video sbloccati in un lettore pop-up sul tuo sito web.",
    builderFreeSliderDesc: "Con il Free Slider, mostri automaticamente i tuoi ultimi 5 testimonial video sbloccati in un carosello da integrare facilmente su una pagina del tuo sito web.",
    builderWallExemple: "Benvenuto nel Wall of Love! Guarda i {X} testimonial video autentici di {Business}.",
    sorry: "Spiacente.",
    sorryText1: "Questa funzionalità non è accessibile su mobile o tablet.",
    sorryText2: "Per favore, accedi da un computer per creare il tuo widget.",
    integDesc: "Non hai ancora configurato alcuno strumento. Scegline uno dall'elenco qui sotto",
    remainingDays: "{count}g rimanenti",
    alreadyAdded: 'già aggiunto',

    //* MODAL INTEGRATION V2 *//

    installWall: 'Integra il Wall of Love direttamente su una pagina del tuo sito web con questo codice.',
    installPopup: 'Integra questa pop-up direttamente sul tuo sito web con il codice.',
    installSlider: 'Integra questo slider direttamente su una pagina del tuo sito web con il codice.',
    codeCreation1: 'è pronto per la diffusione.',
    codeCreation2: 'Copia il codice qui sotto per integrarlo sul tuo sito.',
    modalNeedHelp: "Consulta il tutorial per l'integrazione.",
    modalLinkTitle: 'Condividi il link della tua pagina dedicata sui social network o in una email.',
    modalShopTitle: 'La tua selezione è pronta per essere diffusa 🔥',
    modalShopDesc1: 'Hai selezionato un widget a pagamento. Scegli la durata di visualizzazione che desideri.',
    modalShopDesc2: 'Puoi aumentare la durata in qualsiasi momento.',
    chooseDuration: "scegli una durata",
    inviteNow: 'invita ora',
    customize: 'Personalizza',
    addImage: "Aggiungi un'immagine",
    sliderSettingsTitle: "Titolo",
    sliderSettingsDesc: "Descrizione",
    sliderSettingsWebsite: "www.miosito.com",
    playlistName: "nome della playlist",
    notEnoughCreditsDuration: "Ops, non hai abbastanza crediti per acquistare questa opzione.",
    popular: "POPOLARE",
    newTool: "NUOVO",

    //* AFFILIATION *//

    becomeAffiliate: "Diventa ambasciatore di YourCharlie",
    affiliateDesc1: "Raccomanda YourCharlie e ",
    affiliateDesc2: "guadagna il 10% a vita",
    affiliateDesc3: "sulle vendite dei tuoi affiliati.",
    affiliateDesc4: "I tuoi affiliati beneficiano di ",
    affiliateDesc5: "10€ di sconto",
    affiliateDesc6: "sul loro primo acquisto di crediti.",
    learnMore: 'scopri di più',
    affiliateAccount: "Crea il tuo account",
    affiliateAccount2: " su FirstPromoter",
    affiliateAccountDesc1: "Creando il tuo account su Firstpromoter, avrai accesso a uno strumento completo per gestire e monitorare automaticamente le registrazioni dei tuoi affiliati.",
    affiliateAccountDesc2: "Grazie al tuo link di affiliazione disponibile su FirstPromoter, i tuoi affiliati si registrano su YourCharlie. Ottengono 10€ di sconto sul loro primo acquisto.",
    affiliateAccountDesc3: "Ogni volta che uno dei tuoi affiliati effettua un acquisto su YourCharlie, guadagni il 10% di commissione. E questo, senza limiti di tempo!",
    affiliateLink1: "Gli affiliati si registrano",
    affiliateLink2: "con il tuo link",
    affiliateReward1: "Ricevi",
    affiliateReward2: "la tua commissione del 10%",
    becomeAffiliateSmall: "diventa ambasciatore",

    //* SUBEDITOR *//

    Génération: "Generazione",
    Incrustation: "Incrustazione",
    onGoingSub: "sottotitoli in corso. Si prega di attendere.",
    subtitles: "sottotitoli",
    imageRightsS: "diritti d'immagine",

    //* APPSUMO *//

    appsumo1: "Basta condividere il tuo nome, email e il codice Appsumo per registrarti a YourCharlie. Dopo aver inviato la tua richiesta, sarai pronto a raccogliere le tue prove sociali su video.",

    //* PREMIUMVUE *//

    premiumTitle1: "YourCharlie Premium",
    premiumTitle2: "Scopri YourCharlie Premium",
    premiumSentence1: "Attualmente sei un membro premium, uno status esclusivo riservato a pochissimi dei nostri clienti.",
    premiumSentence2: "Il tuo project manager dedicato è Axel.",
    premiumSentence3: "Vuoi testimonianze video ma non puoi occupartene tu stesso? Sei corto di tempo?",
    premiumSentence4: "Sei corto di tempo o non sai come procedere?",
    premiumSentence5: "Siamo qui per semplificarti la vita gestendo il tuo account da A a Z. Il nostro team dedicato farà le domande giuste ai clienti giusti, li motiverà e convincerà a registrare una testimonianza video.",
    premiumSentence6: "Non c'è bisogno di preoccuparsi dei follow-up, li gestiamo manualmente per massimizzare i tassi di risposta.",
    premiumSentence7: "Con noi, puoi concentrarti sulla crescita della tua attività e lasciare che il team di YourCharlie si occupi del resto.",
    premiumSentence8: "Attenzione, la nostra offerta è esclusiva e limitata a soli 10 imprenditori al mese.",
    premiumSentence9: "A partire da ",
    premiumSentence10: "€89.99 escl. IVA / mese",
    premiumButton1: "Contatta Axel",
    premiumButton2: "Gestisci la mia iscrizione",
    premiumButton3: "Scopri YourCharlie Premium",
    tooltipApi: "visualizza la documentazione dell'API",

    /** API BLOCK **/

    apiSentence1: "Questa chiave ti consente di utilizzare YourCharlie con strumenti di automazione come Make o Zapier. Potresti averne bisogno anche per sviluppi personalizzati basati sulla nostra API.",
    apiSentence2: "Attenzione:",
    apiSentence3: "Per motivi di sicurezza, ogni chiave è unica. Dovrai generarne una nuova",
    apiSentence4: "se perdi la prima chiave generata.",
    seeApiDoc: "vedi documentazione API",

}