<template>
  <div class="tile is-parent">
    <div style="width: 100%">
      <h3>{{ $t('invoice') }}</h3>
      <br />
      <b-table :data="data">
        <b-table-column
          field="date"
          :label="$t('date')"
          centered
          v-slot="props"
        >
          <span
            class="tag"
            style="background-color: #161032; color: white"
          >
            {{ new Date(props.row.createdAt).toLocaleDateString() }}
          </span>
        </b-table-column>
        <b-table-column
          field="productName"
          :label="$t('description')"
          v-slot="props"
        >
          {{ props.row.productName }}
        </b-table-column>
        <b-table-column
          field="productPriceHT"
          :label="$t('amount')"
          v-slot="props"
        >
          {{ props.row.productPriceHT / 100 - props.row.productDiscount / 100  }} € ht
        </b-table-column>
        <b-table-column
          field="invoice"
          :label="$t('invoice')"
          v-slot="props"
        >
          <router-link :to="{ name: 'invoice', params: { ...props.row } }">
            <b-icon
              icon="file-pdf"
              size="is-small"
              style="vertical-align: middle; color: #8c78d6"
            >
            </b-icon><small style="color: #8c78d6">&nbsp;{{ $t('download') }}</small>
          </router-link>
        </b-table-column>
      </b-table>
    </div>
  </div>
</template>

<script>
/**
 * @name Invoices
 * @desc Component for displaying invoices
 */
export default {
  /**
   * @name beforeMount
   * @desc Fetches and sorts invoices data before component is mounted
   */
  beforeMount() {
    this.$store.dispatch('getInvoices').then((invoices) => {
      this.data = invoices.data.sort((a, b) => b.id - a.id);
    });
  },
  /**
   * @name data
   * @desc Data object containing invoice information
   * @returns {object} - Array of invoice objects
   */
  data() {
    return {
      /**
       * @type {Array}
       * @desc Data object containing invoice information
       */
      data: [
        {
          amount: '50.00 €',
          pack: 'Pack de 1000 crédits',
          date: '15/10/2021 | 13:43:27',
          invoice: 'télécharger'
        },
        {
          amount: '100.00 € ',
          pack: 'Pack de 2500 crédits',
          date: '10/06/2021 | 06:00:53',
          invoice: 'télécharger'
        },
        {
          amount: '25.00 € ',
          pack: 'Pack de 500 crédits',
          date: '28/03/2021 | 13:43:27',
          invoice: 'télécharger'
        },
        {
          amount: '00.00 € ',
          pack: '25 crédits de bienvenue',
          date: '21/03/2021 | 18:13:37',
          invoice: ''
        }
      ]
    };
  }
};
</script>

<style src="./index.css">
</style>

 