
<template>
  <section>

    <b-button
      @click="editTemplate({})"
      size="is-small"
      type="is-success"
    >Créer un nouveau template</b-button>
    <br /><br />
    <b-table
      :data="dataTemplates"
      :hoverable="isHoverable"
      paginated
      backend-pagination
      :loading="loading"
      :total=total
      :per-page="perPage"
      @page-change="onPageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      backend-sorting
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      @sort="onSort"
    >

      <b-table-column
        field="id"
        label="ID"
        sortable
        v-slot="props"
      >
        {{ props.row.id }}
      </b-table-column>
      <b-table-column
        field="lang"
        v-slot="props"
      >
        <span v-if="props.row.locale == 'fr'"> 🇫🇷 </span>
        <span v-if="props.row.locale == 'en'"> 🇬🇧 </span>
        <span v-if="props.row.locale == 'es'"> 🇪🇸 </span>
        <span v-if="props.row.locale == 'it'"> 🇮🇹 </span>
      </b-table-column>
      <b-table-column
        field="tu/vous"
        v-slot="props"
      >
        <span> {{ props.row.formality ? "vous" : "tu" }} </span>
      </b-table-column>
      <b-table-column
        label="type"
        field="type"
        v-slot="props"
      >
        <span> {{ props.row.tag }} </span>
      </b-table-column>
      <b-table-column
        label="visibilité"
        field="public"
        v-slot="props"
      >
        <span v-if="props.row.public"> ✅ </span>
        <span v-else> 🚫 </span>
      </b-table-column>
      <b-table-column
        field="name"
        label="Name"
        :searchable="true"
        sortable
        v-slot="props"
      >
        {{ props.row.name }}
      </b-table-column>
      <b-table-column
        field="edit"
        v-slot="props"
      >
        <b-button
          :disabled="templatesInSequences.includes(props.row.id)"
          @click="editTemplate(props.row)"
          style="border: 0px !important"
        >
          <b-icon
            pack="fas"
            icon="pen-to-square"
            style="color: #8c78d6"
          >
          </b-icon>
        </b-button>
      </b-table-column>
      <b-table-column v-slot="props">

        <b-button
          style="border: 0px !important"
          @click=duplicateTemplate(props.row)
        >
          <b-icon
            pack="fas"
            icon="clone"
            style="color: #8c78d6"
          >
          </b-icon>
        </b-button>

      </b-table-column>
      <b-table-column
        field="delete"
        v-slot="props"
      >
        <b-button
          :disabled="templatesInSequences.includes(props.row.id)"
          @click="deleteTemplate(props.row)"
          style="border:  0px !important"
        >
          <b-icon
            pack="fas"
            icon="trash-alt"
            style="color: #8c78d6; "
          >
          </b-icon>
        </b-button>
      </b-table-column>

      <b-loading
        :is-full-page="false"
        :active.sync="loading"
      ></b-loading>
    </b-table>

    <b-modal
      :active.sync="showEditTemplates"
      :has-modal-card="true"
    >
      <div
        class="card box"
        style="max-width: 1060px !important ; width: 1060px !important; height:800px !important; overflow:scroll"
      >
        <editTemplate
          v-on:updateTemplate=refreshList()
          v-on:close=refreshList()
          :template="currentTemplate"
        >
        </editTemplate>
      </div>
    </b-modal>

  </section>
</template>

<script>
import editTemplate from './editTemplate.vue';
import moment from 'moment';

/**
 * @name allTemplates
 * @desc Component for managing all templates
 */

export default {
  name: 'allTemplates',
  components: { editTemplate },
  filters: {
    /**
     * @name moment
     * @desc Filter to format date using moment.js
     * @param {Date} date - The date to format
     * @returns {string} - Formatted date
     */
    moment: function (date) {
      moment.locale('fr');
      return moment(date).format('LLL');
    }
  },
  data: () => {
    return {
      /**
       * @type {boolean}
       * @desc Indicates whether the edit templates are shown
       */
      showEditTemplates: false,
      /**
       * @type {object}
       * @desc The current template being edited
       */
      currentTemplate: {},
      /**
       * @type {array}
       * @desc Array of data templates
       */
      dataTemplates: [],
      /**
       * @type {boolean}
       * @desc Indicates if data is loading
       */
      loading: false,
      /**
       * @type {boolean}
       * @desc Indicates if table rows are hoverable
       */
      isHoverable: true,
      /**
       * @type {string}
       * @desc The field to sort by
       */
      sortField: 'id',
      /**
       * @type {string}
       * @desc The sort order ('asc' or 'desc')
       */
      sortOrder: 'asc',
      /**
       * @type {string}
       * @desc The default sort order
       */
      defaultSortOrder: 'asc',
      /**
       * @type {number}
       * @desc The current page number
       */
      page: 1,
      /**
       * @type {number}
       * @desc Number of items per page
       */
      perPage: 20,
      /**
       * @type {number}
       * @desc Total number of items
       */
      total: 0,
      /**
       * @type {array}
       * @desc Array of template IDs in sequences
       */
      templatesInSequences: []
    };
  },
  beforeMount() {
    /**
     * @name beforeMount
     * @desc Lifecycle hook before component is mounted
     */
    this.$store
      .dispatch('getAdminSequences')
      .then((response) => {
        let sequences = response.data[0];
        sequences.forEach((sequence) => {
          this.templatesInSequences = this.templatesInSequences.concat(
            sequence.sequenceSteps
              .filter((step) => step.mailTemplate != undefined)
              .map((step) => step.mailTemplate.id)
          );
        });
      })
      .catch((error) => {
        this.$buefy.toast.open({
          message: this.$t('errorOccured'),
          type: 'is-danger'
        });
        console.log(error);
      });
    this.refreshList();
  },
  methods: {
    /**
     * @name refreshList
     * @desc Refreshes the list of templates
     */
    refreshList() {
      this.loading = true;
      this.currentTemplate = undefined;
      this.$store
        .dispatch('getMailTemplatesPaginated', {
          page: this.page - 1,
          limit: this.perPage,
          sort: this.sortField,
          order: this.sortOrder.toUpperCase()
        })
        .then((result) => {
          this.dataTemplates = result.data[0];
          this.showEditTemplates = false;
          this.total = result.data[1];
          this.loading = false;
        });
    },
    /**
     * @name editTemplate
     * @desc Edits a template
     * @param {object} template - The template to edit
     */
    editTemplate(template) {
      this.currentTemplate = template;
      this.showEditTemplates = true;
    },
    /**
     * @name deleteTemplate
     * @desc Deletes a template
     * @param {object} template - The template to delete
     */
    deleteTemplate(template) {
      this.loading = true;
      this.$store.dispatch('deleteMailTemplate', template.id).then((result) => {
        this.refreshList();
      });
    },
    /**
     * @name duplicateTemplate
     * @desc Duplicates a template
     * @param {object} template - The template to duplicate
     */
    duplicateTemplate(template) {
      this.currentTemplate = template;
      this.currentTemplate.id = null;
      this.currentTemplate.usageCount = 0;
      this.currentTemplate.successCount = 0;
      this.showEditTemplates = true;
    },
    /**
     * @name onPageChange
     * @desc Handles page change event
     * @param {number} page - The new page number
     */
    onPageChange(page) {
      this.page = page;
      this.refreshList();
    },
    /**
     * @name onSort
     * @desc Handles sorting event
     * @param {string} field - The field to sort by
     * @param {string} order - The sort order
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.refreshList();
    }
  }
};
</script>

<style src="../index.css"></style>