
<template>
  <section>

    <b-table
      :data="dataUsers"
      :hoverable="isHoverable"
      :loading="loading"
      paginated
      backend-pagination
      :total="total"
      :per-page="perPage"
      @page-change="onPageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      backend-sorting
      checkable
      :checked-rows.sync="validateGroup"
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      @sort="onSort"
      @click="onUserClicked($event)"
      :row-class="(row, index) => computeColorFromDateLine(row.userLogs[0].content)"
    >

      <b-table-column
        field="rappel"
        label="Date"
        v-slot="props"
      >

        <b-tag :style="computeColorFromDate(props.row.userLogs[0].content)">
          {{ props.row.userLogs[0].content | momentDate }}</b-tag>

      </b-table-column>

      <b-table-column
        field="business"
        label="Business"
        sortable
        v-slot="props"
      >
        {{ props.row.business }}
      </b-table-column>

      <b-table-column
        field="surname"
        label="Firstname"
        sortable
        v-slot="props"
      >
        {{ props.row.surname }}
      </b-table-column>
      <b-table-column
        field="name"
        label="Lastname"
        sortable
        v-slot="props"
      >
        {{ props.row.name }}
      </b-table-column>

      <b-table-column
        field="email"
        label="Email"
        sortable
        v-slot="props"
      >
        {{ props.row.email }}
      </b-table-column>
      <b-table-column
        field="phoneNumber"
        label="Phone"
        sortable
        v-slot="props"
      >
        <span v-if="props.row.phoneNumber == null || props.row.phoneNumber.length <= 6 "> 📵</span>
        <span v-else> {{ props.row.phoneNumber }}</span>

      </b-table-column>

    </b-table>
    <br />
    <b-button
      class="floatingButtonLeft"
      :disabled="validateGroup.length == 0"
      @click="updateUserLogs()"
      size="is-small"
    >Valider les tâches</b-button>

  </section>
</template>
  
<script>
import moment from 'moment';

/**
 * @name allUsers
 * @desc Component for managing all users
 */

export default {
  name: 'allUsers',
  components: {},
  filters: {
    /**
     * Format date using moment.js
     * @param {Date} date - The date to format
     * @returns {string} - Formatted date
     */
    momentDate: function (date) {
      moment.locale('fr');
      return moment(date).format('LL');
    }
  },
  data: () => {
    return {
      /**
       * @desc Array of users to validate
       * @type {array}
       */
      validateGroup: [],
      /**
       * @desc Array of data for users
       * @type {array}
       */
      dataUsers: [],
      /**
       * @desc Flag for hoverable rows
       * @type {boolean}
       */
      isHoverable: true,
      /**
       * @desc Flag for loading state
       * @type {boolean}
       */
      loading: false,
      /**
       * @desc Field to sort by
       * @type {string}
       */
      sortField: 'createdAt',
      /**
       * @desc Sort order
       * @type {string}
       */
      sortOrder: 'desc',
      /**
       * @desc Default sort order
       * @type {string}
       */
      defaultSortOrder: 'desc',
      /**
       * @desc Current page number
       * @type {number}
       */
      page: 1,
      /**
       * Number of items per page
       * @type {number}
       */
      perPage: 20,
      /**
       * @desc Total number of items
       * @type {number}
       */
      total: 0
    };
  },
  beforeMount() {
    this.refreshList();
  },
  methods: {
    /**
     * @name resetChecked
     * @description Reset checked users
     */
    resetChecked() {
      this.validateGroup = [];
    },

    /**
     * @name computeColorFromDate
     * @description Compute color based on date
     * @param {Date} date - The date to compute color for
     * @returns {object} - Object with background and text color
     */
    computeColorFromDate(date) {
      if (moment(date).isSame(moment(), 'day')) {
        return { backgroundColor: 'green', color: 'white' };
      } else if (moment(date).isBefore(moment())) {
        return { backgroundColor: 'red', color: 'white' };
      }
    },

    /**
     * @name computeColorFromDateLine
     * @description Compute color for date line
     * @param {Date} date - The date to compute color for
     * @returns {string} - Color class for line
     */
    computeColorFromDateLine(date) {
      if (moment(date).isSame(moment(), 'day')) {
        return 'greenLine';
      } else if (moment(date).isBefore(moment())) {
        return 'redLine';
      }
    },

    /**
     * @name updateUserLogs
     * @description Update user logs
     */
    updateUserLogs() {
      this.loading = true;
      const logs = this.validateGroup.map((user) => {
        return {
          ...user.userLogs[0],
          type: 'RAPPEL_DONE'
        };
      });
      this.$store
        .dispatch('updateAdminUserLogs', {
          userLogs: logs
        })
        .then((response) => {
          this.loading = false;
          this.validateGroup = [];
          this.$buefy.toast.open({
            message: this.$t('validReminder'),
            type: 'is-success',
            queue: false,
            position: 'is-top-right',
            duration: 5000
          });
          this.refreshList();
        })
        .catch((error) => {
          console.error(error);
          this.loading = false;
          this.$buefy.toast.open({
            message: this.$t('errorOccured'),
            type: 'is-danger',
            queue: false,
            position: 'is-top-right',
            duration: 5000
          });
        });
    },

    /**
     * @name refreshList
     * @description Refresh user list
     */
    refreshList() {
      this.loading = true;
      this.$store
        .dispatch('getAdminUsersByLogPaginated', {
          page: this.page - 1,
          limit: this.perPage,
          sort: this.sortField,
          order: this.sortOrder.toUpperCase(),
          userLogType: 'RAPPEL'
        })
        .then((response) => {
          this.dataUsers = response.data[0];
          this.loading = false;
          this.total = response.data[1];
        })
        .catch((error) => {
          console.error(error);
          this.loading = false;
          this.$buefy.toast.open({
            message: this.$t('errorOccured'),
            type: 'is-danger',
            queue: false,
            position: 'is-top-right',
            duration: 5000
          });
        });
    },

    /**
     * @name onPageChange
     * @description Handle page change
     * @param {number} page - The page number
     */
    onPageChange(page) {
      this.page = page;
      this.refreshList();
    },

    /**
     * @name onSort
     * @description Handle sorting
     * @param {string} field - The field to sort by
     * @param {string} order - The sort order
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.refreshList();
    },

    /**
     * @name onUserClicked
     * @description Handle user click event
     * @param {object} user - The user object
     */
    onUserClicked(user) {
      this.$emit('userClicked', user.user.id);
    }
  }
};
</script>
  
<style src="../index.css"></style>