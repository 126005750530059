<template>
  <div>
    <div
      style="padding: 40px !important"
      class="onboardMain"
      v-if="step === 1"
    >
      <b-tag style="float: right">1/2</b-tag>
      <p style="font-size: 30px !important; font-weight: 600">{{ $t('welcome') }} 👋</p>

      <p class="">{{ $t('onboardingMessage') }}</p>

      <hr />
      <div style="margin: 0 auto;">
        <b-field style="padding-bottom: 10px">
          <input
            class="inputOnboarding"
            :name="$t('fName')"
            :placeholder="$t('yourName')"
            expanded
            v-model="userInfos.surname"
            required
          />
        </b-field>
        <b-field style="padding-bottom: 10px">
          <input
            class="inputOnboarding"
            :name="$t('lName')"
            :placeholder="$t('yourLastName')"
            expanded
            v-model="userInfos.name"
            require
          />
        </b-field>
        <b-field style="padding-bottom: 10px">
          <input
            class="inputOnboarding"
            :name="$t('entreprise')"
            :placeholder="$t('entreprise')"
            expanded
            v-model="userInfos.business"
            required
          />
        </b-field>
        <b-field style="padding-bottom: 10px">
          <vue-tel-input
            v-model="userInfos.phoneNumber"
            :enabledCountryCode="true"
            mode="international"
            :inputOptions="{
            placeholder: 'mobile',
            styleClasses: 'inputCTelOnboarding',
          }"
            :validCharactersOnly="true"
            styleClasses="inputOnboarding"
            :preferredCountries="['FR']"
            wrapperClasses="inputOnboarding"
            :disabledFetchingCountry="true"
            defaultCountry="FR"
            style="padding: 0; margin-top: -7.5px;"
            class="inputOnboarding"
            required
          ></vue-tel-input>
        </b-field>
        <b-field
          :label="$t('color')"
          class="onboardMain__inputs-color"
          :class="!validColor ? 'is-danger-custom' : ''"
        >
          <b-colorpicker
            position="is-top-right"
            :append-to-body="true"
            :value="userInfos.color"
            @input="testColor"
            v-on:change="updateColor"
            expanded
          >
            <template #footer="{ }">
              <div class="colorpicker-fields">
                <b-field :message="!validColor ? $t('invalidColor') : ''">
                  <input
                    ref="input"
                    :class="!validColor ? 'is-danger-custom' : ''"
                    type="text"
                    v-model="userInfos.color"
                    size="is-small"
                    aria-label="Couleur"
                    required
                  />
                </b-field>
              </div>
            </template>
          </b-colorpicker>
          <p
            class="is-danger-custom"
            v-if="!validColor"
          > Merci de renseigner une couleur valide </p>
        </b-field>
        <br />
        <div style="display: flex; justify-content: flex-end;"> <button
            class="button-3d-small"
            @click="save()"
            :disabled="!valid || loading"
          >
            {{ $t('next') }}
            <b-loading
              style="margin:2px"
              :is-full-page="false"
              :active="loading"
            ></b-loading>
          </button></div>

      </div>
    </div>
    <div
      class="onboardMain"
      style="padding: 40px !important; "
      v-else-if="step === 2"
    >
      <b-tag style="float: right">2/2</b-tag>
      <span style="font-size: 30px !important; font-weight: 600">{{ $t('add1st') }} 🔥</span>
      <p class="">{{ $t('onboardingMessage2') }}</p>
      <hr />

      <div
        class="tile is-vertical"
        style="padding-top: 40px;margin: 0 auto;"
      >
        <input
          class="inputOnboarding"
          v-model="client.surname"
          label="prenom"
          :placeholder="$t('fName')"
          type="text"
          value=""
          style="margin-right: 20px;font-size: 15px !important; width: 130px; margin-bottom:15px "
          required
        />
        <br />
        <input
          class="inputOnboarding"
          v-model="client.name"
          label="nom"
          :placeholder="$t('lName')"
          type="text"
          value=""
          style="margin-right: 20px;font-size: 15px !important; width: 130px; margin-bottom:15px "
          required
        />
        <br />
        <input
          class="inputOnboarding"
          v-model="client.email"
          label="email"
          :placeholder="$t('email')"
          type="text"
          value=""
          style="margin-right: 20px;font-size: 15px !important; margin-bottom:25px "
        />
        <br />
        <vue-tel-input
          v-model="client.phone"
          :enabledCountryCode="true"
          mode="international"
          :inputOptions="{
          placeholder: 'mobile',
          styleClasses: 'inputCTelOnboarding',
        }"
          :validCharactersOnly="true"
          styleClasses="inputOnboarding"
          :preferredCountries="['FR']"
          wrapperClasses="inputC"
          :disabledFetchingCountry="true"
          defaultCountry="FR"
          style="margin-right: 20px; font-size: 15px !important"
        ></vue-tel-input>
      </div>

      <div style=" display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em; padding-top: 80px;">
        <button
          style="width: 210px !important"
          class="button-3d-small-revert"
          @click="explore()"
          :disabled="loading"
        >
          {{ $t('explore') }}
        </button>
        <button
          style="width: 240px !important; display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;"
          class="button-3d-small"
          @click="addClient()"
          :disabled="loading || !validClient"
        ><b-icon
            pack="fas"
            icon="arrow-right"
            size="is-small"
          >
          </b-icon>
          {{ $t('askForTestimonial') }}
        </button>
        <b-loading
          style="margin:2px"
          :is-full-page="false"
          :active="loading"
        ></b-loading>
      </div>
    </div>
  </div>
</template>

<script>
import InvitationCustom from './../components/InvitationCustom.vue';

/**
 * @name Onboarding2
 * @desc Handles the onboarding process for new users, including collecting user information and client creation.
 */

export default {
  name: 'onboarding2',
  components: { InvitationCustom },
  props: {
    /**
     * Firebase user ID
     * @type {String}
     */
    userFirebaseId: {
      type: String
    },
    /**
     * Sponsorship ID for tracking referrals
     * @type {String}
     */
    sponsorshipId: {
      type: String
    }
  },
  data() {
    return {
      /**
       * @desc Indicates if the current form data is valid.
       * @type {Boolean}
       */
      valid: false,
      /**
       * @desc Indicates if the system is currently processing/loading.
       * @type {Boolean}
       */
      loading: false,
      /**
       * @desc Indicates if the client data is valid.
       * @type {Boolean}
       */
      validClient: false,
      /**
       * @desc Stores client information.
       * @type {Object}
       */
      client: {
        /**
         * @desc Client's first name.
         * @type {String}
         */
        name: '',
        /**
         * @desc Client's surname.
         * @type {String}
         */
        surname: '',
        /**
         * @desc Client's business name.
         * @type {String}
         */
        business: '',
        /**
         * @desc Client's email address.
         * @type {String}
         */
        email: '',
        /**
         * @desc Client's phone number.
         * @type {String}
         */
        phone: ''
      },
      /**
       * @desc Current step in the onboarding process.
       * @type {Number}
       */
      step: 1,
      /**
       * @desc Data related to the mission, such as templates and client lists.
       * @type {Object}
       */
      missionData: {
        /**
         * @desc Template for the interview.
         * @type {Object|null}
         */
        interviewTemplate: null,
        /**
         * @desc Type of the mission, e.g., 'invitation'.
         * @type {String}
         */
        type: 'invitation',
        /**
         * @desc List of clients associated with the mission.
         * @type {Array}
         */
        clients: []
      },
      /**
       * @desc Stores user information collected during onboarding.
       * @type {Object}
       */
      userInfos: {
        /**
         * @desc User's first name.
         * @type {String}
         */
        name: '',
        /**
         * @desc User's surname.
         * @type {String}
         */
        surname: '',
        /**
         * @desc User's business name.
         * @type {String}
         */
        business: '',
        /**
         * @desc User's phone number.
         * @type {String}
         */
        phoneNumber: '',
        /**
         * @desc User's chosen color in hex format.
         * @type {String}
         */
        color: '#000000'
      }
    };
  },
  /**
   * @name beforeMount
   * @desc Checks if user data is already filled to skip to the next step.
   */
  beforeMount() {
    const user = this.$store.getters.activeUser;
    if (
      user &&
      user.name &&
      user.business &&
      user.surname &&
      user.name != '' &&
      user.business != '' &&
      user.surname != ''
    ) {
      this.step = 2;
    }
  },
  methods: {
    // /**
    //  * @name explore
    //  * @desc Creates clients based on user information and transitions to the next phase of onboarding.
    //  */
    // explore() {
    //   this.loading = true;
    //   const clients = [
    //     {
    //       ownerId: this.$store.getters.activeUser.user.id,
    //       email: this.$store.getters.activeUser.user.email,
    //       phoneNumber: this.userInfos.phoneNumber,
    //       name: this.userInfos.name,
    //       surname: this.userInfos.surname,
    //       tags: ['TEST']
    //     }
    //   ];

    //   this.$store
    //     .dispatch('createClients', {
    //       data: clients
    //     })
    //     .then(() => {
    //       this.loading = false;
    //       this.doneOnboarding();
    //     })
    //     .catch((err) => {
    //       const notif = this.$buefy.notification.open({
    //         duration: 5000,
    //         message: 'messagerreur',
    //         position: 'is-bottom-right',
    //         style:
    //           'background-color: #f1054d!important; color: white!important',
    //         hasIcon: true
    //       });
    //       this.loading = false;
    //     });
    // },
    // /**
    //  * @name doneOnboarding
    //  * @desc Completes the onboarding process and redirects to the welcome page.
    //  */
    // doneOnboarding() {
    //   this.$emit('save', this.userInfos);
    //   this.$router.push('/welcome').finally(() => {
    //     this.$router.go();
    //   });
    // },
    /**
     * @name addClient
     * @desc Adds a new client based on the form data provided during onboarding.
     */
    async addClient() {
      this.loading = true;
      this.trimEmptyLines();
      this.trimSpaces();
      this.trimPhones();
      if (this.anyPhoneEmpty() && this.phoneEmptyChecked == false) {
        this.loading = false;
        this.phoneEmpty = true;
        return;
      }

      let clients = [];
      let firstClient = new Object();
      firstClient = {
        ownerId: this.$store.getters.activeUser.user.id,
        business: this.client.business,
        email: this.client.email,
        phoneNumber: this.client.phone ? this.client.phone : '',
        name: this.client.name,
        surname: this.client.surname,
        tags: []
      };
      clients.push(firstClient);

      await this.$store
        .dispatch('createClients', {
          data: clients
        })
        .then(() => {
          // this.$store.dispatch('getClients').then(() => {
          //   console.log('result', this.$store.getters.clients);
          //   this.missionData = {
          //     interviewTemplate: null,
          //     type: 'invitation',
          //     clients: [this.$store.getters.clients[0]]
          //   };
          //   console.log('md', this.missionData);
          //   this.loading = false;
          //   this.step = 3;
          // });
        })
        .catch((err) => {
          const notif = this.$buefy.notification.open({
            duration: 5000,
            message: 'messagerreur',
            position: 'is-bottom-right',
            style:
              'background-color: #f1054d!important; color: white!important',
            hasIcon: true
          });
          this.loading = false;
        });
      this.loading = false;
      this.$emit('save', this.userInfos);
      this.$router.push('/directory?onboarding=true');
    },
    /**
     * @name save
     * @desc Saves the user information and updates the user status to completed registration.
     */
    save() {
      this.loading = true;
      fbq('track', 'CompleteRegistration');
      this.userInfos.color = this.userInfos.color.toString('hex');

      if (this.sponsorshipId) {
        console.log('sponsorshipId', this.sponsorshipId);
        console.log('userFirebaseId', this.userFirebaseId);
        this.$store.dispatch('acceptSponsorInvitation', {
          id: this.sponsorshipId,
          receiver: this.userFirebaseId
        });
      }
      this.$store
        .dispatch('updateCharlieUser', this.userInfos)
        .then(() => {
          console.log('updated User');
          this.loading = false;
          this.$buefy.notification.open({
            duration: 3000,
            message: this.$t('infoRegistered'),
            position: 'is-bottom-right',
            style:
              'background-color: #12c477!important; color: white!important',
            hasIcon: true
          });
          this.step = 2;
        })
        .catch((err) => {
          this.loading = false;
          this.$buefy.notification.open({
            duration: 3000,
            message: this.$t('error'),
            position: 'is-bottom-right',
            style:
              'background-color: #f1054d!important; color: white!important',
            hasIcon: true
          });
        });
    },
    /** @name updateColor
     *  @desc Updates the user's preferred color after validation.
     *  @param {$event} event - The color change event.
     */
    updateColor($event) {
      //console.log($event, $event.toString('hex'));
      if (/^#([A-Fa-f0-9]{6})$/.test($event.toString('hex'))) {
        console.log($event.toString('hex'));
        this.userInfos.color = $event.toString('hex');
      }
    },
    /** @name testColor
     *  @desc Tests if the provided color is valid.
     *  @param {$event} event - The color input event.
     */
    testColor($event) {
      console.log($event);
      if (/^#([A-Fa-f0-9]{6})$/.test($event)) {
        console.log($event);
        this.userInfos.color = $event;
      }
    },
    /** @name anyPhoneEmpty
     *  @desc Checks if any phone number field is empty.
     */
    anyPhoneEmpty() {
      return this.client.phone == '' || this.client.phone == null;
    },
    /** @name trimPhones
     *  @desc Trims spaces from phone numbers.
     */
    trimPhones() {
      this.client.phone = this.client.phone.replace(/\s/g, '');
    },
    /** @name trimSpaces
     *  @desc Trims spaces from client information fields.
     */
    trimSpaces() {
      this.client.email = this.client.email?.replace(/\s/g, '');
      this.client.surname = this.client.surname?.replace(/\s/g, '');
      this.client.name = this.client.name?.replace(/\s/g, '');
    },
    /** @name trimEmptyLines
     *  @desc Clears null values from client information fields.
     */
    trimEmptyLines() {
      if (this.client.email == null) {
        this.client.email = '';
      }
      if (this.client.surname == null) {
        this.client.surname = '';
      }
      if (this.client.name == null) {
        this.client.name = '';
      }
      if (this.client.phone == null) {
        this.client.phone = '';
      }
    },
    /** @name checkPhone
     *  @desc Validates the format of phone numbers.
     *  @returns {Boolean} - true if all phone numbers are valid.
     */
    checkPhone() {
      const phonePattern = /^\+33[0-9]{9}$/;
      return !(
        this.client.phone &&
        this.client.phone.length > 0 &&
        !phonePattern.test(this.client.phone)
      );
    }
  },

  computed: {
    /** @name validColor
     *  @desc Checks if the user's chosen color is a valid hex code.
     *  @returns {Boolean} - true if the color is valid.
     */
    validColor: function () {
      return /^#([A-Fa-f0-9]{6})$/.test(this.userInfos.color);
    }
  },
  watch: {
    client: {
      handler(val) {
        this.validClient =
          val.name &&
          val.surname &&
          val.email &&
          val.name !== '' &&
          val.surname !== '' &&
          val.email !== '' &&
          /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(val.email);
      },
      deep: true
    },
    userInfos: {
      handler(val) {
        this.updateColor(val.color);
        const pattern = /^$|^\S+.*/;
        this.valid =
          val.name &&
          val.surname &&
          val.business &&
          val.phoneNumber &&
          val.name !== '' &&
          val.surname !== '' &&
          val.business !== '' &&
          val.phoneNumber !== '' &&
          pattern.test(val.name) &&
          pattern.test(val.surname) &&
          pattern.test(val.business) &&
          pattern.test(val.phoneNumber) &&
          this.validColor;
      },
      deep: true
    }
  }
};
</script>

<style src="./../components/index.css"></style>

 