import { render, staticRenderFns } from "./selectModel.vue?vue&type=template&id=5dc6f14f&scoped=true"
import script from "./selectModel.vue?vue&type=script&lang=js"
export * from "./selectModel.vue?vue&type=script&lang=js"
import style0 from "./selectModel.vue?vue&type=style&index=0&id=5dc6f14f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5dc6f14f",
  null
  
)

export default component.exports