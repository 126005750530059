<template>
  <div>
    <div
      class="invoice-box"
      style="margin-top: 50px"
    >
      <table>
        <tr class="top">
          <td colspan="2">
            <table>
              <tr>
                <td class="title">
                  <img
                    src="../../public/img/charlie.png"
                    alt="Charlie"
                    style="width: 20%; max-width: 300px"
                  />
                </td>
                <td>
                  Facture #: 2004{{ id }}<br />
                  Date: {{ new Date(createdAt).toLocaleDateString() }}<br />
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr class="information">
          <td colspan="2">
            <table>
              <tr>
                <td>
                  Charlie<br />
                  SAS Getinshoot<br />
                  43 avenue du partage des eaux<br />
                  84800 L'Isle sur la Sorgue (France)
                </td>
                <td>
                  {{ $store.getters.activeUser.business }}<br />
                  {{ $store.getters.activeUser.surname }}
                  {{ $store.getters.activeUser.name }}<br />
                  {{ $store.getters.activeUser.email }}
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr
          class="details"
          style="background-color: #fbfcfb; font-weight: bold"
        >
          <td>Description</td>
          <td>Prix</td>
        </tr>
        <tr class="details">
          <td>{{ productName }}</td>
          <td>{{ productPriceHT / 100 }}€</td>
        </tr>
        <br />
        <tr
          v-if="productDiscount != 0"
          class="total"
        >
          <td></td>
          <td>Code promo: -{{ productDiscount / 100 }}€</td>
        </tr>
        <tr class="total">
          <td></td>
          <td>HT: {{ productPriceHT / 100 - productDiscount / 100 }}€</td>
        </tr>
        <tr class="total">
          <td></td>
          <td>TVA: {{ productTVA / 100 }}€</td>
        </tr>
        <tr class="total">
          <td></td>
          <td style="font-weight: bold">
            Total TTC: {{ (productPriceHT / 100 - productDiscount / 100) + productTVA / 100 }}€
          </td>
        </tr>
      </table>
      <br />
      <p style="font-size: 16px; text-align: center; color: green !important">
        Facture payée le {{ new Date(createdAt).toLocaleDateString() }} par CB
        avec stripe
      </p>
      <br />
      <p style="font-size: 10px; text-align: center">
        Dispensé d'immatriculation au Registre du Commerce et des Sociétés et au
        Répertoire des Métiers <br />
        GETINSHOOT - Siret : 853 464 436 00023 - APE : 6201Z - SAS au capital de
        8 525 € - N° TVA : FR41853464436
      </p>
    </div>
    <div style="max-width: 71%;margin-top: 25px;display: flex;justify-content: flex-end;">
      <button class="button-3d-revert noprint">
        <router-link
          to="/profile"
          style="color: black"
        > {{ $t('back') }} </router-link>
      </button>
      <button
        @click="print()"
        class="button-3d noprint"
      >télécharger</button>
    </div>
  </div>
</template>

<script>
/**
 * @name invoice
 * @desc Component for displaying an invoice
 */
export default {
  name: 'invoice',
  props: {
    /**
     * Unique identifier for the invoice
     * @type {Number}
     * @required
     */
    id: {
      type: Number,
      required: true
    },
    /**
     * Name of the product being invoiced
     * @type {String}
     * @required
     */
    productName: {
      type: String,
      required: true
    },
    /**
     * Price of the product before taxes (HT - Hors Taxes)
     * @type {Number}
     * @required
     */
    productPriceHT: {
      type: Number,
      required: true
    },
    /**
     * Creation date of the invoice
     * @type {String}
     * @required
     */
    createdAt: {
      type: String,
      required: true
    },
    /**
     * Value-added tax (TVA) on the product
     * @type {Number}
     * @required
     */
    productTVA: {
      type: Number,
      required: true
    },
    /**
     * Discount applied to the product price, if any
     * @type {Number}
     * @optional
     */
    productDiscount: {
      type: Number,
      required: false
    }
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    /**
     * @name print
     * @desc Method to print the invoice
     */
    print() {
      window.print();
    }
  },
  beforeMount() {}
};
</script>


<style src="./../components/index.css"></style>
