var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.playlist && _vm.playlist.medias)?_c('Builder',{ref:"builderRef",attrs:{"title":_vm.playlist?.title,"medias":_vm.playlist?.medias,"free":_vm.playlist?.free,"expiresAt":_vm.playlist?.expiresAt,"type":_vm.playlist?.type,"playlistId":_vm.playlist?.id,"wallInfos":{
      wallName: _vm.playlist?.wallName,
      wallDescription: _vm.playlist?.wallDescription,
      wallImageUrl: _vm.playlist?.wallImageUrl,
      wallWebsiteUrl: _vm.playlist?.wallWebsiteUrl,
      wallLinkedinUrl: _vm.playlist?.wallLinkedinUrl,
      wallYoutubeUrl: _vm.playlist?.wallYoutubeUrl,
      wallInstagramUrl: _vm.playlist?.wallInstagramUrl,
      wallFacebookUrl: _vm.playlist?.wallFacebookUrl,
      wallTiktokUrl: _vm.playlist?.wallTiktokUrl,
    }},on:{"save":function($event){return _vm.save($event)},"addDuration":function($event){_vm.showCreditsModal = true}}}):_vm._e(),_c('b-modal',{attrs:{"active":_vm.showCreditsModal},on:{"update:active":function($event){_vm.showCreditsModal=$event}}},[_c('ModalShop',{attrs:{"playlistType":_vm.playlist?.type,"savedDuration":_vm.playlist?.duration},on:{"validate":function($event){return _vm.validatePayment($event)},"saveToStorage":function($event){return _vm.saveToStorage($event)}}})],1),_c('b-modal',{staticStyle:{"background-color":"#1610321c"},attrs:{"active":_vm.showSuccessModal,"can-cancel":true},on:{"update:active":function($event){_vm.showSuccessModal=$event}}},[_c('div',{staticClass:"card box",staticStyle:{"background-color":"white","width":"100%","padding":"50px"}},[_c('span',{staticStyle:{"text-align":"center"}},[_c('h2',[_vm._v(_vm._s(_vm.$t('congrats'))+", "+_vm._s(_vm.playlist?.title)+" "+_vm._s(_vm.$t('codeCreation1'))+" 🎉")])]),(_vm.playlist?.type === 'wall')?_c('ModalLink',{attrs:{"playlist":_vm.playlist}}):_vm._e(),_c('br'),_c('ModalCode',{attrs:{"playlist":_vm.playlist}})],1)]),_c('b-loading',{attrs:{"active":!_vm.playlist || _vm.loading,"can-cancel":false}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }