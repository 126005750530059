<template>
  <section>
    <div
      class="tile is-parent is-vertical"
      style="margin: 0 auto; background-color: white !important"
    >
      <div>
        <p
          v-if="playlist.type == 'wall'"
          style="padding-bottom: 10px;"
        >{{ $t('installWall') }}</p>
        <p
          v-if="playlist.type == 'popup'"
          style="padding-bottom: 10px;"
        >{{ $t('installPopup') }}</p>
        <p
          v-if="playlist.type == 'slider'"
          style="padding-bottom: 10px;"
        >{{ $t('installSlider') }}</p>

        <div style="background-color: #F5F5F5; height: 80px;   justify-content: space-between;align-items: center; font-weight: 600;  display: flex;">
          <pre
            v-if="playlist.type == 'slider'"
            style="width: 80% !important;"
          ><code ref="diffusionCharlie">&#60;embed src="{{ diffusionUrl }}{{ playlist?.shortExternalId }}" style="width:100%; background-color:white;" height=350px /&#62;</code></pre>
          <pre v-else-if="playlist.type == 'popup'"><code ref="diffusionCharlie">&#60;script src="{{ rootDiffusionUrl }}/config.js"&#62;&#60;/script&#62;&#60;script src="{{ rootDiffusionUrl }}/tools.js"&#62;&#60;/script&#62;&#60;script src="{{ rootDiffusionUrl }}/popup/popup.js"&#62;&#60;/script&#62;&#60;script&#62;getPlaylist("{{ playlist.shortExternalId }}").then(data=>setupWidget(data))&#60;/script&#62;</code></pre>

          <pre
            v-else-if="playlist.type == 'wall'"
            style="width: 80% !important;"
          ><code ref="diffusionCharlie">&#60;embed src="{{ diffusionUrl }}{{ playlist?.shortExternalId }}" style="width:100%" height=1000px /&#62;</code></pre>
          <button
            style="background-color: #f0f0f0; width: 150px !important; color: #161032; border: 1px solid #e0e0e0; font-weight: bold; height: 100%"
            @click="copyScript"
          >{{ $t('copyCode') }}</button>
        </div>

      </div>
      <br />
      <br />
      <div v-if="$i18n.locale == 'fr'">
        <a
          target="_blank"
          href="https://www.yourcharlie.com/aide/comment-installer-le-wall-of-love/?lang=fr"
          v-if="playlist.type == 'wall'"
        >{{ $t('modalNeedHelp') }}</a>
        <a
          target="_blank"
          href="https://www.yourcharlie.com/aide/comment-installer-la-pop-up/?lang=fr"
          v-if="playlist.type == 'popup'"
        >{{
            $t('modalNeedHelp') }}</a>
        <a
          target="_blank"
          href="https://www.yourcharlie.com/aide/comment-installer-le-carousel/?lang=fr"
          v-if="playlist.type == 'slider'"
        >{{ $t('modalNeedHelp') }}</a>
      </div>
      <div v-if="$i18n.locale == 'en'">
        <a
          target="_blank"
          href="https://www.yourcharlie.com/help/how-to-install-the-wall-of-love/"
          v-if="playlist.type == 'wall'"
        >{{
            $t('modalNeedHelp') }}</a>
        <a
          target="_blank"
          href="https://www.yourcharlie.com/help/how-to-install-the-pop-up/"
          v-if="playlist.type == 'popup'"
        >{{
            $t('modalNeedHelp') }}</a>
        <a
          target="_blank"
          href="https://www.yourcharlie.com/help/how-to-install-the-carousel/"
          v-if="playlist.type == 'slider'"
        >{{
            $t('modalNeedHelp') }}</a>
      </div>
      <div v-if="$i18n.locale == 'es'">
        <a
          target="_blank"
          href="https://www.yourcharlie.com/ayuda/como-instalar-el-muro-del-amor/?lang=es"
          v-if="playlist.type == 'wall'"
        >{{ $t('modalNeedHelp') }}</a>
        <a
          target="_blank"
          href="https://www.yourcharlie.com/ayuda/como-se-instala-la-ventana-emergente/?lang=es"
          v-if="playlist.type == 'popup'"
        >{{ $t('modalNeedHelp') }}</a>
        <a
          target="_blank"
          href="https://www.yourcharlie.com/ayuda/como-se-instala-el-carrusel/?lang=es"
          v-if="playlist.type == 'slider'"
        >{{ $t('modalNeedHelp') }}</a>
      </div>

    </div>

  </section>
</template>
<script>
/**
 * @name modalCode
 * @desc Vue component for modal code display
 */
export default {
  /**
   * @name props
   * @type {Object}
   * @prop {Object} playlist - The playlist object
   */
  name: 'modalCode',
  props: {
    playlist: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      /**
       * @name diffusionUrl
       * @desc URL for diffusion
       * @type {string}
       */
      diffusionUrl:
        process.env.VUE_APP_SHARE_URL +
        '/' +
        this.$store.getters.activeUser.business +
        '-',
      /**
       * @name rootDiffusionUrl
       * @desc Root URL for diffusion
       * @type {string}
       */
      rootDiffusionUrl: process.env.VUE_APP_SHARE_URL
    };
  },
  methods: {
    /**
     * @name copyScript
     * @desc Copies the script content to clipboard
     */
    copyScript() {
      let convertScript = this.$refs.diffusionCharlie.innerHTML;
      convertScript = convertScript.replaceAll('&lt;', '<');
      convertScript = convertScript.replaceAll('&gt;', '>');
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(this.$refs.diffusionCharlie.innerHTML);
        return;
      }
      navigator.clipboard.writeText(convertScript).then(
        () => {
          this.$buefy.toast.open({
            message: this.$t('Copié'),
            type: 'is-success',
            position: 'is-bottom-right'
          });
        },
        (err) => {
          console.error('Async: Could not copy text: ', err);
        }
      );
    }
  }
};
</script>
<style src="../index.css"></style>
<style scoped>
pre {
  width: 80%;
}
</style>