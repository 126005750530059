<template>
  <Doughnut
    v-if=chartData
    id="my-chart-id"
    :options="chartOptions"
    :data="chartData"
  />
</template>

<script>
import { Doughnut } from 'vue-chartjs';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  LinearScale
} from 'chart.js';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  LinearScale
);

/**
 * @name customChart
 * @desc Custom chart component for displaying Doughnut chart
 */

export default {
  name: 'customChart',
  components: { Doughnut },
  props: {
    /**
     * Data for the chart
     * @type {Object}
     */
    chartData: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      /**
       * @desc Options for the chart
       * @type {Object}
       */
      chartOptions: {
        responsive: true
      }
    };
  }
};
</script>