<template>
  <div class="">
    <a
      style="color: #8c78d6 !important"
      href="#"
      @click="signOut()"
    ><b-icon
        icon="power-off"
        size="is-small"
        style="color: #8c78d6 !important"
      >
      </b-icon>
      {{ $t('logOut') }}</a>
  </div>
</template>

<script>
import { signOut } from 'firebase/auth';

/**
 * @name LogoutButton
 * @desc Component for handling user logout
 */
export default {
  data() {
    return {};
  },
  methods: {
    /**
     * @name signOut
     * @desc Signs the user out and redirects to the login page
     */
    signOut() {
      this.$store.dispatch('signOut').then(() => {
        this.$router.push('/login');
      });
    }
  }
};
</script>