<template>
  <section>
    <div
      class="box sliderSettings"
      style=""
    >

      <div style="display: flex; justify-content: space-between;">
        <h3 style="padding-top: 0px;">{{ $t('customize') }}</h3>
        <b-button
          type="is-dark"
          icon-right="close"
          size="is-small"
          @click="save()"
        />
      </div>

      <br />
      <b-field>
        <div
          v-if="infos.wallImageUrl || imageBlobUrl"
          style="position:relative;width: fit-content;margin: auto; height:125px; width: 125px;"
        >
          <img :src="imageBlobUrl ? imageBlobUrl : infos.wallImageUrl" />
          <b-button
            size="is-small"
            icon-left="trash"
            style="position:absolute; top:10px; right:10px;"
            @click="deleteImage()"
          >
          </b-button>
        </div>
        <b-upload
          style="height: 125px;"
          v-else
          v-model="infos.wallImageBlob"
          @input="addImage()"
          drag-drop
          expanded
        >
          <section class="section">
            <div class="content has-text-centered">
              <p>
                <b-icon
                  icon="upload"
                  size="is-large"
                >
                </b-icon>
              </p>
              <p>{{ $t('addImage') }}</p>
            </div>
          </section>
        </b-upload>
      </b-field>
      <input
        :placeholder="$t('sliderSettingsTitle')"
        class="inputD"
        maxlength="45"
        style="color: #161032; height: 30px; margin-bottom: 15px;"
        v-model="infos.wallName"
      />
      <textarea
        :placeholder="$t('sliderSettingsDesc')"
        class="inputD"
        style="color: #161032; height: 150px; margin-bottom: 15px; overflow-y: scroll;"
        maxlength="200"
        type="textarea"
        v-model="infos.wallDescription"
      />
      <input
        :placeholder="$t('sliderSettingsWebsite')"
        class="inputD"
        style="color: #161032; height: 30px; margin-bottom: 15px;"
        v-model="infos.wallWebsiteUrl"
      />
      <div
        class="tile"
        style="align-items: center; gap: 12px;"
      ><img
          src="../../../public/img/linkedin_noir.png"
          style="width: 30px;"
          alt="authentic video by charlie"
        />
        <input
          class="inputD"
          style="color: #161032; height: 30px;"
          v-model="infos.wallLinkedinUrl"
        />
      </div>
      <br />
      <div
        class="tile"
        style="align-items: center; gap: 12px;"
      ><img
          src="../../../public/img/youtube_noir.png"
          style="width: 30px;"
          alt="authentic video by charlie"
        />
        <input
          class="inputD"
          style="color: #161032; height: 30px;"
          v-model="infos.wallYoutubeUrl"
        />
      </div>
      <br />
      <div
        class="tile"
        style="align-items: center; gap: 12px;"
      ><img
          src="../../../public/img/instagram_noir.png"
          style="width: 30px;"
          alt="authentic video by charlie"
        />
        <input
          class="inputD"
          style="color: #161032; height: 30px;"
          v-model="infos.wallInstagramUrl"
        />
      </div>
      <br />
      <div
        class="tile"
        style="align-items: center; gap: 12px;"
      ><img
          src="../../../public/img/tiktok_noir.png"
          style="width: 30px;"
          alt="authentic video by charlie"
        />
        <input
          class="inputD"
          style="color: #161032; height: 30px;"
          v-model="infos.wallTiktokUrl"
        />
      </div>
      <br />
      <div
        class="tile"
        style="align-items: center; gap: 12px;"
      ><img
          src="../../../public/img/facebook_noir.png"
          style="width: 30px;"
          alt="authentic video by charlie"
        />
        <input
          class="inputD"
          style="color: #161032; height: 30px;"
          v-model="infos.wallFacebookUrl"
        />
      </div>
      <br />

    </div>

  </section>
</template>
<script>
/**
 * @name sliderSettings
 * @desc Component for managing slider settings
 */
export default {
  /**
   * @type {string}
   * @desc Name of the component
   */
  name: 'sliderSettings',
  /**
   * @type {Object}
   * @desc Props for the component
   */
  props: {
    infos: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      /**
       * @type {string}
       * @desc URL of the image blob
       */
      imageBlobUrl: null
    };
  },
  methods: {
    /**
     * @name save
     * @desc Saves the data and emits a save event
     */
    save() {
      console.log('save', this.infos);
      this.$emit('save', this.infos);
    },
    /**
     * @name deleteImage
     * @desc Deletes the image
     */
    deleteImage() {
      this.infos.wallImageBlob = null;
      this.imageBlobUrl = null;
      this.infos.wallImageUrl = null;
      this.$forceUpdate();
    },
    /**
     * @name addImage
     * @desc Adds an image
     */
    addImage() {
      this.imageBlobUrl = URL.createObjectURL(this.infos.wallImageBlob);
    }
  }
};
</script>
            
<style src="./../../components/index.css"></style>

<style scoped>
.sliderSettings {
  width: 31%;
  z-index: 1;
  height: 802px;
  margin-top: 162px;
  position: absolute;
  left: 0;
  background-color: white;
  padding-right: 25px;
  padding-left: 25px;
  padding-bottom: 25px;
}

@media all and (max-width: 1400) {
  .sliderSettings {
    overflow: scroll;
    height: 602px !important;
  }
}
</style>