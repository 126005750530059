<template>
  <Bar
    id="my-chart-id"
    v-if=chartData
    :options="chartOptions"
    :data="chartData"
  />
</template>

<script>
import { Bar } from 'vue-chartjs';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

/**
 * @name BarChart
 * @desc Component for displaying a bar chart using Vue Chart.js
 * @component
 */
export default {
  name: 'BarChart',
  components: { Bar },
  props: {
    /**
     * Data for the bar chart
     * @type {Object}
     */
    chartData: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      /**
       * @desc Options for the bar chart
       * @type {Object}
       */
      chartOptions: {
        indexAxis: 'y',
        responsive: true
      }
    };
  }
};
</script>