<template>
  <div>
    <br />
    <div class="tile">
      <div
        class="tile is-7 is-vertical"
        style="padding-right: 25px; "
      >
        <h1>{{ $t('becomeAffiliate') }}</h1>
        <br />
        <div style="background-color: #d9dd1b; padding: 50px; font-size: 18px;">
          <p>{{ $t('affiliateDesc1') }}<b>{{ $t('affiliateDesc2') }}</b>&nbsp;{{ $t('affiliateDesc3') }}
            {{ $t('affiliateDesc4') }}<b>{{ $t('affiliateDesc5') }}</b>&nbsp;{{ $t('affiliateDesc6') }}
            <br /><br /> <a
              href="https://www.yourcharlie.com/programme-daffiliation/?lang=fr"
              target="_blank"
            >
              <button class="button-3d-small">
                {{ $t('learnMore') }}
              </button>
            </a>
          </p>
        </div>
        <br />
      </div>
      <div
        class="tile is-5 is-vertical"
        style="justify-content: flex-end; display: flex;
    align-items: flex-end;"
      >
        <iframe
          width="380px"
          height="380px"
          :src="$t('urlVideoAffiliation')"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;"
          allowfullscreen
        ></iframe>
      </div>
    </div>
    <hr style="margin-top:50px; margin-bottom: 50px;" />
    <div
      class="tile"
      style="gap:35px"
    >
      <div
        class="tile box is-vertical"
        style="    display: flex;
    align-items: center; height:300px"
      >
        <span style="font-size: 50px;">1️⃣ </span>
        <h3 style="text-align: center;">{{ $t('affiliateAccount') }}<br />{{ $t('affiliateAccount2') }}</h3>
        <br />
        <p style="text-align: center; height: 100px;">{{ $t('affiliateAccountDesc1') }}
        </p>
        <br />
      </div>
      <div
        class="tile box is-vertical"
        style="    display: flex;
    align-items: center; height:300px"
      >
        <span style="font-size: 50px;">2️⃣</span>
        <h3 style="text-align: center;">{{ $t('affiliateLink1') }}<br />{{ $t('affiliateLink2') }}</h3>
        <br />
        <p style="text-align: center; height: 100px;">{{ $t('affiliateAccountDesc2') }}
        </p>
        <br />
      </div>
      <div
        class="tile box is-vertical"
        style="    display: flex;
    align-items: center; height:300px"
      >
        <span style="font-size: 50px;">3️⃣</span>
        <h3 style="text-align: center;">{{ $t('affiliateReward1') }}<br />{{ $t('affiliateReward2') }}</h3>
        <br />
        <p style="text-align: center; height: 100px;">{{ $t('affiliateAccountDesc3') }}
        </p>
        <br />
      </div>
    </div>
    <br />
    <div
      class="tile"
      style="justify-content: center;"
    ><a
        href="https://yourcharlie.firstpromoter.com/"
        target="_blank"
      >
        <button
          class="button-3d"
          style="width:400px"
        >
          {{ $t('becomeAffiliateSmall') }}
        </button>
      </a></div>
  </div>
</template>

<script>
/**
 * @name Affiliation
 * @desc Vue component for managing affiliate information and interactions.
 */
export default {
  name: 'affiliation',
  components: {}
};
</script>


<style src="./../components/index.css"></style>
