<template>
  <div
    class="card invitation_container is-ancestor"
    style="border-radius:0px !important"
  >
    <div
      class="headerInvitation"
      v-if="!interviewTemplate"
      :class="interviewTemplate ? 'disabled' : ''"
      style="position: fixed;"
    >
      <div style="background-color: #161032; height: 65px;padding:10px; display: flex; align-items: center;">
        <h1 style="font-size: 22px !important; color: white !important;padding:20px;">{{ $t('newTestimony') }}</h1>
        <div>
          <b-dropdown
            v-model="locale"
            aria-role="list"
          >

            <template
              v-if="this.locale === 'fr'"
              #trigger="{ active }"
            >
              <b-button
                label="🇫🇷 Français"
                size="is-small"
                style="background-color: #161032; color: white; border: 0px solid"
                :icon-right="active ? 'caret-up' : 'caret-down'"
              ></b-button>
            </template>

            <template
              v-else-if="this.locale === 'es'"
              #trigger="{ active }"
            >
              <b-button
                label="🇪🇸 Español"
                size="is-small"
                style="background-color: #161032; color: white; border: 0px solid"
                :icon-right="active ? 'caret-up' : 'caret-down'"
              ></b-button>
            </template>

            <template
              v-else-if="this.locale === 'it'"
              #trigger="{ active }"
            >
              <b-button
                label="🇮🇹 Italiano"
                size="is-small"
                style="background-color: #161032; color: white; border: 0px solid"
                :icon-right="active ? 'caret-up' : 'caret-down'"
              ></b-button>
            </template>

            <template
              v-else
              #trigger="{ active }"
            >
              <b-button
                label="🇬🇧 English"
                style="background-color: #161032; color: white; border: 0px solid"
                size="is-small"
                :icon-right="active ? 'caret-up' : 'caret-down'"
              />
            </template>

            <b-dropdown-item
              value="fr"
              aria-role="listitem"
            >
              <div class="media">
                <div class="media-content">
                  <small>🇫🇷 Français</small>
                </div>
              </div>
            </b-dropdown-item>

            <b-dropdown-item
              value="en"
              aria-role="listitem"
            >
              <div class="media">
                <div class="media-content">
                  <small>🇬🇧 English</small>
                </div>
              </div>
            </b-dropdown-item>

            <b-dropdown-item
              value="es"
              aria-role="listitem"
            >
              <div class="media">
                <div class="media-content">
                  <small>🇪🇸 Español</small>
                </div>
              </div>
            </b-dropdown-item>

            <b-dropdown-item
              value="it"
              aria-role="listitem"
            >
              <div class="media">
                <div class="media-content">
                  <small>🇮🇹 Italiano</small>
                </div>
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </div>

      </div>

      <div
        class="tile is-parent"
        style="background-color: #f6f6f6;height: 45px;padding:10px; display: flex; border-bottom: 2px solid #f0f0f0; align-items: center;"
      >

        <div
          class="tile is-child is-3"
          style="display:flex; padding: 20px; align-items: center;"
        >
          <p style="color: #828282 !important; font-size: 14px; ">{{ $t('from') }}</p>&nbsp;
        </div>
        <div class="tile is-child">
          <input
            class="input"
            type="text"
            v-model="senderName"
          />

        </div>

      </div>
      <div
        class="
          tile
          is-parent"
        style="background-color: #f6f6f6;height: 45px;padding:10px; display: flex; border-bottom: 2px solid #f0f0f0; align-items: center;"
      >

        <div
          class="tile is-child is-3"
          style="display:flex; padding: 20px !important; align-items: center;"
        >
          <p style="color: #828282 !important; font-size: 14px;  ">{{ $t('destination') }}</p>&nbsp;
        </div>
        <div class="tile is-child">
          <div
            class="tile is-child"
            v-if="clients"
          >

            <b-tag
              rounded
              v-for=" client  of  clients "
              :key="client.id"
              style="border: 2px solid #f4F4F4; background-color: white !important"
            > <b-icon
                pack="fas"
                icon="circle-user"
                size="is-small"
                style="height: 18px; float: left; "
              >
              </b-icon>&nbsp;{{ client.user.surname }} {{ client.user.name }} <span style="font-size:10px">
                ({{ client.user.email }})
              </span></b-tag>
          </div>
        </div>

      </div>
    </div>

    <div
      class="headerInvitation"
      v-else
      style="position: fixed;"
    >
      <div style="background-color: #161032; height: 65px;padding:10px; display: flex; align-items: center;">
        <h1 style="font-size: 22px !important; color: white !important;padding:20px;">{{ $t('customMessage') }}</h1>

      </div>

      <div
        class="tile is-parent"
        style="background-color: #f6f6f6;height: 45px;padding:10px; display: flex; border-bottom: 2px solid #f0f0f0; align-items: center;"
      >

        <div
          class="tile is-child is-3"
          style="display:flex; padding: 20px; align-items: center;"
        >
          <p style="color: #828282 !important; font-size: 14px; ">{{ $t('from') }}</p>&nbsp;
        </div>
        <div class="tile is-child">
          <input
            class="input"
            type="text"
            v-model="senderName"
          />

        </div>

      </div>
      <div
        class="
          tile
          is-parent"
        style="background-color: #f6f6f6;height: 45px;padding:10px; display: flex; border-bottom: 2px solid #f0f0f0; align-items: center;"
      >

        <div
          class="tile is-child is-3"
          style="display:flex; padding: 20px; align-items: center;"
        >
          <p style="color: #828282 !important; font-size: 14px;  ">{{ $t('destination') }}</p>&nbsp;
        </div>
        <div class="tile is-child">
          <div
            class="tile is-child"
            v-if="clients"
          >

            <b-tag
              rounded
              v-for=" client  of  clients "
              :key="client.id"
              style="border: 2px solid #f4F4F4; background-color: white !important"
            > <b-icon
                pack="fas"
                icon="circle-user"
                size="is-small"
                style="height: 18px; float: left; "
              >
              </b-icon>&nbsp;{{ client.user.surname }} {{ client.user.name }} <span style="font-size:10px">
                ({{ client.user.email }})
              </span></b-tag>
          </div>
        </div>

      </div>
    </div>

    <div
      :class="interviewTemplate ? 'disabled' : ''"
      v-if="!interviewTemplate"
      class="interieur"
    >

      <div style="padding-left: 30px; padding-right: 30px; padding-bottom: 20px; margin-top: 170px;">
        <p style="font-size:16px;font-weight: 500;color:#161032; float: left;">{{ $t('ask') }}</p>
        <b-tooltip
          position="is-right"
          multilined
          type="is-primary is-light"
        > <template v-slot:content>
            {{ $t('tooltip_questions') }}
          </template>
          <img
            src="/img/sos.png"
            class="sizeSos"
          />
        </b-tooltip>
        <br />
        <div class="tile is-parent">
          <QuestionList
            :questions.sync="questions"
            :show-handle="true"
            :show-delete="true"
            :show-add="true"
            v-on:questionChange="changedQuestions = true"
          />
        </div>

      </div>

      <div
        class="container-addVideo"
        style="padding-left: 30px; padding-right: 30px; padding-top: 15px;  "
      >
        <p style="font-size:16px;font-weight: 500;color:#161032; float: left;">{{ $t('homeVideo') }}</p>
        <b-tooltip
          multilined
          position="is-right"
          type="is-primary is-light"
        >
          <template v-slot:content>
            {{ $t('tooltip_video') }}<br />
            {{ $t('tooltip_video2') }}
          </template>
          <img
            src="/img/sos.png"
            class="sizeSos"
          />
        </b-tooltip>
        <div
          class="tile is-child"
          style="padding-top: 0px !important"
        >
          <div
            v-if="!media"
            class="tile is-child"
          >
            <div>
              <b-button
                @click="showRecordModal = true"
                style="font-size:14px; height: 45px !important; background-color: #fbfcfb; text-align: center;     "
                class="button"
                expanded
              >
                <div><img
                    src="../../public/img/videorec2.png"
                    style=" height: 30px; float: left;"
                  />
                  <p style="line-height: 30px; margin-left:50px"> {{ $t('videoRecord') }}</p>
                </div>
              </b-button>
              <div style="margin-top: 5px; font-size:14px; height: 45px !important; background-color: #fbfcfb; text-align: center; align-items: center;   ">
                <UploaderMission v-on:uploadedMedia="saveMedia" />
              </div>
            </div>
          </div>
          <div v-else>
            <div style=" margin: auto;width: 300px !important;height: 300px !important;overflow: hidden; ">
              <video
                ref="recorderVideoMission"
                style="height: 300px !important; width: 300px !important"
                playsinline
                :controls="true"
                :loop="true"
              ></video>
            </div>
            <br />
            <b-button
              @click="deleteMedia()"
              size="is-small"
            >
              <b-icon
                pack="fas"
                icon="trash-alt"
                size="is-small"
                style="height: 14px"
              >
              </b-icon>&nbsp; &nbsp;{{ $t('delete') }}
            </b-button>
          </div>
        </div>
        <br />

        <div>
          <p style="font-size:16px;font-weight: 500;color:#161032; float: left;">{{ $t('addIncentive') }}</p>
        </div>
        <div
          class="tile is-child"
          style="padding-top: 0px !important; height: 120px;"
        ><b-tooltip
            multilined
            position="is-right"
            type="is-primary is-light"
          >
            <template v-slot:content>
              {{ $t('tooltip_reward') }}
              {{ $t('tooltip_reward2') }}
            </template>
            <img
              src="/img/sos.png"
              class="sizeSos"
            />
          </b-tooltip>
          <br />
          <div>
            <input
              class="inputD"
              :placeholder="$t('addRewardInput')"
              style="font-size: 12px;"
              maxlength="100"
              v-model="customMessage"
            />
          </div>
        </div>

        <br />
      </div>

    </div>

    <SelectModel
      v-else
      class="interieur"
      :interviewTemplate="interviewTemplate"
      :type="type"
      @update="onMessageChanged"
      :onBehalf="onBehalf"
    >
    </SelectModel>

    <div class="footerInvitation">

      <div
        class="tile is-parent"
        style="display: flex;justify-content: center; align-items: center;position: relative"
        v-if="interviewTemplate"
      >

        <div class="footer_contact-display">
          <span class="footer_font">{{ $t('sendWith') }}
            &nbsp;&nbsp;</span>
          <b-button
            size="is-small;"
            style=" font-size: 14px; background-color: white; color: black; margin-right:10px"
            @click.prevent="share(gmail)"
          >
            <img
              src="../../public/img/gmail.png"
              alt="Charlie"
              style="width: 20px; margin-right: 5px; float:left ; "
            />&nbsp;gmail
          </b-button>
          <b-button
            size="is-small;"
            style="font-size: 14px; background-color:white;  margin-right:10px"
            @click.prevent="share(mail)"
          >
            <img
              src="../../public/img/email.png"
              alt="Charlie"
              style="width: 20px; margin-right: 5px; float:left ; "
            />&nbsp;email
          </b-button>
          <b-button
            size="is-small;"
            style="font-size: 14px; background-color:white;  "
            @click.prevent="share(whatsapp)"
          >
            <img
              src="../../public/img/whatsapp.png"
              alt="Charlie"
              style="width: 20px;margin-right: 5px; float:left  "
            />&nbsp;whatsapp
          </b-button>
        </div>
      </div>

      <div
        style="display: flex; justify-content: flex-end;"
        class="tile is-parent"
        v-else
      >
        <div>

          <button
            class="button-3d-small"
            style="width: 300px !important; font-weight:bold"
            @click=createInterviewTemplate(false)
            size="is-small;"
          >
            {{ $t('next') }}
          </button>
        </div>
      </div>

      <!-- BOUTON D'ENVOI AVEC CHARLIE
        <div
        class="tile is-parent is-vertical"
        style="display: flex;
    flex-direction: row;
    justify-content: space-around; border-left: 1px dashed #6a5ab5 !important "
      ><button
          class="button-3d-revert "
          style="width: 250px; "
          @click=createInterviewTemplate(true)
        >
          <b-icon
            pack="fas"
            icon="paper-plane"
            size="is-small"
          >
          </b-icon>
          envoyer avec <span style="font-family: 'Knewave', sans-serif; font-size: 18px">charlie</span>
        </button></div> -->

    </div>

    <b-modal
      :active.sync="showRecordModal"
      :has-modal-card="true"
    >
      <div
        class="card box"
        style="background-color: white !important"
      >
        <!-- <CharlieRecorderMission v-on:recordedMedia="saveMedia" /> -->
        <ultimaterecorder-gis
          style="margin: auto"
          :locale="$i18n.locale"
          @recorded="saveRecordedMedia($event)"
        ></ultimaterecorder-gis>
      </div>
    </b-modal>
    <b-modal
      :active.sync="showUploadModal"
      :has-modal-card="true"
    >
      <div style="background-color: white !important">
        <UploaderMission v-on:uploadedMedia="saveMedia" />
      </div>
    </b-modal>
    <b-loading
      :is-full-page="true"
      :active.sync="loading"
      :can-cancel="false"
    ></b-loading>
  </div>
</template>

<script>
import SelectModel from '../components/selectModel.vue';
import QuestionList from '../components/questionList.vue';
import UploaderMission from '../components/uploaderMission.vue';

import 'ultimaterecorder';

/**
 * @name InvitationCustom
 * @desc Vue component for managing custom invitations
 */
export default {
  components: {
    QuestionList,
    UploaderMission,
    SelectModel
  },
  props: {
    clients: {
      type: Array,
      default: () => []
    },
    interviewTemplate: {
      type: Object,
      default: () => null
    },
    type: {
      type: String,
      default: 'invitation'
    },
    onBehalf: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      /**
       * @type {object}
       * @desc Data related to the custom invitation
       */
      locale: this.$i18n.locale,
      /**
       * @type {boolean}
       * @desc Flag indicating formality
       */
      formality: true,
      /**
       * @type {string}
       * @desc Full name of the sender
       */
      senderName:
        this.$store.getters.activeUser.surname +
        ' ' +
        this.$store.getters.activeUser.name,
      /**
       * @type {array}
       * @desc List of questions
       */
      questions: [],
      /**
       * @type {boolean}
       * @desc Flag indicating if questions have been changed
       */
      changedQuestions: false,
      /**
       * @type {string}
       * @desc Custom message for the invitation
       */
      customMessage: '',
      /**
       * @type {boolean}
       * @desc Flag indicating if the record modal is shown
       */
      showRecordModal: false,
      /**
       * @type {boolean}
       * @desc Flag indicating if the upload modal is shown
       */
      showUploadModal: false,
      /**
       * @type {object}
       * @desc Data related to the message
       */
      messageData: {},
      /**
       * @type {boolean}
       * @desc Flag indicating if loading is in progress
       */
      loading: false,
      /**
       * @type {null}
       * @desc Media content associated with the invitation
       */
      media: null,
      /**
       * @type {object}
       * @desc Providers for different messaging services
       */
      providers: {
        mail: 'mailto::to?subject=:subject&body=:text',
        gmail:
          'https://mail.google.com/mail/?view=cm&fs=1&to=:to&su=:subject&body=:text',
        whatsapp: 'https://api.whatsapp.com/send?phone=:phone&text=:text'
      }
    };
  },
  mounted() {
    this.updateQuestions();
  },

  computed: {
    /**
     * @name emailSubject
     * Computed property for email subject based on locale
     */
    emailSubject() {
      if (this.locale === 'fr') {
        return 'Demande de témoignage';
      } else if (this.locale === 'en') {
        return 'Request for feedback';
      }
    },
    /**
     * @name whatsapp
     * Computed property for generating WhatsApp message
     */
    whatsapp() {
      const contentWithLineBreaks = this.messageData.content.replace(
        /<br\/>/g,
        '\n'
      );
      const encodedText = encodeURIComponent(contentWithLineBreaks);
      let encodedPhone = '';
      if (this.clients[0].user.phoneNumber) {
        encodedPhone = encodeURIComponent(this.clients[0].user.phoneNumber);
      }
      return this.providers.whatsapp
        .replace(':phone', encodedPhone)
        .replace(':text', encodedText);
    },

    /**
     * @name gmail
     * Computed property for generating Gmail message
     */
    gmail() {
      const encodedSubject = encodeURIComponent(this.messageData.subject);
      const contentWithLineBreaks = this.messageData.content.replace(
        /<br\/>/g,
        '\n'
      );
      const encodedText = encodeURIComponent(contentWithLineBreaks);
      const encodedTo = encodeURIComponent(this.clients[0].user.email);
      return this.providers.gmail
        .replace(':to', encodedTo)
        .replace(':subject', encodedSubject)
        .replace(':text', encodedText);
    },
    /**
     * @name mail
     * Computed property for generating mail message
     */
    mail() {
      const encodedSubject = encodeURIComponent(this.messageData.subject);
      const contentWithLineBreaks = this.messageData.content.replace(
        /<br\/>/g,
        '\n'
      );
      const encodedText = encodeURIComponent(contentWithLineBreaks);
      const encodedTo = encodeURIComponent(this.clients[0].user.email);
      return this.providers.mail
        .replace(':to', encodedTo)
        .replace(':subject', encodedSubject)
        .replace(':text', encodedText);
    }
  },

  methods: {
    /**
     * @name onFormalityChange
     * @desc Handles the change in formality value
     * @param {string} newValue - The new formality value
     */
    onFormalityChange(newValue) {
      this.formality = newValue === 'true';
      this.updateQuestions();
    },

    /**
     * @name onMessageChanged
     * @desc Handles the change in message data
     * @param {any} newValue - The new message data
     */
    onMessageChanged(newValue) {
      console.log('message changed', newValue);
      this.messageData = newValue;
    },

    /**
     * @name share
     * @desc Shares content using a provided URL
     * @param {string} url - The URL for sharing
     * @returns {boolean} - True if sharing is successful
     */
    async share(url) {
      const encodedSubject = encodeURIComponent(this.messageData.subject);
      const encodedText = encodeURIComponent(this.messageData.content);
      const shareUrl = url
        .replace(':subject', encodedSubject)
        .replace(':text', encodedText);
      window.open(
        shareUrl,
        'sharer',
        'toolbar=0,status=0,width=700,height=700'
      );
      return true;
    },

    /**
     * @name saveRecordedMedia
     * @desc Saves event media content
     * @param {any} event - The event with content to save
     */
    saveRecordedMedia(event) {
      this.media = event.detail;
      this.showRecordModal = false;
    },
    /**
     * @name saveMedia
     * @desc Saves media content
     * @param {any} media - The media content to save
     */
    saveMedia(media) {
      this.media = media;
      this.showRecordModal = false;
      this.showUploadModal = false;
    },

    /**
     * @name deleteMedia
     * @desc Deletes media content
     */
    deleteMedia() {
      this.media = null;
    },

    /**
     * @name updateQuestions
     * @desc Updates the list of questions based on locale and formality
     */
    updateQuestions() {
      if (this.changedQuestions) {
        return;
      }
      const savedQuestions = localStorage.getItem(
        'questions-' + this.locale + '-' + !!this.formality
      );
      if (savedQuestions && savedQuestions != '') {
        this.questions = JSON.parse(savedQuestions);
        return;
      }
      if (this.locale == 'fr') {
        this.questions = this.formality
          ? [
              {
                text: `Quel était votre problème avant de faire appel à ${this.$store.getters.activeUser.business} ?`,
                index: 0
              },
              {
                text: `À quel point ce problème était douloureux pour vous ?`,
                index: 1
              },
              {
                text: `En quoi ${this.$store.getters.activeUser.business} a résolu votre problème ?`,
                index: 2
              },
              {
                text: `Qu’est-ce qui vous a décidé à travailler avec ${this.$store.getters.activeUser.business} ?`,
                index: 3
              },
              {
                text: `Quels ont été vos résultats ?`,
                index: 4
              }
            ]
          : [
              {
                text: `C’était quoi ton problème avant de faire appel à ${this.$store.getters.activeUser.business}?`,
                index: 0
              },
              {
                text: `À quel point ce problème était douloureux pour toi ?`,
                index: 1
              },
              {
                text: `En quoi ${this.$store.getters.activeUser.business} a résolu ton problème ?`,
                index: 2
              },
              {
                text: `Qu’est-ce qui t'a décidé à travailler avec ${this.$store.getters.activeUser.business} ?`,
                index: 3
              },
              {
                text: `Quels ont été tes résultats ?`,
                index: 4
              }
            ];
      }

      if (this.locale == 'en') {
        this.questions = [
          {
            text: `What was your problem before working with ${this.$store.getters.activeUser.business}?`,
            index: 0
          },
          {
            text: `How painful this problem was for you ?`,
            index: 1
          },
          {
            text: `How ${this.$store.getters.activeUser.business} solved your problem ?`,
            index: 2
          },
          {
            text: `What made you decide to work with ${this.$store.getters.activeUser.business} ?`,
            index: 3
          },
          {
            text: `What are your results?`,
            index: 4
          }
        ];
      }

      if (this.locale == 'es') {
        this.questions = [
          {
            text: `¿Cuál era tu problema antes de trabajar con ${this.$store.getters.activeUser.business}?`,
            index: 0
          },
          {
            text: `¿Qué tan doloroso fue este problema para ti?`,
            index: 1
          },
          {
            text: `¿Cómo ${this.$store.getters.activeUser.business} resolvió tu problema?`,
            index: 2
          },
          {
            text: `¿Qué te hizo decidir trabajar con ${this.$store.getters.activeUser.business}?`,
            index: 3
          },
          {
            text: `¿Cuáles son tus resultados?`,
            index: 4
          }
        ];
      }
    },
    /**
     * @name createInterviewTemplate
     * @desc Creates an interview template and starts it
     * @param {boolean} withSequence - Flag indicating if a sequence is included
     * @param {string} shareUrl - Optional share URL
     */
    async createInterviewTemplate(withSequence, shareUrl = undefined) {
      this.loading = true;
      if (this.changedQuestions) {
        localStorage.setItem(
          'questions-' + this.locale + '-' + !!this.formality,
          JSON.stringify(this.questions)
        );
      }
      let result;
      if (!this.onBehalf) {
        result = await this.$store
          .dispatch('createInterviewTemplate', {
            clients: this.clients,
            questions: this.questions
              .sort((a, b) => a.index - b.index)
              .map((question) => question.text),
            customMessage: this.customMessage,
            senderName: this.senderName,
            name: 'Mission' + new Date().getTime(),
            locale: this.locale,
            formality: this.formality
          })
          .catch((err) => {
            const notif = this.$buefy.notification.open({
              duration: 5000,
              message: this.$t('errorSavingMission'),
              position: 'is-bottom-right',
              style:
                'background-color: #f1054d!important; color: white!important',
              hasIcon: true
            });
            this.loading = false;
            this.disableButton = false;
          });
      } else {
        result = await this.$store
          .dispatch('createAdminInterviewTemplates', {
            ownerUserId: this.onBehalf.user.id,
            data: {
              clients: this.clients,
              questions: this.questions
                .sort((a, b) => a.index - b.index)
                .map((question) => question.text),
              customMessage: this.customMessage,
              senderName: this.senderName,
              name: 'Mission' + new Date().getTime(),
              locale: this.locale,
              formality: this.formality
            }
          })
          .catch((err) => {
            const notif = this.$buefy.notification.open({
              duration: 5000,
              message: this.$t('errorSavingMission'),
              position: 'is-bottom-right',
              style:
                'background-color: #f1054d!important; color: white!important',
              hasIcon: true
            });
            this.loading = false;
            this.disableButton = false;
          });
      }
      console.log(result);
      if (result) {
        this.$store
          .dispatch(
            !this.onBehalf
              ? 'startInterviewTemplate'
              : 'startAdminInterviewTemplates',
            {
              id: result.data.id,
              blob: this.media?.blob,
              filename: this.media?.filename,
              duration: this.media?.duration,
              withSequence: withSequence
            }
          )
          .then(() => {
            this.loading = false;
            this.$buefy.notification.open({
              duration: 5000,
              message: this.$t('launchSuccess'),
              position: 'is-bottom-right',
              style:
                'background-color: #12c477!important; color: white!important',
              hasIcon: true
            });
            this.interviewTemplate = result.data;
            this.$emit('updateCurrentMissionData', this.interviewTemplate);
          })
          .catch((err) => {
            this.loading = false;
            this.$buefy.notification.open({
              duration: 5000,
              message: this.$t('missionError'),
              position: 'is-bottom-right',
              style:
                'background-color: #f1054d!important; color: white!important',
              hasIcon: true
            });
          });
        if (!this.onBehalf) {
          fbq('track', 'Lead');
        }
      } else {
        this.loading = false;
      }
    },

    /**
     * @name copyInterviewUrl
     * @desc Copies the interview URL to the clipboard
     */
    async copyInterviewUrl() {
      let longUrl =
        process.env.VUE_APP_SELF_URL +
        '/charlie/' +
        this.interviewTemplate.interviews[0].externalId;
      const url = await this.$store.dispatch('getShortLink', { url: longUrl });
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(this.$refs.widgetCharlie.innerHTML);
        this.$emit('started');
        return;
      }
      navigator.clipboard.writeText(url).then(
        () => {
          this.$buefy.toast.open({
            message: this.$t('Lien copié dans le presse-papier !'),
            type: 'is-success',
            position: 'is-bottom-right'
          });
          this.$emit('started');
        },
        (err) => {
          console.error('Async: Could not copy text: ', err);
        }
      );
    }
  },

  /**
   * @name watch
   * @desc Watches for changes in locale, formality, and media to update questions and handle media content
   */
  watch: {
    /**
     * @name locale
     * @desc Watches for changes in locale and updates questions accordingly
     * @param {string} val - The new locale value
     */
    locale: function (val) {
      this.updateQuestions();
    },

    /**
     * @name formality
     * @desc Watches for changes in formality and updates questions accordingly
     */
    formality() {
      this.updateQuestions();
    },

    /**
     * @name media
     * @desc Watches for changes in media content and updates the video element accordingly
     * @param {any} newVal - The new media content
     */
    media: function (newVal) {
      if (newVal) {
        setTimeout(async () => {
          const videoEl = this.$refs.recorderVideoMission;

          // Set the new URL
          let src = newVal.blob;

          if (!newVal.blob.type || newVal.blob.type == '') {
            const buf = await newVal.blob.arrayBuffer();
            src = new Blob([buf], { type: 'video/mp4' });
          } else {
            videoEl.style =
              'margin: auto; width: 300px !important; height: 300px !important; object-fit: cover !important';
            videoEl.classList.add('squareVideoPlayer');
          }
          videoEl.src = URL.createObjectURL(src);

          // Load the video
          videoEl.load();
        }, 500);
      }
    }
  }
};
</script>

<style src="../components/index.css"></style>

<style>
.invitation_container {
  height: 550px;
  margin: 0 auto;
}

.modal-content {
  overflow: hidden !important;
}

.headerInvitation {
  z-index: 10 !important;
  width: 100%;
  position: absolute !important;
}

.template_modal-container {
  height: 650px !important;
  width: 650px;
  overflow-y: scroll;
}

.interieur {
  height: 550px !important;
  overflow-y: scroll;
}

.footerInvitation {
  height: 65px;
  padding: 10px;
  background-color: #f0f0f0;
  justify-content: center;
  flex-direction: row;
  position: fixed;
  display: flex;
  align-items: center;
  border-top: 2px solid #f1f1f1;
  width: 100%;
  position: absolute;
  bottom: 0px;
}

.footer_contact-display {
  position: absolute;
  right: 30px;
  align-items: center;
  display: flex;
}

.footer_link-display {
  position: absolute;
  left: 30px;
  align-items: center;
  display: flex;
}

.footer_font {
  font-size: 15px;
  font-weight: 500;
  color: black;
}
</style>

<style scoped>
.is-large.modal-close,
.is-large.delete {
  display: none;
}

:deep .animation-content {
  position: relative !important;
}

:deep .animation-content .modal-close {
  background-color: rgba(10, 10, 10, 0.2);
}

@media all and (max-width: 900px) {
  .modal-content {
    position: absolute !important;
    top: 30px;
    max-height: 100vh !important;
  }

  .invitation_container {
    height: 552px;
  }

  .invitation_container h1 {
    font-size: 18px !important;
  }

  .interieur p {
    font-size: 14px !important;
  }

  .interieur {
    height: 550px !important;
    padding-bottom: 20px;
    width: 100%;
    position: absolute;
  }

  .container-addVideo {
    padding-top: 40px !important;
  }

  .item {
    font-size: 12px !important;
    gap: 2px;
  }

  .icon,
  .trash-icon {
    width: 12px !important;
  }

  .footerInvitation {
    bottom: -55px;
  }

  .box {
    margin-top: -15px !important;
  }

  .footer_font {
    font-size: 13px !important;
  }

  .is-large.modal-close,
  .is-large.delete {
    display: none;
  }
}

.media-content {
  display: flex;
  align-items: center !important;
  gap: 5px;
}

.inputC {
  width: 60%;
  height: 25px;
  margin-bottom: 8px;
}

.addVideo_button-style {
  width: 100%;
}
</style>