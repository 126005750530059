
<template>
  <section>
    <div class="tile is-ancestor">
      <div class="tile is-parent is-2">
        <article class="tile is-child box">
          <h3>Period</h3>
          <b-field>
            <b-datepicker
              placeholder="Click to select..."
              v-on:range-start="setStartFilterDate($event)"
              v-on:range-end="filterMissions($event)"
              range
              v-model=rangeDates
            >
              <div style="display: flex; justify-content: space-evenly">
                <b-button
                  label="Today"
                  type="is-primary"
                  icon-left="calendar-today"
                  @click="setToday()"
                />
                <b-button
                  label="Clear"
                  type="is-danger"
                  icon-left="close"
                  outlined
                  @click="cancelRange()"
                />
              </div>
            </b-datepicker>
          </b-field>
        </article>
      </div>
      <div class="tile is-parent is-1">
        <article class="tile is-child box">
          <h3>Users</h3>
          <p class="subtitle">
            <b-tag
              style="background-color: #fbfcfb; color: #161032"
              size="is-large"
            >
              {{ stats.totalUsers }}</b-tag>
          </p>
        </article>
      </div>
      <!-- <div class="tile is-parent">
        <article class="tile is-child box">
          <h3>Missions</h3>
          <p class="subtitle">
            <b-tag
              style="background-color: #828282; color: white"
              size="is-large"
            >
              {{ stats.totalInterviewTemplates - stats.totalStartedInterviewTemplates }}</b-tag>&nbsp;
            <b-tag
              style="background-color: #d9dd1b"
              size="is-large"
            >
              {{ stats.totalStartedInterviewTemplates }}</b-tag>
          </p>
        </article>
      </div> -->
      <div class="tile is-parent ">
        <article class="tile is-child box">
          <h3>Score</h3>
          <p class="subtitle">
            <b-tag
              style="background-color: #fbfcfb"
              size="is-large"
            >
              {{ stats.totalSuccessfulInterviews }} / {{ stats.totalInterviews }}
            </b-tag>&nbsp;

            <b-tag
              :style="{
              backgroundColor: computeColorFromValue(
                (stats.totalSuccessfulInterviews * 100) / stats.totalInterviews
              )
            }"
              size="is-large"
            >
              {{ ((stats.totalSuccessfulInterviews * 100) / stats.totalInterviews).toFixed(2) }}%
            </b-tag>
          </p>
        </article>
      </div>

      <div class="tile is-parent">
        <article class="tile is-child box">
          <h3>Unlocked</h3>
          <p class="subtitle">
            <b-tag
              style="background-color: #fbfcfb"
              size="is-large"
            >
              {{ stats.totalUnlockedInterviews }} / {{ stats.totalSuccessfulInterviews }}
            </b-tag>&nbsp;

            <b-tag
              :style="{
              backgroundColor: computeColorFromValue(
                (stats.totalUnlockedInterviews * 100) / stats.totalSuccessfulInterviews)
            }"
              size="is-large"
            >
              {{ ((stats.totalUnlockedInterviews * 100) / stats.totalSuccessfulInterviews).toFixed(2) }}%
            </b-tag>
          </p>
        </article>
      </div>
      <div class="tile is-parent is-4">
        <article class="tile is-child box">

          <p class="subtitle">
            <b-tag
              style="background-color: #161032; color: white"
              size="is-medium"
            >
              {{ stats.totalAmountSold }} € HT
            </b-tag>
            &nbsp;&nbsp;
            <b-tag
              style="background-color: #fbfcfb; color: black"
              size="is-medium"
            >
              ≈ {{ (stats.totalAmountSold / stats.totalInvoices).toFixed(2) }} € HT /order
            </b-tag>

          <div
            class="tile"
            style="text-align: center; gap: 30px;"
          >

            <span>total <br />
              <b-tag
                style="background-color: #161032; color: white"
                size="is-medium"
              >
                {{ stats.totalInvoices }}
              </b-tag>
            </span>

            <span>60€ <br />
              <b-tag
                style="background-color: #fbfcfb; color: black"
                size="is-medium"
              >
                {{ (stats.total75Sold) }}
              </b-tag>
            </span>

            <span>150€ <br />
              <b-tag
                style="background-color: #fbfcfb; color: black"
                size="is-medium"
              >
                {{ (stats.total250Sold)
                }}
              </b-tag>
            </span>

            <span>250€ <br />
              <b-tag
                style="background-color: #fbfcfb; color: black"
                size="is-medium"
              >
                {{ (stats.total500Sold) }}
              </b-tag>
            </span>
            <span>500€ <br />
              <b-tag
                style="background-color: #fbfcfb; color: black"
                size="is-medium"
              >
                {{ (stats.total1120Sold) }}
              </b-tag>
            </span>
            <span>Autre <br />
              <b-tag
                style="background-color: #fbfcfb; color: black"
                size="is-medium"
              >
                {{ stats.totalInvoices - (parseInt(stats.total75Sold) + parseInt(stats.total250Sold) + parseInt(stats.total500Sold) + parseInt(stats.total1120Sold)) }}
              </b-tag>
            </span>
          </div>
          </p>
        </article>
      </div>
      <b-loading
        :is-full-page="false"
        :active.sync="loading"
      ></b-loading>
    </div>
    <br />
  </section>
</template>
  
<script>
import moment from 'moment';

/**
 * @name allStats
 * @desc Component for displaying statistics
 */

export default {
  name: 'allStats',
  components: {},
  props: {
    /**
     * Loading status
     */
    loading: {
      type: Boolean,
      default: false
    },
    /**
     * Statistics data
     */
    stats: {
      type: Object,
      default: () => {
        return {
          /**
           * @desc Total number of users
           * @type {number}
           */
          totalUsers: 0,
          /**
           * @desc Total number of unresponded interviews
           * @type {number}
           */
          totalUnrespondedInterviews: 0,
          /**
           * @desc Total number of interview templates
           * @type {number}
           */
          totalInterviewTemplates: 0,
          /**
           * @desc Total number of invoices
           * @type {number}
           */
          totalInvoices: 0,
          /**
           * @desc Total number of sponsorships
           * @type {number}
           */
          totalSponsorships: 0,
          /**
           * @desc Total number of mail templates
           * @type {number}
           */
          totalMailTemplates: 0,
          /**
           * @desc Total number of started interview templates
           * @type {number}
           */
          totalStartedInterviewTemplates: 0,
          /**
           * @desc Total number of successful interviews
           * @type {number}
           */
          totalSuccessfulInterviews: 0,
          /**
           * @desc Total number of interviews
           * @type {number}
           */
          totalInterviews: 0,
          /**
           * @desc Total number of unlocked interviews
           * @type {number}
           */
          totalUnlockedInterviews: 0,
          /**
           * @desc Total amount sold
           * @type {number}
           */
          totalAmountSold: 0,
          /**
           * @desc Total amount sold for 75
           * @type {number}
           */
          total75Sold: 0,
          /**
           * @desc Total amount sold for 250
           * @type {number}
           */
          total250Sold: 0,
          /**
           * @desc Total amount sold for 500
           * @type {number}
           */
          total500Sold: 0,
          /**
           * @desc Total amount sold for 1120
           * @type {number}
           */
          total1120Sold: 0
        };
      }
    }
  },
  data: () => {
    return {
      /**
       * Start filter date
       * @type {Date}
       */
      startFilterDate: null,
      /**
       * Range of dates
       * @type {Array}
       */
      rangeDates: null
    };
  },
  methods: {
    /**
     * Set today's date range
     */
    setToday() {
      this.rangeDates = [
        new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
        new Date()
      ];
      this.$emit('filterStats', {
        from: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
        to: new Date()
      });
    },
    /**
     * Cancel date range selection
     */
    cancelRange() {
      this.rangeDates = null;
      this.$emit('filterStats', { from: null, to: null });
    },
    /**
     * Filter missions based on date range
     * @param {Date} event - End date of the range
     */
    filterMissions(event) {
      this.$emit('filterStats', { from: this.startFilterDate, to: event });
    },
    /**
     * Set start filter date
     * @param {Date} event - Start date of the filter
     */
    setStartFilterDate(event) {
      this.startFilterDate = event;
    },
    /**
     * Compute color based on value
     * @param {number} value - Value to determine color
     * @returns {string} - Color code
     */
    computeColorFromValue(value) {
      if (value == 0) {
        return '#fc9f9f';
      } else if (value < 30) {
        return '#ffcbc4';
      } else if (value < 50) {
        return '#f7db5e';
      } else {
        return '#9ffcb0';
      }
    }
  }
};
</script>
  
<style src="../index.css">
</style>