<template>
  <div
    class="card"
    style="height: 550px; padding:50px !important; overflow-y : visible;"
  >
    <h1>Add Client</h1>
    <br />
    <div>
      <div
        class="tile is-ancestor is-vertical"
        :name="index"
        style=" margin-top: 20px !important; margin-bottom: 20px !important;"
        :key="index"
      >
        <div
          class="tile "
          style=""
        >
          <input
            class="inputC"
            label="prenom"
            :placeholder="$t('fName')"
            type="text"
            value=""
            style="margin-right: 20px"
          />
          <input
            class="inputC"
            label="nom"
            :placeholder="$t('lName')"
            type="text"
            value=""
            style="margin-right: 20px"
          />
          <input
            class="inputC"
            label="email"
            :placeholder="$t('email')"
            type="text"
            value=""
            style="margin-right: 20px"
          />
          <input
            class="inputC"
            label="nom de l'entreprise"
            :placeholder="$t('entreprise')"
            type="text"
            value=""
            style="margin-right: 20px"
          />
          <vue-tel-input
            :enabledCountryCode="true"
            mode="international"
            :inputOptions="{
            placeholder: 'mobile',
            styleClasses: 'inputCTel',
          }"
            :validCharactersOnly="true"
            styleClasses="inputC"
            :preferredCountries="['FR']"
            wrapperClasses="inputC"
            :disabledFetchingCountry="true"
            defaultCountry="FR"
            v-on:enter="addLine()"
            style="margin-right: 20px; font-size: 15px !important"
            class="inputC"
          ></vue-tel-input>
          <b-button
            @click="removeLine(index)"
            type="is-small"
            icon-left="trash"
            style="margin-top: 10px;border: 0px !important;background-color: white !important;
                                                                                                    "
          >
          </b-button>
        </div>
      </div>
      <div
        class="tile is-ancestor is-vertical"
        :name="index"
        style=" margin-top: 20px !important; margin-bottom: 20px !important;"
        :key="index"
      >
        <div
          class="tile "
          style=""
        >
          <input
            class="inputC"
            label="prenom"
            :placeholder="$t('fName')"
            type="text"
            value=""
            style="margin-right: 20px"
          />
          <input
            class="inputC"
            label="nom"
            :placeholder="$t('lName')"
            type="text"
            value=""
            style="margin-right: 20px"
          />
          <input
            class="inputC"
            label="email"
            :placeholder="$t('email')"
            type="text"
            value=""
            style="margin-right: 20px"
          />
          <input
            class="inputC"
            label="nom de l'entreprise"
            :placeholder="$t('entreprise')"
            type="text"
            value=""
            style="margin-right: 20px"
          />
          <vue-tel-input
            :enabledCountryCode="true"
            mode="international"
            :inputOptions="{
            placeholder: 'mobile',
            styleClasses: 'inputCTel',
          }"
            :validCharactersOnly="true"
            styleClasses="inputC"
            :preferredCountries="['FR']"
            wrapperClasses="inputC"
            :disabledFetchingCountry="true"
            defaultCountry="FR"
            v-on:enter="addLine()"
            style="margin-right: 20px; font-size: 15px !important"
            class="inputC"
          ></vue-tel-input>
          <b-button
            @click="removeLine(index)"
            type="is-small"
            icon-left="trash"
            style="margin-top: 10px;border: 0px !important;background-color: white !important;
                                                                                                    "
          >
          </b-button>
        </div>
      </div>

      <div
        class="tile is-ancestor is-vertical"
        :name="index"
        style=" margin-top: 20px !important; margin-bottom: 20px !important;"
        :key="index"
      >
        <div
          class="tile "
          style=""
        >
          <input
            class="inputC"
            label="prenom"
            :placeholder="$t('fName')"
            type="text"
            value=""
            style="margin-right: 20px"
          />
          <input
            class="inputC"
            label="nom"
            :placeholder="$t('lName')"
            type="text"
            value=""
            style="margin-right: 20px"
          />
          <input
            class="inputC"
            label="email"
            :placeholder="$t('email')"
            type="text"
            value=""
            style="margin-right: 20px"
          />
          <input
            class="inputC"
            label="nom de l'entreprise"
            :placeholder="$t('entreprise')"
            type="text"
            value=""
            style="margin-right: 20px"
          />
          <vue-tel-input
            :enabledCountryCode="true"
            mode="international"
            :inputOptions="{
            placeholder: 'mobile',
            styleClasses: 'inputCTel',
          }"
            :validCharactersOnly="true"
            styleClasses="inputC"
            :preferredCountries="['FR']"
            wrapperClasses="inputC"
            :disabledFetchingCountry="true"
            defaultCountry="FR"
            v-on:enter="addLine()"
            style="margin-right: 20px; font-size: 15px !important"
            class="inputC"
          ></vue-tel-input>

          <b-button
            @click="removeLine(index)"
            type="is-small"
            icon-left="trash"
            style="margin-top: 10px;border: 0px !important;background-color: white !important;
                                                                                                    "
          >
          </b-button>
        </div>
        <div
          class="tile"
          style="display: flex; justify-content: center"
        >
          <b-button
            @click="addLine"
            expanded
            icon-left="user-plus"
            style="margin-right: 10px;margin-top: 20px;background-color: white;color: #828282;border: 2px dashed #fbfcfb;
                                                                                                    "
          >&nbsp;&nbsp;{{
                                                                                                      $t('addContact')
                                                                                                    }}</b-button>
        </div>
        <div
          class="tile"
          style="display: flex; justify-content: center"
        >
          <b-button
            @click="openFileExplorer"
            expanded
            icon-left="upload"
            style="margin-right: 10px;margin-top: 10px;background-color: white;color: #828282;border: 2px dashed #fbfcfb;"
          >&nbsp;&nbsp;{{
              $t('importCSV') }}</b-button>
        </div>
        <input
          ref="csvFiles"
          type="file"
          accept="text/csv"
          style="display: none"
        />
      </div>
      <div style="display: flex; justify-content: center;">
        <button class="button-3d-small">sauvegarder</button>
      </div>

    </div>
  </div>
</template>

<script>
import InvitationList from '../components/invitationList.vue';

/**
 * @name AddClientList
 * @desc Component for managing client list
 */
export default {
  components: { InvitationList },
  data() {
    return {};
  }
};
</script>