<template>
  <div class="tile is-parent dashboard_shop-display">
    <div class="tile is-4 dashboard_shop-desc">
      <span>
        <h2>{{ $t('shop') }}</h2><br />
        <p style="font-weight: bold; font-size: 20px;">{{ $t('shopDesc') }}</p><br />
        {{ $t('shopMessage') }}
      </span>
    </div>
    <div
      class="tile"
      style="margin: auto; display: flex"
    >
      <div
        class="tile flexShop "
        style="width: 100%;"
      >
        <div class="tile is-4">
          <product
            :name="$t('75credits')"
            :type="$t('videoTestimonials')"
            :desc="$t('3videos')"
            :price="$t('75credPrice')"
            :stripePrice="p75"
          />
        </div>
        <div class="tile is-4">
          <product
            :name="$t('250credits')"
            :type="$t('videoTestimonials')"
            :desc="$t('10videos')"
            :price="$t('250credPrice')"
            :save="$t('save25')"
            :stripePrice="p250"
          />
        </div>
        <div class="tile is-4">
          <product
            :name="$t('500credits')"
            :type="$t('videoTestimonials')"
            :desc="$t('20videos')"
            :price="$t('500credPrice')"
            :save="$t('save37')"
            :stripePrice="p500"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import product from './product.vue';

/**
 * @name Shop
 * @desc Component for displaying products in the shop
 */
export default {
  components: {
    product
  },
  data: () => ({
    /**
     * @desc Price for 75 credits product
     * @type {string}
     */
    p75: process.env.VUE_APP_PRODUCT_75,
    /**
     * @desc Price for 250 credits product
     * @type {string}
     */
    p250: process.env.VUE_APP_PRODUCT_250,
    /**
     * @desc Price for 500 credits product
     * @type {string}
     */
    p500: process.env.VUE_APP_PRODUCT_500
  })
};
</script>

<style src="./index.css"></style>