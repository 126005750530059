<template>
  <div class="backgroundLogin">
    <div class="container">
      <div
        style="margin: 0 auto"
        class="tile is-ancestor is-vertical"
      >
        <br />
        <div
          style="right: 30px; top: 15px; position: absolute"
          class="tile is-ancestor is-vertical"
        >
        </div>
        <br /><br />
        <div
          class="tile is-ancestor login__box"
          v-show="!loading"
        >
          <div class="tile is-4 is-parent is-vertical login__box-size">
            <MenuLang class="login__box-lang" />
            <div style="display: flex; align-items: center; justify-content: center; gap: 15px;">
              <div
                class="appsumo-logo"
                style="width: 150px;"
              >
                <img src="../.././public/img/YourCharlie_logo.png" />
              </div>
              <div
                class="heart"
                style="font-size: 40px;"
              >
                💜
              </div>
              <div
                class="appsumo-logo"
                style="width: 150px;"
              >
                <img src="../.././public/img/as-logo-dark.png" />
              </div>
            </div>
            <div style="display: flex; align-items: center; justify-content: center; gap: 15px;">
              <div
                class="login__logo-position"
                style="margin: 15px 0 15px 0;font-weight: 600;"
              >
                Hello Sumo-lings 👋
              </div><br />
            </div>
            <div>
              <p>{{ $t('appsumo1') }} 💪</p>
            </div><br />
            <form @submit.prevent="submit">
              <p
                v-if="errors && errors.length > 0"
                class="notification"
                style="font-size: 12px;font-weight: bold;border: 1px solid #f14668;background-color: white;color: #f14668 !important;"
              >
                <span
                  v-for="error in errors"
                  :key="error"
                  class="div-danger"
                >
                  {{ error }}
                </span>
              </p>

              <b-field>
                <b-input
                  class="inputC"
                  v-model="email"
                  :placeholder="$t('email')"
                  type="email"
                  size="is-small"
                  required
                />
              </b-field><br />

              <b-field>
                <b-input
                  class="inputC"
                  v-model="password"
                  ref="password"
                  :placeholder="$t('password')"
                  password-reveal
                  type="password"
                  size="is-small"
                  required
                />
              </b-field><br />

              <b-field>
                <b-input
                  class="inputC"
                  v-model="appsumoCode"
                  ref="appsumoCode"
                  placeholder="AppSumo Code"
                  type="text"
                  size="is-small"
                  required
                />
              </b-field>

              <div style="display: flex; justify-content: center; text-align: center;">
                <div class="registerGCU">{{ $t('registerGCU') }}&nbsp;
                  <a
                    v-if="$i18n.locale == 'es'"
                    target="_blank"
                    href="https://www.yourcharlie.com/cgu-charlie/?lang=es"
                  ><br />{{ $t('GCU')
                    }}</a>{{ $t('registerGCU2') }}<a
                    v-if="$i18n.locale == 'es'"
                    target="_blank"
                    href="https://www.yourcharlie.com/politica-de-privacidad/?lang=es"
                  >{{
                      $t('PDC') }}</a>
                  <a
                    v-if="$i18n.locale == 'en'"
                    target="_blank"
                    href="https://www.yourcharlie.com/cgu-charlie/"
                  ><br />{{
                    $t('GCU')
                  }}</a>{{ $t('registerGCU2') }}<a
                    v-if="$i18n.locale == 'en'"
                    target="_blank"
                    href="https://www.yourcharlie.com/privacy-policy/"
                  >{{
                      $t('PDC') }}</a>
                  <a
                    v-if="$i18n.locale == 'fr'"
                    target="_blank"
                    href="https://www.yourcharlie.com/cgu/?lang=fr"
                  ><br />{{
                    $t('GCU')
                  }}</a>{{ $t('registerGCU2') }}<a
                    v-if="$i18n.locale == 'fr'"
                    target="_blank"
                    href="https://www.yourcharlie.com/politique-de-confidentialite/?lang=fr"
                  >{{
                      $t('PDC') }}</a>
                </div>
              </div><br />

              <div
                class="text-center"
                style="display: flex; justify-content: center;"
              >
                <button
                  class="button-3d"
                  native-type="submit"
                  id="inscription"
                >
                  {{ $t('submit') }}
                </button>
              </div>

            </form>
          </div>
          <br />
        </div>
        <b-loading
          :is-full-page="true"
          v-model="loading"
          :can-cancel="false"
          style="height: fit-content"
        >
          <div
            class="charlie__message charlie__message_A"
            style="margin-top: 15px"
          >
            <div class="is-flex is-flex-direction-column m-4 p-3 registerMessage">
              <span style="text-align: left; margin-left: 10px; margin-top: 5px;">{{ $t('serviceConnection')
              }}</span>
              <b-icon
                pack="fas"
                icon="spinner"
                size="is-small"
                custom-class="fa-spin"
                style="--fa-animation-duration:0.8s; margin-left: 10px;"
              >
              </b-icon>
            </div>
          </div>
        </b-loading>
      </div>

      <br />
    </div>
    <br />
  </div>
</template>

<script>
import MenuLang from '../components/menuLang.vue';
import { mapState } from 'vuex';

/**
 * @name appsumo
 * @desc Component for managing AppSumo related functionality.
 */
export default {
  name: 'appsumo',
  components: { MenuLang },
  props: {
    /**
     * Indicates if the user is already registered
     * @type {Boolean}
     * @default false
     */
    registered: {
      type: Boolean,
      default: false
    },
    /**
     * Determines the return type after operations
     * @type {String}
     * @default 'root'
     */
    returnType: {
      type: String,
      default: 'root'
    }
  },
  data() {
    return {
      /**
       * @desc Indicates if the component is in a loading state
       * @type {boolean}
       */
      loading: true,
      /**
       * @desc Position of the label
       * @type {string}
       */
      labelPosition: 'on-border',
      /**
       * @desc User's email
       * @type {string}
       */
      email: '',
      /**
       * @desc AppSumo code
       * @type {string}
       */
      appsumoCode: '',
      /**
       * @desc User's password
       * @type {string}
       */
      password: '',
      /**
       * @desc User's password confirmation
       * @type {string}
       */
      confirmPassword: '',
      /**
       * @desc Array to store errors
       * @type {array}
       */
      errors: [],
      /**
       * @desc Method for authentication
       * @type {null}
       */
      method: null
    };
  },
  computed: mapState(['loaded', 'appsumoCodeError']),
  /**
   * @name mounted
   * @desc Sets the locale and checks if the user is loaded and if there is an appsumo code error
   */
  mounted() {
    this.$i18n.locale = 'en';
    if (this.$store.state.loaded && !this.$store.getters.activeUser) {
      this.loading = false;
      if (this.$store.state.appsumoCodeError) {
        this.errors = [this.$t('appsumoCodeError')];
        this.$store.commit('setAppsumoCodeError', false);
      }
    }
  },
  methods: {
    /**
     * @name submit
     * @desc Handles the form submission
     * @param {Event} event - The submit event
     */
    submit(event) {
      console.log('submit ?');
      this.errors = [];
      if (!this.email) {
        this.errors.push(this.$t('invalidEmail'));
      }
      if (!this.password) {
        this.errors.push(this.$t('passwordRequirement'));
      }

      if (!this.errors.length) {
        this.loading = true;
        console.log('validate');
        this.method = 'email';
        this.$store.dispatch('signUpWithEmail', {
          email: this.email,
          pwd: this.password,
          appsumoCode: this.appsumoCode
        });
      }
    },
    /**
     * @name handleResult
     * @desc Handles the result based on the returnType
     */
    handleResult() {
      switch (this.returnType) {
        case 'root':
          if (
            this.$router.currentRoute.path !== '/' &&
            this.$router.currentRoute.path !== '/inscription'
          ) {
            this.$router.push('/');
          }
          break;
        case 'profile':
          this.$router.push('/profile');
          break;
        default:
          this.$emit(this.returnType);
      }
    },
    /**
     * @name catchError
     * @desc Handles errors during the signup process
     * @param {Object} error - The error object
     */
    catchError(error) {
      console.log('error singing up', error);
      console.error(error);
      if (error.code === 'auth/user-not-found') {
        this.errors.push(this.$t('invalidEmail'));
      } else if (error.code === 'auth/wrong-password') {
        this.errors.push(this.$t('invalidPassword'));
      } else if (error.code === 'auth/email-already-in-use') {
        this.errors.push(this.$t('alreadyUsedEmail'));
      } else if (error.code === 'auth/weak-password') {
        this.errors.push(this.$t('passwordRequirement'));
      } else if (error.code === 'auth/invalid-email') {
        this.errors.push(this.$t('invalidEmail'));
      } else if (error.code === 'auth/network-request-failed') {
        this.errors.push(this.$t('connectionError'));
      } else if (error.code === 'auth/popup-closed-by-user') {
        this.errors.push(this.$t('connectionCancelled'));
      } else if (error.code == 'auth/popup-blocked') {
        this.errors.push(this.$t('blockedPopup'));
      } else {
        console.log('bad code');
        this.errors.push(this.$t('bad code'));
      }
    }
  },
  watch: {
    /**
     * @name loaded
     * @desc Watches the loaded property and handles user authentication status
     * @param {Boolean} newValue - The new value of loaded
     * @param {Boolean} oldValue - The old value of loaded
     */
    loaded(newValue, oldValue) {
      console.log('loaded changed');
      if (newValue && this.$store.getters.activeUser) {
        if (this.method && this.method != null) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'registration',
            authentication_method: this.method,
            user_id: this.$store.getters.activeUser.id
          });
        }
        this.$router.push({ name: 'dashboard' });
      } else if (newValue && !this.$store.getters.activeUser) {
        this.loading = false;
      }
    }
  }
};
</script>


<style src="./../components/index.css"></style>
