<template>
  <div>
    <h1>{{ $t('profile') }}</h1>
    <LogoutButton />
    <br />
    <ProfilDetail />
    <br />
    <hr />
    <Shop />
    <hr />
    <br />
    <Invoices />
    <br />
    <br />
    <div class="seeGCU">{{ $t('seeGCU') }}&nbsp;
      <a
        v-if="$i18n.locale == 'es'"
        target="_blank"
        href="https://www.yourcharlie.com/cgu-charlie/?lang=es"
      >{{ $t('GCU')
      }}</a>
      <a
        v-if="$i18n.locale == 'en'"
        target="_blank"
        href="https://www.yourcharlie.com/cgu-charlie/"
      >{{ $t('GCU')
      }}</a>
      <a
        v-if="$i18n.locale == 'fr'"
        target="_blank"
        href="https://www.yourcharlie.com/cgu/?lang=fr"
      >
        {{ $t('GCU')
        }}</a> &
      <a
        v-if="$i18n.locale == 'fr'"
        target="_blank"
        href="https://www.yourcharlie.com/politique-de-confidentialite/?lang=fr"
      >{{ $t('PDC') }}</a>
      <a
        v-if="$i18n.locale == 'es'"
        target="_blank"
        href="https://www.yourcharlie.com/politica-de-privacidad/?lang=es"
      >{{
        $t('PDC') }}</a>
      <a
        v-if="$i18n.locale == 'en'"
        target="_blank"
        href="https://www.yourcharlie.com/privacy-policy/"
      >{{ $t('PDC')
      }}</a>
    </div>
  </div>
</template>

<script>
import LogoutButton from './../components/logoutButton.vue';
import Invoices from './../components/invoices.vue';
import Shop from './../components/shop.vue';
import ProfilDetail from './../components/profilDetail.vue';

/**
 * @name Profile
 * @desc Page de profil de l'utilisateur, permettant l'accès aux détails du profil, aux factures, à la boutique et à la déconnexion.
 */

export default {
  name: 'profile',
  components: { LogoutButton, Invoices, Shop, ProfilDetail },
  /**
   * @name mounted
   * @desc Fonction exécutée après le montage du composant Vue, peut être utilisée pour des appels API initiaux ou des configurations.
   */
  mounted() {
    // Code pour les configurations initiales ou appels API
  }
};
</script>


<style src="./../components/index.css"></style>
