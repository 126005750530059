<template>
  <section>
    <transition name="fade">
      <Particles
        v-show="showParticles"
        id="tsparticles"
        :particlesInit="particlesInit"
        :particlesLoaded="particlesLoaded"
        :options="{
          fullScreen: {
            zIndex: 1
          },
          particles: {
            number: {
              value: 550
            },
            color: {
              value: [
                '#00FFFC',
                '#FC00FF',
                '#fffc00'
              ]
            },
            shape: {
              type: [
                'circle',
                'square'
              ],
              options: {}
            },
            opacity: {
              value: 1,
              animation: {
                enable: true,
                minimumValue: 0,
                speed: 1,
                startValue: 'max',
                destroy: 'min'
              }
            },
            size: {
              value: 10,
              random: {
                enable: true,
                minimumValue: 2
              }
            },
            links: {
              enable: false
            },
            life: {
              duration: {
                sync: true,
                value: 8
              },
              count: 1
            },
            move: {
              enable: true,
              gravity: {
                enable: true,
                acceleration: 5
              },
              speed: {
                min: 20,
                max: 40
              },
              decay: 0.1,
              direction: 'none',
              straight: false,
              outModes: {
                default: 'destroy',
                top: 'none'
              }
            },
            rotate: {
              value: {
                min: 0,
                max: 360
              },
              direction: this.random,
              move: true,
              animation: {
                enable: true,
                speed: 60
              }
            },
            tilt: {
              direction: this.random,
              enable: true,
              move: true,
              value: {
                min: 0,
                max: 360
              },
              animation: {
                enable: true,
                speed: 60
              }
            },
            roll: {
              darken: {
                enable: true,
                value: 25
              },
              enable: true,
              speed: {
                min: 15,
                max: 25
              }
            },
            wobble: {
              distance: 30,
              enable: true,
              move: true,
              speed: {
                min: -15,
                max: 15
              }
            }
          },
          emitters: {
            life: {
              count: 0,
              duration: 0.1,
              delay: 0.4
            },
            rate: {
              delay: 0.1,
              quantity: 150
            },
            size: {
              width: 0,
              height: 0
            }
          }
        }"
      />
    </transition>
    <h1>
      {{ $t('myTestimonial') }}<span style="font-size: 16px !important">
        ({{ testimoniesWithStatusThree.length }})</span>
    </h1>
    <br />
    <div class="tile is-ancestor is-vertical">
      <div
        class="tile is-parent"
        style="gap:35px; flex-wrap: wrap; padding-left: 55px;"
      >
        <div
          v-for="(testimony) in getTestimoniesWithStatusThree()"
          class="tile is-child is-2"
        >
          <div
            @click="handleTestimonyClick(testimony)"
            class="card"
            style="position: relative; cursor: pointer;"
          >
            <img
              v-if="!testimony.unlocked"
              src="./../../../public/img/locked.png"
              style="position: absolute; top:5px; right: 5px; width: 15%;z-index: 1;"
            />
            <figure
              v-if="testimony.loading == false"
              class=" image is-square"
            >
              <img
                v-if="!testimony.unlocked"
                :src="testimony.thumbnailGifUrl"
                alt="Thumbnail image"
              />
              <img
                v-else
                :src="testimony.thumbnailUrl"
                alt="Thumbnail image"
              />
            </figure>
            <figure v-if="testimony.unlocked == false && testimony.loading == true">
              <div style="display: flex; justify-content: space-evenly; align-items: center; flex-direction: column-reverse; height: 184px;">
                <p style="font-size: 12px !important; color: #161032 !important; font-weight: bold;">
                  {{ $t('mounting') }}
                </p>
                <img
                  style="width: 70px;"
                  alt="Charlie mounting loading"
                  src="../../../public/img/loading.gif"
                />
              </div>
            </figure>
            <div class="card-content">
              <p
                class="title is-4"
                style="font-size: 14px;text-overflow: ellipsis;margin-bottom: 10px;"
              >

                {{ testimony.user.surname[0].toUpperCase() + testimony.user.surname.slice(1) }} {{
                  testimony.user.name[0].toUpperCase() + testimony.user.name.slice(1) }}
              </p>
              <div
                class="content"
                style="line-height: 7px;"
              >
                <p style="color: #828282;font-size: 12px;">{{
                  testimony.user.updatedAt | momentFilter($i18n.locale) }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="tile is-child is-2">
          <router-link :to="'/directory/'">
            <div
              class="card"
              style="position: relative; cursor: pointer"
            >
              <figure class=" image is-square">
                <img
                  src="./../../../public/img/add.png"
                  alt="Thumbnail image"
                />
              </figure>
              <div class="card-content">
                <p
                  class="title is-4"
                  style="font-size: 14px; margin-bottom: 10px;"
                >{{ $t('newTestimony') }}</p>
                <div
                  class="content"
                  style="line-height: 7px;"
                >
                  <p style="color: #828282;font-size: 12px;">{{ $t('inviteNow') }}</p>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <b-modal
      v-model="showLocked"
      :width="600"
      scroll="keep"
      style="background-color: white !important"
    >
      <div
        class="card"
        v-if="selectedInterview && showLocked"
      >
        <div
          class="card-image responsiveCard"
          :style="[
          selectedInterview && selectedInterview.thumbnailGifUrl
            ? {
              'background-image': `url(${selectedInterview.thumbnailGifUrl})`
            }
            : selectedInterview && selectedInterview.thumbnailUrl
              ? {
                'background-image': `url(${selectedInterview.thumbnailUrl})`
              }
              : {
                'background-image': 'url(../../public/img/thumbPreview.png)'
              }
        ]"
        >
          <div
            class="block"
            style="
              height: 600px;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >

            <button
              class="button-3d responsiveButton"
              style="width: 400px !important"
              @click="unlock()"
            >
              <img
                style="padding: 15px; vertical-align: middle"
                alt="Charlie Credits"
                src="./../../../public/img/token.png"
              />{{ $t('unlockCredits') }}
            </button>
          </div>
        </div>
        <div class="card-content">
          <div class="media">
            <div class="media-content">
              <p class="title is-4">
                {{ selectedInterview.user.surname }}&nbsp;{{
                  selectedInterview.user.name
                }}
              </p>
              <p class="subtitle is-6">
                {{ selectedInterview.updatedAt | momentFilter($i18n.locale) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      v-model="showUnlocked"
      :width="800"
      style="width: 100%; height: 100%; overflow: scroll"
    >
      <div
        class="card"
        style="display: flex !important; overflow: hidden;"
        v-if="selectedInterview && showUnlocked"
      >
        <div class="card-content">
          <div
            class="tile is-parent"
            style="display: flex !important;flex-direction: column !important;gap: 15px !important;"
          >

            <button
              class="button-3d"
              @click="navigateToTestimonialsManager()"
              style="margin-top: 15px"
            >
              {{ $t('gototestimonialsManager') }}
            </button>

          </div>
        </div>
      </div>
    </b-modal>
    <b-loading
      :is-full-page="true"
      :active.sync="loading"
      :can-cancel="false"
    />

  </section>
</template>
    
<script>
import i18n from '@/i18n';
import moment from 'moment';
import { loadFull } from 'tsparticles';
import DownloadTestimonial from '../../components/downloadTestimonial.vue';
import { RouterLink } from 'vue-router';
import Subtitles from '../../components/subtitles.vue';

/**
 * @name mytestimony
 * @desc Component for managing testimonies
 */

export default {
  name: 'mytestimony',
  components: {
    DownloadTestimonial,
    RouterLink,
    Subtitles
  },
  data() {
    return {
      /**
       * @desc Filter value object
       * @type {object}
       */
      filterValue: {
        action: null
      },
      /**
       * @desc Stats data
       * @type {null}
       */
      stats: null,
      /**
       * @desc Loading status
       * @type {boolean}
       */
      loading: false,
      /**
       * @desc List of interviews
       * @type {array}
       */
      interviews: [],
      /**
       * @desc Flag for showing locked content
       * @type {boolean}
       */
      showLocked: false,
      /**
       * @desc Flag for showing unlocked content
       * @type {boolean}
       */
      showUnlocked: false,
      /**
       * @desc Selected interview object
       * @type {object}
       */
      selectedInterview: null,
      /**
       * @desc estimonies with status three
       * @type {array}
       */
      testimoniesWithStatusThree: [],
      /**
       * @desc Flag for showing particles
       * @type {boolean}
       */
      showParticles: false,
      /**
       * @desc Flag for showing modal subscriptions
       * @type {boolean}
       */
      showModalSubs: false,
      /**
       * @desc Random value for particles
       * @type {string}
       */
      random: 'random'
    };
  },
  methods: {
    /**
     * @name particlesLoaded
     * @desc Callback when particles are loaded
     * @param {Object} container - Container for particles
     */
    particlesLoaded(container) {
      console.log('', container);
    },
    /**
     * @name handleTestimonyClick
     * @desc Handles click on a testimony
     * @param {Object} testimony - The testimony object
     */
    handleTestimonyClick(testimony) {
      if (testimony.unlocked) {
        this.show(testimony, testimony.unlocked);
      } else {
        this.selectedInterview = testimony;
        this.showLocked = true;
      }
    },
    /**
     * @name navigateToTestimonialsManager
     * @desc Navigates to testimonials manager
     * @param {Object} testimony - The testimony object
     */
    navigateToTestimonialsManager(testimony) {
      this.$router.push({
        name: 'testimonials_manager',
        params: {
          id: testimony.id,
          interview: testimony
        }
      });
    },
    /**
     * @name getTestimoniesWithStatusThree
     * @desc Retrieves testimonies with status 3
     * @returns {Array} - Testimonies with status 3
     */
    getTestimoniesWithStatusThree() {
      const clients = this.$store.getters.clients;
      const testimonies = clients
        .filter((client) => {
          return (
            client.interviews &&
            client.interviews.length > 0 &&
            client.interviews[0].status === 3
          );
        })
        .map((client) => {
          return { user: { ...client.user }, ...client.interviews[0] };
        });
      this.interviews = testimonies;
      return testimonies;
    },
    /**
     * @name unlock
     * @desc Unlocks a testimony
     */
    unlock() {
      if (this.$store.getters.activeUser.credits < 25) {
        this.$buefy.notification.open({
          message: this.$t('notEnoughCredits'),
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 5000
        });
        this.$router.push('/profile');
        return;
      }
      this.$store
        .dispatch('unlockInterview', this.selectedInterview.id)
        .catch((error) => {
          console.log('error', error);
          if (error == 'Error: Request failed with status code 402') {
            this.$buefy.notification.open({
              message: this.$t('notEnoughCredits'),
              type: 'is-danger',
              position: 'is-bottom-right',
              duration: 5000
            });
            this.$router.push('/profile');
          }
        });
      this.waitForUnlock(this.selectedInterview);
      this.showParticles = true;
      setTimeout(() => {
        this.showParticles = false;
      }, 3000);
      this.showLocked = false;
      this.selectedInterview = false;
      fbq('track', 'Donate');
    },
    /**
     * @name waitForUnlock
     * @desc Waits for a testimony to be unlocked
     * @param {Object} interviewToWait - The interview to wait for
     */
    waitForUnlock(interviewToWait) {
      const interviewId = interviewToWait.id;
      this.$store.getters.clients.forEach((client) => {
        client.interviews.forEach((interview) => {
          if (interview.id == interviewId) {
            interview['loading'] = true;
          }
        });
      });
      this.checkInterval = setInterval(() => {
        this.refreshClients();
        this.$store.getters.clients.forEach((client) => {
          client.interviews.forEach((interview) => {
            if (interview.id == interviewId && interview.unlocked) {
              clearInterval(this.checkInterval);
              this.$buefy.notification.open({
                message: this.$t('unlockSuccess'),
                type: 'is-success',
                position: 'is-bottom-right',
                duration: 5000
              });
            }
          });
        });
      }, 10000);
    },
    /**
     * @name show
     * @desc Shows a testimony
     * @param {Object} testimony - The testimony object
     * @param {Boolean} unlocked - Flag indicating if the testimony is unlocked
     */
    show(testimony, unlocked) {
      this.loading = true;
      this.$store
        .dispatch('getInterviewTemplate', testimony.interviewTemplate.id)
        .then((result) => {
          let itwTemplate = result.data;
          itwTemplate.interviews.forEach((interview) => {
            if (
              interview.id == testimony.id &&
              interview.unlocked == unlocked
            ) {
              this.selectedInterview = interview;
              if (unlocked) {
                this.navigateToTestimonialsManager(this.selectedInterview);
              }
            }
          });
          this.loading = false;
        });
    },
    /**
     * @name handleFilterChange
     * @desc Handles filter change
     */
    handleFilterChange() {
      this.clients = this.$store.getters.clients.filter((client) => {
        let condition = true;
        switch (this.filterValue.action) {
          case 'unlock':
            condition =
              condition &&
              client.interviews.length != 0 &&
              client.interviews[0].status === 3 &&
              client.interviews[0].unlocked === false &&
              client.interviews[0].loading === false;
            break;
          case 'thanks':
            condition =
              condition &&
              client.interviews.length != 0 &&
              client.interviews[0].status === 3 &&
              client.interviews[0].unlocked === true &&
              client.interviews[0].loading === false;
            break;
          default:
            break;
        }
        return condition;
      });
      if (this.onboarding) {
        this.loading = true;
        this.$store.dispatch('getClients').finally(() => {
          this.loading = false;
          this.askForTestimonial(
            [this.$store.getters.clients[0]],
            'invitation'
          );
        });
      }
    },
    /**
     * @name refreshClients
     * @desc Refreshes client data
     */
    refreshClients() {
      this.$store.dispatch('getClients').finally(() => {
        this.handleFilterChange();
      });
    },
    /**
     * @name particlesInit
     * @desc Initializes particles engine
     * @param {Object} engine - The particles engine
     */
    async particlesInit(engine) {
      await loadFull(engine);
    }
  },
  beforeMount() {
    this.$store.dispatch('getCharlieUserStats').then((data) => {
      this.stats = data.data;
    });
    this.testimoniesWithStatusThree = this.getTestimoniesWithStatusThree();
  }
};
</script>
    
    
<style src="./../../components/index.css"></style>

<style scoped>
.card-content {
  padding: 11px;
}

.container-testimonials {
  gap: 35px;
  flex-wrap: wrap;
  padding-left: 55px;
}

@media all and (max-width: 768px) {
  .container-testimonials {
    padding: 0 55px 0 55px !important;
  }

  .card {
    margin-bottom: 20px;
  }
}
</style>