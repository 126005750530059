<template>
  <section>
    <div
      class="tile is-parent is-vertical card box"
      style="width: 80%;margin: 0 auto; padding:50px; background-color: white !important"
    >
      <span>
        <h3>{{ $t('modalShopTitle') }}</h3>
        <p>{{ $t('modalShopDesc1') }} {{ $t('modalShopDesc2') }}</p>
      </span>
      <br />

      <div
        class="tile"
        style="display: flex; margin:0;
    align-items: center; justify-content: center;"
      >

        <div class="tile is-5">
          <b-field>
            <b-select
              v-model="duration"
              style="border: 1px solid #c9c9c9; border-radius: 5px;"
              :placeholder="$t('chooseDuration')"
            >
              <option :value="7890000">3 {{ $t('months') }}</option>
              <option :value="7890000 * 2">6 {{ $t('months') }}</option>
              <option :value="7890000 * 4">12 {{ $t('months') }}</option>
            </b-select>
          </b-field>

        </div>
        <div
          class="tile is-3"
          style="justify-content: center; font-size: 26px; align-items: center; background-color: #f4f4f4;"
        > {{ price }}
          &nbsp;
          <img
            src="../../../public/img/token.png"
            style="width: 25px; height: 25px; "
          />
        </div>
      </div>
      <br />
      <br />
      <div v-if="price > $store.getters.activeUser.credits"></div>
      <button
        v-else
        @click="$emit('validate', { duration: duration })"
        :disabled="!duration || price > $store.getters.activeUser.credits"
        class="button-3d"
      >{{ $t('buy')
        }}</button>
      <br />

      <div v-if="price > $store.getters.activeUser.credits">
        <div style="text-align: center; background-color: #ffe1eb; padding: 20px; color:#f1054d">{{
          $t('notEnoughCreditsDuration') }}</div>
        <br /> <br />
        <div
          class="tile"
          style="margin: auto; display: flex"
        >
          <div class="tile flexShop ">
            <div class="tile is-4">
              <product
                :name="$t('75credits')"
                :price="$t('75credPrice')"
                :stripePrice="p75"
                v-on:buyClicked="$emit('saveToStorage', { duration: duration })"
                :diffusionType="$route.params.id"
              />
            </div>
            <div class="tile is-4">
              <product
                :name="$t('250credits')"
                :price="$t('250credPrice')"
                :save="$t('save25')"
                :stripePrice="p250"
                v-on:buyClicked="$emit('saveToStorage', { duration: duration })"
                :diffusionType="$route.params.id"
              />
            </div>
            <div class="tile is-4">
              <product
                :name="$t('500credits')"
                :price="$t('500credPrice')"
                :save="$t('save37')"
                :stripePrice="p500"
                v-on:buyClicked="$emit('saveToStorage', { duration: duration })"
                :diffusionType="$route.params.id"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>
</template>
<script>
import product from '../product.vue';

/**
 * @name modalShop
 * @desc Component for modal shop functionality
 */

export default {
  components: { product },
  props: {
    /**
     * Type of playlist
     */
    playlistType: {
      type: String,
      required: true
    },
    /**
     * Saved duration
     */
    savedDuration: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      /**
       * @type {number|null}
       * @desc Duration of the product
       */
      duration: this.savedDuration || null,

      /**
       * @type {number}
       * @desc Price of the product
       */
      price: this.computePrice(this.savedDuration, this.playlistType),

      /**
       * @type {number}
       * @desc Price for product 75
       */
      p75: process.env.VUE_APP_PRODUCT_75,

      /**
       * @type {number}
       * @desc Price for product 250
       */
      p250: process.env.VUE_APP_PRODUCT_250,

      /**
       * @type {number}
       * @desc Price for product 500
       */
      p500: process.env.VUE_APP_PRODUCT_500
    };
  },
  methods: {
    /**
     * @name computePrice
     * @desc Computes the price based on duration and playlist type
     * @param {Number} duration - Duration of the product
     * @param {String} playlistType - Type of playlist
     * @returns {Number} - Calculated price
     */
    computePrice(duration, playlistType) {
      let price = 0;
      switch (duration) {
        case 7890000:
          price = playlistType == 'wall' ? 40 : 20;
          break;
        case 15780000:
          price = playlistType == 'wall' ? 70 : 35;
          break;
        case 31560000:
          price = playlistType == 'wall' ? 120 : 60;
          break;
        default:
          price = 0;
      }
      return price;
    }
  },
  watch: {
    /**
     * Watcher for duration changes
     * @param {Number} val - New duration value
     */
    duration: function (val) {
      this.price = this.computePrice(val, this.playlistType);
    }
  }
};
</script>
<style src="../index.css"></style>