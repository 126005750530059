
<template>
  <section>
    <b-table
      :data="dataMissions"
      ref="table"
      :show-detail-icon="false"
      :opened-detailed="defaultOpenedDetails"
      v-on:details-open="handleDetailsOpen"
      detailed
      detail-key="id"
      :loading="loading"
      paginated
      backend-pagination
      :total="total"
      :per-page="perPage"
      @page-change="onPageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      backend-sorting
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      @sort="onSort"
    >
      <b-table-column
        field="error"
        label=""
        sortable
        v-slot="props"
      >
        {{ props.row.interviews.some((i) => i.status == -1) ? '🚨' : (props.row.errorCode == 1 ? '⚠️' : '') }}
      </b-table-column>

      <b-table-column
        field="createdAt"
        label="Create"
        sortable
        v-slot="props"
        style="width: 200px"
      >
        <b-tag style="background-color: #fbfcfb">{{
          props.row.createdAt | moment
        }}</b-tag>
      </b-table-column>

      <b-table-column
        field="startedAt"
        label="Launch"
        sortable
        v-slot="props"
      >
        <b-tag style="background-color: #fbfcfb">{{
          props.row.startedAt | moment
        }}</b-tag>
      </b-table-column>

      <b-table-column
        field="status"
        label="Status"
        v-slot="props"
      >
        <b-tag
          v-if="props.row.status == 0"
          style="background-color: #828282; color: white"
        >draft
        </b-tag>
        <b-tag
          v-if="props.row.status == 1 &&
          props.row.interviews.filter(
            (i) =>
              i.status == 3 ||
              i.status == -1 ||
              i.lastNotifStatus == 'noAgree'
          ).length != props.row.interviews.length
          "
          style="background-color: #d9dd1b"
        >running</b-tag>
        <b-tag
          v-if="(props.row.interviews.filter(
          (i) =>
            i.status == 3 ||
            i.status == -1 ||
            i.lastNotifStatus == 'noAgree'
        ).length ==
            props.row.interviews.length) !=
          (props.row.status == 1 &&
            props.row.interviews.filter((i) => i.lastNotifStatus == 'noAgree')
              .length == props.row.interviews.length)
          "
          style="background-color: #323232; color: white"
        >
          finish
        </b-tag>
        <b-tag
          v-if="props.row.status == 1 &&
          props.row.interviews.filter((i) => i.lastNotifStatus == 'noAgree')
            .length == props.row.interviews.length
          "
          style="background-color: #fc9f9f; color: black"
        >refused</b-tag>
        &nbsp;
        <b-tag
          v-if="props.row.interviews.filter((i) => i.status == 3).length -
          props.row.interviews.filter((i) => i.unlocked).length >=
          1
          "
          style="
                                                                                  background-color: white;
                                                                                  border: 2px solid #fc9f9f;
                                                                                  font-weight: bold;
                                                                                "
        >🔒
          {{
            props.row.interviews.filter((i) => i.status == 3).length -
            props.row.interviews.filter((i) => i.unlocked).length
          }}</b-tag>

        <b-tag
          v-else
          style="background-color: white !important"
        ></b-tag>
      </b-table-column>
      <b-table-column
        field="interviews.length"
        label="Score"
        v-slot="props"
      >
        <div v-if="!props.row.status == 0">
          <b-tag style="background-color: #fbfcfb">{{
            props.row.interviews.filter(
              (i) => i.status == 3 || i.status == -1
            ).length
          }}
            / {{ props.row.interviews.length }}</b-tag>&nbsp;&nbsp;

          <b-tag
            :style="{
            backgroundColor: computeColorFromValue(
              (props.row.interviews.filter(
                (i) => i.status == 3 || i.status == -1
              ).length *
                100) /
              props.row.interviews.length
            )
          }"
            size="is-small"
          >
            {{
              (
                (props.row.interviews.filter(
                  (i) => i.status == 3 || i.status == -1
                ).length *
                  100) /
                props.row.interviews.length
              ).toFixed(2)
            }}%
          </b-tag>
        </div>
      </b-table-column>
      <!-- <b-table-column
        field="questions"
        label="Q"
        v-slot="props"
      >
        <b-dropdown aria-role="list">
          <template #trigger="{ active }">
            <b-button
              label="?"
              type="is-secondary"
              :icon-right="active ? 'menu-up' : 'menu-down'"
            />
          </template>
          <b-dropdown-item aria-role="listitem">- Question n°{{ index + 1 }} : {{ item.text }}</b-dropdown-item>
        </b-dropdown>
            </b-table-column> -->
      <b-table-column
        field="customMedia"
        label=""
        v-slot="props"
      >
        <div v-if="props.row.customMedia == null"></div>
        <div v-else>
          <a
            @click="showModal(props.row.customMedia)"
            style="display:flex; justify-content: center; align-items: center;"
          >
            <b-icon
              pack="fas"
              icon="eye"
              size="is-small"
              custom-class="fa-eye"
            >
            </b-icon>
          </a>
        </div>
      </b-table-column>
      <b-table-column
        field="formality"
        label=""
        v-slot="props"
      >
        <div v-if="props.row.formality == true"><img
            src="/img/cordiale.png"
            style="max-width: 25px!important
                          "
          /></div>
        <div v-if="props.row.formality == false"><img
            src="/img/amicale.png"
            style="max-width: 25px!important
                          "
          /></div>
      </b-table-column>
      <b-table-column
        field="name"
        label="Mission name"
        sortable
        v-slot="props"
      >
        <template v-if="showDetailIcon">
          {{ props.row.name }}
        </template>
        <template v-else>
          <a @click="props.toggleDetails(props.row)">
            {{ props.row.name }}
          </a>
        </template>
      </b-table-column>
      <b-table-column
        field="sender"
        label="Sender"
        v-slot="props"
      >
        <div>{{ props.row.senderName }}</div>
      </b-table-column>
      <b-table-column
        field="owner.name"
        label="Makerz"
        sortable
        v-slot="props"
      >
        <a @click="onUserClickedMission(props.row.owner)">{{ props.row.owner.name }}</a>
      </b-table-column>
      <b-table-column
        field="delete"
        label=""
        sortable
        v-slot="props"
      >
        <b-button
          type="is-danger"
          size="is-small"
          :disabled="$store.getters.activeUser.role.name !== 'SUPERADMIN'"
          @click="
            showValidationModal = true;
          currentId = props.row.id;
          "
        >
          delete
        </b-button>
      </b-table-column>
      <template #detail="props">
        <b-table :data="props.row.interviews">
          <b-table-column
            field="notifs"
            width="200PX"
            label="Notification"
            v-slot="props"
          >
            <span>
              <small v-if="props.row.lastNotification">
                {{ props.row.lastNotification.type }}
                {{ props.row.lastNotification.date | moment }}</small></span>
            <b-button
              v-if="props.row.status !== 3 &&
              props.row.status !== -1 &&
              props.row.remainingNotifications > 0
              "
              type="is-danger"
              size="is-small"
              :disabled="$store.getters.activeUser.role.name !== 'SUPERADMIN'"
              @click="cancelNotificationsForInterview(props.row.id)"
            >
              cancel
            </b-button>
          </b-table-column>
          <b-table-column
            field="status"
            label="C"
            v-slot="props"
          >
            <span v-if="props.row.status == 0 && props.row.lastNotifStatus != 'noAgree'
              ">⏱️</span>
            <b-tooltip :label="(props.row.lastUserActionAt || props.row.updatedAt) | moment">
              <span v-if="props.row.status == -1 &&
                props.row.lastNotifStatus != 'noAgree'
                ">✅</span>
            </b-tooltip>
            <b-tooltip :label="(props.row.lastUserActionAt || props.row.updatedAt) | moment">
              <span v-if="props.row.status == 1 &&
                props.row.lastNotifStatus != 'noAgree'
                ">🔗</span>
            </b-tooltip>
            <b-tooltip :label="(props.row.lastUserActionAt || props.row.updatedAt) | moment">
              <span v-if="props.row.status == 2 &&
                props.row.lastNotifStatus != 'noAgree'
                ">🤔</span>
            </b-tooltip>
            <b-tooltip :label="(props.row.lastUserActionAt || props.row.updatedAt) | moment">
              <span v-if="props.row.status == 3 &&
                props.row.lastNotifStatus != 'noAgree'
                ">✅</span>
            </b-tooltip>
            <b-tooltip :label="(props.row.lastUserActionAt || props.row.updatedAt) | moment">
              <span v-if="props.row.lastNotifStatus == 'noAgree' &&
                props.row.lastNotifStatus != null
                ">🚫</span>
            </b-tooltip>
          </b-table-column>
          <b-table-column
            field="unlocked"
            label="U"
            v-slot="props"
          >
            <span v-if="props.row.status == -1">🚨 &nbsp;&nbsp;
              <b-button
                size="is-small"
                type="is-danger is-light"
                @click="retryUnlock(props.row.id)"
              >
                retry
              </b-button>
            </span>
            <span v-else-if="!props.row.unlocked &&
              !props.row.loading &&
              props.row.status == 3
              ">🔒</span>
            <span v-else-if="props.row.loading && !props.row.unlocked">⚙️</span>
            <span v-else-if="props.row.unlocked">
              <b-tooltip :label="(props.row.lastUserActionAt || props.row.updatedAt) | moment">🎉</b-tooltip>
            </span>
            <span v-else>-</span>
          </b-table-column>
          <b-table-column
            field="nom"
            label="Lastname"
            v-slot="props"
          >{{ props.row.user.name }}
          </b-table-column>
          <b-table-column
            field="prenom"
            label="Firstname"
            v-slot="props"
          >{{ props.row.user.surname }}
          </b-table-column>
          <b-table-column
            field="notes"
            label="Notes"
            v-slot="props"
          >{{ props.row.notes }}
          </b-table-column>
          <b-table-column
            field="email"
            label="Email"
            v-slot="props"
          >{{ props.row.user.email }}
          </b-table-column>
          <b-table-column
            field="phone"
            label="Phone"
            v-slot="props"
          >{{ props.row.user.phoneNumber }}
          </b-table-column>
        </b-table>
      </template>
    </b-table>

    <b-modal v-model="showCustomMedia">
      <div style="display: flex; justify-content: center;">
        <video
          :src="customMediaURL"
          controls
          autoplay
        >
        </video>
      </div>
    </b-modal>

  </section>
</template>
  
<script>
import moment from 'moment';

/**
 * @name allMissions
 * @desc Component for displaying all missions
 */
export default {
  name: 'allMissions',
  components: {},
  filters: {
    /**
     * Filter to format date using moment.js
     * @param {Date} date - The date to format
     * @returns {string} - Formatted date
     */
    moment: function (date) {
      moment.locale('fr');
      return moment(date).format('LLL');
    }
  },
  data: () => {
    return {
      /**
       * @desc Array containing data for all missions
       * @type {Array}
       */
      dataMissions: [],
      /**
       * @desc Flag indicating if the component is hoverable
       * @type {Boolean}
       */
      isHoverable: true,
      /**
       * @desc Array containing details that are initially opened
       * @type {Array}
       */
      defaultOpenedDetails: [],
      /**
       * @desc Flag indicating if the detail icon is shown
       * @type {Boolean}
       */
      showDetailIcon: false,
      /**
       * @desc Flag indicating if data is currently loading
       * @type {Boolean}
       */
      loading: false,
      /**
       * @desc Flag indicating if custom media is shown
       * @type {Boolean}
       */
      showCustomMedia: false,
      /**
       * @desc URL for custom media
       * @type {String}
       */
      customMediaURL: null,
      /**
       * @desc Field to sort the data by
       * @type {String}
       */
      sortField: 'createdAt',
      /**
       * @desc Order in which the data is sorted (ascending or descending)
       * @type {String}
       */
      sortOrder: 'desc',
      /**
       * @desc Default sort order
       * @type {String}
       */
      defaultSortOrder: 'desc',
      /**
       * @desc Current page number
       * @type {Number}
       */
      page: 1,
      /**
       * @desc Number of items per page
       * @type {Number}
       */
      perPage: 20,
      /**
       * @desc Total number of items
       * @type {Number}
       */
      total: 200
    };
  },
  beforeMount() {
    this.refreshList();
  },
  methods: {
    /**
     * @name showModal
     * @desc Show modal with custom media
     * @param {Object} customMedia - Custom media object
     */
    showModal(customMedia) {
      this.customMediaURL = customMedia.previewUrl;
      this.showCustomMedia = true;
    },
    /**
     * @name refreshList
     * @desc Refresh the list of missions
     */
    refreshList() {
      this.loading = true;
      this.$store
        .dispatch('getAdminInterviewTemplatesPaginated', {
          page: this.page - 1,
          limit: this.perPage,
          sort: this.sortField,
          order: this.sortOrder.toUpperCase()
        })
        .then((response) => {
          this.dataMissions = response.data[0];
          this.filteredDataMissions = response.data;
          this.loading = false;
          this.total = response.data[1];
        })
        .catch((error) => {
          this.loading = false;
          console.error(error);
          this.$buefy.toast.open({
            message: this.$t('errorOccured'),
            type: 'is-danger',
            queue: false,
            position: 'is-top-right',
            duration: 5000
          });
        });
    },
    /**
     * @name onPageChange
     * @desc Handle page change event
     * @param {number} page - The new page number
     */
    onPageChange(page) {
      this.page = page;
      this.refreshList();
    },
    /**
     * @name onSort
     * @desc Handle sorting event
     * @param {string} field - The field to sort by
     * @param {string} order - The sort order
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.refreshList();
    },
    /**
     * @name deleteInterviewTemplate
     * @desc Delete an interview template
     * @param {string} id - The ID of the template to delete
     */
    deleteInterviewTemplate(id) {
      this.loading = true;
      this.$store
        .dispatch('deleteInterviewTemplate', id)
        .then(() => {
          this.loading = false;
          this.$router.go();
        })
        .catch((error) => {
          this.loading = false;
          console.error(error);
          this.$buefy.toast.open({
            message: this.$t('errorOccured'),
            type: 'is-danger',
            queue: false,
            position: 'is-top-right',
            duration: 5000
          });
        });
    },
    /**
     * @name onUserClickedMission
     * @desc Handle user click on a mission
     * @param {Object} user - The user object
     */
    onUserClickedMission(user) {
      this.$emit('userClicked', user.id);
    },
    /**
     * @name retryUnlock
     * @desc Retry unlocking a mission
     * @param {string} id - The ID of the mission to retry unlocking
     */
    retryUnlock(id) {
      this.loading = true;
      this.$store
        .dispatch('retryUnlock', id)
        .catch((error) => {
          this.loading = false;
          console.error(error);
          this.$buefy.toast.open({
            message: this.$t('errorOccured'),
            type: 'is-danger',
            queue: false,
            position: 'is-top-right',
            duration: 5000
          });
        })
        .then(() => {
          this.loading = false;
          this.$router.go();
        });
    },
    /**
     * @name cancelNotificationsForInterview
     * @desc Cancel notifications for a specific interview
     * @param {string} id - The ID of the interview
     */
    cancelNotificationsForInterview(id) {
      this.loading = true;
      this.$store
        .dispatch('cancelNotificationsForInterview', id)
        .catch((error) => {
          this.loading = false;
          console.error(error);
          this.$buefy.toast.open({
            message: this.$t('errorOccured'),
            type: 'is-danger',
            queue: false,
            position: 'is-top-right',
            duration: 5000
          });
        })
        .then(() => {
          this.loading = false;
          this.$router.go();
        });
    },
    /**
     * @name handleDetailsOpen
     * @desc Handle opening details for a row
     * @param {Object} row - The row object
     */
    handleDetailsOpen(row) {
      this.$store
        .dispatch('getNotificationsForInterviewTemplates', row.id)
        .then((response) => {
          const sentNotifsForInterviewTemplate = response.data;
          for (let interview of row.interviews) {
            const sentNotifs = sentNotifsForInterviewTemplate
              .filter(
                (notif) =>
                  notif.interviewId == interview.id &&
                  notif.interviewTemplateId == null
              )
              .sort((a, b) => b.updatedAt - a.updatedAt);
            if (sentNotifs && sentNotifs.length > 0) {
              const lastNotif = sentNotifsForInterviewTemplate
                .filter(
                  (notif) =>
                    notif.interviewId == interview.id &&
                    notif.status == 1 &&
                    notif.interviewTemplateId == null
                )
                .sort(
                  (a, b) =>
                    new Date(b.updatedAt).getTime() -
                    new Date(a.updatedAt).getTime()
                )[0];
              if (lastNotif) {
                let mailsCount = sentNotifs.filter(
                  (n) => n.type == 'mail' && n.status == 1
                ).length;
                interview.lastNotification = {
                  date: lastNotif.updatedAt,
                  type:
                    lastNotif.type == 'mail'
                      ? mailsCount == 1
                        ? 'mail'
                        : 'relance' + (mailsCount - 1)
                      : lastNotif.type === 'customerServiceCall'
                      ? 'relance nadège'
                      : 'sms'
                };
              }
            }
            interview.remainingNotifications = sentNotifs.filter(
              (n) => n.status == 0
            ).length;
          }
          row.interviews = [...row.interviews];
          this.$forceUpdate();
        });
    },
    /** @name computeColorFromValue
     * @desc Compute color based on a value
     * @param {number} value - The value to determine color for
     * @returns {string} - Color code */
    computeColorFromValue(value) {
      if (value == 0) {
        return '#fc9f9f';
      } else if (value < 30) {
        return '#ffcbc4';
      } else if (value < 50) {
        return '#f7db5e';
      } else {
        return '#9ffcb0';
      }
    }
  }
};
</script>
  
<style src="../index.css"></style>