<template>
  <div
    class="card"
    style="border-radius:0px !important; padding: 40px;"
  >
    <p>
      {{ actionText }}
    </p>
    <br />
    <div
      style="display: flex;justify-content: center;"
      class="buttons"
    ><button
        class="button-3d-revert"
        @click="cancel"
      >
        {{ $t('no') }}
      </button>
      <button
        class="button-3d"
        @click="validate"
      >
        {{ $t('yes') }}
      </button>

      <b-loading
        :is-full-page="false"
        :active.sync="loading"
      >
      </b-loading>
    </div>
  </div>
</template>

<script>
/**
 * @name charlieUserActionValidation
 * @desc Component for validating user actions
 */
export default {
  name: 'charlieUserActionValidation',
  props: {
    /**
     * Type of action being performed
     */
    actionType: {
      type: String,
      required: true
    },
    /**
     * ID of the interview
     */
    interviewId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      /**
       * @desc Text related to the action
       * @type {string}
       */
      actionText: null,
      /**
       * @desc Loading indicator
       * @type {boolean}
       */
      loading: false
    };
  },
  mounted() {
    switch (this.actionType) {
      case 'invitation':
        this.actionText = this.$t('actionSent1');
        break;
      case 'relance':
        this.actionText = this.$t('actionSent2');
        break;
      case 'remerciement':
        this.actionText = this.$t('actionSent3');
        break;
    }
  },
  methods: {
    /**
     * @name validate
     * @desc Validates the action
     */
    async validate() {
      this.loading = true;
      this.$store
        .dispatch('addOwnerLog', {
          interviewId: this.interviewId,
          type:
            this.actionType == 'invitation'
              ? 'SENT'
              : this.actionType == 'relance'
              ? 'RESENT'
              : this.actionType == 'remerciement'
              ? 'THANKS'
              : null
        })
        .then(() => {
          this.loading = false;
          this.$emit('close');
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /**
     * @name cancel
     * @desc Cancels the action
     */
    async cancel() {
      this.$emit('close');
    }
  }
};
</script>

<style src="./index.css"></style>