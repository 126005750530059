export const es = {
    //* BUTTON *//

    addContact: 'agregar una fila',
    send: 'enviar',
    sent: 'enviado',
    validate: 'confirmar',
    download: 'descargar',
    importCSV: 'importar un archivo CSV',
    importVideo: 'importar un archivo de video',
    addQuestion: 'agregar una nueva pregunta',
    addLastQuestion: 'agregar una última pregunta',
    videoRecord: 'grabar video',
    previous: 'anterior',
    draft: 'borrador',
    seeMission: 'ver mis misiones',
    confirm: 'confirmar',
    next: 'siguiente',
    save: 'guardar',
    logOut: 'Cerrar sesión',
    buy: 'comprar',
    imageRights: 'certificado de derechos de imagen',
    downloadRush: 'archivos originales',
    addWidget: 'agregar a mi widget',
    removeWidget: 'eliminar de mi widget',
    import: 'importar un archivo',
    tryAgain: 'reintentar',
    continue: 'continuar',
    back: 'atrás',
    cancel: 'cancelar',
    delete: 'eliminar',
    duplicate: 'duplicar',
    understood: 'entendido',
    yes: 'sí',
    no: 'no',
    submit: 'enviar',
    embedButton: "incorporar en mi sitio",
    exportSubtitled: "exportar el vídeo subtitulado",
    subtitlesSaved: "Subtítulos guardados",
    errorSubtitles: "Error al guardar los subtítulos",

    //* VUE TOUR *//

    buttonSkipTour: "Saltar",
    buttonNextTour: "Siguiente",
    buttonPreviousTour: "Anterior",
    buttonStopTour: "Finalizar",
    content_directory1: "Importa un archivo CSV o agrega manualmente la lista de tus clientes.",
    content_directory2: "Encuentra rápidamente tus contactos gracias a los filtros.",
    content_directory3: "Edita o elimina definitivamente un contacto y todos sus datos.",
    content_directory4: "Aquí es donde envías tus solicitudes y donde se mostrarán los testimonios de video recopilados.",
    content_dash1: "Administra tus solicitudes de testimonios desde el directorio de clientes.",
    content_dash2: "Difunde tus testimonios de video en tu sitio web con nuestras herramientas de integración.",
    content_dash3: "Encuentra tu información personal y tus facturas en tu cuenta.",
    content_dash4: "Compra paquetes de créditos para poder desbloquear tus testimonios recibidos.",

    //* TOAST MESSAGES *//

    clientDeleted: 'Cliente eliminado correctamente',
    errorFormat: 'El formato del archivo no es compatible.',
    errorDuration: 'La duración del video es demasiado larga.',
    errorAnalysis: 'Se produjo un error durante el análisis del video.',
    errorOccured: '¡Se produjo un error!',
    errorOccuredForm: '¡Se produjo un error! ¡Falta información sobre su cliente!',
    errorInfo: "Verifica los datos de contacto de tus invitados, pueden estar incompletos o incorrectos.",
    sequenceLaunched: 'La secuencia ha sido iniciada.',
    sequenceStopped: 'La secuencia ha sido pausada.',
    validReminder: 'Los recordatorios han sido validados.',
    updateDone: 'Actualización realizada',
    saved: 'guardado',

    //* DASHBOARD *//

    hello: 'Hola',
    invites: 'invitaciones',
    welcomeWords:
        'Estoy aquí para ayudarte a obtener testimonios en video de tus clientes. Dime a quién quieres contactar y cuáles son tus preguntas, y en un instante te crearé un enlace único que puedes compartir por correo electrónico o WhatsApp.',
    welcomeWords2:
        'No corres ningún riesgo al probar mis servicios. Solo me pagan si decides desbloquear los testimonios que haya obtenido para ti.',
    testimonials: 'testimonios',
    testimony: 'testimonio',
    videoTestimonials: 'testimonios en video',
    welcome: 'Bienvenido a YourCharlie',
    explore: 'explorar el software',
    askForTestimonial: 'solicitar un testimonio',
    onboardingMessage:
        'Con esta información, tus solicitudes de testimonios serán más personalizadas para obtener mejores resultados.',
    onboardingMessage2:
        'Toma acción ahora y agrega un cliente para solicitarle un testimonio en video.',
    success: 'éxito',
    clients: "clientes",
    goodStart: 'Comienza bien con YourCharlie:',
    infoChecked: 'Completa tu información',
    infoChecked2: 'Para solicitudes más personalizadas',
    addChecked: 'Agrega 3 clientes',
    addChecked2: 'Para comenzar a llenar tu lista de contactos.',
    sendChecked: 'Envía 3 solicitudes',
    sendChecked2: 'Para tener 3 veces más posibilidades de éxito.',
    unlockChecked: 'Desbloquea 1 testimonio',
    unlockChecked2: 'Para descubrir el poder de YourCharlie.',
    publishChecked: 'Publica con el popup',
    publishChecked2: 'Para aumentar tus conversiones.',
    add1st: 'Agrega tu primer cliente',

    //* SIDE BAR *//

    missions: 'misiones',
    newMission: 'nueva misión',
    help: 'ayuda',
    sponsoring: 'patrocinio',
    selection: 'selección',

    //* SPONSORING *//

    satisfaction: '¿Qué opinas de mi trabajo?',
    sponsorWords:
        '¡Como quizás ya sepas, funciono a través de recomendaciones! Así que te propongo un trato: cada vez que invites a una empresa y se registre, les ofrezco 25 créditos a ambos.',
    oneMoment:
        'Un momento, me encargaré de enviar las invitaciones de patrocinio 😉',
    onGoingSponsor: 'Patrocinio en curso, por favor espera 😎',
    fName: 'nombre',
    lName: 'apellido',
    email: 'correo electrónico',
    phone: 'teléfono',
    yourName: 'tu nombre',
    yourLastName: 'tu apellido',
    sponsoringSuccess:
        '🟢 La inscripción de {name} {surname} ha sido validada. Se han añadido 25 créditos a tu cuenta y a la suya 🎉',
    sponsoringWait: '🟡 La inscripción de {name} {surname} está pendiente.',
    sponsoringFail:
        '🔴 La inscripción de {name} {surname} no se ha completado y el enlace ha caducado.',

    //* SPONSORED *//

    godfather: 'eres patrocinado por',
    reward:
        'tu recompensa es de 25 créditos una vez que tu inscripción sea validada',
    createAccount: 'crear una cuenta gratuita',
    invalidMail: 'Correo electrónico inválido.',

    //* SHOP *//
    '3videos': '3',
    '10videos': '10',
    '20videos': '20',
    '75credits': '75 créditos',
    '250credits': '250 créditos',
    '500credits': '500 créditos',
    '75credPrice': '60€ sin IVA',
    '250credPrice': '150€ sin IVA',
    '500credPrice': '250€ sin IVA',
    shop: 'La tienda',
    shopDesc: '1 testimonio en video = 25 créditos',
    shopMessage:
        'Compra un paquete de créditos para desbloquear los testimonios obtenidos. Cuanto más grande sea el paquete de créditos, más económico será el costo por testimonio para ti.',
    save25: 'ahorra un 25%',
    save37: 'ahorra un 37,5%',

    //* MISSIONS *//

    step1: 'Paso 1/4',
    step2: 'Paso 2/4',
    step3: 'Paso 3/4',
    step4: 'Paso 4/4',
    unlockError: '¡Ups! Parece que hubo un problema con el montaje del video.',
    unlockError2: 'Para obtener ayuda, contacta con el soporte.',
    addMissionName: 'testimonio de cliente para {business}',
    missionName: 'Nombre de la misión',
    missionFormality: 'Tono de las interacciones con tus clientes',
    missionLang: 'Idioma hablado por tus clientes',
    formelChoice: 'formal',
    amicalChoice: 'amigable',
    formelText: 'Uso del tratamiento de "usted" y un tono convencional.',
    amicalText: 'Uso del tratamiento de "tú" y un tono cálido.',
    language: 'idioma',
    missionLaunch: 'lanzar una misión',
    whoContact: 'Lista de tus clientes para invitar',
    columnFit: 'Ajustar columnas',
    ignoreFirstLine: 'Ignorar la primera línea',
    tooManyLines: 'Tu archivo debe contener menos de 50 líneas.',
    choose: 'elegir',
    ask: 'Lista de tus preguntas',
    questionHere: 'escribe tu pregunta aquí',
    customizeSenderName: 'Remitente',
    inviteText: 'Recompensa para los participantes',
    rewardBase: 'Para agradecerte, te ofrezco ',
    rewardGift: 'mi eterno agradecimiento',
    scriptDL: 'descargar la guía',
    welcomeVideo: 'Video de motivación para tus clientes',
    congrats: '¡Felicidades!',
    missionLaunchSuccess: 'Acabo de lanzar la misión.',
    missionLaunchSuccess2:
        'Enviaré las invitaciones en el mejor momento para tus destinatarios.',
    missionLaunchSuccess3:
        '¡Con este método, maximizo las posibilidades de que los mensajes sean abiertos! 😎',
    noMissionLaunched: 'Todavía no tengo misiones en curso',
    started: 'iniciada',
    vertical: 'formato vertical',
    square: 'formato cuadrado',
    verticalVideo: 'video vertical',
    squareVideo: 'video cuadrado',
    subtitled: "subtitulada",
    montage:
        '¡Aquí vamos! El montaje está en proceso. Estará listo en unos minutos 😉',
    unlockCredits: 'desbloquear con 25 créditos',
    notEnoughCredits:
        'No tienes suficientes créditos para desbloquear este testimonio',
    waitingDraft: 'Espera un momento, estoy guardando tu borrador',
    errorSavingDraft: 'Error al guardar el borrador',
    errorSavingMission: 'Error al guardar la misión',
    deleteMission: '¿Realmente quieres eliminar esta misión?',
    unlock: 'desbloquear',
    toUnlock: 'desbloquear',
    maxGuests: 'Máximo 50 clientes por misión',
    maxQuestions: 'Tus clientes tendrán 30 segundos por pregunta para responder.',
    maxVideo: 'Máximo 1 minuto y 30 segundos',
    phoneEmptyAdvice1: '¡Falta el número de teléfono!',
    phoneEmptyAdvice2:
        'Al agregarlo, podrás disfrutar de varias funciones de personalización.',
    QuestionsTitle: 'ejemplos',
    Sample_naming: 'consejos',
    Sample_invitations: 'trucos',
    Sample_customize: 'ideas',
    Sample_invit4:
        'Utiliza solo una dirección de correo electrónico por cliente. ¡Spamear a tus clientes está mal!',
    Sample_invit1:
        'Invita primero a los clientes que te hayan expresado su satisfacción por escrito o verbalmente.',
    Sample_invit2:
        'Selecciona preferentemente a los clientes con los que hayas tenido un contacto reciente. De lo contrario, aprovecha la oportunidad para informarles.',
    Sample_invit3:
        'Si un cliente rechazó en una misión anterior de YourCharlie, espera un poco antes de invitarlo nuevamente.',
    Sample_naming1:
        'Debes crear una misión por categoría de cliente para personalizar al máximo tus solicitudes de testimonios. ¡Optimizarás tu tasa de respuesta!',
    Sample_naming2: 'Aquí tienes algunos ejemplos de segmentación de clientes:',
    Sample_naming3: '🗣️ por idioma hablado',
    Sample_naming4: '🫶 por afinidad',
    Sample_naming5: '🧠 por tipo de experiencia',
    Sample_naming6: '💵 por volumen de transacción',
    Sample_question:
        'Te ofrecemos 5 preguntas para sugerirte ideas, pero puedes modificarlas como desees.',
    Sample_question1:
        '⏱ Para cada pregunta, tu cliente tendrá 30 segundos para responder.',
    Sample_question2: '❓ Puedes escribir un máximo de 5 preguntas.',
    Sample_custom_video1:
        'un regalo: un producto/servicio relacionado con tu empresa o territorio.',
    Sample_custom_video2:
        'un código de descuento: en un producto/servicio que sea de especial interés para tus clientes.',
    Sample_custom_video3:
        'una botella: Un buen vino o champán siempre alegra a tus clientes.',
    Sample_custom_video4:
        'un agradecimiento: efectivo solo si tienes una buena relación con tus clientes.',
    Sample_custom_video:
        'Ofrece una recompensa para agradecer a tus clientes por tomarse el tiempo de hacer un testimonio para ti. Aquí tienes algunas ideas:',
    tooltip_model:
        'Estos modelos te ayudarán a escribir tus solicitudes de testimonios.',
    tooltip_model2:
        'Puedes modificarlos justo antes de enviarlos al servicio que elijas.',
    tooltip_questions:
        'Tu cliente tendrá 30 segundos para responder a cada pregunta.',
    tooltip_video:
        'Este video es recomendada. Se mostrará al comienzo de tu cuestionario.',
    tooltip_video2:
        'Para la importación, máximo 1 minuto y formatos .MOV o .MP4 aceptados.',
    tooltip_reward: "Agregar una recompensa no es obligatorio,",
    tooltip_reward2: "¡pero a veces puede marcar la diferencia! 😉",
    emailAlreadyUsed: 'Este correo electrónico ya está en uso',

    //* DIRECTORY *//

    directory: 'clientes',
    addClients: 'agregar clientes',
    add: 'Agregar',
    addSmall: 'agregar',
    actionFilter: 'Acción',
    askFilter: 'Solicitar',
    recontactFilter: 'Volver a contactar',
    unlockFilter: 'Desbloquear',
    thankFilter: 'Agradecer',
    actions: 'acciones a realizar',
    relaunch: 'volver a contactar a la persona',
    contactSupport: 'Contactar al soporte',
    askTestimony: 'solicitar un testimonio',
    thanks: 'agradecer a la persona',
    unlockTestimony: 'desbloquear el testimonio',
    mounting: 'montaje de video en curso',
    status: 'estado',
    seeTestimony: 'ver el testimonio en video',
    sentDemande: 'enviar la solicitud',
    received: 'recibido desde',
    filterBy: 'Filtrar por',
    tagName: 'añadir una etiqueta',
    resent: 'volver a contactar',
    editClient: 'Editar la información del cliente',
    deleteClient: 'Eliminar el cliente',
    deleteClient1: '¿Estás seguro de que quieres eliminar permanentemente a ',
    deleteClient2:
        'y todos sus datos? Esta operación es irreversible y eliminará su información de contacto, sus derechos de imagen y todos los archivos de video.',
    actionSent1: '¿Has enviado correctamente tu solicitud de testimonio?',
    actionSent2: '¿Has vuelto a contactar correctamente a tu cliente?',
    actionSent3: '¿Has agradecido correctamente a tu cliente?',
    today: "hoy",
    yesterday: 'ayer',
    days: ' días',
    weeks: ' semanas',
    week: ' semana',
    months: ' meses',
    month: ' mes',
    unlockSuccess: '¡Testimonio desbloqueado!',


    //* INVITATION CUSTOM *//

    newTestimony: 'nuevo testimonio',
    formal: '🤝 Formal',
    amical: '🫶 Amigable',
    customMessage: 'personalización del mensaje',
    from: 'De parte de',
    destination: 'Destinatario',
    homeVideo: 'Video de inicio',
    invitLink: 'Obtener el enlace',
    copyLink: 'copiar el enlace',
    sendWith: 'Enviar con',
    selectModel: 'Seleccionar un modelo de mensaje',
    linkCopied: 'Enlace copiado en el portapapeles',
    addIncentive: "Recompensa",
    addRewardInput: "Ofrezca una recompensa a sus clientes",

    //* SELECT MODEL *//

    slectModel: 'Seleccionar un modelo',
    select: 'seleccionar',

    //* RECORDER *//

    sendingVideo: 'Enviando tu video...',
    testimonialSent: '¡Tu testimonio ha sido enviado exitosamente!',
    unexpectedError: 'Error inesperado',

    //* MESSAGE CHARLIE *//

    waitMission: 'Creando la misión {getInterviewTemplate}...',
    readyToLaunch: 'Estoy listo para lanzar la misión {missionName}.',
    askedLaunch: 'Has hecho {asked} pregunta.',
    askedLaunch2: 'Has hecho {asked} preguntas.',
    invitedLaunch: 'Has invitado a {invited} persona.',
    invitedLaunch2: 'Has invitado a {invited} personas.',
    finalLaunch: '¿Está todo bien para ti?',
    forgotNameMission: 'Ups, olvidaste darle un nombre a la misión.',
    needName: 'Necesito un nombre, un apellido y un correo electrónico.',
    needQuestion: 'Necesito al menos una pregunta para continuar.',
    needInviteText: 'Necesito un video de invitación y una recompensa para continuar.',
    missionError: 'Error al crear la misión',
    launchSuccess: '¡Solicitud creada!',
    draftRegistered: 'Borrador registrado',
    waitingAnswer: 'esperando respuesta',
    decline: 'rechazó participar el',
    clicked: 'hizo clic',
    downloading: 'Descargando...',
    noFile: 'No hay archivos para descargar',
    hiCharlie: 'Hola {userSurname}, soy YourCharlie 👋',
    questionToWitness: 'Entonces, aquí está la primera pregunta de {ownerSurname}:',
    beforeStartingMessage:
        '¡Qué bueno verte aquí! {ownerSurname} está ansioso por ver tu testimonio. Por cierto, {ownerSurname} me dejó un mensaje para ti:',
    acceptImageRights:
        'Antes de terminar, ¿puedes confirmar que aceptas ceder tus derechos de imagen a {ownerBusiness}?',
    seeYouSoon: '¡Perfecto! Gracias por tu participación. ¡Lo hiciste genial! ¡Hasta pronto! 😉',
    testimonyCollected: 'Este testimonio ya ha sido recopilado.',
    welcomingCharlie: 'Hola y bienvenido. Soy YourCharlie 👋',
    andYou: '¿Y tú?',
    enterFirstName: 'Ingresa tu nombre aquí',
    niceToMeet: 'Mucho gusto, {userSurname} 😉',
    goodWork: '¡Vamos a hacer un buen trabajo juntos!',
    experienceEnhancer: 'Antes de comenzar, tengo algunas preguntas para mejorar tu experiencia',
    enterLastName: 'Ingresa tu apellido aquí',
    companyName: '¿Cuál es el nombre de tu empresa?',
    enterCompanyName: 'Ingresa el nombre aquí',
    phoneNumber: '¿Cuál es tu número de teléfono móvil?',
    enterPhoneNumber: 'Ingresa tu número aquí',
    companyColor: '¿Cuál es el color principal de {ownerBusiness}?',
    pickColor: 'Selecciona el color aquí',
    wellDone: '¡Excelente! Todo está bien desde mi lado.',
    navError: 'Ups... 😣 La función de grabación no funciona con este navegador... ¡Intenta con otro!',
    provideEmail: 'Por favor, proporciona un correo electrónico',


    //* PROFILE *//

    profile: 'Mi perfil',
    entreprise: 'empresa',
    invoice: 'Facturas',
    date: 'Fecha',
    description: 'Descripción',
    amount: 'Monto',
    color: 'color de los videos',
    newColor: 'Nuevo color guardado',
    invalidColor: 'Color inválido',
    infoRegistered: 'Información registrada',

    // APPSUMO PROILE

    dealSentence: 'Actualmente estás en una oferta de por vida de AppSumo 👏',
    codeRedeemed: 'Códigos canjeados',
    monthCredits: 'Créditos por mes',
    purchaseSentence: "Si eres uno de los afortunados que compró más de un código de AppSumo, estás en el lugar correcto. Por favor, agrega aquí tu código adicional.",
    applyCode: 'aplicar código',
    appsumoCodeError: 'Lamentablemente, este código no es válido 😕',
    codeStacked: 'Felicitaciones, el código se ha agregado exitosamente.',

    //* SETTINGS *//

    audioSource: 'Fuente de audio',

    //* LOGIN/INSCRIPTION *//

    password: 'contraseña',
    lostPassword: 'Olvidé mi contraseña',
    alreadyRegistered: '¿Ya tienes una cuenta? Inicia sesión ahora',
    noAccount: '¿No tienes una cuenta? Regístrate ahora',
    serviceConnection: 'Un momento, estoy conectándote al servicio',
    invalidEmail: 'Correo electrónico inválido.',
    passwordRequirement:
        'Tu contraseña debe tener al menos 8 caracteres, incluyendo una mayúscula, una minúscula, un número y un carácter especial.',
    alreadyUsedEmail: 'Correo electrónico ya utilizado. ¿Quizás te registraste con Google?',
    invalidPassword: 'Error de contraseña',
    connectionError: 'Error de conexión',
    connectionCancelled: 'Conexión cancelada',
    connection: 'Conexión',
    register: 'Registrar',
    unknownError: 'Error desconocido',
    blockedPopup: 'Bloqueo de ventanas emergentes. Por favor, permítelo',
    googleRegister: 'Registrarse con Google',
    googleConnect: 'Iniciar sesión con Google',
    or: 'O',
    logIn: 'Iniciar sesión',
    resetPassword: 'Restablecer contraseña',
    emailUsed:
        'Ingresa la dirección de correo electrónico asociada a tu cuenta y te enviaremos un enlace para restablecer tu contraseña.',
    emailSent:
        'Se ha enviado un correo electrónico a la dirección proporcionada. Solo tienes que seguir las instrucciones para restablecer tu contraseña 😎',
    registerGCU: 'Al registrarte, confirmas que has leído y aceptado nuestros',
    GCU: 'CGU',
    registerGCU2: 'así como nuestra',
    PDC: 'Política de Privacidad.',
    seeGCU: 'Ver nuestros',
    loginListBold1: 'Paga por rendimiento',
    loginList1: 'Sin suscripción ni compromiso.',
    loginListBold2: 'Lanza misiones de forma gratuita',
    loginList2: 'No se requiere tarjeta de crédito.',
    loginListBold3: 'El equipo de YourCharlie te acompaña',
    loginList3: 'Cuidamos de ti de lunes a viernes de 9 a 17 horas.',
    new: 'NUEVO',
    display: 'Display',
    trusted: 'la insignia de confianza',
    onyourwebsite: 'en su sitio para tranquilizar a sus visitantes',
    passwordRepeat: 'repetir la contraseña',
    passwordsDontMatch: 'Las contraseñas no coinciden',

    //* IMPORT *//

    chooseFile: 'Ok, elige un archivo ...',
    acceptedFormat: 'formatos aceptados:',
    mediaDuration: 'duración máxima del video {mediaDuration} segundos',
    videoImportModal1:
        'importa un video motivacional para animar a tus clientes a grabar un testimonio para ti',
    videoImportModal2: 'duración del video: máximo 1 minuto y 30 segundos',
    videoImportModal3: 'formatos aceptados: mp4, mov, avi',

    //* STOP PAGE *//

    stop_title: 'Qué pena {surname}, estoy muy triste 😢',
    stop_text1: 'Solo pido 3 minutos adicionales,',
    stop_text2: 'para guardar un bonito recuerdo de nuestro viaje juntos',
    recorderNotification: 'Qué lástima... ¿Quizás será para la próxima vez?',

    //* VIDEO *//

    urlVideoOnboarding: 'https://www.youtube.com/embed/xtBvLvQJ36c',
    urlVideoLogin: 'https://player.vimeo.com/video/773707744',
    urlVideoAffiliation: 'https://www.youtube.com/embed/qr3cMP2MsAs?si=LY6BWqPRK1QG9UwA',

    //* RECORDER V2 *//

    actualQuestion: 'pregunta',
    helloThere1: 'Bienvenido',
    helloThere2: 'Estás a punto de grabar un testimonio en video.',
    recordingTips: "Ver los consejos de YourCharlie",
    recordingTips1: '30 segundos por pregunta',
    recordingTips1Q: "preguntas",
    recordingTips2: 'Reinicia o valida para pasar a la siguiente pregunta.',
    recordingTips3: 'Mejora la calidad del audio con un "kit manos libres".',
    recordingTips4: "No olvides sonreír, es por diversión",
    homeRecorderReward: "Recompensa:",
    begin: 'grabar mi testimonio',
    MThanks: 'Gracias por testificar !',
    endingCongrats1: 'Hecho con YourCharlie, el software que simplifica los testimonios en video.',
    endingRewards: 'Promesa hecha, promesa cumplida 🎁',
    iOffer: '¡está en camino!',
    referalCharlie: 'recopilar testimonios en video',
    discoverCharlie: 'Descubre YourCharlie',
    modalRetry: 'Hola 👋',
    modalRetry2: 'Ya has respondido algunas preguntas. ¿Quieres empezar de nuevo o continuar donde lo dejaste?',
    fatalErrorRecord: "¡Ups! Un problema técnico parece estar impidiendo la grabación de su testimonio. Este problema podría estar relacionado con el dispositivo que está utilizando actualmente. ¿Podría intentar la experiencia desde otro dispositivo, por favor? Su contribución es muy valiosa para nosotros. ¡Gracias! 🌟",
    tooShortRecord: "¡Ups! Parece que tu respuesta es demasiado corta. ¿Te gustaría grabarla de nuevo? Tu contribución es muy valiosa para nosotros. ¡Gracias! 🌟",

    //* INTEGRATION *//

    addedToPlaylist: 'Testimonio de video agregado al widget',
    removedFromPlaylist: 'Testimonio de video eliminado del widget',
    copyCode: 'copiar código',
    widgetPres1: "El widget YourCharlie en tu sitio es lo mejor para",
    widgetPres1b: 'aumentar tu tasa de conversión',
    widgetPres2:
        'Descubre los dos pasos para agregar fácilmente testimonios en video en tu sitio.',
    widgetStep1: 'Paso 1 - Selecciona un máximo de 5 videos',
    Step1expA: 'Para agregar un video al widget, ve a',
    Step1expB: 'Junto a cada testimonio recibido, tienes el botón ',
    Step1expC: 'agregar al widget',
    widgetStep2: 'Paso 2 - Agrega el widget en tu sitio',
    Step2expA: 'Para agregar este widget en todas las páginas de tu sitio,',
    Step2expB: 'haz clic en ',
    Step2expC: 'copiar el código',
    Step2expD: 'y pégalo en la sección <HEAD> de tu sitio web.',
    WordpressWidget:
        'Para agregar el widget a tu sitio de WordPress, es muy sencillo:',
    wpDownload: "descargar la extensión",
    wpStep1a: "Descarga la extensión ",
    wpStep1c: 'haciendo clic en el botón de aquí',
    wpStep2a: 'En tu panel de control ',
    wpStep2b: ', ve a Extensiones > Añadir',
    wpStep3:
        "Haz clic en el botón ''Subir extensión'' y carga el archivo zip",
    wpStep4: "Haz clic en ''Instalar ahora'' y luego activa la extensión",
    wpInstallEnd:
        "Una vez instalada y activada la extensión, puedes acceder a la página de configuración yendo al menú de la izquierda > YourCharlie",
    chart_title: 'Carta de confianza',
    chart_purpose:
        'Para obtener el distintivo de autenticidad otorgado por YourCharlie, me comprometo a cumplir con los siguientes puntos:',
    chart_list_1:
        'Invito a clientes reales de mi empresa con quienes he tenido al menos una transacción financiera a cambio de un producto o servicio.',
    chart_list_2:
        'Afirmo que mis clientes no han negociado ninguna compensación a cambio de grabar un testimonio en video.',
    chart_list_3:
        'No he intentado influir en las palabras del cliente proporcionando un guion o respuestas preparadas.',
    chart_list_4:
        'Entiendo que no puedo modificar la selección de testimonios en video para no engañar a mis visitantes.',
    chart_list_5:
        'Acepto la autenticidad de mis clientes y, por lo tanto, la posibilidad de que los testimonios no sean perfectos en términos de imagen o forma de expresarse.',
    integ_page_title: 'Demuestra que se puede confiar en ti',
    integ_purpose1: 'A veces es difícil distinguir lo real de lo falso.',
    integ_purpose2:
        'Con el distintivo YourCharlie, aumenta tu credibilidad y tranquiliza a tus prospectos',
    integ_purpose3:
        'mostrando pruebas sociales auténticas de tus clientes.',
    popup_title: 'Integra el pop-up de YourCharlie en tu sitio',
    popup_1:
        'El pop-up se muestra superpuesto en la esquina izquierda de tu sitio. Tus visitantes hacen clic en él para verlo en grande y escuchar tus testimonios.',
    popup_2:
        'Para agregar el pop-up en todas las páginas de tu sitio, haz clic en ',
    popup_3: 'y pégalo en la sección "<head>" de tu sitio web',
    slider_title: 'Integra el carrusel de YourCharlie en tu sitio',
    slider_1:
        'El carrusel se muestra donde quieras en tu sitio, en la página que elijas. Tus visitantes hacen clic en los videos para activar el sonido y pueden desplazarse por ellos.',
    slider_2:
        'Para agregar el carrusel en una página de tu sitio, haz clic en ',
    slider_3: ' y pégalo en una etiqueta HTML de tu sitio web',
    slider: 'carrusel',

    //* INTEGRATION V2 *//

    interestingTools: 'herramientas que pueden interesarte',
    myTools: "mis herramientas",
    freePopDesc: "Tus últimas 5 testimonios en un reproductor superpuesto en tu sitio.",
    freeSliderDesc: "Tus últimas 5 testimonios en un carrusel en una página de tu sitio.",
    bigPopDesc: "Hasta 15 testimonios en un reproductor superpuesto en tu sitio.",
    bigSliderDesc: "Hasta 15 testimonios en un carrusel fácil de integrar en una página.",
    wallDesc: "Testimonios de video ilimitados en una galería en tu sitio web.",
    lockedPopSliderText: "Para crear una selección personalizada de testimonios, añada un Big Pop-up o un Big Slider.",
    builderWallDesc: "Arrastra tus testimonios de la lista de la izquierda y suéltalos abajo para organizar los vídeos en tu Muro del Amor. Puedes añadir tantos vídeos como quieras.",
    builderBigPopDesc: "Arrastre sus testimonios de la lista de la izquierda y suéltelos debajo para organizar los vídeos.",
    builderBigPopDesc2: "Puedes añadir hasta 15 testimonios en vídeo.",
    builderBigSliderDesc: "Arrastre sus testimonios de la lista de la izquierda y suéltelos debajo para organizar los vídeos.",
    builderBigSliderDesc2: "Puedes añadir hasta 15 testimonios en vídeo.",
    builderFreePopDesc: "Con el Pop-Up gratuito, usted muestra automáticamente sus 5 últimos testimonios en vídeo desbloqueados en un reproductor de vídeo superpuesto en su sitio web.",
    builderFreeSliderDesc: "Con el Free Slider, puedes mostrar automáticamente sus 5 últimos testimonios en vídeo desbloqueados en un carrusel que puedes integrar fácilmente en una página de tu sitio web.",
    builderWallExemple: "¡Bienvenido al Muro del Amor! Vea los {X} auténticos testimonios en vídeo de {Business}.",
    sorry: "Lo siento.",
    sorryText1: "Esta función no está disponible en dispositivos móviles o tabletas.",
    sorryText2: "Por favor, accede desde un ordenador para crear tu widget.",
    remainingDays: "{count}d restantes",
    alreadyAdded: 'ya añadido',

    //* MODAL INTEGRATION V2 *//

    installWall: 'Integra el Wall of Love directamente en una página de tu sitio web con este código.',
    installPopup: 'Integra este popup directamente en tu sitio web con el código.',
    installSlider: 'Integra este slider directamente en una página de tu sitio web con el código.',
    codeCreation1: 'está listo para transmitir.',
    codeCreation2: 'Copia el código a continuación para integrarlo en tu sitio.',
    modalNeedHelp: "Consulte el tutorial de integración.",
    modalLinkTitle: 'Comparte el enlace de tu página dedicada en las redes sociales o en un correo electrónico.',
    modalShopTitle: 'Tu selección está lista para su emisión 🔥',
    modalShopDesc1: 'Ha seleccionado un widget de emisión de pago. Elija la duración de visualización que desee.',
    modalShopDesc2: 'Puedes aumentar la duración en cualquier momento.',
    chooseDuration: "elegir una duración",
    inviteNow: 'invitar ahora',
    customize: 'Personalizar',
    addImage: 'Agregar una imagen',
    sliderSettingsTitle: "Título",
    sliderSettingsDesc: "Descripción",
    sliderSettingsWebsite: "www.misitio.com",
    playlistName: "nombre de la lista de reproducción",
    notEnoughCreditsDuration: "Ups, no tienes suficientes créditos para comprar esta opción.",
    popular: "POPULAR",
    newTool: "NUEVO",

    //* AFFILIATION *//

    becomeAffiliate: "Conviértete en embajador de YourCharlie",
    affiliateDesc1: "Recomienda YourCharlie y ",
    affiliateDesc2: "gana un 10% de por vida",
    affiliateDesc3: " en las ventas de tus afiliados.",
    affiliateDesc4: "Tus afiliados obtienen ",
    affiliateDesc5: "10€ de descuento",
    affiliateDesc6: " en su primera compra de créditos.",
    learnMore: 'saber más',
    affiliateAccount: "Crea tu cuenta ",
    affiliateAccount2: " en FirstPromoter",
    affiliateAccountDesc1: "Al crear tu cuenta en Firstpromoter, accede a una herramienta completa para gestionar y rastrear automáticamente las inscripciones de tus afiliados.",
    affiliateAccountDesc2: "Gracias a tu enlace de afiliado disponible en FirstPromoter,s afiliados se registran en YourCharlie. Obtienen tuundefined10€ de descuento en su primera compra.",
    affiliateAccountDesc3: "Cada vez que uno de tus afiliados realiza una compra en YourCharlie, obtienes una comisión del 10%. ¡Y esto, sin límite de tiempo!",
    affiliateLink1: "Los afiliados se registran ",
    affiliateLink2: "con tu enlace",
    affiliateReward1: "Recibes ",
    affiliateReward2: "tu comisión del 10%",
    becomeAffiliateSmall: "convertirse en embajador",

    //* SUBEDITOR *//

    Génération: "Generación",
    Incrustation: "Superposición",
    onGoingSub: "subtítulos en progreso. Por favor, espere.",
    subtitles: "subtítulos",
    imageRightsS: "derechos de imagen",

    //* APPSUMO *//

    appsumo1: "Sólo tienes que compartir tu nombre, correo electrónico y código Appsumo para inscribirte en YourCharlie. Después de enviarlo estarás listo para recoger tus pruebas sociales en vídeo. ",

    //* PREMIUMVUE *//

    premiumTitle1: "YourCharlie Premium",
    premiumTitle2: "Descubre YourCharlie Premium",
    premiumSentence1: "Actualmente eres un miembro premium, un estatus exclusivo reservado para muy pocos de nuestros clientes.",
    premiumSentence2: "Tu director de proyecto dedicado es Axel.",
    premiumSentence3: "¿Quieres testimonios en video pero no puedes encargarte de ellos tú mismo? ¿Tienes poco tiempo?",
    premiumSentence4: "¿Tienes poco tiempo o no sabes cómo proceder?",
    premiumSentence5: "Estamos aquí para simplificarte la vida gestionando tu cuenta de principio a fin. Nuestro equipo dedicado hará las preguntas correctas a los clientes adecuados, los motivará y convencerá para grabar un testimonio en video.",
    premiumSentence6: "No tienes que preocuparte por los seguimientos, los manejamos manualmente para maximizar las tasas de respuesta.",
    premiumSentence7: "Con nosotros, puedes concentrarte en hacer crecer tu negocio y dejar que el equipo de YourCharlie se encargue del resto.",
    premiumSentence8: "Atención, nuestra oferta es exclusiva y está limitada a solo 10 emprendedores por mes.",
    premiumSentence9: "A partir de ",
    premiumSentence10: "€89.99 excl. IVA / mes",
    premiumButton1: "Contactar a Axel",
    premiumButton2: "Gestionar mi suscripción",
    premiumButton3: "Descubrir YourCharlie Premium",
    tooltipApi: "ver documentación de la API",

    /** API BLOCK **/

    apiSentence1: "Esta clave te permite usar YourCharlie con herramientas de automatización como Make o Zapier. También puedes necesitarla para desarrollos personalizados basados en nuestra API.",
    apiSentence2: "Atención:",
    apiSentence3: "Por razones de seguridad, cada clave es única. Deberás generar una nueva",
    apiSentence4: "si pierdes la primera clave generada.",
    seeApiDoc: "ver documentación de la API",

};
