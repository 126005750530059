<template>
  <section>
    <div
      class="tile is-parent is-vertical"
      style="margin: 0 auto; background-color: white !important"
    >
      <br />
      <div>
        <p style="padding-bottom: 10px;">{{ $t('modalLinkTitle') }}</p>
        <div style="background-color: #d9dd1b;   height: 60px; padding-left: 20px;   justify-content: space-between;align-items: center; font-weight: 600; text-align: center; display: flex;">
          <span style="font-weight: 500; color: #161032">{{ diffusionUrl }}{{ playlist?.shortExternalId }}</span>
          <button
            style="background-color: #f0f0f0; width: 150px; color: #161032; border: 1px solid #e0e0e0; font-weight: bold; height: 100%"
            @click="copy()"
          >{{ $t('copyLink') }}</button>
        </div>

      </div>
    </div>
  </section>
</template>
<script>
/**
 * @name modalLink
 * @desc Component for displaying and copying a modal link
 */
export default {
  name: 'modalLink',
  props: {
    /**
     * Playlist object containing information
     */
    playlist: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      /**
       * @desc URL for sharing the modal link
       * @type {string}
       */
      diffusionUrl:
        process.env.VUE_APP_SHARE_URL +
        '/' +
        this.$store.getters.activeUser.business +
        '-'
    };
  },
  methods: {
    /**
     * @name copy
     * @desc Copies the modal link to the clipboard
     */
    copy() {
      const el = document.createElement('textarea');
      el.value = this.diffusionUrl + this.playlist?.shortExternalId;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);

      navigator.clipboard.writeText(el.value).then(
        () => {
          this.$buefy.toast.open({
            message: this.$t('Copié'),
            type: 'is-success',
            position: 'is-bottom-right'
          });
        },
        (err) => {
          console.error('Async: Could not copy text: ', err);
        }
      );
    }
  }
};
</script>
<style src="../index.css"></style>