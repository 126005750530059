
<template>
  <section>
    <b-field class="searchWrapper">
      <b-input
        v-model="filter"
        placeholder="Email, nom, prénom, business"
        icon="
        magnify"
      ></b-input>
      <b-button
        type="is-primary"
        icon-left="search"
        @click="refreshList(true)"
        :loading="loading"
      >
        {{ $t('recherche') }}
      </b-button>
    </b-field>
    <b-table
      :data="dataUsers"
      :hoverable="isHoverable"
      :loading="loading"
      paginated
      backend-pagination
      :total="total"
      :per-page="perPage"
      @page-change="onPageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      backend-sorting
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      @sort="onSort"
      @click="onUserClicked($event)"
    >
      <b-table-column
        field="idUser"
        label="id"
        sortable
        v-slot="props"
      >

        <b-tag style="background-color: #ffffff">
          {{ props.row.id }}</b-tag>
      </b-table-column>
      <b-table-column
        field="lang"
        v-slot="props"
      >
        <span v-if="props.row.locale == 'fr'">🇫🇷</span>
        <span v-if="props.row.locale == 'en'">🇬🇧</span>
        <span v-if="props.row.locale == 'es'">🇪🇸</span>
        <span v-if="props.row.locale == 'it'"> 🇮🇹 </span>

      </b-table-column>
      <b-table-column
        sortable
        v-slot="props"
      >
        <div style="width:30px !important;height: 30px !important; display: flex; justify-content: center; align-items: center;">
          <span v-if="props.row.userSource == 'search'">
            <img
              style="height:20px; width: 20px"
              src="./../../../public/img/google.png"
            />
          </span>
          <span v-if="props.row.userSource == 'youtube'">
            <img
              style="height:20px; width: 20px"
              src="./../../../public/img/youtube_noir.png"
            />
          </span>
          <span v-if="props.row.userSource == 'linkedin'">
            <img
              style="height:20px; width: 20px"
              src="./../../../public/img/linkedin_noir.png"
            />
          </span>
          <span v-if="props.row.userSource == 'facebook'">
            <img
              style="height:25px; width: 25px"
              src="./../../../public/img/facebook_noir.png"
            />
          </span>
          <span v-if="props.row.userSource == 'instagram'">
            <img
              style="height:25px; width: 25px"
              src="./../../../public/img/instagram_noir.png"
            />
          </span>
          <span v-if="props.row.userSource == 'tiktok'">
            <img
              style="height:25px; width: 25px"
              src="./../../../public/img/tiktok_noir.png"
            />
          </span>
          <span v-if="props.row.userSource == 'webikeo'">
            <img
              style="height:20px; width: 20px"
              src="./../../../public/img/webikeo.png"
            />
          </span>
          <span v-if="props.row.userSource == 'gartner'">
            <img
              style="height:100%"
              src="./../../../public/img/gartner_noir.png"
            />
          </span>
          <span v-if="props.row.userSource == 'appsumo'">
            <img
              style="height:20px; width: 20px"
              src="./../../../public/img/sumo.png"
            />
          </span>
          <span v-if="props.row.userSource == 'affiliation'">
            <img
              style="height:20px; width: 20px"
              src="./../../../public/img/firstpromoter.png"
            />
          </span>
          <span v-if="props.row.userSource == 'referral'">
            <img
              style="height:18px; width: 20px"
              src="./../../../public/img/star-solid.png"
            />
          </span>
          <span v-if="props.row.userSource == 'other'">
            <img
              style="height:22px;"
              src="./../../../public/img/question-solid.png"
            />
          </span>
          <span v-else>
          </span>
        </div>

      </b-table-column>
      <b-table-column
        field="createdAt"
        label="Inscription"
        sortable
        v-slot="props"
      >
        <b-tag style="background-color: #fbfcfb">
          {{ props.row.createdAt | moment }}</b-tag>
      </b-table-column>

      <b-table-column
        field="business"
        label="Business"
        sortable
        v-slot="props"
      >
        {{ props.row.business }}
      </b-table-column>

      <b-table-column
        field="surname"
        label="Firstname"
        sortable
        v-slot="props"
      >
        {{ props.row.surname }}
      </b-table-column>
      <b-table-column
        field="name"
        label="Lastname"
        sortable
        v-slot="props"
      >
        {{ props.row.name }}
      </b-table-column>

      <b-table-column
        field="email"
        label="Email"
        sortable
        v-slot="props"
      >
        {{ props.row.email }}
      </b-table-column>
      <b-table-column
        field="phoneNumber"
        label="Phone"
        sortable
        v-slot="props"
      >

        <span v-if="props.row.phoneNumber == null || props.row.phoneNumber.length <= 6"> 📵</span>
        <span v-else> {{ props.row.phoneNumber }}</span>
      </b-table-column>
      <b-table-column
        field="lastContact"
        label="Last Contact"
        v-slot="props"
      >
        <b-tag :style="{
          backgroundColor:
            props.row.lastContact &&
              new Date(props.row.lastContact) >
              new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 30) ? '#fbfcfb' : '#fc9f9f'
        }">
          {{ (props.row.lastContact ? props.row.lastContact : props.row.createdAt) | moment }}</b-tag>
      </b-table-column>
      <b-table-column
        field="lockedInterviews"
        label="Locked"
        v-slot="props"
      >
        <b-tag
          v-if="props.row.lockedInterviews >= 1"
          style="background-color: #fbfcfb"
        >🔒 {{
          props.row.lockedInterviews
        }}</b-tag>
        <b-tag
          v-else
          style="background-color: white !important"
        ></b-tag>
      </b-table-column>
      <b-table-column
        field="startedMissions"
        label="Invitation"
        v-slot="props"
      >
        <b-tag
          v-if="props.row.startedMissions >= 1"
          style="background-color: #fbfcfb"
        >{{
          props.row.startedMissions
        }} 🚀</b-tag>
        <b-tag
          v-else
          style="background-color: white !important"
        ></b-tag>
      </b-table-column>
      <b-table-column
        field="credits"
        label="Credits"
        v-slot="props"
      >
        <b-tag
          v-if="props.row.credits != 0"
          style="background-color: #f7db5e"
        >{{ props.row.credits }}
        </b-tag>
        <b-tag
          v-else
          style="background-color: #fbfcfb"
        >{{
          props.row.credits
        }}</b-tag>
      </b-table-column>
    </b-table>

  </section>
</template>
  
<script>
import moment from 'moment';

/**
 * @name allUsers
 * @desc Component for displaying all users in the admin panel.
 */
export default {
  name: 'allUsers',
  components: {},
  filters: {
    /**
     * Filter to format date using moment.js in French locale.
     * @param {Date} date - The date to format.
     * @returns {string} - Formatted date.
     */
    moment: function (date) {
      moment.locale('fr');
      return moment(date).format('LLL');
    }
  },
  props: {
    /**
     * Indicates if the user is a premium user.
     * @type {Boolean}
     * @default false
     */
    premium: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      /**
       * @desc Array of users data.
       * @type {Array}
       */
      dataUsers: [],
      /**
       * @desc Flag to enable hover effect.
       * @type {Boolean}
       */
      isHoverable: true,
      /**
       * @desc Flag to indicate loading state.
       * @type {Boolean}
       */
      loading: false,
      /**
       * @desc Filter value for searching users.
       * @type {null}
       */
      filter: null,
      /**
       * @desc Field to sort users by (default: createdAt).
       * @type {String}
       */
      sortField: 'createdAt',
      /**
       * @desc Sort order for users (default: desc).
       * @type {String}
       */
      sortOrder: 'desc',
      /**
       * @desc Default sort order.
       * @type {String}
       */
      defaultSortOrder: 'desc',
      /**
       * @desc Current page number.
       * @type {Number}
       */
      page: 1,
      /**
       * @desc Number of users per page.
       * @type {Number}
       */
      perPage: 20,
      /**
       * @desc Total number of users.
       * @type {Number}
       */
      total: 0
    };
  },
  beforeMount() {
    this.refreshList();
  },
  methods: {
    /**
     * Refreshes the list of users.
     * @param {boolean} reset - Indicates if the list should be reset.
     */
    refreshList(reset = false) {
      this.loading = true;
      if (reset) this.page = 1;
      this.$store
        .dispatch('getAdminUsersPaginated', {
          page: this.page - 1,
          limit: this.perPage,
          sort: this.sortField,
          order: this.sortOrder.toUpperCase(),
          filter: this.filter && this.filter != '' ? this.filter : undefined,
          premium: this.premium
        })
        .then((response) => {
          this.dataUsers = response.data[0];
          this.loading = false;
          this.total = response.data[1];
        })
        .catch((error) => {
          console.error(error);
          this.loading = false;
          this.$buefy.toast.open({
            message: this.$t('errorOccured'),
            type: 'is-danger',
            queue: false,
            position: 'is-top-right',
            duration: 5000
          });
        });
    },
    /**
     * Handles page change event.
     * @param {number} page - The new page number.
     */
    onPageChange(page) {
      this.page = page;
      this.refreshList();
    },
    /**
     * Handles sorting of users.
     * @param {string} field - The field to sort by.
     * @param {string} order - The sort order.
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.refreshList();
    },
    /**
     * Handles user click event.
     * @param {object} user - The user object clicked.
     */
    onUserClicked(user) {
      this.$emit('userClicked', user.user.id);
    }
  }
};
</script>
  
<style src="../index.css"></style>