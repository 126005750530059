<template>
  <section>
    <h1>
      {{ $t('myTools') }} <span style="font-size: 16px !important">
        ({{ $store.getters.playlists.length }})</span>
    </h1>
    <br />
    <div
      v-if="$store.getters.playlists.length == 0"
      style="background-color: #e7e1ff ; color:#7957d5; padding: 20px; margin-bottom: 10px; margin-top: 26px;"
    >{{
        $t('integDesc') }}</div>

    <div
      v-else
      class="tile is-ancestor"
      style="overflow-x: scroll;"
    >
      <div
        class="tile is-5 is-parent"
        style="gap:20px"
      >

        <div
          class="tile is-4 is-child"
          style="padding-bottom: 10px;"
          v-for="playlist of $store.getters.playlists"
          :key="playlist.id"
          @click="$router.push({ name: 'integrationDetails', params: { id: playlist.id } })"
        >
          <div class="card">
            <figure class="image is-square">
              <img
                v-if="playlist.type == 'popup' && playlist.free"
                src="./../../../public/img/Popup.png"
                alt="Placeholder image"
              />
              <img
                v-else-if="playlist.type == 'slider' && playlist.free"
                src="./../../../public/img/Slider.png"
                alt="Placeholder image"
              />
              <img
                v-else-if="playlist.type == 'popup' && !playlist.free"
                src="./../../../public/img/PopupXL.png"
                alt="Placeholder image"
              />
              <img
                v-else-if="playlist.type == 'slider' && !playlist.free"
                src="./../../../public/img/SliderXL.png"
                alt="Placeholder image"
              />
              <img
                v-else-if="playlist.type == 'wall'"
                src="./../../../public/img/WallOfLove.png"
                alt="Placeholder image"
              />
              <img
                v-else
                src="https://bulma.io/images/placeholders/256x256.png"
              />
            </figure>
            <div
              class="card-content"
              style="padding: 10px;"
            >
              <p
                class="title is-5"
                style="margin-bottom: 5px; font-size: 16px; height:60px"
              >{{ playlist.title ||
                playlist.type }}</p>
              <div style="display: flex; align-items: center; gap: 5px;">
                <p class="flip-horizontal">⏳</p>
                <p
                  :style="{ color: computeRemainingDaysColor(playlist) }"
                  style="font-size: 13px;"
                >{{ playlist.free ?
                  'Illimité' :
                  computePlaylistRemainingDays(playlist) }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="tile is-ancestor">
      <div class="tile is-parent is-vertical">
        <div class="box" style="height: 150px !important; width: 150px; margin-bottom: 10px;">
        </div>
        <p style="font-weight: 600; margin-left: 8px;">carousel</p>
      </div>
    </div> -->

    <br />
  </section>
</template>
  

<script>
import { mapState } from 'vuex';

/**
 * @name mytools
 * @desc Component for managing tools
 */

export default {
  name: 'mytools',
  data: function () {
    return {};
  },
  /**
   * @name beforeMount
   * @desc Dispatches action to get playlists before component is mounted
   */
  beforeMount() {
    this.$store.dispatch('getPlaylists');
  },
  methods: {
    /**
     * @name computePlaylistRemainingDays
     * @desc Computes the remaining days for a playlist based on its expiration date
     * @param {object} playlist - The playlist object
     * @returns {string} - Translated string with the remaining days
     */
    computePlaylistRemainingDays(playlist) {
      const now = new Date();
      const diff = new Date(playlist.expiresAt) - now;
      const days = Math.ceil(diff / (1000 * 60 * 60 * 24));
      return this.$t('remainingDays', { count: days });
    },
    /**
     * @name computeRemainingDaysColor
     * @desc Computes the color based on the remaining days of a playlist
     * @param {object} playlist - The playlist object
     * @returns {string} - Color code for styling
     */
    computeRemainingDaysColor(playlist) {
      const now = new Date();
      const diff = new Date(playlist.expiresAt) - now;
      const days = Math.ceil(diff / (1000 * 60 * 60 * 24));
      return days <= 30 && !playlist.free ? '#f1054d !important' : '#161032';
    }
  }
};
</script>


<style src="./../../components/index.css"></style>


<style scoped>
.flip-horizontal {
  -webkit-animation: flip-horizontal 1.5s
    cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite both;
  animation: flip-horizontal 1.5s cubic-bezier(0.455, 0.03, 0.515, 0.955)
    infinite both;
}

@keyframes flip-horizontal {
  0% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
  }

  50% {
    -webkit-transform: rotateX(-180deg);
    transform: rotateX(-180deg);
  }

  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
  }
}
</style>