<template>
  <div class="backgroundLogin">
    <div class="container">
      <div
        style="margin: 0 auto;"
        class="tile is-ancestor is-vertical"
      >
        <br />
        <div
          style="right: 30px; top: 15px; position: absolute"
          class="tile is-ancestor is-vertical"
        >
        </div>
        <br /><br />
        <div class="tile is-ancestor login__box">
          <!-- <div class="tile is-5  is-parent login__box-tips ">
            <h3></h3>
            <div class="login__box-player">
              <img style="padding: 15px; vertical-align: middle" alt="Getinshoot logo"
                src="../../public/img/bienvenuesurcharlie.jpg" />
            </div>
            <br />
            <ul class="login__box_tips-position">
              <li class="login__box-fontStyle">✔️ &nbsp;{{ $t('loginListBold1') }}</li>
              <p class="login__box-fontStyle-small">{{ $t('loginList1') }}</p>
              <br />
              <li class="login__box-fontStyle">✔️ &nbsp;{{ $t('loginListBold2') }}</li>
              <p class="login__box-fontStyle-small">{{ $t('loginList2') }}</p>
              <br />
              <li class="login__box-fontStyle">✔️ &nbsp;{{ $t('loginListBold3') }}</li>
              <p class="login__box-fontStyle-small">{{ $t('loginList3') }}</p>
            </ul>
            <br /> 
          </div>-->
          <div
            class="tile is-5 is-parent is-vertical login__box-size"
            style="border: none; border-radius: 4px;"
          >
            <div style="display: flex; justify-content: space-between; align-items: center;">
              <div style="width: 100px">
                <img src="../.././public/img/YourCharlie_logo.png" />
              </div>
              <MenuLang class="login__box-lang" />
            </div>
            <span
              class="login__logo-position"
              style="padding-top: 25px !important; padding-bottom: 25px !important;"
            >
              <div>
                <h1>{{ $t('register') }}</h1>
              </div>
            </span><br />
            <div
              style="background-color: white;position: relative;"
              class="has-text-centered"
            >
              <div v-if="!loading">
                <div>
                  <form @submit.prevent="submit">
                    <b-button
                      size="is-default"
                      style="color: #161032; border: 1px solid #161032 !important"
                      expanded
                      id="googleSign"
                      class="mb-2"
                      @click.native="googleSignUp"
                    ><img
                        style="padding: 15px; vertical-align: middle"
                        alt="Getinshoot logo"
                        src="../../public/img/google.png"
                      />{{ $t('googleRegister') }}</b-button>

                    <div style="
              width: 100%;
              height: 15px;
              border-bottom: 1px solid #f3f5f6;
              text-align: center;
            ">
                      <span style="
                font-size: 10px;
                color: #161032;
                background-color: white;
                padding: 0 10px;
              ">
                        {{ $t('or') }}
                      </span>
                    </div>
                    <br />

                    <p
                      v-if="errors && errors.length > 0"
                      class="notification"
                      style="
              font-size: 12px;
              font-weight: bold;
              border: 1px solid #f14668;
              background-color: white;
              color: #f14668 !important;
            "
                    >
                      <span
                        v-for="error in errors"
                        :key="error"
                        class="div-danger"
                      >
                        {{ error }}
                      </span>
                    </p>

                    <b-field
                      :label="$t('email')"
                      :label-position="labelPosition"
                    >
                      <b-input
                        class="inputC"
                        v-model="email"
                        type="email"
                        size="is-small"
                        required
                      />
                    </b-field>
                    <br />
                    <b-field
                      :label="$t('password')"
                      :label-position="labelPosition"
                    >
                      <b-input
                        class="inputC"
                        v-model="password"
                        ref="password"
                        password-reveal
                        type="password"
                        size="is-small"
                        required
                      />
                    </b-field>
                    <br />
                    <b-field
                      :label="$t('passwordRepeat')"
                      :label-position="labelPosition"
                    >
                      <b-input
                        class="inputC"
                        v-model="passwordRepeat"
                        ref="passwordRepeat"
                        password-reveal
                        type="password"
                        size="is-small"
                        required
                      />
                    </b-field>

                    <div class="registerGCU">
                      {{ $t('registerGCU') }}&nbsp;
                      <div v-if="$i18n.locale == 'es'">
                        <a
                          target="_blank"
                          href="https://www.yourcharlie.com/cgu-charlie/?lang=es"
                        >
                          {{ $t('GCU') }}
                        </a>
                        {{ $t('registerGCU2') }}
                        <a
                          target="_blank"
                          href="https://www.yourcharlie.com/politica-de-privacidad/?lang=es"
                        >
                          {{ $t('PDC') }}
                        </a>
                      </div>
                      <div v-if="$i18n.locale == 'en'">
                        <a
                          target="_blank"
                          href="https://www.yourcharlie.com/cgu-charlie/"
                        >
                          {{ $t('GCU') }}
                        </a>
                        {{ $t('registerGCU2') }}
                        <a
                          target="_blank"
                          href="https://www.yourcharlie.com/privacy-policy/"
                        >
                          {{ $t('PDC') }}
                        </a>
                      </div>
                      <div v-if="$i18n.locale == 'fr'">
                        <a
                          target="_blank"
                          href="https://www.yourcharlie.com/cgu/?lang=fr"
                        >
                          {{ $t('GCU') }}
                        </a>{{ $t('registerGCU2') }}<a
                          target="_blank"
                          href="https://www.yourcharlie.com/politique-de-confidentialite/?lang=fr"
                        >
                          {{ $t('PDC') }}
                        </a>
                      </div>
                    </div>
                    <br />
                    <div class="text-center">
                      <button
                        class="button-3d"
                        native-type="submit"
                        id="inscription"
                      >
                        {{ $t('createAccount') }}
                      </button>
                    </div>
                  </form>
                </div>

                <br />

                <router-link to="/login">
                  <span
                    style="cursor: pointer; font-size: 12px !important; font-weight: bold"
                    id="connexion"
                  >
                    {{ $t('alreadyRegistered') }}
                  </span>
                </router-link>

                <br />
              </div>
              <b-loading
                :is-full-page="false"
                v-model="loading"
                :can-cancel="false"
                style="height: fit-content"
              >
                <div
                  class="charlie__message charlie__message_A"
                  style="margin-top: 15px"
                >
                  <div class="is-flex is-flex-direction-column m-4 p-3 registerMessage">
                    <span style="text-align: left; margin-left: 10px; margin-top: 5px;">{{ $t('serviceConnection')
                    }}</span>
                    <b-icon
                      pack="fas"
                      icon="spinner"
                      size="is-small"
                      custom-class="fa-spin"
                      style="--fa-animation-duration:0.8s; margin-left: 10px;"
                    >
                    </b-icon>
                  </div>
                </div>
              </b-loading>
            </div>

            <br />
          </div>
        </div>
      </div>
      <br />
    </div>
  </div>
</template>


<script>
import MenuLang from '@/components/menuLang.vue';
import { mapState } from 'vuex';

/**
 * @name Login
 * @desc Component for user login and registration
 */
export default {
  name: 'Login',
  props: {
    /**
     * Indicates if the user is already registered
     * @type {Boolean}
     * @default false
     */
    registered: {
      type: Boolean,
      default: false
    },
    /**
     * Determines the route to return after login or registration
     * @type {String}
     * @default 'root'
     */
    returnType: {
      type: String,
      default: 'root'
    }
  },
  components: { MenuLang },
  data() {
    return {
      /**
       * @type {boolean}
       * @desc Indicates if the component is in a loading state
       */
      loading: true,
      /**
       * @type {string}
       * @desc The position of the label in the form
       */
      labelPosition: 'on-border',
      /**
       * @type {string}
       * @desc The user's email address
       */
      email: '',
      /**
       * @type {string}
       * @desc The user's password
       */
      password: '',
      /**
       * @type {string}
       * @desc The user's password confirmation
       */
      confirmPassword: '',
      /**
       * @type {string}
       * @desc The repeated password for validation
       */
      passwordRepeat: '',
      /**
       * @type {Array}
       * @desc An array to store any errors that occur
       */
      errors: [],
      /**
       * @type {string}
       * @desc The method used for user authentication
       */
      method: null
    };
  },

  computed: mapState(['loaded']),
  mounted() {
    if (this.$store.state.loaded && !this.$store.getters.activeUser) {
      this.loading = false;
    }
  },

  methods: {
    /**
     * @name submit
     * @desc Handles form submission for user registration
     * @param {Event} event - The form submission event
     */
    submit(event) {
      console.log('submit ?');
      this.errors = [];
      if (this.passwordRepeat !== this.password) {
        this.errors.push(this.$t('passwordsDontMatch'));
      }
      if (!this.email) {
        this.errors.push(this.$t('invalidEmail'));
      }
      if (!this.password) {
        this.errors.push(this.$t('passwordRequirement'));
      }

      if (!this.errors.length) {
        this.loading = true;
        event.target.classList.add('was-validated');
        this.method = 'email';
        this.$store
          .dispatch('signUpWithEmail', {
            email: this.email,
            pwd: this.password
          })
          .then(() => {
            this.handleResult();
          })
          .catch(this.catchError);
      }
    },

    /**
     * @name googleSignUp
     * @desc Handles Google sign up process
     */
    googleSignUp() {
      this.loading = true;
      this.method = 'google';
      this.$store
        .dispatch('signInWithGoogle')
        .then(() => {
          this.handleResult();
        })
        .catch(this.catchError);
    },

    /**
     * @name handleResult
     * @desc Handles the result of user registration or login
     */
    handleResult() {
      switch (this.returnType) {
        case 'root':
          if (
            this.$router.currentRoute.path !== '/' &&
            this.$router.currentRoute.path !== '/inscription'
          ) {
            this.$router.push('/');
          }
          break;
        case 'profile':
          this.$router.push('/profile');
          break;
        default:
          this.$emit(this.returnType);
      }
    },

    /**
     * @name catchError
     * @desc Handles errors during user registration or login
     * @param {Error} error - The error object
     */
    catchError(error) {
      this.loading = false;
      console.error(error);
      this.$store.dispatch('autoSignIn', null);
      if (error.code === 'auth/user-not-found') {
        this.errors.push(this.$t('invalidEmail'));
      } else if (error.code === 'auth/wrong-password') {
        this.errors.push(this.$t('invalidPassword'));
      } else if (error.code === 'auth/email-already-in-use') {
        this.errors.push(this.$t('alreadyUsedEmail'));
      } else if (error.code === 'auth/weak-password') {
        this.errors.push(this.$t('passwordRequirement'));
      } else if (error.code === 'auth/invalid-email') {
        this.errors.push(this.$t('invalidEmail'));
      } else if (error.code === 'auth/network-request-failed') {
        this.errors.push(this.$t('connectionError'));
      } else if (error.code === 'auth/popup-closed-by-user') {
        this.errors.push(this.$t('connectionCancelled'));
      } else if (error.code == 'auth/popup-blocked') {
        this.errors.push(this.$t('blockedPopup'));
      } else {
        this.errors.push(this.$t('unknownError'));
      }
    }
  },

  watch: {
    loaded(newValue, oldValue) {
      if (newValue && this.$store.getters.activeUser) {
        if (this.method && this.method != null) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'registration',
            authentication_method: this.method,
            user_id: this.$store.getters.activeUser.id
          });
        }
        this.$router.push({ name: 'dashboard' });
      } else if (newValue && !this.$store.getters.activeUser) {
        this.loading = false;
      }
    }
  }
};
</script>


<style src="./../components/index.css"></style>

<style scoped>
.container {
  background-color: #e7e1ff !important;
}

.inputC {
  border-bottom: 1px solid #cecece !important;
}
</style>
