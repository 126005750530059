<template>
  <div>
    <div style="padding-bottom: 20px;">
      <a @click.prevent="showHideDownloadOptions()">
        <button
          class="button-3d-small-revert"
          type="is-danger"
        >
          <b-icon
            pack="fas"
            icon="download"
            size="is-small"
            style="height: 14px"
          >
          </b-icon>&nbsp;{{$t('download')}}
        </button>
      </a>

    </div>
    <div v-if="showDownloadOptions">
      <div style="padding: 2px">
        <b-button
          type="is-light"
          size="is-small"
          pack="fas"
          icon-left="square"
          @click="download(squareUrl, 'charlie_square_video.mp4')"
        >
          {{ $t('square') }}
        </b-button>

      </div>
      <div style="padding: 2px">
        <b-button
          type="is-light"
          size="is-small"
          pack="fas"
          icon-left="mobile"
          @click="download(verticalUrl, 'charlie_vertical_video.mp4')"
        >
          {{ $t('vertical') }}
        </b-button>
      </div>

      <div style="padding: 2px">

        <b-button
          type="is-light"
          size="is-small"
          pack="fas"
          icon-left="file-pdf"
          @click="download(termsUrl, 'charlie_rightsCession.pdf')"
        >
          {{ $t('imageRights') }}
        </b-button>
      </div>

      <div style="padding: 2px">

        <b-button
          type="is-light"
          size="is-small"
          pack="fas"
          icon-left="download"
          @click="downloadOriginals()"
        >
          {{ $t('downloadRush') }}
        </b-button>
      </div>
    </div>

    <br />
  </div>
</template>

<script>
import { saveAs } from 'file-saver';
import JsZip from 'jszip';
import JSZipUtils from 'jszip-utils';

/**
 * @name downloadTestimonial
 * @desc Component for downloading testimonials
 */

export default {
  name: 'downloadTestimonial',
  props: {
    /**
     * URL for square video
     */
    squareUrl: String,
    /**
     * URL for vertical video
     */
    verticalUrl: String,
    /**
     * URL for terms document
     */
    termsUrl: String,
    /**
     * List of answers
     */
    answers: []
  },
  data() {
    return {
      /**
       * @desc Flag to show/hide download options
       * @type {Boolean}
       */
      showDownloadOptions: false
    };
  },
  methods: {
    /**
     * @name downloadOriginals
     * @desc Downloads original files as a zip
     */
    downloadOriginals() {
      const loading = this.$buefy.loading.open({
        message: this.$t('downloading')
      });
      const urls = this.answers.map((answer) => answer.media.url);
      console.log(this.answers);
      if (urls && urls.length > 0) {
        const zip = new JsZip();
        let count = 0;
        const zipFilename = 'charlie_' + new Date().toISOString() + '.zip';
        urls.forEach(async function (url) {
          const urlArr = url.replaceAll('%2F', '/').split('/');
          const filename = urlArr[urlArr.length - 1].split('?')[0];
          try {
            const file = await JSZipUtils.getBinaryContent(url);
            zip.file(filename, file, { binary: true });
            count++;
            if (count === urls.length) {
              zip.generateAsync({ type: 'blob' }).then(function (content) {
                saveAs(content, zipFilename);
                loading.close();
              });
            }
          } catch (err) {
            console.error(err);
            this.$buefy.notification.open({
              message: this.$t('downloadingError'),
              type: 'is-danger',
              position: 'is-bottom-right',
              duration: 5000
            });
          }
        });
      } else {
        loading.close();
        this.$buefy.notification.open({
          message: this.$t('noFile'),
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 5000
        });
      }
    },
    /**
     * @name showHideDownloadOptions
     * @desc Toggles visibility of download options
     */
    showHideDownloadOptions() {
      this.showDownloadOptions = !this.showDownloadOptions;
    },
    /**
     * @name download
     * @desc Downloads a file from a given URL
     * @param {string} url - The URL of the file to download
     * @param {string} filename - The name to save the file as
     */
    download(url, filename) {
      if (!url) {
        console.error('URL is not provided');
        return;
      }

      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          saveAs(blob, filename || 'download.mp4');
        })
        .catch((error) => {
          console.error('Error downloading the file:', error);
        });
    }
  }
};
</script>


<style src="./../components/index.css">
</style>
