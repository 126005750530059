
<template>
  <section>

    <b-table
      :data="dataSponsorships"
      :hoverable="isHoverable"
      paginated
      backend-pagination
      :total="total"
      :per-page="perPage"
      @page-change="onPageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      :loading="loading"
      backend-sorting
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      @sort="onSort"
    >
      <b-table-column
        field="createdAt"
        label="Date"
        sortable
        v-slot="props"
      >
        <span
          class="tag"
          style="background-color: #fbfcfb"
        >
          {{ props.row.createdAt | moment }}
        </span>
      </b-table-column>
      <b-table-column
        field="complete"
        label="Status"
        :searchable="true"
        sortable
        v-slot="props"
      >
        {{ props.row.complete ? '🟢' : '🔴' }}
      </b-table-column>
      <b-table-column
        field="parrain"
        label="Sponsor"
        v-slot="props"
      >
        {{ props.row.sender.email }}
      </b-table-column>
      <b-table-column
        field="filleul"
        label="Target"
        v-slot="props"
      >
        {{ props.row.targetName }} {{ props.row.targetSurname }}
      </b-table-column>
      <b-table-column
        field="filleul"
        label="Email"
        v-slot="props"
      >
        {{ props.row.email }}
      </b-table-column>
    </b-table>

  </section>
</template>
  
<script>
import moment from 'moment';

/**
 * @name allSponsoring
 * @desc Component for managing sponsorships in admin panel
 */

export default {
  name: 'allSponsoring',
  components: {},
  filters: {
    /**
     * @name moment
     * @desc Filter to format dates using moment.js with French locale
     * @param {Date} date - The date to format
     * @returns {String} Formatted date string
     */
    moment: function (date) {
      moment.locale('fr');
      return moment(date).format('LLL');
    }
  },
  data: () => {
    return {
      /**
       * @type {Array}
       * @desc Array of sponsorship data
       */
      dataSponsorships: [],
      /**
       * @type {Boolean}
       * @desc Indicates if table rows should be hoverable
       */
      isHoverable: true,

      /**
       * @type {String}
       * @desc Field to sort by
       */
      sortField: 'createdAt',
      /**
       * @type {String}
       * @desc Order to sort (asc or desc)
       */
      sortOrder: 'desc',
      /**
       * @type {String}
       * @desc Default sort order
       */
      defaultSortOrder: 'desc',
      /**
       * @type {Number}
       * @desc Current page number
       */
      page: 1,
      /**
       * @type {Number}
       * @desc Number of items per page
       */
      perPage: 20,
      /**
       * @type {Number}
       * @desc Total number of items
       */
      total: 0,
      /**
       * @type {Boolean}
       * @desc Indicates if data is loading
       */
      loading: false
    };
  },
  /**
   * @name beforeMount
   * @desc Lifecycle hook that is called before the component is mounted
   */
  beforeMount() {
    this.refreshList();
  },
  methods: {
    /**
     * @name refreshList
     * @desc Fetches and refreshes the list of sponsorships
     */
    refreshList() {
      this.loading = true;
      this.$store
        .dispatch('getAdminSponsorshipsPaginated', {
          page: this.page - 1,
          limit: this.perPage,
          sort: this.sortField,
          order: this.sortOrder.toUpperCase()
        })
        .then((response) => {
          this.dataSponsorships = response.data[0].sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          this.total = response.data[1];
          this.loading = false;
        });
    },
    /**
     * @name onPageChange
     * @desc Handles page changes
     * @param {Number} page - The new page number
     */
    onPageChange(page) {
      this.page = page;
      this.refreshList();
    },
    /**
     * @name onSort
     * @desc Handles sorting of the sponsorship list
     * @param {String} field - The field to sort by
     * @param {String} order - The order to sort (asc or desc)
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.refreshList();
    }
  }
};
</script>
  
  <style src="../index.css">
</style>