<template>
  <div>
    <div class="tile is-ancestor">
      <div
        class="tile is-child box is-6"
        style="box-shadow: none"
      >
        <section>
          <b-field
            horizontal
            :label="$t('fName')"
          >
            <input
              class="inputC"
              :name="$t('fName')"
              expanded
              v-model="userInfos.surname"
            />
          </b-field>

          <b-field
            horizontal
            :label="$t('lName')"
          >
            <input
              class="inputC"
              :name="$t('lName')"
              expanded
              v-model="userInfos.name"
            />
          </b-field>

          <b-field
            horizontal
            :label="$t('entreprise')"
          >
            <input
              class="inputC"
              :name="$t('entreprise')"
              expanded
              v-model="userInfos.business"
            />
          </b-field>
          <b-field
            horizontal
            :label="$t('phone')"
          >
            <input
              class="inputC"
              :name="$t('phone')"
              expanded
              v-model="userInfos.phoneNumber"
            />
          </b-field>
          <b-field
            horizontal
            :label="$t('email')"
          >
            <input
              :disabled="!showPassword"
              class="inputC"
              :name="$t('email')"
              expanded
              v-model="userInfos.email"
            />
          </b-field>
          <b-field
            horizontal
            :label="$t('password')"
            v-if="showPassword"
          >
            <input
              ref="password"
              class="inputC"
              type="password"
              :name="$t('password')"
              expanded
              v-model="userInfos.password"
            />
          </b-field>
        </section>
      </div>
      <div
        class="tile box"
        style="box-shadow: none; margin-left: 20px !important"
      >
        <div class="tile">
          <section>
            <!-- <b-field label="Typo">
              <b-select placeholder="Select a character" rounded>
                <option value="flint">Flint</option>
                <option value="silver">Silver</option>
              </b-select>
            </b-field>
            <br /><br /> -->
            <b-field :label="$t('color')">
              <b-colorpicker
                position="is-bottom-right"
                :append-to-body="true"
                v-model="userInfos.color"
                :value="userInfos.color"
                v-on:input="updateColor"
              >
                <template #footer="{}">
                  <div class="colorpicker-fields">
                    <b-field :message="!validColor ? $t('invalidColor') : ''">
                      <input
                        :class="!validColor ? 'is-danger-custom' : ''"
                        ref="input"
                        type="text"
                        v-model="userInfos.color"
                        size="is-small"
                        aria-label="Couleur"
                      />
                    </b-field>
                  </div>
                </template>
              </b-colorpicker>
            </b-field>
            <br>
            <button
              class="button-3d-revert"
              @click="saveInfos()"
              :disabled="!valid"
            >
              {{ $t('save') }}
            </button>
            <br /><br />
            <button
              v-if="showPassword"
              class="button-3d-revert"
              @click="openResetPasswordModal()"
            >
              {{ $t('resetPwd') }}
            </button>
          </section>
        </div>

        <!-- <div class="tile">
          <section>
            <strong>Logo</strong>
            <br />
            <br />
            <figure class="image is-128x128">
              <img src="https://bulma.io/images/placeholders/128x128.png" />
            </figure>
          </section>
        </div> -->
      </div>

    </div>

    <div
      v-if="$store.getters.activeUser.source == 'appsumo' && userCodeInfos !== null"
      class="tile box"
      style="background-color: #FBFCFB; position:relative"
    >
      <div
        class="tile is-vertical"
        style="border: 2px solid #F0F0F0; padding:20px; color: #161032; text-align: center;"
      >
        <div style="display: flex; align-items: center; justify-content: center; gap: 15px; margin-bottom: 15px;">
          <div
            class="appsumo-logo"
            style="width: 120px;"
          >
            <img src="../.././public/img/YourCharlie_logo.png" />
          </div>
          <div
            class="heart"
            style="font-size: 30px;"
          >
            💜
          </div>
          <div
            class="appsumo-logo"
            style="width: 120px;"
          >
            <img src="../.././public/img/as-logo-dark.png" />
          </div>
        </div>

        <span style="font-weight: 500;">{{ $t("dealSentence") }}</span>
        <div>
          {{ $t("codeRedeemed") }}: {{ userCodeInfos.redeemedCodes }} <span style="color:#828282;">&nbsp;&nbsp;|&nbsp;&nbsp;</span> {{ $t("monthCredits") }}: {{
              userCodeInfos.redeemedCodesCredits }}</div>

      </div>

      <div
        class="tile is-vertical"
        style="padding:20px; font-size: 14px;"
      > {{ $t("purchaseSentence") }}

        <div
          class="tile"
          style="margin-top: 25px; gap:10px"
        >

          <div> <b-field>
              <b-input
                class="inputC"
                v-model="appsumoCode"
                ref="appsumoCode"
                placeholder="AppSumo Code"
                type="text"
                size="is-small"
                required
              />
            </b-field></div>
          <div> <button
              class="button-3d-small"
              style="width: 150px"
              :disabled="!appsumoCode"
              @click="stackCode(appsumoCode)"
            >
              {{ $t('applyCode') }}
            </button> </div>

        </div>
      </div>
      <b-loading
        :active.sync="loadingCode"
        :is-full-page="false"
      ></b-loading>
    </div>
    <div v-else></div>
    <br>

    <div class="tile is parent">
      <div class="tile is-7">
        <PremiumVue />
      </div>
      <div class="tile is-vertical">
        <div class="tile is-child ">
          <p style="font-size: 30px; font-weight: 600; text-align: center;">API
            <a
              href="https://dashboard.yourcharlie.com/api"
              target="_blank"
            ><b-tooltip
                position="is-right"
                multilined
                type="is-primary is-light"
              ><template v-slot:content>{{$t('tooltipApi')}}</template>
                <img
                  src="/img/sos.png"
                  class="sizeSos"
                />
              </b-tooltip>
            </a>
          </p>

          <p>{{ $t('apiSentence1') }}<br>
            <b>{{ $t('apiSentence2') }}</b> {{ $t('apiSentence3') }}
            {{ $t('apiSentence4') }}
          </p>
        </div>
        <div
          class="tile is-vertical"
          style="margin: auto 0 !important"
        >
          <div
            v-if="userInfos.hasApiKey"
            style="display:flex;flex-direction: column;"
          >
            <div>
              <input
                v-if="!apiKey"
                value="*************"
                readonly
                style="border: 1px solid #828282; height: 30px; width: 85%"
              >
              <input
                v-else
                v-model="apiKey"
                readonly
                style="border: 1px solid #828282; height: 30px; width: 85%"
              >
              <b-button
                v-if="apiKey"
                size="is-small"
                icon-left="copy"
                @click="copyApiKey()"
              ></b-button>
              <b-button
                size="is-small"
                @click="refreshApiKey()"
                type="is-dark"
                icon-left="arrows-rotate"
              >re-générer une clé</b-button>
            </div>
          </div>
          <div v-else>
            <b-button
              @click="getApiKey()"
              type="is-success"
              expanded
            >générer une clé</b-button>

          </div>
        </div>

        <br>

      </div>
      <b-modal
        :active.sync="isOpenResetPasswordModal"
        :has-modal-card="true"
      >
        <div
          class="card box"
          style="background-color: white !important"
        >
          <p class="title">{{ $t('resetPwdWarning') }}</p>
          <button
            class="button-3d-revert"
            @click="sendMailResetPassword()"
          >
            {{ $t('resetPwd') }}
          </button>
        </div>
      </b-modal>
    </div>

  </div>
</template>

<script>
</script>

<script>
import PremiumVue from './../components/premiumVue.vue';
export default {
  name: 'profilDetail',
  components: { PremiumVue },
  data() {
    return {
      /**
       * @type {boolean}
       * @desc Indicates if the code is loading
       */
      loadingCode: false,
      /**
       * @type {boolean}
       * @desc Indicates if the data is valid
       */
      valid: false,
      /**
       * @type {string} T
       * @desc he AppSumo code
       */
      appsumoCode: '',
      /**
       * @type {object}
       * @desc User information
       */
      userInfos: {
        name: '',
        surname: '',
        business: '',
        phoneNumber: '',
        email: '',
        color: '',
        password: null,
        hasApiKey: false
      },
      apiKey: null,
      isOpenResetPasswordModal: false,
      /**
       * @type {object}
       * @desc User code information
       */
      userCodeInfos: null,
      /**
       * @type {boolean}
       * @desc Indicates if password is shown
       */
      showPassword: false
    };
  },
  beforeMount() {
    const user = this.$store.getters.activeUser;
    this.userInfos = {
      name: user.name,
      surname: user.surname,
      business: user.business,
      phoneNumber: user.phoneNumber,
      email: user.email,
      logoUrl: '',
      color: user.color,
      password: null,
      hasApiKey: user.hasApiKey
    };
    this.$store.dispatch('getAuthProvider').then((provider) => {
      if (provider === 'password') {
        this.showPassword = true;
      } else {
        this.showPassword = false;
      }
    });
    this.getUserCodeInfos();
  },
  computed: {
    validColor: function () {
      return /^#([A-Fa-f0-9]{6})$/.test(this.userInfos.color);
    }
  },
  watch: {
    userInfos: {
      handler: function (val, oldVal) {
        const pattern = /^$|^\S+.*/;
        this.valid =
          val.name &&
          val.surname &&
          val.business &&
          val.phoneNumber &&
          val.name !== '' &&
          val.surname !== '' &&
          val.business !== '' &&
          val.phoneNumber !== '' &&
          pattern.test(val.name) &&
          pattern.test(val.surname) &&
          pattern.test(val.business) &&
          pattern.test(val.phoneNumber) &&
          this.validColor;
      },
      deep: true
    }
  },
  methods: {
    openResetPasswordModal() {
      this.isOpenResetPasswordModal = true;
    },
    sendMailResetPassword() {
      this.$store
        .dispatch('askResetWithEmail', this.userInfos)
        .then(() => {
          this.$buefy.notification.open({
            duration: 3000,
            message: this.$t('resetPwdMailSent'),
            position: 'is-bottom-right',
            style:
              'background-color: #12c477!important; color: white!important',
            hasIcon: true
          });
          this.isOpenResetPasswordModal = false;
        })
        .catch((error) => {
          this.$buefy.notification.open({
            duration: 3000,
            message: this.$t('unknownError'),
            position: 'is-bottom-right',
            style:
              'background-color: #f1054d!important; color: white!important',
            hasIcon: true
          });
        });
    },
    stackCode(code) {
      this.loadingCode = true;
      this.$store
        .dispatch('stackCode', { code: code })
        .then(() => {
          this.appsumoCode = null;
          this.getUserCodeInfos();
          this.$store.dispatch('refreshUser');
          this.$buefy.notification.open({
            duration: 3000,
            message: this.$t('codeStacked'),
            position: 'is-bottom-right',
            style:
              'background-color: #12c477!important; color: white!important',
            hasIcon: true
          });
          this.loadingCode = false;
        })
        .catch((error) => {
          this.loadingCode = false;
          this.appsumoCode = null;
          this.$buefy.notification.open({
            duration: 3000,
            message: this.$t('appsumoCodeError'),
            position: 'is-bottom-right',
            style:
              'background-color: #f1054d!important; color: white!important',
            hasIcon: true
          });
        });
    },
    getUserCodeInfos() {
      this.$store.dispatch('getUserCodeInfos').then((response) => {
        this.userCodeInfos = response.data;
      });
    },
    updateColor($event) {
      if (/^#([A-Fa-f0-9]{6})$/.test($event.toString('hex'))) {
        this.userInfos.color = $event.toString('hex');
        this.$buefy.notification.open({
          duration: 3000,
          message: this.$t('newColor'),
          position: 'is-bottom-right',
          style: 'background-color: #12c477!important; color: white!important',
          hasIcon: true
        });
      } else {
        this.$buefy.notification.open({
          duration: 3000,
          message: this.$t('invalidColor'),
          position: 'is-bottom-right',
          style: 'background-color: #f1054d!important; color: white!important',
          hasIcon: true
        });
      }
    },
    getApiKey() {
      this.$store.dispatch('getApiKey').then((response) => {
        this.apiKey = response.data;
        this.userInfos.hasApiKey = true;
      });
    },
    copyApiKey() {
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(this.apiKey);
        return;
      }
      navigator.clipboard.writeText(this.apiKey).then(
        () => {
          this.$buefy.toast.open({
            message: this.$t('Copié'),
            type: 'is-success',
            position: 'is-bottom-right'
          });
        },
        (err) => {
          console.error('Async: Could not copy text: ', err);
        }
      );
    },
    refreshApiKey() {
      this.$store.dispatch('refreshApiKey').then((response) => {
        this.apiKey = response.data;
        this.userInfos.hasApiKey = true;
      });
    },
    saveInfos() {
      if (!this.validColor) {
        this.$buefy.notification.open({
          duration: 3000,
          message: this.$t('invalidColor'),
          position: 'is-bottom-right',
          style: 'background-color: #f1054d!important; color: white!important',
          hasIcon: true
        });
        return;
      }
      if (
        this.userInfos.email !== this.$store.getters.activeUser.email &&
        (this.userInfos.password === null || this.userInfos.password === '')
      ) {
        this.$refs.password.focus();
        this.$buefy.notification.open({
          duration: 3000,
          message: this.$t('passwordRequired'),
          position: 'is-bottom-right',
          style: 'background-color: #f1054d!important; color: white!important',
          hasIcon: true
        });
        return;
      }
      this.$store
        .dispatch('updateCharlieUser', this.userInfos)
        .then(() => {
          this.$buefy.notification.open({
            duration: 3000,
            message: this.$t('infoRegistered'),
            position: 'is-bottom-right',
            style:
              'background-color: #12c477!important; color: white!important',
            hasIcon: true
          });
        })
        .catch((error) => {
          let message = this.$t('unknownError');
          if (error.code === 'auth/user-not-found') {
            message = this.$t('invalidEmail');
          } else if (error.code === 'auth/wrong-password') {
            message = this.$t('invalidPassword');
          } else if (error.code === 'auth/email-already-in-use') {
            message = this.$t('alreadyUsedEmail');
          } else if (error.code === 'auth/weak-password') {
            message = this.$t('passwordRequirement');
          } else if (error.code === 'auth/invalid-email') {
            message = this.$t('invalidEmail');
          }
          this.$buefy.notification.open({
            duration: 3000,
            message: message,
            position: 'is-bottom-right',
            style:
              'background-color: #f1054d!important; color: white!important',
            hasIcon: true
          });
        });
    }
  }
};
</script>
</script>

<style src="./index.css"></style>

 